import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import {
  InformatiiCont,
  SpecializarileMele,
  UltimulReview,
  VideoPrezentare,
} from "../../../components";
import HeaderSecond from "../../../components/Header/HeaderSecond";
import { UserContext } from "../../../providers/UserProvider";

const ContulMeu = () => {
  const [client, setClient] = useState({});
  const { user } = useContext(UserContext);
  useEffect(() => {
    setClient(user.user);
  }, []);
  return (
    <>
      <HeaderSecond />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-20 mb-10">
          <div>
            <div className="grid grid-cols-2 gap-5 lg:w-3/5">
              <div>
                <div className="my-auto">
                  <div className="mt-2 mb-1 lg:m-0">
                    <Link to="/dashboard/specialist/setari-cont">
                      <button className="w-full flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-gray-inactive-text bg-gray-inactive-bg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                        Date Cont
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div>
                <div className="my-auto">
                  <div className="mt-2 mb-1 lg:m-0">
                    <Link to="/dashboard/specialist/contul-meu">
                      <button className="w-full flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                        Contul Meu
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div>
            <InformatiiCont
              name={client.firstName + " " + client.lastName}
              avatarUrl={client.avatarUrl}
              reviewsCount={client.reviewsCount}
              reviewsAverage={client.reviewsAverage}
              specializationType={
                !client.specialistInfo
                  ? ""
                  : client.specialistInfo.specializationType
              }
              specializationName={
                !client.specialistInfo ? "" : client.specialistInfo.lastStudies
              }
              city={client.city}
              description={client.description}
            />
          </div>
          <div className="flex flex-col flex-nowrap h-full gap-5">
            <div className="grid grid-cols-1 2xl:grid-cols-2 gap-5">
              <div>
                <VideoPrezentare url={client.videoUrl} />
              </div>
              <div>
                <UltimulReview />
              </div>
            </div>
            <div className="h-full">
              <SpecializarileMele />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContulMeu;
