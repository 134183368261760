import React, { createRef, useEffect, useState } from "react";
import HeaderClient from "../../../components/Header/HeaderClient";
import avatar from "../../../assets/img/Elena_Ionescu.jpg";
import { Link } from "react-router-dom";
import { auth } from "../../../api";
import { getAppoiments } from "../../../api/getAppoiments.api";
import Moment from "moment";
import { addAngajat, getAngajati } from "../../../api/saveSettings.api";
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import { companyPayApi, getSumaDatorata } from "../../../api/stripe.api";
import { toast } from "react-toastify";

const Angajati = () => {
  const [showPopup, setShowPopup] = useState();
  const [idAngajat, setIdAngajat] = useState();
  const [angajatiArray, setAngajatiArray] = useState();
  const [filterByName, setFilterByName] = useState();
  const [filterByCity, setFilterByCity] = useState();
  const [filterByCode, setFilterByCode] = useState();
  const [priceToPay, setPriceToPay] = useState();
  const { user } = useContext(UserContext);

  const onChange = (e) => {
    setIdAngajat(e.target.value);
  };

  const handleSubmit = (e) => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        addAngajat(idToken, idAngajat, user.user.publicId).then((res) => {
          if (res.status == 200) {
            toast.success("Angajat adaugat cu succes!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Cod de angajat invalid!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      });
  };

  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getAngajati(idToken, user.user.publicId).then((res) => {
          setAngajatiArray(res.angajati);
        });
        getSumaDatorata(user.user.publicId, idToken).then((res) =>
          setPriceToPay(res.data.price)
        );
      });
  }, []);

  return (
    <>
      <HeaderClient />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="mb-3 block grid grid-cols-1 xl:grid-cols-7">
          <h4 className="col-span-1 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Filtreaza
          </h4>
          <h4 className="hidden 2xl:block col-span-4 2xl:col-span-3 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Angajatii companiei
          </h4>
        </div>
        <div className="2xl:grid  grid-cols-1 2xl:grid-cols-7 gap-0 2xl:gap-5 mb-10 text-sm">
          <div className="col-span-1">
            <div className="font-openSans text-secondary flex flex-col flex-nowrap font-bold">
              <div className="mb-3">
                <h4 className=" show-review md:hidden text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
                  <span className="md:hidden">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.96"
                      height="4.227"
                      viewBox="0 0 6.96 4.227"
                    >
                      <g
                        id="up-arrow-svgrepo-com"
                        transform="translate(6.96 4.227) rotate(180)"
                      >
                        <path
                          id="Path_283"
                          data-name="Path 283"
                          d="M5.684,4.009A.748.748,0,0,0,6.741,2.951L4.009.219a.749.749,0,0,0-1.058,0L.219,2.951A.748.748,0,0,0,1.276,4.009l2.2-2.2Z"
                          fill="#0b1e39"
                        />
                      </g>
                    </svg>
                  </span>
                </h4>
                <div
                  className={`bg-white rounded-xl ${
                    false === true ? "hidden" : ""
                  } md:flex flex-col p-4 shadow-lg`}
                >
                  <h3 className="text-dark-blue pb-2">Nume angajat</h3>
                  <div className="relative pb-2">
                    <input
                      onChange={(e) => setFilterByName(e.target.value)}
                      name="nume-specialist"
                      className="border-2 rounded-lg py-1 pl-10 w-full font-sans"
                    />
                    <svg
                      className="absolute top-2 left-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.918"
                      height="20.919"
                      viewBox="0 0 20.918 20.919"
                    >
                      <g id="magnifier" transform="translate(-0.001 0)">
                        <g
                          id="Group_145"
                          data-name="Group 145"
                          transform="translate(0.001 0)"
                        >
                          <path
                            id="Path_101"
                            data-name="Path 101"
                            d="M18.437,14.486a8.488,8.488,0,0,0-12-12A8.323,8.323,0,0,0,5.156,12.726a.357.357,0,0,1-.049.425L.777,17.482a1.978,1.978,0,0,0-.3,2.832l.132.131a1.978,1.978,0,0,0,2.832-.3l4.321-4.321a.362.362,0,0,1,.435-.058A8.323,8.323,0,0,0,18.437,14.486ZM8,12.919a6.272,6.272,0,1,1,8.87,0A6.279,6.279,0,0,1,8,12.919Z"
                            transform="translate(-0.001 0)"
                            fill="#0b1e39"
                          />
                          <g
                            id="Group_144"
                            data-name="Group 144"
                            transform="translate(7.665 3.397)"
                          >
                            <path
                              id="Path_102"
                              data-name="Path 102"
                              d="M114.628,54.992a.877.877,0,0,1-.806-1.218,5.521,5.521,0,0,1,7.228-2.93.876.876,0,1,1-.683,1.614,3.767,3.767,0,0,0-4.932,2A.876.876,0,0,1,114.628,54.992Z"
                              transform="translate(-113.752 -50.409)"
                              fill="#0b1e39"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <h3 className="text-dark-blue pb-2">Oras angajat</h3>
                  <div className="relative pb-2">
                    <input
                      onChange={(e) => setFilterByCity(e.target.value)}
                      name="nume-specialist"
                      className="border-2 rounded-lg py-1 pl-10 w-full font-sans"
                    />
                    <svg
                      className="absolute top-2 left-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.918"
                      height="20.919"
                      viewBox="0 0 20.918 20.919"
                    >
                      <g id="magnifier" transform="translate(-0.001 0)">
                        <g
                          id="Group_145"
                          data-name="Group 145"
                          transform="translate(0.001 0)"
                        >
                          <path
                            id="Path_101"
                            data-name="Path 101"
                            d="M18.437,14.486a8.488,8.488,0,0,0-12-12A8.323,8.323,0,0,0,5.156,12.726a.357.357,0,0,1-.049.425L.777,17.482a1.978,1.978,0,0,0-.3,2.832l.132.131a1.978,1.978,0,0,0,2.832-.3l4.321-4.321a.362.362,0,0,1,.435-.058A8.323,8.323,0,0,0,18.437,14.486ZM8,12.919a6.272,6.272,0,1,1,8.87,0A6.279,6.279,0,0,1,8,12.919Z"
                            transform="translate(-0.001 0)"
                            fill="#0b1e39"
                          />
                          <g
                            id="Group_144"
                            data-name="Group 144"
                            transform="translate(7.665 3.397)"
                          >
                            <path
                              id="Path_102"
                              data-name="Path 102"
                              d="M114.628,54.992a.877.877,0,0,1-.806-1.218,5.521,5.521,0,0,1,7.228-2.93.876.876,0,1,1-.683,1.614,3.767,3.767,0,0,0-4.932,2A.876.876,0,0,1,114.628,54.992Z"
                              transform="translate(-113.752 -50.409)"
                              fill="#0b1e39"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <h3 className="text-dark-blue pb-2">Cod angajat</h3>
                  <div className="relative pb-2">
                    <input
                      onChange={(e) => setFilterByCode(e.target.value)}
                      name="nume-specialist"
                      className="border-2 rounded-lg py-1 pl-10 w-full font-sans"
                    />
                    <svg
                      className="absolute top-2 left-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.918"
                      height="20.919"
                      viewBox="0 0 20.918 20.919"
                    >
                      <g id="magnifier" transform="translate(-0.001 0)">
                        <g
                          id="Group_145"
                          data-name="Group 145"
                          transform="translate(0.001 0)"
                        >
                          <path
                            id="Path_101"
                            data-name="Path 101"
                            d="M18.437,14.486a8.488,8.488,0,0,0-12-12A8.323,8.323,0,0,0,5.156,12.726a.357.357,0,0,1-.049.425L.777,17.482a1.978,1.978,0,0,0-.3,2.832l.132.131a1.978,1.978,0,0,0,2.832-.3l4.321-4.321a.362.362,0,0,1,.435-.058A8.323,8.323,0,0,0,18.437,14.486ZM8,12.919a6.272,6.272,0,1,1,8.87,0A6.279,6.279,0,0,1,8,12.919Z"
                            transform="translate(-0.001 0)"
                            fill="#0b1e39"
                          />
                          <g
                            id="Group_144"
                            data-name="Group 144"
                            transform="translate(7.665 3.397)"
                          >
                            <path
                              id="Path_102"
                              data-name="Path 102"
                              d="M114.628,54.992a.877.877,0,0,1-.806-1.218,5.521,5.521,0,0,1,7.228-2.93.876.876,0,1,1-.683,1.614,3.767,3.767,0,0,0-4.932,2A.876.876,0,0,1,114.628,54.992Z"
                              transform="translate(-113.752 -50.409)"
                              fill="#0b1e39"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <h4 className="col-span-1 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
              Adauga angajat
            </h4>
            <div className=" bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <div className="left  font-bold flex flex-col justify-between  md:flex-row 2xl:flex-col gap-2 sm:gap-5 ">
                  <p>Cod angajat</p>
                  <input
                    onChange={onChange}
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="w-full flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                  >
                    Adauga
                  </button>
                </div>
              </div>
            </div>
            <h4 className=" mt-5 col-span-1 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
              Suma datorata
            </h4>
            <div className=" bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <div className="left  font-bold flex flex-col justify-between  md:flex-row 2xl:flex-col gap-2 sm:gap-5 ">
                  <p>Aveti de platit:</p>
                  <p>{priceToPay ? priceToPay : "0"} RON</p>

                  <Link to="/dashboard/client/plata-specialisti">
                    <button
                      type="button"
                      className="w-full flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                    >
                      Plateste
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className=" pt-5 2xl:pt-0 h-auto col-span-4 2xl:col-span-6 flex flex-col  items-start gap-4">
            <ul className="hidden sm:grid grid-cols-12 w-full bg-gray-inactive-bg text-dark-blue font-ralewayBold text-xs py-3.5 pl-3 sm:pl-9 pr-2">
              <li className="col-span-1">NR.</li>
              <li className="col-span-2">NUME</li>
              <li className="col-span-2">ORAS</li>
              <li className="col-span-2">COD UTILIZATOR</li>
              <li className="col-span-2">EMAIL</li>
              <li className="col-span-1">VEZI</li>
            </ul>
            {!angajatiArray
              ? ""
              : angajatiArray
                  .filter((sp) => {
                    var name = true;
                    var city = true;
                    var code = true;
                    if (filterByName) {
                      name = (sp.lastName + " " + sp.firstName)
                        .toLowerCase()
                        .includes(filterByName.toLowerCase());
                    }
                    if (filterByCity) {
                      city = sp.city
                        .toLowerCase()
                        .includes(filterByCity.toLowerCase());
                    }
                    if (filterByCode) {
                      code = sp.publicId
                        .toLowerCase()
                        .includes(filterByCode.toLowerCase());
                    }

                    return name && code && city;
                  })
                  .map((ang, index) => (
                    <>
                      <ul className=" hidden sm:grid grid-cols-12 w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                        <li className="col-span-1">{index + 1}.</li>
                        <li className="col-span-2">
                          {ang.firstName + " " + ang.lastName}
                        </li>
                        <li className="col-span-2">{ang.city}</li>
                        <li className="col-span-2">{ang.publicId}</li>
                        <li className="col-span-2">{ang.email}</li>
                        <li className="col-span-1">
                          <Link
                            to={
                              "/dashboard/client/vezi-angajat/" + ang.publicId
                            }
                          >
                            VEZI
                          </Link>
                        </li>
                      </ul>
                      <div className=" flex flex-col sm:hidden gap-1  w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow-lg bg-white overflow-hidden mt-3">
                        <div className=" grid grid-cols-2">
                          <span className="font-ralewayBold">NUMĂR:</span>
                          {index + 1}.
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="font-ralewayBold">NUME:</span>
                          {ang.firstName + " " + ang.lastName}
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="font-ralewayBold">ORAS:</span>
                          {ang.city}
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="font-ralewayBold">
                            COD UTILIZATOR
                          </span>
                          {ang.publicId}
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="font-ralewayBold">EMAIL:</span>
                          {ang.email}
                        </div>
                        <div className="col-span-1">
                          <Link
                            to={
                              "/dashboard/client/vezi-angajat/" + ang.publicId
                            }
                            className="mt-2 whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm px-6 py-2 border border-primary text-secondary rounded-full shadow-lg duration-300  hover:bg-primary hover:text-white"
                          >
                            Vezi Profil
                          </Link>
                        </div>
                      </div>
                    </>
                  ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Angajati;
