import React from "react";
import ReactDOM from "react-dom";

const Card = (props) => {
  return (
    <>
      <div>
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="text-center font-openSans font-bold text-secondary">
              <div>
                <p className="text-5xl">{props.value}</p>
              </div>
              <div>
                <p className="text-base">{props.title}</p>
              </div>
              <div>
                <p className="text-xs text-green-stats">{props.stats}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
