import axios from "axios";

import { HOST, PORT } from "../utils/Constants";

export const sendMail = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/mail/sendlead`,
      data
    );
    const response = res.data;
    return response;
  } catch (err) {
    return err.response;
  }
};
