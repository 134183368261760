import React, { useContext, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { auth } from "../../api";
import { addClientToAppointment } from "../../api/createAppoinment";
import { createInvoice } from "../../api/stripe.api";
import { HeaderClient } from "../../components";
import { UserContext } from "../../providers/UserProvider";

const SuccesPay = () => {
  const { appId } = useParams();
  const { loc } = useParams();
  const { user } = useContext(UserContext);
  const [sp, setSp] = useSearchParams();
  const session_id = sp.get("session_id");
  const stripeId = sp.get("sp_id");
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        var type;
        if (user.user.companyId) {
          type = "company";
        } else {
          type = "personal";
        }

        const city = user.user.city;
        const line1 =
          "Str. " +
          user.user.street +
          ", Nr. " +
          user.user.streetNr +
          ", Bl. " +
          user.user.building +
          ", Sc. " +
          user.user.staircase +
          ", Ap. " +
          user.user.apartment;
        const region = user.user.region;
        const country = "RO";

        addClientToAppointment(
          loc,
          user.user.firstName,
          appId,
          idToken,
          type,
          user.user.companyId,
          session_id,
          city,
          line1,
          region,
          country,
          stripeId
        ).then((res) => {
          if (res.status == 200) {
            toast.success("Te-ai programat cu succes!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.success(
              "A aparut o eroare la efectuarea programarii! Te rugam sa ne contactezi!",
              {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        });
      });
  }, []);

  return (
    <>
      <HeaderClient />
      <div className="min-h-screen flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8 font-raleway font-medium ">
        <div className="mt-8 sm:mx-auto md:w-full sm:max-w-2xl mx-6 w-11/12">
          <div className="bg-white overflow-hidden shadow rounded-lg m-auto mx-auto">
            <div className="px-4 py-5 sm:px-5 sm:py-5">
              <div className="AppWrapper">Ai facut plata cu succes!</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccesPay;
