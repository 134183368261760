import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { auth } from "../../api";
import { getAppoiments } from "../../api/getAppoiments.api";
import ViewMoreSedinta from "./ViewMoreSedinta";
import Moment from "moment";
import LoadingComponent from "../LoadingScreen/LoadingComponent";
import { ElementScrollController } from "@fullcalendar/react";

const TabelaIstoricSedinte = (props) => {
  const [sedinte, setSedinte] = useState();
  const [loading, setLoading] = useState(false);
  const [coloane, setColoane] = useState([]);
  const [locationFilterOnline, setLocationFilterOnline] = useState(
    props.locationFilterOnline
  );
  const [locationFilterOffline, setLocationFilterOffline] = useState(
    props.locationFilterOffline
  );
  var count = 0;

  useEffect(() => {
    setLoading(true);

    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getAppoiments(idToken).then((res) => {
          if (res.status == 200) {
            var filtered = res.data.appointments;
            if (
              (props.locationFilterOnline == true &&
                props.locationFilterOffline == true) ||
              (props.locationFilterOnline == false &&
                props.locationFilterOffline == false)
            ) {
            } else if (props.locationFilterOffline) {
              filtered = res.data.appointments.filter((app) => {
                return app.location == "offline";
              });
            } else if (props.locationFilterOnline) {
              filtered = res.data.appointments.filter((app) => {
                return app.location == "online";
              });
            }

            let sortedAsceding = filtered.sort((a, b) => {
              return Moment(a.date) - Moment(b.date);
            });
            setSedinte(res.data.appointments);

            setColoane(props.columns);

            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      });
  }, [props.locationFilterOffline, props.locationFilterOnline]);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div>
          <div
            className={
              "overflow-hidden " +
              (sedinte ? "shadow ring-1 ring-black ring-opacity-5" : "") +
              " md:rounded-b-lg font-raleway font-normal text-dark-blue text-sm"
            }
          >
            {sedinte ? (
              <table className="min-w-full divide-y divide-gray-300">
                {sedinte ? (
                  <thead className="bg-gray-50">
                    <tr>
                      {coloane.map((col) => (
                        <th
                          scope="col"
                          className={
                            (col.hidden == true
                              ? "hidden " + col.hiddenWidth + "table-cell "
                              : "") +
                            "py-3.5 pl-4 pr-3 text-left font-bold sm:pl-6"
                          }
                        >
                          {col.nume}
                        </th>
                      ))}
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                ) : (
                  "Nu exista sedinte in istoric"
                )}

                <tbody className="divide-y divide-gray-200 bg-white space-y-2">
                  {!sedinte
                    ? ""
                    : sedinte.map((sed) => {
                        if (Moment(sed.date).format() < Moment().format())
                          count++;

                        if (Moment(sed.date).format() < Moment().format())
                          return (
                            <>
                              <tr>
                                <td
                                  className={
                                    "whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6"
                                  }
                                >
                                  {count}
                                </td>
                                <td
                                  className={
                                    "whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6"
                                  }
                                >
                                  {Moment(sed.date).format("DD-MM-YY")}
                                </td>
                                <td
                                  className={
                                    "hidden lg:table-cell whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6"
                                  }
                                >
                                  {sed.startHour + " -"}
                                  <br></br>
                                  {sed.endHour}
                                </td>
                                <td
                                  className={
                                    "whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6"
                                  }
                                >
                                  {sed.clientFirstName}
                                </td>
                                {/* <td
                                  className={
                                    "hidden lg:table-cell whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6"
                                  }
                                >
                                  {sed.location}
                                </td> */}

                                <td
                                  className={
                                    "hidden lg:table-cell whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6"
                                  }
                                >
                                  {sed.price + " RON"}
                                </td>

                                <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right font-medium sm:pr-6">
                                  <Link
                                    to={
                                      "/dashboard/specialist/sedinta/" + sed._id
                                    }
                                  >
                                    Vezi istoric/facturi
                                    <span className="sr-only"></span>
                                  </Link>
                                </td>
                              </tr>
                            </>
                          );
                      })}
                </tbody>
              </table>
            ) : (
              "Nu exista sedinte in istoric"
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TabelaIstoricSedinte;
