import React from "react";
import { Link } from "react-router-dom";

const BackWithText2 = ({ title, services, background }) => {
  return (
    <section
      className={`bg-secondary ${
        background === "white"
          ? "lg:bg-white"
          : background === "body-g"
          ? "lg:bg-body-g"
          : ""
      }  lg:bg-back2 bg-no-repeat bg-cover bg-center`}
    >
      <div className="sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto px-4 pt-10 pb-10 lg:pt-52 lg:pb-28 xl:pt-60 xl:pb-60 2xl:pt-28rem 2xl:pb-80 text-center">
        <h2 className="text-center text-2xl sm:text-3xl xl:text-5xl font-ralewayBold text-white uppercase pb-6 sm:pb-12 mt-20">
          {title}
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-6 sm:flex-row gap-4 sm:gap-10 2xl:gap-20 justify-center">
          {services.map((service, index) => (
            <div
              className={
                (index < 3 ? "lg:col-span-2 " : "lg:col-span-3 ") +
                "bg-white p-10 2xl:p-10 rounded-3xl flex flex-col items-center sm:basis-45% lg:basis-21% justify-center"
              }
              key={index}
            >
              <h3 className="text-xl xl:text-2xl text-primary font-ralewaySemibold uppercase py-2.5">
                {service.title}
              </h3>
              <p className="text-sm font-ralewayRegular">{service.paragraph}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BackWithText2;
