import React, { createRef, useEffect, useState } from "react";
import HeaderClient from "../../../components/Header/HeaderClient";
import avatar from "../../../assets/img/Elena_Ionescu.jpg";
import { Link } from "react-router-dom";
import { auth } from "../../../api";
import { getAppoiments } from "../../../api/getAppoiments.api";
import Moment from "moment";
import { addAngajat, getAngajati } from "../../../api/saveSettings.api";
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import {
  companyPayApi,
  getCompanyInvoices,
  getSumaDatorata,
} from "../../../api/stripe.api";
import { toast } from "react-toastify";
import moment from "moment";

const Tranzactii = () => {
  const [idAngajat, setIdAngajat] = useState();
  const [invoices, setInvoices] = useState([]);

  const [priceToPay, setPriceToPay] = useState();
  const { user } = useContext(UserContext);

  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getCompanyInvoices(user.user.publicId, idToken).then((res) => {
          setInvoices(res.data.invoices);
        });
      });
  }, []);

  return (
    <>
      <HeaderClient />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="mb-3 block grid grid-cols-1 xl:grid-cols-7">
          <h4 className="hidden 2xl:block col-span-4 2xl:col-span-3 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Facturi companie
          </h4>
        </div>
        <div className="2xl:grid  grid-cols-1 2xl:grid-cols-7 gap-0 2xl:gap-5 mb-10 text-sm">
          <div className=" pt-5 2xl:pt-0 h-auto col-span-5 2xl:col-span-7 flex flex-col overflow-hidden  items-start gap-4">
            <ul className="hidden sm:grid grid-cols-4 w-full bg-gray-inactive-bg text-dark-blue font-ralewayBold text-xs py-3.5 pl-3 sm:pl-9 pr-2">
              <li className="col-span-1">NR.</li>
              <li className="col-span-1">Data</li>
              <li className="col-span-1">PRET</li>
              <li className="col-span-1">VEZI</li>
            </ul>
            {!invoices
              ? ""
              : invoices.map((inv, index) => (
                  <>
                    <ul className=" hidden sm:grid grid-cols-4 w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                      <li className="col-span-1">{index + 1}.</li>
                      <li className="col-span-1">
                        {moment(inv.paidAt).format("YYYY-MM-DD")}
                      </li>
                      <li className="col-span-1">
                        {parseFloat(inv.price) / 100 + " RON"}
                      </li>

                      <li className="col-span-1">
                        <a href={inv.url}>VEZI</a>
                      </li>
                    </ul>
                    <div className=" flex flex-col sm:hidden gap-1  w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                      <div className=" grid grid-cols-2">
                        <span className="font-ralewayBold">NUMĂR:</span>
                        {index + 1}.
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="font-ralewayBold">NUME:</span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="font-ralewayBold">ORAS:</span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="font-ralewayBold">COD UTILIZATOR</span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="font-ralewayBold">EMAIL:</span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="font-ralewayBold">VEZI:</span>
                        {"Aici"}
                      </div>
                    </div>
                  </>
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tranzactii;
