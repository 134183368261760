import React, { useEffect, useState } from "react";
import { auth } from "../../api";
import { getRules } from "../../api/saveSettings.api";
import download from "../../assets/img/download-svgrepo-com.svg";
import HeaderSecond from "../../components/Header/HeaderSecond";
import moment from "moment";

const contracts = [
  {
    name: "Model contract Nume Aici",
    file: "../../assets/img/download-svgrepo-com.svg",
  },
  {
    name: "Model contract Nume Aici",
    file: "../../assets/img/download-svgrepo-com.svg",
  },
  {
    name: "Model contract Nume Aici",
    file: "../../assets/img/download-svgrepo-com.svg",
  },
];
const Rules = () => {
  const [setari, setSetari] = useState();
  const [contracte, setContracte] = useState([]);
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getRules(idToken).then((res) => {
          setSetari(res.data.settings);
          setContracte(res.data.settings.contracts);
        });
      });
  }, []);

  return (
    <>
      <HeaderSecond />
      <div className="flex  max-w-1753px m-auto px-5 flex-col lg:flex-row gap-4 lg:gap-0">
        <div className="left basis-3/5 ">
          <div className="lg:pr-8">
            <h4 className="font-bold text-dark-blue pb-4 text-lg">
              Regulament Platforma
            </h4>
            <div className="bg-white flex flex-col rounded-xl p-4 sm:p-10 shadow-lg font-semibold pt-4 max-h-710px">
              <p>
                Ultima actualizare:{" "}
                {setari
                  ? moment(setari.lastUpdatedRules).format("DD.MM.YYYY")
                  : ""}
              </p>
              <a target="_blank" href={setari ? setari.rulesUrl : "#"}>
                <h2 className="mt-4 text-xl sm:text-2xl xl:text-3xl">
                  DESCARCĂ Regulamentul
                </h2>
              </a>
            </div>
          </div>
        </div>
        <div className="left basis-2/5 font-bold text-dark-blue text-lg">
          <h4 className="font-bold text-dark-blue pb-4 text-lg">Contracte</h4>
          <div className=" flex flex-col gap-3.5">
            {contracte
              ? contracte.map((contract, index) => (
                  <div
                    className="bg-white flex rounded-xl shadow-lg p-3 xl:p-7 gap-2 xl:gap-5 justify-between"
                    key={index}
                  >
                    <h4 className=" text-sm xl:text-xl font-semibold text-dark-blue">
                      {contract.name}
                    </h4>
                    <a target="_blank" href={contract.file} download>
                      <button className="flex items-end font-ralewayBold text-xs  gap-1 xl:gap-3 hover:opacity-50 duration-300">
                        <img src={download} /> <span>Descarca Model</span>
                      </button>
                    </a>
                  </div>
                ))
              : ""}
          </div>
        </div>
      </div>
    </>
  );
};

export default Rules;
