import React from "react";
import { Header, Footer } from "../../../components";

const DataProcessingAgreement = () => {
  return (
    <>
      <Header />
      <div className="px-10 mb-20">
        <section className="mt-20">
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h1 className="text-4xl text-center">
                ACORD PRIVIND PRELUCRAREA DATELOR CU CARACTER PERSONAL
              </h1>
              <h3 className="text-2xl text-center">
                (”Data Processing Agreement” sau ”DPA”)
              </h3>
              <p className="text-base text-center mt-5">
                Versiune în vigoare la data de 04.10.2022
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">I. Scop</h2>
            </div>
            <div>
              <p className="mt-2">
                1.1. Prezentul acord, denumit în cele ce urmează ”DPA” (Data
                Processing Agreement), are semnificația unui contract încheiat
                conform prevederilor art. 28 alin. (3) din Regulamentul (UE)
                2016/679 (Regulamentul GDPR), între SOLUTION TALK S.R.L.,
                persoană juridică română, cu sediul în București, Str. Smaranda
                Brăescu, nr. 40, Bl. 20H, Sc. 1, Et. 2, Ap. 6, Sector 1,
                înmatriculată la ORC sub nr. J40 /2852 /2022, cod fiscal
                45650372, e-mail contact@solutiontalk.ro (în continuare
                ”SOLUTION TALK”), în calitate de Persoană Împuternicită și
                fiecare SPECIALIST care listează și/sau prestează Servicii prin
                intermediul Website-ului, în calitate de Operator. Prezentul DPA
                stabilește obiectul, durata, natura și scopul prelucrării, tipul
                de date cu caracter personal, categoriile de persoane vizate
                precum și drepturile și obligațiile Operatorului.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">II. Definiții</h2>
            </div>
            <div>
              <p className="mt-2">În sensul prezentului DPA:</p>
              <p className="mt-2">
                Regulamentul GDPR - Regulamentul (UE) 2016/679 al Parlamentului
                European și al Consiliului din 27 aprilie 2016 privind protecția
                persoanelor fizice în ceea ce privește prelucrarea datelor cu
                caracter personal și privind libera circulație a acestor date și
                de abrogare a Directivei 95/46/CE (Regulamentul general privind
                protecția datelor);
              </p>
              <p className="mt-2">
                Date cu caracter personal, categorii speciale de date,
                prelucrare, Operator, Persoană împuternicită de către operator,
                persoană vizată și autoritate de supraveghere au același înțeles
                ca cel din Regulamentul GDPR;
              </p>
              <p className="mt-2">
                Măsuri tehnice și organizatorice de securitate - înseamnă
                măsurile menite să protejeze datele cu caracter personal
                împotriva distrugerii accidentale sau ilegale sau a pierderii
                accidentale, modificării, divulgării sau accesului neautorizat
                precum și împotriva oricărei alte forme ilegale de prelucrare;
              </p>
              <p className="mt-2">
                RWebsite-ul – înseamnă https://solutiontalk.ro/, proprietatea
                SOLUTION TALK;
              </p>
              <p className="mt-2">
                Specialist – înseamnă un Utilizator al Website-ului care, în
                calitatea sa de psiholog sau psihiatru atestat potrivit
                legislației în vigoare, listează servicii de psihologie sau
                psihiatrie prin intermediul Website-ului iar în schimb datorează
                companiei SOLUTION TALK Comisionul prevăzut în Termeni și
                Condiții (disponibil aici);
              </p>
              <p className="mt-2">
                Client – înseamnă un Utilizator al Website-ului care apelează la
                Serviciile unui Specialist prin intermediul Website-ului (care
                rezervă, plătește sau participă la o ședință online sau
                offline);
              </p>
              <p className="mt-2">
                Serviciu – înseamnă un serviciu de psihologie și/ sau
                psihiatrie, care este prestat de către un Specialist unuia sau
                mai multor Clienți prin interacțiune directă, care se poate
                realiza online (audio și/ sau video) sau offline (în locațiile
                fizice ale Specialistului);
              </p>
              <p className="mt-2">
                Contract – înseamnă înțelegerea consensuală la distanță
                intervenită între SOLUTION TALK și fiecare Specialist având ca
                și obiect furnizarea de servicii de către SOLUTION TALK potrivit
                Termenilor și Condițiilor;
              </p>
              <p className="mt-2">
                Politica de confidențialitate – înseamnă documentul care descrie
                modul în care SOLUTION TALK prelucrează datele cu caracter
                personal, întocmit cu respectarea prevederilor art. 13 din
                Regulamentul GDPR;
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                III. Obiectul, natura și scopul prelucrării. Categoriile de date
                cu caracter personal, categoriile de persoane vizate și durata
                prelucrării.{" "}
              </h2>
            </div>
            <div>
              <p className="mt-2">
                3.1. În baza prezentului DPA, SOLUTION TALK va prelucra, în
                numele Specialistului, anumite date cu caracter personal,
                inclusiv categorii speciale de date aparținând, în principal,
                Clienților. Obiectul, natura și scopul prelucrării pe care
                SOLUTION TALK o va realiza în numele Specialistului în baza
                prezentului DPA, categoriile de date cu caracter personal,
                categoriile de persoane vizate și durata prelucrării sunt
                următoarele:
              </p>
              <table className="my-4 ">
                <tr>
                  <td className="border border-slate-300 p-4">
                    Obiectul, natura și scopul prelucrării care face obiectul
                    acestui DPA:
                  </td>
                  <td className="border border-slate-300 p-4">
                    Datele cu caracter personal care cad sub incidența
                    prezentului DPA vor fi prelucrate de SOLUTION TALK în scopul
                    încheierii și executării Contractului cu Specialistul,
                    executării obligațiilor legale ale SOLUTION TALK,
                    constatării, exercitării sau apărării unor drepturi ale
                    SOLUTION TALK în instanță.
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 p-4">
                    Categoriile de persoane vizate și categoriile de date:
                  </td>
                  <td className="border border-slate-300 p-4">
                    Clienții, cu privire la care se vor prelucra următoarele
                    categorii de date: <br></br>• date cu privire la starea de
                    sănătate a Clienților, inclusiv istoricul medical,
                    diagnostice, afecțiuni, simptome, tratamente, rezultatele
                    tratamentelor, recomandările medicale, evoluții,
                    investigații și rezultatele lor etc., <br></br>• date legate
                    de situația personală, familială, profesională sau de altă
                    natură a Clienților, necesare sau furnizate de Client
                    Specialistului în cadrul interacțiunii dintre aceștia, care
                    pot consta în relația cu familia, persoanele apropiate,
                    relațiile dintre soți, stări generale și evoluția lor,
                    relațiile profesionale etc. <br></br>• în general toate
                    datele care sunt transmise între Client și Specialist în
                    cadrul ”Zonei de Mesagerie”, care sunt extrase de Specialist
                    în urma interacțiunii cu Clientul și notate de Specialist în
                    zona ”Notițe pentru Ședințe” sau care sunt în alt mod
                    prelucrate de Specialist prin intermediul Website-ului;
                    <br></br>Orice alte persoane, care pot fi membrii de
                    familie, colegi sau alte persoane cu care Clienții
                    interacționează, ale căror date cu caracter personal sunt
                    transmise între Client și Specialist cu ocazia furnizării de
                    Servicii de către Specialist, în cadrul ”Zonei de
                    Mesagerie”, care sunt extrase de Specialist în urma
                    interacțiunii cu Clientul și notate de Specialist în zona
                    ”Notițe pentru Ședințe” sau care sunt în alt mod prelucrate
                    de Specialist prin intermediul Website-ului;
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 p-4">
                    Durata prelucrării
                  </td>
                  <td className="border border-slate-300 p-4">
                    Prelucrarea datelor va avea loc pe toată durata cât Contul
                    este menținut activ sau până la ștergerea acestor date de
                    către Specialist/ Client (”Durata Prelucrării”). După
                    expirarea Duratei prelucrării, SOLUTION TALK șterge datele
                    și elimină copiile existente. Cu toate acestea, SOLUTION
                    TALK este îndreptățită să continue să stocheze datele cu
                    caracter personal care fac obiectul prezentului DPA pe o
                    perioadă de maxim 3 ani de la expirarea Duratei prelucrării,
                    însă doar dacă există o obligație legală în acest sens, dacă
                    datele sunt necesare în scopul constatării, exercitării sau
                    apărării unor drepturi în instanță sau dacă păstrarea
                    datelor este necesară pentru realizarea altor interese
                    legitime ale SOLUTION TALK, în condițiile legii. Toate
                    drepturile și obligațiile Părților ce derivă din prezentul
                    DPA menite să persiste și după expirarea Duratei prelucrării
                    datelor cu caracter personal vor continua să își producă
                    efectele juridice, fiind obligatorii pentru ambele părți
                    contractuale.
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                IV. Responsabilitățile Specialistului
              </h2>
            </div>
            <div>
              <h2 className="text-xl mt-5 font-bold">
                4.1 Specialistul se obligă:
              </h2>
            </div>
            <div>
              <p className="mt-2">
                (i) să prelucreze datele cu caracter personal în conformitate cu
                dispozițiile relevante ale legislației în vigoare, cu
                respectarea Regulamentului GDPR și a dreptului intern incident,
                inclusiv legislația privind drepturile pacienților; <br></br>
                (ii) să obțină consimțământul valabil al persoanelor vizate în
                toate cazurile în care prelucrarea se bazează pe consimțământul
                acestora; <br></br>(iii) să informeze persoanele vizate privind
                prelucrarea de date, inclusiv transferul de date către SOLUTION
                TALK; <br></br>(iv) să furnizeze SOLUTION TALK instrucțiuni
                documentate și obligatorii în legătură cu prelucrarea datelor cu
                caracter personal; <br></br>(v) să păstreze în permanență
                controlul și responsabilitatea asupra datelor cu caracter
                personal prelucrate; <br></br>(vi) să nu încredințeze SOLUTION
                TALK spre prelucrare conținut ilegal sau imoral sau de natură să
                încalce drepturile de proprietate intelectuală sau de altă
                natură ale terțelor părți;
              </p>

              <div>
                <h2 className="text-xl mt-5 font-bold">
                  4.2. De asemenea, Specialistul garantează că:
                </h2>
              </div>
              <p className="mt-2">
                (i) a documentat măsurile tehnice și organizatorice necesare
                prelucrării de date iar măsurile implementate de SOLUTION TALK
                sunt adecvate pentru a proteja datele cu caracter personal
                împotriva distrugerii accidentale sau ilegale sau a pierderii
                accidentale, modificării, divulgării sau accesului neautorizat
                precum și împotriva oricăror alte forme ilegale de prelucrare,
                și că aceste măsuri asigură un nivel adecvat de securitate în
                ceea ce privește riscurile prezentate de prelucrarea sau natura
                datelor care trebuie protejate, ținând seama de cele mai recente
                tehnici din sector și de costurile punerii lor în aplicare;{" "}
                <br></br>(ii) va asigura respectarea măsurilor de securitate;
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                V. Responsabilitățile SOLUTION TALK
              </h2>
            </div>
            <div>
              <h2 className="text-xl mt-5 font-bold">
                5.1. Prelucrarea datelor în baza instrucțiunilor Specialistului
              </h2>
            </div>
            <div>
              <p className="mt-2">
                5.1.1. SOLUTION TALK prelucrează datele cu caracter personal
                numai pe baza instrucțiunilor documentate din partea
                Specialistului, inclusiv în ceea ce privește transferurile de
                date cu caracter personal către o țară terță sau o organizație
                internațională, cu excepția cazului în care această obligație îi
                revine SOLUTION TALK în temeiul dreptului Uniunii sau al
                dreptului intern care i se aplică; în acest caz, notifică
                această obligație juridică Specialistului înainte de prelucrare,
                cu excepția cazului în care dreptul respectiv interzice o astfel
                de notificare din motive importante legate de interesul public;
                <br></br>5.1.2. SOLUTION TALK va informa imediat Specialistul
                dacă consideră că instrucțiunile acestuia încalcă prevederile
                Regulamentului GDPR sau alte dispoziții din dreptul intern
                referitoare la protecția datelor; <br></br>5.1.3. SOLUTION TALK
                desemnează un responsabil cu protecția datelor (în continuare
                DPO), atunci când o asemenea obligație îi revine în conformitate
                cu Regulamentul GDPR. Datele DPO vor fi comunicate atât
                Specialistului cât și autorității de supraveghere, cu
                respectarea prevederilor Regulamentului GDPR.
              </p>

              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.2. Securitatea datelor
                </h2>
              </div>
              <p className="mt-2">
                5.2.1. SOLUTION TALK se asigură că salariații săi sau alte
                persoane autorizate care prelucrează datele cu caracter personal
                s-au angajat să respecte confidențialitatea acestor date sau au
                o obligație statutară adecvată de confidențialitate. <br></br>
                5.2.2. SOLUTION TALK are implementate măsuri tehnice și
                organizatorice pentru protejarea datelor cu caracter personal
                împotriva distrugerii, pierderii, modificării, divulgării
                neautorizate sau a accesului neautorizat la date, măsuri care
                pot suferi modificări în funcție de progresul tehnic, cu
                condiția ca nivelul de siguranță al măsurilor specificate să nu
                fie compromis. <br></br>5.2.3. SOLUTION TALK ajută Specialistul
                să asigure respectarea obligațiilor prevăzute la art. 32-36 din
                Regulamentul GDPR ținând seama de caracterul prelucrării și de
                informațiile aflate la dispoziția SOLUTION TALK.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.3. Evidența datelor
                </h2>
              </div>
              <p className="mt-2">
                5.3.1. SOLUTION TALK ține evidența datelor cu caracter personal
                prelucrate în numele Specialistului și pune la dispoziția
                acestuia, la cerere, această evidență. Evidența datelor va
                include cel puțin: numele și datele de contact ale
                Specialistului în numele căruia acționează SOLUTION TALK ca
                persoană împuternicită precum și ale reprezentantului
                Specialistului, categoriile de activități de prelucrare
                desfășurate în numele Specialistului, dacă este cazul,
                transferurile de date cu caracter personal către o țară terță UE
                sau organizație internațională, inclusiv identificarea țării
                terțe respective sau a organizației internaționale respective
                precum și documentația care dovedește existența unor garanții
                adecvate, acolo unde este posibil, o descriere generală a
                măsurilor tehnice și organizatorice de securitate implementate.
              </p>

              <div>
                <h2 className="text-xl mt-5 font-bold">5.4. Alte obligații</h2>
              </div>
              <p className="mt-2">
                5.4.1. Ținând seama de natura prelucrării, SOLUTION TALK oferă
                asistență Specialistului prin măsuri tehnice și organizatorice
                adecvate, în măsura în care acest lucru este posibil, pentru
                îndeplinirea obligației Specialistului de a răspunde cererilor
                privind exercitarea de către persoanele vizate a drepturilor
                prevăzute de Regulamentul GDPR. <br></br>5.4.2. SOLUTION TALK
                pune la dispoziția Specialistului toate informațiile necesare
                pentru a demonstra respectarea obligațiilor prevăzute de
                prezentul DPA, permite desfășurarea auditurilor/ inspecțiilor,
                efectuate de Specialist sau alt auditor mandatat și contribuie
                la acestea. Orice solicitare de audit/ inspecție din partea
                Specialistului va fi notificată în scris SOLUTION TALK cu cel
                puțin 30 de zile înainte de data la care aceasta se dorește a fi
                efectuată.
                <br></br>5.4.3. SOLUTION TALK informează Specialistul cu privire
                la orice cerere, anchetă, inspecție, reclamație formulată/
                demarată de persoanele vizate, autoritatea de supraveghere sau
                terțe părți, inclusiv instituții și/ sau autorități publice în
                legătură cu datele prelucrate în numele Specialistului și îi
                furnizează acestuia detalii complete. <br></br>5.4.4. SOLUTION
                TALK informează Specialistul, fără nicio întârziere, în cazul în
                care aceasta nu mai poate asigura conformitatea cu Regulamentul
                GDPR și nu își mai poate onora obligațiile stabilite prin
                prezentul DPA, caz în care Specialistul are dreptul să suspende
                transferul de date și/sau să rezilieze Contractul.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                VI. Subcontractare și transferul datelor
              </h2>
            </div>
            <div>
              <p className="mt-2">
                6.1. Prin acceptarea prezentului DPA, Specialistul autorizează
                în mod expres SOLUTION TALK ca în activitatea de prelucrare a
                datelor cu caracter personal care cade sub incidența prezentului
                DPA să apeleze la terțe persoane sub-împuternicite. Persoanele
                care prelucrează date în numele SOLUTION TALK sunt prevăzute în
                Politica de Confidențialitate și aprobate de Specialist prin
                încheierea prezentului DPA. SOLUTION TALK va informa
                Specialistul cu privire la orice modificări preconizate privind
                adăugarea sau înlocuirea altor persoane împuternicite, prin
                modificarea Politicii de Confidențialitate, oferind astfel
                posibilitatea Specialistului de a formula obiecții față de
                aceste modificări. <br></br>6.2. Ori de câte ori SOLUTION TALK
                recrutează o altă persoană împuternicită pentru efectuarea de
                activități de prelucrare specifice în numele Specialistului,
                aceleași obligații privind protecția datelor prevăzute în
                prezentul DPA revin celei de a doua persoane împuternicite, prin
                intermediul unui contract sau al unui alt act juridic, în
                temeiul dreptului Uniunii sau al dreptului intern, în special
                furnizarea de garanții suficiente pentru punerea în aplicare a
                unor măsuri tehnice și organizatorice adecvate, astfel încât
                prelucrarea să îndeplinească cerințele Regulamentului GDPR. În
                cazul în care persoana sub-împuternicită nu își respectă
                obligațiile privind protecția datelor, SOLUTION TALK rămâne pe
                deplin răspunzătoare față de Specialist în ceea ce privește
                îndeplinirea obligațiilor acestei a doua persoane împuternicite,
                în limitele și condițiile prevăzute în prezentul DPA. <br></br>
                6.3. Specialistul declară că înțelege și este de acord cu faptul
                că, atunci când SOLUTION TALK apelează la persoane
                sub-împuternicite, se poate realiza un transfer de date cu
                caracter personal către țări terțe UE sau organizații
                internaționale.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                VII. Răspunderea contractuală
              </h2>
            </div>
            <div>
              <p className="mt-2">
                7.1. Părțile convin ca persoanele vizate care au suferit
                prejudicii în urma unei încălcări a securității datelor cu
                caracter personal ce derivă din prezentul DPA sunt îndreptățite
                de a solicita despăgubiri fie direct de la Specialist, fie
                direct de la SOLUTION TALK. Partea care a plătit despăgubiri
                integrale sau parțiale poate formula ulterior o acțiune în
                regres împotriva celeilalte părți care se face vinovată de
                cauzarea prejudiciului, cu respectarea clauzelor prezentului
                DPA. <br></br>7.2. Părțile convin că SOLUTION TALK va fi
                răspunzătoare pentru orice prejudiciu rezultând din încălcarea
                culpabilă a prezentului DPA, a legislației incidente sau a
                instrucțiunilor Specialistului, răspunderea sa fiind limitată la
                contravaloarea Comisioanelor achitate de Specialist în baza
                Contractului dintre Părți. Orice sume care depășesc acest
                plafon, achitate de SOLUTION TALK persoanelor vizate, vor fi
                rambursate de către Specialist în termen de maxim 30 de zile de
                la data la care SOLUTION TALK face dovada achitării, către
                persoanele vizate, a acestor sume. <br></br>7.3. Despăgubirea
                Specialistului de către SOLUTION TALK este condiționată de
                îndeplinirea următoarelor condiții cumulative: Specialistul
                notifică fără întârziere SOLUTION TALK existența unei cereri de
                despăgubire și SOLUTION TALK i se dă posibilitatea de a coopera
                cu Specialistul privind apărarea și soluționarea cererii de
                despăgubire.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">VIII. Clauze finale</h2>
            </div>
            <div>
              <p className="mt-2">
                8.1. Prezentul DPA este guvernat de legea Română iar, în caz de
                litigiu cu privire la orice aspect legat de interpretarea,
                executarea sau încetarea prezentului DPA, vor fi competente
                instanțele judecătorești de la sediul SOLUTION TALK .
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default DataProcessingAgreement;
