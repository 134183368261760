import { auth } from "../api";

export const HandleLogout = () => {
  auth
    .auth()
    .signOut()
    .then((result) => {
      window.location.href = "/";
      // TODO: REDIRECT
    });
};
