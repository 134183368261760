import React, {
  useEffect,
  useState,
  createRef,
  useRef,
  useContext,
} from "react";
import roLocale from "@fullcalendar/core/locales/ro";
import HeaderSecond from "../../../components/Header/HeaderSecond";
import FullCalendar, { eventTupleToStore } from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { sliceEvents, createPlugin } from "@fullcalendar/core";
import { auth } from "../../../api";
import { getAppoiments } from "../../../api/getAppoiments.api";
import Moment from "moment";
import {
  cancelAppointmentBySpecialist,
  createappointment,
} from "../../../api/createAppoinment";
import { UserContext } from "../../../providers/UserProvider";
import { toast } from "react-toastify";

var events = [
  {
    // this object will be "parsed" into an Event Object
    title: "The Title", // a property!
    start: "2022-05-16 12:45", // a property!
    end: "2022-05-16 13:45", // a property! ** see important note below about 'end' **
    extendedProps: {
      location: "Online",
      name: "Daniela Lungu",
    },
  },
  {
    // this object will be "parsed" into an Event Object
    title: "The Title", // a property!
    start: "2022-05-17 08:00", // a property!
    end: "2022-05-17 09:00", // a property! ** see important note below about 'end' **
    extendedProps: {
      location: "Online",
      name: "Daniela Lungu",
    },
  },
  {
    // this object will be "parsed" into an Event Object
    title: "The Title", // a property!
    start: "2022-05-19 14:00", // a property!
    end: "2022-05-19 15:00", // a property! ** see important note below about 'end' **
    extendedProps: {
      location: "Online",
      name: "Daniela Lungu",
    },
  },
  {
    // this object will be "parsed" into an Event Object
    title: "The Title", // a property!
    start: "2022-05-15 13:00", // a property!
    end: "2022-05-15 14:00", // a property! ** see important note below about 'end' **
    extendedProps: {
      location: "Cabinet",
      name: "Daniela Lungu",
    },
  },
];

// var events = [];
const Calendar = () => {
  var calendarRef = createRef();
  var popupRef = createRef();
  var popupRefCancel = createRef();
  var popupDateRef = createRef();
  var popupTimeRef = createRef();
  const [showPopupCancel, setShowPopupCancel] = useState();
  const [popupStartDateValue, setPopupStartDateValue] = useState();
  const [popupEndDateValue, setPopupEndDateValue] = useState();
  var [popupLocationOffline, setPopupLocationOffline] = useState(false);
  var [popupLocationOnline, setPopupLocationOnline] = useState(false);
  var [showPopup, setShowPopup] = useState(false);
  const [duration, setDuration] = useState();
  const { user, setUser } = useContext(UserContext);
  const [loadingCalendar, setLoadingCalendar] = useState(true);
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [eventR, setEventR] = useState();

  const onChangeDuration = (e) => {
    var value = e.target.value;
    setDuration(value);
    popupTimeRef.current.innerHTML =
      "Interval: " +
      Moment(popupStartDateValue).format("LT") +
      " - " +
      Moment(popupStartDateValue).add(value, "minutes").format("LT");
    setPopupEndDateValue(Moment(popupStartDateValue).add(value, "minutes"));
  };

  const cancelAppointment = () => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        cancelAppointmentBySpecialist(selectedAppointment, idToken).then(
          (res) => {
            if (res.status == 200) {
              toast.success("Programarea a fost anulata cu succes!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowPopupCancel(false);

              eventR.remove();
              // conso;
            } else {
              toast.error("Eroare la anularea programarii!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        );
      });
  };

  const onChangeCheckbox = (event) => {
    var { name, value } = event.target;
    if (event.target.checked) {
      switch (event.target.name) {
        case "availableSlotCabinet":
          setPopupLocationOffline(true);
          break;
        case "availableSlotOnline":
          setPopupLocationOnline(true);
          break;
        default:
          break;
      }
    } else {
      switch (event.target.name) {
        case "availableSlotCabinet":
          setPopupLocationOffline(false);
          break;
        case "availableSlotOnline":
          setPopupLocationOnline(false);
          break;
        default:
          break;
      }
    }
  };

  /*
    Functie ce adauga un slot liber in calendarul specialistului
  */

  const saveSlot = () => {
    if (!user.user.meetingEmail) {
      toast.error("Trebuie sa va setati email-ul pentru meeting mai intai!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!duration) {
      toast.error("Trebuie sa selectati durata!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!popupLocationOffline && !popupLocationOnline) {
      toast.error("Trebuie sa selectati locatia!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    var app = {
      specialist: user.user.publicId,
      availableSlot: true,
      date: popupStartDateValue,
      type: "personal",
      specialistFirstName: user.user.firstName,
      specialistLastName: user.user.lastName,
      specializationType: user.user.specialistInfo.specializationType,
      specialistCity: user.user.city,
      specialistAvatarUrl: user.user.avatarUrl,
      lastStudies: user.user.specialistInfo.lastStudies,
      availableOnline: popupLocationOnline,
      availableOffline: popupLocationOffline,
      startHour: Moment(popupStartDateValue).format("HH:mm"),
      endHour: Moment(popupStartDateValue)
        .add(duration, "minutes")
        .format("HH:mm"),
    };
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        var appprice;
        switch (duration) {
          case "25":
            appprice = user.user.specialistInfo.price25;
            break;
          case "50":
            appprice = user.user.specialistInfo.price50;
            break;
          case "90":
            appprice = user.user.specialistInfo.price90;
            break;
          default:
            appprice = "0 RON";
            break;
        }
        if (!appprice) {
          toast.error("Nu ati setat pretul pentru acest tip de sedinta!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
        createappointment(appprice, app, idToken).then((res) => {
          if (res.status == 200) {
            var e = res.data.appointment;
            var x = {
              // this object will be "parsed" into an Event Object
              title: e.clientFirstName, // a property!
              start: Moment(e.date).format("YYYY-MM-DD") + " " + e.startHour, // a property!
              end: Moment(e.date).format("YYYY-MM-DD") + " " + e.endHour, // a property! ** see important note below about 'end' **
              extendedProps: {
                location: e.location,
                name: e.clientFirstName,
                test: e.date,
                _id: e._id,
              },
            };
            var eventData = calendarRef.current.getApi().addEvent(x);
            //
            if (res.data.appointment.availableSlot) {
              eventData.setProp("backgroundColor", "rgba(160, 255, 118,0.3)");
            } else {
              eventData.setProp("backgroundColor", "rgba(30, 129, 255,0.3)");
            }
            toast.success("Ati adaugat un slot liber cu succes!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (res.status == 405) {
            toast.error("Nu aveti inca contul de Stripe activat!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(
              "A aparut o eroare necunoscuta la adaugarea slotului!",
              {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
          setShowPopup(false);
        });
      });
  };

  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getAppoiments(idToken).then((res) => {
          res.data.appointments.map((e) => {
            var x = {
              title: e.clientFirstName, // a property!
              start: Moment(e.date).format("YYYY-MM-DD") + " " + e.startHour, // a property!
              end: Moment(e.date).format("YYYY-MM-DD") + " " + e.endHour, // a property! ** see important note below about 'end' **
              extendedProps: {
                location: e.location,
                name: e.clientFirstName,
                test: e.date,
                _id: e._id,
              },
            };
            var eventData = calendarRef.current.getApi().addEvent(x);
            if (!e.availableSlot) {
              eventData.setProp("backgroundColor", "rgba(30, 129, 255,0.3)");
            } else {
              eventData.setProp("backgroundColor", "rgba(160, 255, 118,0.3)");
            }
          });
        });
      });
  }, []);
  return (
    <>
      <HeaderSecond />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="grid grid-cols-1 xl:grid-cols-5 gap-5">
          <div className="col-span-1">
            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="px-4 py-5 sm:px-5 sm:py-5 mini-calendar font-openSans">
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  locale={roLocale}
                  height={"auto"}
                  dayHeaderContent={(arg) => {
                    const day = arg.date.getUTCDay();
                    var dayToString = "";
                    switch (day) {
                      case 1:
                        dayToString = "LUN";
                        break;
                      case 2:
                        dayToString = "MAR";
                        break;
                      case 3:
                        dayToString = "MIE";
                        break;
                      case 4:
                        dayToString = "JOI";
                        break;
                      case 5:
                        dayToString = "VIN";
                        break;
                      case 6:
                        dayToString = "SAM";
                        break;
                      case 0:
                        dayToString = "DUM";
                        break;
                    }
                    return (
                      <>
                        <div className="font-openSans font-bold text-xs text-light-blue">
                          <p>{dayToString}</p>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-4">
            <div
              className={
                (!showPopup ? "hidden " : "") +
                "w-80 h-96 fixed inset-0 m-auto z-50"
              }
              ref={popupRef}
            >
              <div className="bg-white overflow-hidden shadow rounded-lg h-full w-full">
                <div className="px-4 py-5 sm:px-6 sm:py-4 bg-dark-blue relative">
                  <p className="text-white font-openSans font-bold">
                    Programare
                  </p>
                  <p
                    onClick={(e) => setShowPopup(false)}
                    className="text-white text-2xl cursor-pointer w-fit absolute top-2.5 right-2.5"
                  >
                    x
                  </p>
                </div>
                <div className="px-4 sm:px-6 mt-5">
                  <p
                    className="font-openSans text-xl text-dark-blue font-bold"
                    ref={popupDateRef}
                  ></p>
                  <p
                    className="font-openSans text-xl text-dark-blue font-bold"
                    ref={popupTimeRef}
                  ></p>
                  <div className="relative flex items-start font-openSans text-base text-dark-blue font-bold mt-5">
                    <div className="flex items-center h-5">
                      <input
                        onChange={onChangeCheckbox}
                        id="availableSlotOnline"
                        aria-describedby="availableSlotOnline-description"
                        name="availableSlotOnline"
                        type="checkbox"
                        className="focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>

                    <div className="ml-1">
                      <label
                        htmlFor="specializationTypePsihoterapeut"
                        className=" "
                      >
                        Online
                      </label>
                    </div>
                  </div>
                  <div className="relative flex items-start font-openSans text-base text-dark-blue font-bold mt-2">
                    <div className="flex items-center h-5">
                      <input
                        onChange={onChangeCheckbox}
                        id="availableSlotCabinet"
                        aria-describedby="specializationTypePsihiatru-description"
                        name="availableSlotCabinet"
                        type="checkbox"
                        className="focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-1">
                      <label htmlFor="availableSlotCabinet" className=" ">
                        Cabinet
                      </label>
                    </div>
                  </div>
                  <div className="relative flex items-start mt-4">
                    <div className="flex items-center h-5">
                      <input
                        onChange={onChangeDuration}
                        id="specializationTypePsihoterapeut"
                        aria-describedby="pecializationTypePsihoterapeut-description"
                        name="duration"
                        type="radio"
                        value="25"
                        className="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>

                    <div className="ml-1 text-sm">
                      <label htmlFor="specialization" className=" ">
                        25 min
                      </label>
                    </div>
                  </div>
                  <div className="relative flex items-start mt-2">
                    <div className="flex items-center h-5">
                      <input
                        onChange={onChangeDuration}
                        id="specializationTypePsihoterapeut"
                        aria-describedby="pecializationTypePsihoterapeut-description"
                        name="duration"
                        type="radio"
                        value="50"
                        className="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>

                    <div className="ml-1 text-sm">
                      <label htmlFor="specialization" className=" ">
                        50 min
                      </label>
                    </div>
                  </div>
                  <div className="relative flex items-start mt-2">
                    <div className="flex items-center h-5">
                      <input
                        onChange={onChangeDuration}
                        id="specializationTypePsihoterapeut"
                        aria-describedby="pecializationTypePsihoterapeut-description"
                        name="duration"
                        type="radio"
                        value="90"
                        className="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>

                    <div className="ml-1 text-sm">
                      <label htmlFor="specialization" className=" ">
                        90 min
                      </label>
                    </div>
                  </div>

                  <button
                    className="w-2/5 flex mx-auto mt-5 justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                    onClick={saveSlot}
                    type="button"
                  >
                    {" "}
                    Salveaza
                  </button>
                </div>
              </div>
            </div>
            <FullCalendar
              plugins={[timeGridPlugin, interactionPlugin]}
              locale={roLocale}
              initialView={"timeGrid"}
              duration={{
                days: window.innerWidth < 760 ? 1 : 7,
              }}
              selectLongPressDelay={10}
              longPressDelay={10}
              eventLongPressDelay={10}
              selectable={true}
              select={(data) => {
                popupDateRef.current.innerHTML =
                  "Data: " + Moment(data.start).format("DD.MM.YYYY");
                popupTimeRef.current.innerHTML =
                  "Interval: " +
                  Moment(data.start).format("LT") +
                  " - " +
                  Moment(data.start).add("25", "minutes").format("LT");
                setPopupEndDateValue(data.end);
                setPopupStartDateValue(data.start);

                setShowPopup(true);
              }}
              ref={calendarRef}
              events={events}
              height={"1000px"}
              eventClick={(arg) => {
                setEventR(arg.event);
                setSelectedAppointment(arg.event.extendedProps._id);
                setShowPopupCancel(true);
              }}
              contentHeight={"auto"}
              month="2-digit"
              allDaySlot={false}
              slotDuration="0:15:00"
              eventDataTransform={(eventData) => {
                if (eventData.extendedProps.location == "Online")
                  eventData.color = "rgba(248, 154, 118,0.3)";
                else if (eventData.extendedProps.location == "Cabinet")
                  eventData.color = "rgba(11, 30, 57,0.15)";
                eventData.classNames = "pl-3";
                return eventData;
              }}
              dayHeaderContent={(arg) => {
                const day = arg.date.getUTCDay();
                var dayToString = "";
                switch (day) {
                  case 0:
                    dayToString = "LUNI";
                    break;
                  case 1:
                    dayToString = "MARTI";
                    break;
                  case 2:
                    dayToString = "MIERCURI";
                    break;
                  case 3:
                    dayToString = "JOI";
                    break;
                  case 4:
                    dayToString = "VINERI";
                    break;
                  case 5:
                    dayToString = "SAMBATA";
                    break;
                  case 6:
                    dayToString = "DUMINICA";
                    break;
                }
                return (
                  <>
                    <div className="py-4">
                      <div className="font-openSans font-bold text-xs text-light-blue">
                        <p>{dayToString}</p>
                      </div>
                      <div className="mt-1">
                        <p className="text-3xl font-openSans font-bold text-light-blue">
                          {arg.date.getDate()}
                        </p>
                      </div>
                    </div>
                  </>
                );
              }}
              eventContent={(arg) => {
                return (
                  <>
                    <div className="text-secondary h-42 cursor-pointer">
                      <div className="font-openSans text-xs">
                        <p>Sedinta {arg.event.extendedProps.location}</p>
                      </div>
                      <div>
                        <p className="font-bold font-openSans text-base">
                          {arg.event.extendedProps.name}
                        </p>
                      </div>
                      <div>
                        <p>
                          {arg.event.start.getHours()}:
                          {arg.event.start.getMinutes() >= 10
                            ? arg.event.start.getMinutes()
                            : "0" + arg.event.start.getMinutes()}{" "}
                          - {arg.event.end.getHours()}:
                          {arg.event.end.getMinutes() >= 10
                            ? arg.event.end.getMinutes()
                            : "0" + arg.event.end.getMinutes()}
                        </p>
                      </div>
                    </div>
                  </>
                );
              }}
            />
          </div>
          <div
            className={
              (!showPopupCancel ? "hidden " : "") +
              "w-80 h-80 fixed inset-0 m-auto z-50"
            }
            ref={popupRefCancel}
          >
            <div className="bg-white overflow-hidden shadow rounded-lg h-full w-full">
              <div className="px-4 py-5 sm:px-6 sm:py-4 bg-dark-blue relative">
                <p className="text-white font-openSans font-bold">
                  Anulare programare
                </p>
                <p
                  onClick={(e) => {
                    setShowPopupCancel(false);
                  }}
                  className="text-white cursor-pointer w-fit absolute top-2.5 right-2.5"
                >
                  x
                </p>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <p className="font-openSans text-xl text-dark-blue font-bold">
                  Doriti sa continuati anularea programarii?
                </p>

                <button
                  className="w-3/5 flex mx-auto mt-5 justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                  onClick={cancelAppointment}
                  type="button"
                >
                  Anuleaza programarea
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
