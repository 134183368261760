import React, { useEffect, useState } from "react";
import HeaderClient from "../../../components/Header/HeaderClient";
import { StarIcon } from "@heroicons/react/solid";
import { Link, useParams } from "react-router-dom";
import {
  getClientById,
  getSpecialistById,
} from "../../../api/getSpecialist.api";
import { auth } from "../../../api";
import { getSpecialistReviews } from "../../../api/getReviews.api";
import HeaderAdmin from "../../../components/Header/HeaderAdmin";
import Moment from "moment";
import { getAppoimentsByPublicId } from "../../../api/getAppoiments.api";

const VeziProfilSpecialist = () => {
  const [specialist, setSpecialist] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    street: "",
    streetNr: "",
    building: "",
    staircase: "",
    apartment: "",
    videoUrl: "",
    description: "",
    companyInfo: {
      companyName: "",
      companyCUI: "",
      companyRegNr: "",
      companyTVACode: "",
      companyCity: "",
      companyRegion: "",
      companyEmail: "",
      companyStreetNr: "",
      companyBuilding: "",
      companyStaircase: "",
      companyApartment: "",
      companyStreet: "",
    },
    specialistInfo: {
      specializationType: "",
      specializationName: "",
      yearsExperience: "",
      collegeCode: "",
      license: "off",
      masters: "off",
      doctorate: "off",
      university: "",
      otherCourses: "",
      lastStudies: "",
      price: "",
      companyDiscount: "",
      specializations: [],
    },
  });
  const { id } = useParams();
  const [pastAppointments, setPastAppoiments] = useState([]);
  const [futureAppointments, setFutureAppointments] = useState([]);

  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getClientById(idToken, id).then((res) => {
          setSpecialist(res.specialist);
        });
        getAppoimentsByPublicId(idToken, id).then((res) => {
          let apps = [];
          let fapps = [];
          if (res.status == 200) {
            res.data.appointments.forEach((element) => {
              if (Moment(element.date) < Moment()) apps.push(element);
              else fapps.push(element);
            });
            setPastAppoiments(apps);
            setFutureAppointments(fapps);
            console.log(pastAppointments);
            console.log(futureAppointments);
          }
        });
      });
  }, []);
  return (
    <>
      <HeaderAdmin />

      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-2 sm:gap-5 xl:gap-20 mb-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:w-3/5">
            <Link to="/dashboard/admin/clienti">
              <button className="w-full flex justify-center py-4 px-2 border rounded-md shadow-sm text-xs border-primary text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                Inapoi pagina de Clienti
              </button>
            </Link>
          </div>
          {specialist.companyId ? (
            <div>
              <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-5 lg:w-3/5 lg:float-right">
                <div></div>
                <div>
                  <div className="my-auto">
                    <div className="lg:mt-2 mb-1 lg:m-0">
                      <Link
                        to={
                          "/dashboard/admin/companie/" +
                          specialist.companyId +
                          "/afisare-companie"
                        }
                        className="w-full flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                      >
                        Vezi companie
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="grid grid-cols-1 2xl:grid-cols-2 gap-5">
          <div className="font-openSans text-secondary flex flex-col flex-nowrap h-full ">
            <div className="mb-3">
              <p className="text-lg font-bold">Despre Companie</p>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg h-full">
              <div className="px-4 py-5 sm:p-6">
                <div className="grid grid-cols-1 lg:grid-cols-4">
                  <div className="col-span-1 text-center">
                    <div
                      className="flex-shrink-0 inline-block rounded-full overflow-hidden h-44 w-44"
                      aria-hidden="true"
                    >
                      <img
                        className="rounded-full h-full w-full border-primary p-2 border-2 object-cover"
                        src={specialist.avatarUrl}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div>
                      <div className="flex justify-between">
                        <div>
                          <p className="text-3xl text-secondary font-bold mb-4">
                            {specialist.lastName + " " + specialist.firstName}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Email: " + specialist.email}
                          </p>
                          <p className="text-base text-gray-primary font-normal mb-2">
                            {"Telefon: " + specialist.phone}
                          </p>
                          <p className="text-xl text-secondary font-bold mb-2 mt-4">
                            {"Adresa"}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Judet: " + specialist.region}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Localitate: " + specialist.city}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Strada: " + specialist.street}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Nr.: " + specialist.streetNr}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Bloc: " + specialist.building}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Scara: " + specialist.staircase}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Apartament: " + specialist.apartment}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="font-openSans text-secondary flex flex-col flex-nowrap h-full ">
            <div className="mb-3">
              <p className="text-lg font-bold">Istoric sedinte</p>
            </div>
            <div className=" pt-5 2xl:pt-0 h-auto col-span-4 2xl:col-span-3 flex flex-col overflow-hidden  items-start gap-4">
              <ul className="hidden sm:grid grid-cols-7 w-full bg-gray-inactive-bg text-dark-blue font-ralewayBold text-xs py-3.5 pl-3 sm:pl-9 pr-2">
                <li className="col-span-1">NR.</li>
                <li className="col-span-2">DATA</li>

                <li className="col-span-2">TERAPEUT</li>

                <li className="col-span-2">COST</li>
              </ul>
              {!pastAppointments
                ? ""
                : pastAppointments.map((sedinta, index) => (
                    <>
                      <ul className=" hidden sm:grid grid-cols-7 w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                        <li className="col-span-1">{index + 1}.</li>
                        <li className="col-span-2">
                          {Moment(sedinta.date).format("YYYY-MM-DD")}
                        </li>

                        <li className="col-span-2">
                          {sedinta.specialistLastName +
                            " " +
                            sedinta.specialistFirstName}
                        </li>

                        <li className="col-span-2">{sedinta.price + " RON"}</li>
                      </ul>
                      <div className=" flex flex-col sm:hidden gap-1  w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                        <div className=" grid grid-cols-2">
                          <span className="font-ralewayBold">NUMĂR:</span>
                          {index + 1}.
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="font-ralewayBold">DATA:</span>
                          {Moment(sedinta.date).format("YYYY-MM-DD")}
                        </div>

                        <div className="grid grid-cols-2">
                          <span className="font-ralewayBold">TERAPEUT:</span>
                          {sedinta.specialistLastName +
                            " " +
                            sedinta.specialistLastName}
                        </div>

                        <div className="grid grid-cols-2">
                          <span className="font-ralewayBold">COST:</span>
                          {sedinta.price + " RON"}
                        </div>
                      </div>
                    </>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VeziProfilSpecialist;
