import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { auth } from "../../api";
import { companyMarkAsPaid, createInvoice } from "../../api/stripe.api";
import { HeaderClient } from "../../components";
import { UserContext } from "../../providers/UserProvider";

const SuccesPayCompany = () => {
  return (
    <>
      <HeaderClient />
      <div className="min-h-screen flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8 font-raleway font-medium ">
        <div className="mt-8 sm:mx-auto md:w-full sm:max-w-2xl mx-6 w-11/12">
          <div className="bg-white overflow-hidden shadow rounded-lg m-auto mx-auto">
            <div className="px-4 py-5 sm:px-5 sm:py-5">
              <div className="AppWrapper">Ai facut plata cu succes!</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccesPayCompany;
