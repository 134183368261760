import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { auth } from "../../api";
import { addCommentToAppointment } from "../../api/createAppoinment";
import { getAppoimentById } from "../../api/getAppoiments.api";

const NotiteClient = (props) => {
  const [comment, setComment] = useState("");
  const { id } = useParams();
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getAppoimentById(idToken, id).then((res) => {
          setComment(res.appointment.comment);
        });
      });
  }, []);

  return (
    <>
      <form>
        <div>
          <div className="bg-white overflow-hidden shadow rounded-lg h-full">
            <div className="px-4 py-5 sm:p-10">
              <div className="mb-3">
                <p className="text-xl font-bold text-secondary font-openSans">
                  Notite sedinta
                </p>
              </div>
              <div className="bg-white overflow-hidden rounded-lg">
                <div className="">
                  <div className="mt-1">
                    <p>
                      {comment
                        ? comment
                        : "Nu exista notite pentru aceasta sedinta."}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default NotiteClient;
