import React from "react";
import ReactDOM from "react-dom";
import { StarIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const InformatiiCont = (props) => {
  return (
    <>
      <div className="font-openSans text-secondary flex flex-col flex-nowrap h-full ">
        <div className="mb-3">
          <p className="text-lg font-bold">Informatii de Cont</p>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg h-full">
          <div className="px-4 py-5 sm:p-6">
            <div className="grid grid-cols-1 lg:grid-cols-4">
              <div className="col-span-1 text-center">
                <div
                  className="flex-shrink-0 inline-block rounded-full overflow-hidden h-auto w-32 h-32 justify-centers items-center"
                  aria-hidden="true"
                >
                  <img
                    className="rounded-full h-full w-full border-primary p-2 border-2 object-cover object-center"
                    src={props.avatarUrl}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-span-3">
                <div>
                  <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div>
                      <p className="font-bold text-primary text-sm">
                        {props.specializationType}
                      </p>
                      <p className="text-3xl text-secondary font-bold">
                        {props.name}
                      </p>
                      <p className="text-base text-gray-primary font-normal">
                        {props.specializationName}
                      </p>
                    </div>
                    <div className="my-auto">
                      <div className="lg:float-right float-left mt-2 mb-1 lg:m-0">
                        <Link to="/dashboard/specialist/setari-cont">
                          <button className="w-32 flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                            Editeaza profil
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="border-t-2 mt-1 pt-2">
                    <p className="font-bold text-lg">{props.city}</p>
                    <div>
                      <div className="mt-1 flex items-center">
                        <div>
                          <div className="flex items-center">
                            {[0, 1, 2, 3, 4].map((rating) => (
                              <StarIcon
                                key={rating}
                                className={classNames(
                                  props.reviewsAverage > rating
                                    ? "text-primary"
                                    : "text-gray-300",
                                  "flex-shrink-0 h-5 w-5"
                                )}
                                aria-hidden="true"
                              />
                            ))}
                          </div>
                          <p className="sr-only">
                            {props.reviewsAverage} out of 5 stars
                          </p>
                        </div>
                        <p className="ml-2 text-sm text-gray-900">
                          ({props.reviewsCount} reviews)
                        </p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <p className="font-bold text-lg">Descriere</p>
                      <div className="mt-2">
                        <p className="font-raleway font-medium text-justify">
                          {props.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InformatiiCont;
