import React from "react";
import { Link } from "react-router-dom";
import { Footer, Header, Seo } from "../../components";
import FormContact from "../../components/FormContact/FormContact";

const PageNotFound = () => {
  return (
    <>
      <Seo
        title="Pagina nu există - Solution Talk"
        description="Ai încercat să accesezi o pagină care nu exista. Caută pagina dorită în meniul de mai sus!"
      />
      <Header background={true} />
      <section className=" py-20 sm:py-36 px-10 sm:px-20 xl:px-52">
        <div className="flex sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto gap-12 flex-col lg:flex-row">
          <div className="basis-2/2">
            <h1 className=" text-2xl sm:text-3xl xl:text-5xl font-ralewayBold text-secondary uppercase pb-4 xl:pb-6">
              Pagina nu a fost gasită
            </h1>
            <p className=" text-lg sm:text-xl	font-ralewaySemibold text-secondary pb-6 sm:pb-12">
              Din păcate pagina pe care încerci să o accesezi nu există. Poți să
              găsești tot ce ai nevoie pe pagina
              <Link to={"/"}>
                <span className="text-primary"> Home</span>
              </Link>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PageNotFound;
