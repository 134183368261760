import React, { useContext } from "react";
import avatar from "../../assets/img/Elena_Ionescu.jpg";
import setting from "../../assets/img/settings.svg";
import mail from "../../assets/img/envelope.svg";
import hamburger from "../../assets/img/hamburger.svg";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { HandleLogout } from "../../utils";
import {
  BookmarkAltIcon,
  BriefcaseIcon,
  DesktopComputerIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  NewspaperIcon,
  OfficeBuildingIcon,
  ShieldCheckIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { UserContext } from "../../providers/UserProvider";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const menu = [
  { name: "Specialisti", link: "/dashboard/admin/specialisti" },
  { name: "Clienti", link: "/dashboard/admin/clienti" },
  { name: "Companii", link: "/dashboard/admin/companii" },
  { name: "Regulament", link: "/dashboard/admin/regulament" },
];
const HeaderAdmin = () => {
  const { user } = useContext(UserContext);
  return (
    <header className="pt-8 pb-6 px-5 max-w-1753px px-5 m-auto">
      <div className="flex flex-wrap bg-white relative rounded-xl shadow-lg justify-between items-center py-2 pl-3 sm:pl-6 xl:pl-9  pr-3 sm:pr-6 xl:pr-16">
        <div className="flex gap-1 sm:gap-4">
          <div className="rounded-full w-16 h-16">
            <img
              className="rounded-full w-full h-full object-center object-cover"
              src={user.user.avatarUrl}
            />
          </div>
          <div className="flex flex-col justify-center">
            <h4 className="text-xs text-gray-p">Bine ai revenit!</h4>
            <h3 className=" text-xs sm:text-base xl:text-lg text-dark-blue font-bold">
              {user.user.lastName + " " + user.user.firstName}
            </h3>
          </div>
        </div>
        <div className=" hidden lg:block">
          <ul className="flex gap-2 xl:gap-5 2xl:gap-11 font-bold ">
            {menu.map((item, index) => (
              <li
                className={` ${
                  window.location.pathname === item.link
                    ? "text-primary"
                    : "text-light-blue"
                } text-xs xl:text-base hover:text-dark-blue duration-300`}
                key={index}
              >
                <Link to={item.link}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className=" lg lg:hidden">
          <Popover className="">
            {({ open }) => (
              <>
                <div className="">
                  <div className="max-w-7xl mx-auto flex px-4 py-6 sm:px-6 lg:px-8">
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      )}
                    >
                      <img src={hamburger} />
                    </Popover.Button>
                  </div>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 -translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 -translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 inset-x-0 transform shadow-lg bg-white rounded-b-xl">
                    <ul className="flex flex-col gap-2 xl:gap-5 2xl:gap-11 font-bold py-4 pl-3 sm:pl-6">
                      {menu.map((item, index) => (
                        <li
                          className={` ${
                            window.location.pathname === item.link
                              ? "text-primary"
                              : "text-light-blue"
                          } text-xs xl:text-base hover:text-dark-blue duration-300`}
                          key={index}
                        >
                          <Link to={item.link}>{item.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
        <div className="flex gap-2 sm:gap-5 xl:gap-10">
          <button
            onClick={HandleLogout}
            className="flex items-center gap-1.5 text-xs xl:text-base  font-bold text-light-blue hover:text-dark-blue duration-300"
          >
            <span className="block">Log out</span>
          </button>
          <Link to="/dashboard/admin/setari-cont">
            <button className="flex items-center gap-1.5 text-xs xl:text-base font-bold text-light-blue hover:text-dark-blue duration-300">
              <img src={setting} />
              <span className="hidden sm:block">Setari Cont</span>
            </button>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default HeaderAdmin;
