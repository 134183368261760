import React, { useContext, useEffect, useState } from "react";
import {
  logInWithEmailAndPassword,
  signInWithGoogle,
} from "../../api/firebaseConfig";
import { Seo } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/img/Solution_Talk_Logo.png";

const Login = () => {
  const [fields, setFields] = useState({
    email: "",
    password: "",
  });
  const handleInputChange = (e) => {
    let fieldsCopy = Object.assign({}, fields);

    fieldsCopy[e.target.id] = e.target.value;

    setFields(fieldsCopy);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    logInWithEmailAndPassword(fields);
  };

  return (
    <>
      <Seo
        title="Autentifică-te în Contul Tău - Solution Talk"
        description="Autentifică-te în contul tău chiar aici. Solution Talk te pune în legătură cu psihoterapeuți de tot direct din confortul casei tale. Te așteptăm!"
      />
      <div className="min-h-screen flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8 font-raleway font-medium">
        <div className="sm:mx-auto sm:w-full sm:max-w-2xl mx-6 w-11/12">
          <Link to="/">
            <img className="mx-auto h-auto w-2/5" src={logo} alt="Workflow" />
          </Link>
          <h2 className="mt-6 text-center text-3xl font-ptSans font-bold text-gray-900">
            Autentifica-te in contul tau
          </h2>
          <p className="text-center text-xl font-light text-gray-900">
            Ne bucuram sa va revedem! Va rugam sa va autentificati!
          </p>
        </div>

        <div className="mt-8 sm:mx-auto md:w-full sm:max-w-2xl mx-6 w-11/12">
          <div className="bg-white md:py-16 py-10 px-8 shadow sm:rounded-lg md:px-16">
            <form className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-base text-gray-700"
                >
                  Adresa de email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={fields.email}
                    onChange={handleInputChange}
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-base text-gray-700"
                >
                  Parola
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={fields.password}
                    onChange={handleInputChange}
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="flex mb-4">
                <div className="w-1/2 "></div>
                <div className="text-sm w-1/2 text-right">
                  <Link
                    to="/lost-password"
                    className="text-base text-primary hover:text-primary-hover"
                  >
                    Ai uitat parola?
                  </Link>
                </div>
              </div>
              <div>
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                >
                  Autentificare
                </button>
              </div>
            </form>
            <div className="text-center mt-6">
              <p className="font-regular text-xl">
                Inca nu ai un cont?
                <Link
                  to="/register"
                  className="text-primary ml-1 hover:text-primary-hover"
                >
                  Inregistreaza-te acum!
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
