import React, { useContext, useEffect } from "react";
import { Footer, Header, Seo } from "../../components";

const Faq = () => {
  return (
    <>
      <Seo title="Acasa" description="Pagina de acasa" />
      <Header />
      Faq
      <Footer />
    </>
  );
};
export default Faq;
