import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { UserContext } from "../../providers/UserProvider";

const SpecializarileMele = (props) => {
  const { user } = useContext(UserContext);
  return (
    <>
      <div className="font-openSans flex flex-col flex-nowrap h-full">
        <div className="mb-3">
          <p className="text-lg font-bold text-secondary">
            Specializarile Mele
          </p>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg h-full">
          <div className="px-4 py-5 sm:p-6">
            <div className="gap-5 flex flex-wrap font-raleway">
              {!user.user.specialistInfo.specializations
                ? ""
                : user.user.specialistInfo.specializations.map((spec) => (
                    <div className="px-5 py-3 rounded-full bg-primary-blur text-black w-fit text-xs">
                      <p>{spec}</p>
                    </div>
                  ))}
            </div>
            <div className="flex items-center justify-center mt-5"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecializarileMele;
