import React from "react";
import "../ContentImage/style.css";
import { Link } from "react-router-dom";
const ContentImage = ({ image, title, paragraph, link, text_link, elipse }) => {
  return (
    <>
      <section className={`bg-white ${elipse ? "-mt-4 2xl:-mt-64" : ""}`}>
        {elipse ? <div className="background-elipse"></div> : ""}

        <div className="bg-body-g px-10 sm:px-20 xl:px-52">
          <div className="sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto  flex flex-col lg:flex-row -mt-0.5 items-center gap-10 xl:gap-32">
            <div className="basis-1/2">
              <h3 className=" text-2xl sm:text-3xl xl:text-5xl font-ralewayBold text-secondary uppercase pb-4 xl:pb-6">
                {title}
              </h3>

              <span className="text-sm sm:text-base xl:text-base font-ralewayRegular text-secondary pb-12 inline-block">
                {paragraph}
              </span>
              <Link
                to={link}
                className="whitespace-nowrap inline-flex items-center uppercase font-ralewayBold justify-center px-14 py-3 border border-transparent rounded-full shadow-lg  text-white bg-primary hover:bg-primary-hover"
              >
                {text_link}
              </Link>
            </div>
            <div className=" basis-1/2">
              <img src={image} className="rounded-xl" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContentImage;
