import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import HeaderSecond from "../../../components/Header/HeaderSecond";
// import { useDropzone, Dropzone } from "react-dropzone";
import Dropzone from "react-dropzone-uploader";
import { Link } from "react-router-dom";
import { updateUser, updateUserAvatar } from "../../../api/saveSettings.api";
import HeaderClient from "../../../components/Header/HeaderClient";
import { auth } from "../../../api";
import { getSpecialist } from "../../../api/getSpecialist.api";
import { storage } from "../../../api/firebaseConfig";
import { UserContext } from "../../../providers/UserProvider";
// import { toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import HeaderAdmin from "../../../components/Header/HeaderAdmin";

const SetariContAdmin = () => {
  const [files, setFiles] = useState();
  const { user } = useContext(UserContext);
  const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = useState("");
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);
  const handleImageAsFile = ({ meta, file }, status) => {
    setImageAsFile((imageFile) => file);
  };

  /*
    Functie de urcat imaginile de avatar in firebase
  */
  const handleFireBaseUpload = (e) => {
    e.preventDefault();

    if (false) {
      console.log("test-----------------------");
      let pictureRef = storage.refFromURL(files[0]);

      //2.
      pictureRef
        .delete()
        .then(() => {
          const uploadTask = storage
            .ref(`/${user.user.uid}/avatar/${imageAsFile.name}`)
            .put(imageAsFile);
          uploadTask.on(
            "state_changed",
            (snapShot) => {
              //takes a snap shot of the process as it is happening
              console.log(snapShot);
            },
            (err) => {
              //catches the errors
              console.log(err);
            },
            () => {
              // gets the functions from storage refences the image storage in firebase by the children
              // gets the download url then sets the image from firebase as the value for the imgUrl key:
              storage
                .ref(`/${user.user.uid}/avatar/`)
                .child(imageAsFile.name)
                .getDownloadURL()
                .then((fireBaseUrl) => {
                  updateUserAvatar(fireBaseUrl);
                  console.log(fireBaseUrl);
                  setImageAsUrl((prevObject) => ({
                    ...prevObject,
                    imgUrl: fireBaseUrl,
                  }));
                });
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const uploadTask = storage
        .ref(`/${user.user.uid}/avatar/${imageAsFile.name}`)
        .put(imageAsFile);

      uploadTask.on(
        "state_changed",
        (snapShot) => {
          console.log(snapShot);
        },
        (err) => {
          console.log(err);
        },
        () => {
          storage
            .ref(`/${user.user.uid}/avatar/`)
            .child(imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              auth
                .auth()
                .currentUser.getIdToken(/* forceRefresh */ true)
                .then(async function (idToken) {
                  updateUserAvatar(idToken, fireBaseUrl).then((res) => {
                    if (res.status == "200") {
                      toast.success("Imagine schimbata cu succes", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }
                  });
                });
              setImageAsUrl((prevObject) => ({
                ...prevObject,
                imgUrl: fireBaseUrl,
              }));
            });
        }
      );
    }
  };

  const clientObj = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    street: "",
    streetNr: "",
    building: "",
    staircase: "",
    apartment: "",
    avatarUrl: "",
    isCompany: "",
    companyInfo: {
      companyName: "",
      companyCUI: "",
      companyRegNr: "",
      companyTVACode: "",
      companyCity: "",
      companyRegion: "",
      companyEmail: "",
      companyStreetNr: "",
      companyBuilding: "",
      companyStaircase: "",
      companyApartment: "",
      companyStreet: "",
    },
  };
  const [client, setClient] = useState(clientObj);
  useEffect(() => {
    // Luam imaginea de avatar din baza de date
    const fetchImages = async () => {
      let result = await storage
        .ref()
        .child(`/${user.user.uid}/avatar/`)
        .listAll();
      let urlPromises = result.items.map((imageRef) =>
        imageRef.getDownloadURL()
      );

      return Promise.all(urlPromises);
    };

    const loadImages = async () => {
      const urls = await fetchImages();
      setFiles([urls[0]]);
    };
    loadImages();
    setClient(user.user);
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    setClient((prevState) => ({
      ...prevState, // shallow copy all previous state
      [name]: value, // update specific key/value
    }));
  };

  const onChangeCompanyInfo = (event) => {
    const { name, value } = event.target;
    setClient((prevState) => ({
      ...prevState,
      companyInfo: {
        ...prevState.companyInfo, // shallow copy all previous state
        [name]: value, // update specific key/value
      },
    }));
  };

  const handleSubmit = () => {
    {
      auth
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(async function (idToken) {
          updateUser(idToken, client).then((res) => {
            if (res.status == "200") {
              toast.success("Setari schimbate cu succes!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
        });
    }
  };
  return (
    <>
      <HeaderAdmin />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <form>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 xl:gap-20 mb-10">
            <div></div>
            <div>
              <div className="grid grid-cols-2 lg:grid-cols-2 gap-5 lg:w-3/5 lg:float-right">
                <div>
                  <div className="my-auto">
                    <div className="mt-2 mb-1 lg:m-0">
                      <button
                        onClick={handleSubmit}
                        type="button"
                        className="w-full flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                      >
                        Salveaza Informatia
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="my-auto">
                    <div className="mt-2 mb-1 lg:m-0">
                      <Link to="/dashboard/specialist/contul-meu">
                        <button className="w-full flex justify-center py-4 px-2 border rounded-md shadow-sm text-xs border-primary text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                          Inchide Contul
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" grid grid-cols-1 2xl:grid-cols-2 gap-5 font-openSans font-bold text-secondary">
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
                <div>
                  <div className="mb-3">
                    <p className="text-lg">Informatii cont</p>
                  </div>
                  <div className="bg-white overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <div>
                        <label htmlFor="lastName" className="block text-sm  ">
                          Nume*
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChange}
                            value={client.lastName}
                            type="text"
                            name="lastName"
                            id="lastName"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="firstName" className="block text-sm  ">
                          Prenume*
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChange}
                            value={client.firstName}
                            type="text"
                            name="firstName"
                            id="firstName"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="email" className="block text-sm  ">
                          Adresa Email*
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChange}
                            value={client.email}
                            type="text"
                            name="email"
                            id="email"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="phone" className="block text-sm  ">
                          Telefon*
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChange}
                            value={client.phone}
                            type="text"
                            name="phone"
                            id="phone"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 md:mt-0">
                  <div className="mb-3">
                    <p className="text-lg">Adresa</p>
                  </div>
                  <div className="bg-white overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <div>
                        <label htmlFor="city" className="block text-sm  ">
                          Orasul
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChange}
                            value={client.city}
                            type="text"
                            name="city"
                            id="city"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="region" className="block text-sm  ">
                          Judetul
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChange}
                            value={client.region}
                            type="text"
                            name="region"
                            id="region"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="street" className="block text-sm  ">
                          Strada
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChange}
                            value={client.street}
                            type="text"
                            name="street"
                            id="street"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4 grid grid-cols-4 gap-x-5">
                        <div>
                          <label htmlFor="streetNr" className="block text-sm  ">
                            Nr.
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChange}
                              value={client.streetNr}
                              type="text"
                              name="streetNr"
                              id="streetNr"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="building" className="block text-sm  ">
                            Bloc
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChange}
                              value={client.building}
                              type="text"
                              name="building"
                              id="building"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="staircase"
                            className="block text-sm  "
                          >
                            Sc.
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChange}
                              value={client.staircase}
                              type="text"
                              name="staircase"
                              id="staircase"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="apartment"
                            className="block text-sm  "
                          >
                            Ap.
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChange}
                              value={client.apartment}
                              type="text"
                              name="apartment"
                              id="apartment"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-3 mt-5">
                  <p className="text-lg">Date de Firma/Facturare</p>
                </div>
                <div className="mt-5 bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6 grid grid-cols-1 md:grid-cols-2 gap-x-5">
                    <div>
                      <div>
                        <label
                          htmlFor="companyName"
                          className="block text-sm  "
                        >
                          Denumire firma*
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeCompanyInfo}
                            value={
                              !client.companyInfo
                                ? ""
                                : client.companyInfo.companyName
                            }
                            ty
                            type="text"
                            name="companyName"
                            id="companyName"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="companyCUI" className="block text-sm  ">
                          Cod Unic de Inregistrare*
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeCompanyInfo}
                            value={
                              !client.companyInfo
                                ? ""
                                : client.companyInfo.companyCUI
                            }
                            ty
                            type="text"
                            name="companyCUI"
                            id="companyCUI"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="companyRegNr"
                          className="block text-sm  "
                        >
                          Nr. Inregistrare Registrul Comertului
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeCompanyInfo}
                            value={
                              !client.companyInfo
                                ? ""
                                : client.companyInfo.companyRegNr
                            }
                            ty
                            type="text"
                            name="companyRegNr"
                            id="companyRegNr"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="companyTVACode"
                          className="block text-sm  "
                        >
                          Cod TVA
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeCompanyInfo}
                            value={
                              !client.companyInfo
                                ? ""
                                : client.companyInfo.companyTVACode
                            }
                            ty
                            type="text"
                            name="companyTVACode"
                            id="companyTVACode"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <label
                          htmlFor="companyCity"
                          className="block text-sm  "
                        >
                          Orasul
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeCompanyInfo}
                            value={
                              !client.companyInfo
                                ? ""
                                : client.companyInfo.companyCity
                            }
                            ty
                            type="text"
                            name="companyCity"
                            id="companyCity"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="companyRegion"
                          className="block text-sm  "
                        >
                          Judetul
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeCompanyInfo}
                            value={
                              !client.companyInfo
                                ? ""
                                : client.companyInfo.companyRegion
                            }
                            ty
                            type="text"
                            name="companyRegion"
                            id="companyRegion"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="comapnyStreet"
                          className="block text-sm  "
                        >
                          Strada
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeCompanyInfo}
                            value={
                              !client.companyInfo
                                ? ""
                                : client.companyInfo.companyStreet
                            }
                            type="text"
                            name="companyStreet"
                            id="comapnyStreet"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4 grid grid-cols-4 gap-x-5">
                        <div>
                          <label
                            htmlFor="companyStreetNr"
                            className="block text-sm  "
                          >
                            Nr.
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChangeCompanyInfo}
                              value={
                                !client.companyInfo
                                  ? ""
                                  : client.companyInfo.companyStreetNr
                              }
                              type="text"
                              name="companyStreetNr"
                              id="companyStreetNr"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="companyBuilding"
                            className="block text-sm  "
                          >
                            Bloc
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChangeCompanyInfo}
                              value={
                                !client.companyInfo
                                  ? ""
                                  : client.companyInfo.companyBuilding
                              }
                              type="text"
                              name="companyBuilding"
                              id="companyBulding"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="companyStaircase"
                            className="block text-sm  "
                          >
                            Sc.
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChangeCompanyInfo}
                              value={
                                !client.companyInfo
                                  ? ""
                                  : client.companyInfo.companyStaircase
                              }
                              type="text"
                              name="companyStaircase"
                              id="companyStaircase"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="companyApartment"
                            className="block text-sm  "
                          >
                            Ap.
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChangeCompanyInfo}
                              value={
                                !client.companyInfo
                                  ? ""
                                  : client.companyInfo.companyApartment
                              }
                              type="text"
                              name="companyApartment"
                              id="companyApartment"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
                <div className="mt-5 md:mt-0">
                  <div className="grid grid-cols-1  gap-5">
                    <div>
                      <div className="mb-3">
                        <p className="text-lg">Imagine Avatar</p>
                      </div>

                      <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6 sm:py-10">
                          {user.user.avatarUrl ? (
                            <div className="text-center m-auto">
                              <img
                                className="m-auto"
                                // width={100}
                                height={30}
                                src={user.user.avatarUrl}
                              />
                            </div>
                          ) : (
                            <p className="px-16 text-center text-gray-inactive-text">
                              Nu aveti o imagine incarcata in acest moment
                            </p>
                          )}
                          <>
                            <Dropzone
                              classNames="h-full w-full overflow-hidden"
                              onChangeStatus={handleImageAsFile}
                              onSubmit={handleFireBaseUpload}
                              SubmitButtonComponent={(e) => {
                                console.log(e);
                                return (
                                  <>
                                    <button
                                      onClick={handleFireBaseUpload}
                                      type="button"
                                      className="w-32 flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                                    >
                                      Incarca
                                    </button>
                                  </>
                                );
                              }}
                              styles={{
                                dropzone: {
                                  overflow: "hidden",
                                  border: 0,
                                  minHeight: "64px",
                                },
                              }}
                              inputContent={
                                <div className="flex items-center  justify-center font-normal">
                                  <svg
                                    id="Component_4_1"
                                    data-name="Component 4 – 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="21.699"
                                    height="40"
                                    viewBox="0 0 21.699 40"
                                  >
                                    <text
                                      id="_"
                                      data-name="+"
                                      transform="translate(3.875 32)"
                                      fill="#f89a76"
                                      fontSize="34"
                                      fontFamily="Raleway-Regular, Raleway"
                                    >
                                      <tspan x="0" y="0">
                                        +
                                      </tspan>
                                    </text>
                                    <g
                                      id="Ellipse_37"
                                      data-name="Ellipse 37"
                                      transform="translate(0 9.075)"
                                      fill="none"
                                      stroke="#f89a76"
                                      strokeWidth="2"
                                    >
                                      <circle
                                        cx="10.85"
                                        cy="10.85"
                                        r="10.85"
                                        stroke="none"
                                      />
                                      <circle
                                        cx="10.85"
                                        cy="10.85"
                                        r="9.85"
                                        fill="none"
                                      />
                                    </g>
                                  </svg>

                                  <p className="inline-block text-primary font-raleway text-sm ml-1 font-bold">
                                    Incarcati imagine
                                  </p>
                                </div>
                              }
                              accept="image/*,audio/*,video/*"
                            />
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
                  <div className="mt-5 md:mt-0">
                    <div className="grid grid-cols-1  gap-5">
                      <div>
                        <div className="mb-3">
                          <p className="text-lg">ID public</p>
                        </div>

                        <div className="bg-white overflow-hidden shadow rounded-lg">
                          <div className="px-4 py-5 sm:p-6 ">
                            <div className="relative flex items-start">
                              <div className=" text-sm">
                                <p>ID-ul tau public este:</p>
                                <p>{user.user.publicId}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SetariContAdmin;
