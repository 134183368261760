import React, { useState, useEffect, useContext, useRef } from "react";
import ReactDOM from "react-dom";
import HeaderSecond from "../../../components/Header/HeaderSecond";
import Dropzone from "react-dropzone-uploader";
import { Link } from "react-router-dom";
import {
  cerereAprobareSp,
  updateUser,
  updateUserAvatar,
} from "../../../api/saveSettings.api";
import { auth } from "../../../api";
import { storage } from "../../../api/firebaseConfig";
import { UserContext } from "../../../providers/UserProvider";
import { ToastContainer, toast } from "react-toastify";
import { ProtectedRouteLoggedSpecialist } from "../../../utils/ProtectedRoute";
import DocumenteStudii from "./DocumenteStudii";
import { HandleLogout } from "../../../utils";
import { deleteSelfAccount } from "../../../api/auth.api";
const SetariCont = () => {
  const addSpecRef = useRef();
  const trTitle = useRef();
  const trSchool = useRef();
  const { user, setUser } = useContext(UserContext);
  const [files, setFiles] = useState();
  const [isActiveVideo, setActiveVideo] = useState(false);
  const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = useState("");
  const [deleteAccountVerify, setDeleteAccountVerfiy] = useState("");
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);
  var [toAddSpec, setToAddSpec] = useState("");
  var [specialistSpecs, setSpecialistSpecs] = useState([]);

  const [document, setDocument] = useState();
  const [trainingTitle, setTrainingTitle] = useState();
  const [trainingSchool, setTrainingSchool] = useState();
  const [schoolTitle, setSchoolTitle] = useState();
  const [schoolName, setSchoolName] = useState();
  const [schoolDocName, setSchoolDocName] = useState();
  var [showPopup, setShowPopup] = useState(false);
  const handleImageAsFile = ({ meta, file }, status) => {
    setImageAsFile((imageFile) => file);
  };

  const deleteAccount = (e) => {
    if (deleteAccountVerify == "solutiontalk") {
      auth
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(async function (idToken) {
          deleteSelfAccount(idToken).then((resdelete) => {
            if (resdelete.status == 200) {
              auth
                .auth()
                .currentUser.delete()
                .then((res) => {
                  toast.success("Contul a fost sters cu succes!", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                })
                .catch((err) => {
                  toast.error(
                    "Trebuie sa va logati din nou pentru a putea sterge contul!",
                    {
                      position: "bottom-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                });
            } else {
              toast.error("Eroare necunoscuta la stergerea contului!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
        });
    } else {
      toast.error("Nu ati completat corect textul de verificare!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onChangeSchoolTitle = (e) => {
    e.preventDefault();
    setSchoolTitle(e.target.value);
  };

  const onChangeSchoolName = (e) => {
    e.preventDefault();
    setSchoolName(e.target.value);
  };

  const onChangeSchoolDocName = (e) => {
    e.preventDefault();
    setSchoolDocName(e.target.value);
  };

  const onChangeTrainingTitle = (e) => {
    e.preventDefault();
    setTrainingTitle(e.target.value);
  };

  const onChangeTrainingSchool = (e) => {
    e.preventDefault();
    setTrainingSchool(e.target.value);
  };

  const handleDocument = ({ meta, file }, status) => {
    setDocument(file);
    setImageAsFile((imageFile) => file);
  };

  const onChangeSpec = (e) => {
    e.preventDefault();

    toAddSpec = e.target.value;
  };

  const onSaveSpec = (e) => {
    specialistSpecs.push(toAddSpec);

    setClient((prevState) => ({
      ...prevState,
      specialistInfo: {
        ...prevState.specialistInfo, // shallow copy all previous state
        ["specializations"]: specialistSpecs, // update specific key/value
      },
    }));

    addSpecRef.current.value = "";
  };

  const deleteTraining = (e, index) => {
    e.preventDefault();

    specialistTrainings.splice(index, 1);

    setClient((prevState) => ({
      ...prevState,
      specialistInfo: {
        ...prevState.specialistInfo, // shallow copy all previous state
        ["trainings"]: specialistTrainings, // update specific key/value
      },
    }));
  };

  const deleteSpecs = (e, index) => {
    e.preventDefault();

    specialistSpecs.splice(index, 1);
    setClient((prevState) => ({
      ...prevState,
      specialistInfo: {
        ...prevState.specialistInfo, // shallow copy all previous state
        ["specializations"]: specialistSpecs, // update specific key/value
      },
    }));
  };

  const deleteSchool = (e, index) => {
    e.preventDefault();

    spUni.splice(index, 1);
    setClient((prevState) => ({
      ...prevState,
      specialistInfo: {
        ...prevState.specialistInfo, // shallow copy all previous state
        ["schools"]: spUni, // update specific key/value
      },
    }));
  };

  /*
    Functie pentru adaugarea documentelor de catre specialist in firebase
  */
  const handleFireBaseUploadDocuments = (e) => {
    e.preventDefault();

    const uploadTask = storage
      .ref(`/${user.user.uid}/documents/${document.name}`)
      .put(document);

    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
      },
      (err) => {
        //catches the errors
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref(`/${user.user.uid}/documents/`)
          .child(document.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            storageDocuments.push({ name: document.name, url: fireBaseUrl });

            setClient((prevState) => ({
              ...prevState,
              specialistInfo: {
                ...prevState.specialistInfo, // shallow copy all previous state
                ["documents"]: storageDocuments, // update specific key/value
              },
            }));
          });
      }
    );
  };
  const handleSubmitTraining = (e) => {
    e.preventDefault();
    if (trainingSchool && trainingTitle) {
      specialistTrainings.push({
        title: trainingTitle,
        school: trainingSchool,
      });

      setClient((prevState) => ({
        ...prevState,
        specialistInfo: {
          ...prevState.specialistInfo, // shallow copy all previous state
          ["trainings"]: specialistTrainings, // update specific key/value
        },
      }));

      trTitle.current.value = "";
      trSchool.current.value = "";
      setTrainingSchool("");
      setTrainingTitle("");
    }
  };

  const handleSubmitSchool = (e) => {
    e.preventDefault();
    if (schoolTitle && schoolName) {
      spUni.push({
        title: schoolTitle,
        name: schoolName,
        docName: schoolDocName,
      });
      //
      setClient((prevState) => ({
        ...prevState,
        specialistInfo: {
          ...prevState.specialistInfo, // shallow copy all previous state
          ["schools"]: spUni, // update specific key/value
        },
      }));

      setSchoolName("");
      setSchoolTitle("");
      setSchoolDocName("");
    }
  };

  /*
    Functie de incarcare imagine de avatar in firebase
  */
  const handleFireBaseUpload = (e) => {
    e.preventDefault();

    if (imageAsFile === "") {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
    }
    if (files) {
      let pictureRef = storage.refFromURL(files[0]);

      //2.
      pictureRef
        .delete()
        .then(() => {
          const uploadTask = storage
            .ref(`/${user.user.uid}/avatar/${imageAsFile.name}`)
            .put(imageAsFile);
          uploadTask.on(
            "state_changed",
            (snapShot) => {
              //takes a snap shot of the process as it is happening
            },
            (err) => {
              //catches the errors
            },
            () => {
              // gets the functions from storage refences the image storage in firebase by the children
              // gets the download url then sets the image from firebase as the value for the imgUrl key:
              storage
                .ref(`/${user.user.uid}/avatar/`)
                .child(imageAsFile.name)
                .getDownloadURL()
                .then((fireBaseUrl) => {
                  auth
                    .auth()
                    .currentUser.getIdToken(/* forceRefresh */ true)
                    .then(async function (idToken) {
                      updateUserAvatar(idToken, fireBaseUrl).then((res) => {
                        if (res.status == "200") {
                          setUser((prevState) => ({
                            ...prevState,
                            ["avatarUrl"]: fireBaseUrl,
                          }));

                          toast.success("Imagine schimbata cu succes", {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        }
                      });
                    });
                  setImageAsUrl((prevObject) => ({
                    ...prevObject,
                    imgUrl: fireBaseUrl,
                  }));
                });
            }
          );
        })
        .catch((err) => {});
    } else {
      const uploadTask = storage
        .ref(`/${user.user.uid}/avatar/${imageAsFile.name}`)
        .put(imageAsFile);

      uploadTask.on(
        "state_changed",
        (snapShot) => {
          //takes a snap shot of the process as it is happening
        },
        (err) => {
          //catches the errors
        },
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          storage
            .ref(`/${user.user.uid}/avatar/`)
            .child(imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              auth
                .auth()
                .currentUser.getIdToken(/* forceRefresh */ true)
                .then(async function (idToken) {
                  updateUserAvatar(idToken, fireBaseUrl).then((res) => {
                    if (res.status == "200") {
                      // De adaugat set pentru refresh avatar
                      setUser((prevState) => ({
                        ...prevState,
                        user: {
                          ...prevState.user, // shallow copy all previous state
                          ["avatarUrl"]: fireBaseUrl, // update specific key/value
                        },
                      }));

                      toast.success("Imagine schimbata cu succes", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }
                  });
                });
              // updateUserAvatar(fireBaseUrl).then((res) =>
              setImageAsUrl((prevObject) => ({
                ...prevObject,
                imgUrl: fireBaseUrl,
              }));
            });
        }
      );
    }
  };

  const clientObj = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    street: "",
    streetNr: "",
    building: "",
    staircase: "",
    apartment: "",
    videoUrl: "",
    description: "",
    companyInfo: {
      companyName: "",
      companyCUI: "",
      companyRegNr: "",
      companyTVACode: "",
      companyCity: "",
      companyRegion: "",
      companyEmail: "",
      companyStreetNr: "",
      companyBuilding: "",
      companyStaircase: "",
      companyApartment: "",
      companyStreet: "",
    },
    specialistInfo: {
      specializationType: "",
      specializationName: "",
      yearsExperience: "",
      collegeCode: "",
      license: "off",
      masters: "off",
      doctorate: "off",
      university: "",
      otherCourses: "",
      lastStudies: "",
      price: "",
      price25: "",
      price50: "",
      price90: "",
      training: "",
      companyDiscount: "",
      specializations: [],
      documents: [],
      trainings: [],
      schools: [],
    },
  };

  var [storageDocuments, setStorageDocuments] = useState([]);
  var [spUni, setSpecialistSchools] = useState([]);

  var [specialistTrainings, setSpecialistTrainings] = useState([]);

  var ref = useRef();
  const [client, setClient] = useState(clientObj);

  useEffect(() => {
    setClient(user.user);
    setSpecialistTrainings(user.user.specialistInfo.trainings);
    if (user.user.specialistInfo.schools) {
      setSpecialistSchools(user.user.specialistInfo.schools);
    }
    setStorageDocuments(user.user.specialistInfo.documents);
    setSpecialistSpecs(user.user.specialistInfo.specializations);
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;

    setClient((prevState) => ({
      ...prevState, // shallow copy all previous state
      [name]: value, // update specific key/value
    }));
  };
  const onChangeCompanyInfo = (event) => {
    const { name, value } = event.target;
    setClient((prevState) => ({
      ...prevState,
      companyInfo: {
        ...prevState.companyInfo, // shallow copy all previous state
        [name]: value, // update specific key/value
      },
    }));
  };
  const onChangeSpecialistInfo = (event) => {
    const { name, value } = event.target;
    setClient((prevState) => ({
      ...prevState,
      specialistInfo: {
        ...prevState.specialistInfo, // shallow copy all previous state
        [name]: value, // update specific key/value
      },
    }));
  };

  const onChangeDiscountCheckbox = (event) => {
    var { name, value } = event.target;
    if (event.target.checked) {
      setClient((prevState) => ({
        ...prevState,
        specialistInfo: {
          ...prevState.specialistInfo, // shallow copy all previous state
          ["companyDiscount"]: "on", // update specific key/value
        },
      }));
    } else {
      setClient((prevState) => ({
        ...prevState,
        specialistInfo: {
          ...prevState.specialistInfo, // shallow copy all previous state
          ["companyDiscount"]: "off", // update specific key/value
        },
      }));
    }
  };
  const onChangeSpecialistInfoCheckbox = (event) => {
    var { name, value } = event.target;
    setClient((prevState) => ({
      ...prevState,
      specialistInfo: {
        ...prevState.specialistInfo, // shallow copy all previous state
        [name]: value, // update specific key/value
      },
    }));
  };
  const handleSubmit = () => {
    if (client.phone == null) {
      toast.error("Trebuie sa completati numarul de telefon!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (client.phone.length != 9) {
      toast.error("Numarul de telefon nu este valid!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (client.meetingEmail == null) {
      toast.error("Trebuie sa completati adresa de email pentru meeting!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!client.meetingEmail.includes("@gmail")) {
      toast.error("Adresa de email pentru meeting trebuie sa fie Gmail!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    {
      auth
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(async function (idToken) {
          updateUser(idToken, client).then((res) => {
            if (res.status == "200") {
              toast.success("Setari schimbate cu succes!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
        });
    }
  };

  const cerereAprobare = () => {
    if (client.phone == null) {
      toast.error("Trebuie sa completati numarul de telefon!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (client.phone.length != 9) {
      toast.error("Numarul de telefon nu este valid!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (client.meetingEmail == null) {
      toast.error("Trebuie sa completati adresa de email pentru meeting!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!client.meetingEmail.includes("@gmail")) {
      toast.error("Adresa de email pentru meeting trebuie sa fie Gmail!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    {
      auth
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(async function (idToken) {
          cerereAprobareSp(idToken, client).then((res) => {
            if (res.status == "200") {
              toast.success(
                "Cererea a fost trimisa catre administrator! Va rugam sa asteptati aprobarea contului!",
                {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }
          });
        });
    }
  };
  return (
    <>
      <HeaderSecond />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <form>
          <div className="grid grid-cols-2 lg:grid-cols-12 gap-5 xl:gap-20 mb-10">
            <div className="col-span-4">
              <div className=" w-full grid grid-cols-1 lg:grid-cols-2 gap-5 ">
                <div>
                  <div className="my-auto">
                    <div className="mt-2 mb-1 lg:m-0">
                      <Link to="/dashboard/specialist/setari-cont">
                        <button className="w-full flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                          Date Cont
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="my-auto">
                    <div className="mt-2 mb-1 lg:m-0">
                      <Link to="/dashboard/specialist/contul-meu">
                        <button className="w-full flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-gray-inactive-text bg-gray-inactive-bg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                          Contul Meu
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-4">
              {user.user.approved ? (
                ""
              ) : (
                <div className="bg-white rounded-lg h-32 px-4">
                  <p className="font-bold font-openSans text-base text-center m-auto py-3">
                    Contul este inactiv. Trebuie aprobat de catre un
                    administrator. Dupa activare, trebuie sa intrati pe link-ul
                    Stripe din meniu pentru a face setarile legate de plata.
                  </p>
                </div>
              )}
            </div>
            <div className="col-span-4">
              <div className=" w-full grid grid-cols-1 lg:grid-cols-2 gap-5  lg:float-right">
                <div>
                  <div className="my-auto">
                    <div className="mt-2 mb-1 lg:m-0">
                      <button
                        onClick={handleSubmit}
                        type="button"
                        className="w-full flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                      >
                        Salveaza Informatia
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="my-auto">
                    <div className="mt-2 mb-1 lg:m-0">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPopup(true);
                        }}
                        className="w-full flex justify-center py-4 px-2 border rounded-md shadow-sm text-xs border-primary text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                      >
                        Inchide Contul
                      </button>
                    </div>
                  </div>
                </div>
                <div className={user.user.approved ? "hidden" : ""}>
                  <div className="my-auto">
                    <div className="mt-2 mb-1 lg:m-0">
                      <button
                        onClick={cerereAprobare}
                        type="button"
                        className="w-full flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                      >
                        CERE APROBARE CONT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" grid grid-cols-1 2xl:grid-cols-2 gap-5 font-openSans font-bold text-secondary">
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
                <div>
                  <div className="mb-3">
                    <p className="text-lg">Informatii cont</p>
                  </div>
                  <div className="bg-white overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <div>
                        <label htmlFor="lastName" className="block text-sm  ">
                          Nume*
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChange}
                            value={client.lastName}
                            type="text"
                            name="lastName"
                            id="lastName"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="firstName" className="block text-sm  ">
                          Prenume*
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChange}
                            value={client.firstName}
                            type="text"
                            name="firstName"
                            id="firstName"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="email" className="block text-sm  ">
                          Adresa Email*
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChange}
                            value={client.email}
                            readOnly
                            type="text"
                            name="email"
                            id="email"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="phone" className="block text-sm  ">
                          Telefon*
                        </label>
                        <div className="mt-2 relative">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="sm:text-sm">+40</span>
                          </div>
                          <input
                            onChange={onChange}
                            value={client.phone}
                            type="text"
                            name="phone"
                            id="phone"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-10 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="email" className="block text-sm  ">
                          Email pentru meeting*
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChange}
                            value={client.meetingEmail}
                            type="text"
                            name="meetingEmail"
                            id="meetingEmail"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <p className="text-sm">
                          *Această adresă trebuie neapărat să fie conectată la
                          un cont de Gmail.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 md:mt-0">
                  <div className="mb-3">
                    <p className="text-lg">Adresa</p>
                  </div>
                  <div className="bg-white overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <div>
                        <label htmlFor="city" className="block text-sm  ">
                          Orasul
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChange}
                            value={client.city}
                            type="text"
                            name="city"
                            id="city"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="region" className="block text-sm  ">
                          Judetul
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChange}
                            value={client.region}
                            type="text"
                            name="region"
                            id="region"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="street" className="block text-sm  ">
                          Strada
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChange}
                            value={client.street}
                            type="text"
                            name="street"
                            id="street"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4 grid grid-cols-4 gap-x-5">
                        <div>
                          <label htmlFor="streetNr" className="block text-sm  ">
                            Nr.
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChange}
                              value={client.streetNr}
                              type="text"
                              name="streetNr"
                              id="streetNr"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="building" className="block text-sm  ">
                            Bloc
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChange}
                              value={client.building}
                              type="text"
                              name="building"
                              id="building"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="staircase"
                            className="block text-sm  "
                          >
                            Sc.
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChange}
                              value={client.staircase}
                              type="text"
                              name="staircase"
                              id="staircase"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="apartment"
                            className="block text-sm  "
                          >
                            Ap.
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChange}
                              value={client.apartment}
                              type="text"
                              name="apartment"
                              id="apartment"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-3 mt-5">
                  <p className="text-lg">Date de Firma/Facturare</p>
                </div>
                <div className="mt-5 bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6 grid grid-cols-1 md:grid-cols-2 gap-x-5">
                    <div>
                      <div>
                        <label
                          htmlFor="companyName"
                          className="block text-sm  "
                        >
                          Denumire firma*
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeCompanyInfo}
                            value={
                              !client.companyInfo
                                ? ""
                                : client.companyInfo.companyName
                            }
                            type="text"
                            name="companyName"
                            id="companyName"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="companyCUI" className="block text-sm  ">
                          Cod Unic de Inregistrare*
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeCompanyInfo}
                            value={
                              !client.companyInfo
                                ? ""
                                : client.companyInfo.companyCUI
                            }
                            type="text"
                            name="companyCUI"
                            id="companyCUI"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="companyRegNr"
                          className="block text-sm  "
                        >
                          Nr. Inregistrare Registrul Comertului
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeCompanyInfo}
                            value={
                              !client.companyInfo
                                ? ""
                                : client.companyInfo.companyRegNr
                            }
                            type="text"
                            name="companyRegNr"
                            id="companyRegNr"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="companyTVACode"
                          className="block text-sm  "
                        >
                          Cod TVA
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeCompanyInfo}
                            value={
                              !client.companyInfo
                                ? ""
                                : client.companyInfo.companyTVACode
                            }
                            type="text"
                            name="companyTVACode"
                            id="companyTVACode"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <label
                          htmlFor="companyCity"
                          className="block text-sm  "
                        >
                          Orasul
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeCompanyInfo}
                            value={
                              !client.companyInfo
                                ? ""
                                : client.companyInfo.companyCity
                            }
                            type="text"
                            name="companyCity"
                            id="companyCity"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="companyRegion"
                          className="block text-sm  "
                        >
                          Judetul
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeCompanyInfo}
                            value={
                              !client.companyInfo
                                ? ""
                                : client.companyInfo.companyRegion
                            }
                            type="text"
                            name="companyRegion"
                            id="companyRegion"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="comapnyStreet"
                          className="block text-sm  "
                        >
                          Strada
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={onChangeCompanyInfo}
                            value={
                              !client.companyInfo
                                ? ""
                                : client.companyInfo.companyStreet
                            }
                            type="text"
                            name="companyStreet"
                            id="comapnyStreet"
                            className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="mt-4 grid grid-cols-4 gap-x-5">
                        <div>
                          <label
                            htmlFor="companyStreetNr"
                            className="block text-sm  "
                          >
                            Nr.
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChangeCompanyInfo}
                              value={
                                !client.companyInfo
                                  ? ""
                                  : client.companyInfo.companyStreetNr
                              }
                              type="text"
                              name="companyStreetNr"
                              id="companyStreetNr"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="companyBuilding"
                            className="block text-sm  "
                          >
                            Bloc
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChangeCompanyInfo}
                              value={
                                !client.companyInfo
                                  ? ""
                                  : client.companyInfo.companyBuilding
                              }
                              type="text"
                              name="companyBuilding"
                              id="companyBulding"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="companyStaircase"
                            className="block text-sm  "
                          >
                            Sc.
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChangeCompanyInfo}
                              value={
                                !client.companyInfo
                                  ? ""
                                  : client.companyInfo.companyStaircase
                              }
                              type="text"
                              name="companyStaircase"
                              id="companyStaircase"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="companyApartment"
                            className="block text-sm "
                          >
                            Ap.
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChangeCompanyInfo}
                              value={
                                !client.companyInfo
                                  ? ""
                                  : client.companyInfo.companyApartment
                              }
                              type="text"
                              name="companyApartment"
                              id="companyApartment"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3 mt-5">
                  <p className="text-lg">Descriere Profil</p>
                </div>
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="mt-1">
                      <textarea
                        onChange={onChange}
                        rows={4}
                        name="description"
                        id="description"
                        className="pl-3 pt-3 h-52 border-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        value={client.description}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
                <div>
                  <div>
                    <div className="mb-3">
                      <p className="text-lg">Titlu profesional</p>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <div className="mb-3">
                          <label
                            htmlFor="specializationType"
                            className="block text-sm   mb-2"
                          >
                            Tipul Specializarii
                          </label>
                          <div className="grid grid-cols-3 gap-x-8">
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  onChange={onChangeSpecialistInfoCheckbox}
                                  id="specializationTypePsihoterapeut"
                                  aria-describedby="pecializationTypePsihoterapeut-description"
                                  name="specializationType"
                                  type="radio"
                                  value="Psihoterapeut"
                                  checked={
                                    client.specialistInfo
                                      ? client.specialistInfo
                                          .specializationType == "Psihoterapeut"
                                        ? true
                                        : false
                                      : ""
                                  }
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                              </div>

                              <div className="ml-1 text-sm">
                                <label htmlFor="specialization" className=" ">
                                  Psihoterapeut
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  onChange={onChangeSpecialistInfoCheckbox}
                                  id="specializationTypePsihiatru"
                                  aria-describedby="specializationTypePsihiatru-description"
                                  name="specializationType"
                                  type="radio"
                                  value="Psihiatru"
                                  checked={
                                    client.specialistInfo
                                      ? client.specialistInfo
                                          .specializationType == "Psihiatru"
                                        ? true
                                        : false
                                      : ""
                                  }
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                              </div>
                              <div className="ml-1 text-sm">
                                <label
                                  htmlFor="specializationTypePsihiatru"
                                  className=" "
                                >
                                  Psihiatru
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  onChange={onChangeSpecialistInfoCheckbox}
                                  id="specializationTypeCoach"
                                  aria-describedby="specializationTypeCoach-description"
                                  name="specializationType"
                                  type="radio"
                                  value="Coach"
                                  checked={
                                    client.specialistInfo
                                      ? client.specialistInfo
                                          .specializationType == "Coach"
                                        ? true
                                        : false
                                      : ""
                                  }
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                              </div>
                              <div className="ml-1 text-sm">
                                <label
                                  htmlFor="specializationTypeCoach"
                                  className=" "
                                >
                                  Coach
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mt-4">
                          <label
                            htmlFor="yearsExperience"
                            className="block text-sm  "
                          >
                            Ani Experienta
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChangeSpecialistInfo}
                              value={
                                !client.specialistInfo
                                  ? ""
                                  : client.specialistInfo.yearsExperience
                              }
                              type="text"
                              name="yearsExperience"
                              id="yearsExperience"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="collegeCode"
                            className="block text-sm  "
                          >
                            Codul de Colegiu
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={onChangeSpecialistInfo}
                              value={
                                !client.specialistInfo
                                  ? ""
                                  : client.specialistInfo.collegeCode
                              }
                              type="text"
                              name="collegeCode"
                              id="collegeCode"
                              className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div className="my-3">
                          <label
                            htmlFor="lastStudies"
                            className="block text-sm   mb-2"
                          >
                            Ultimele studii absolvite
                          </label>
                          <div className="grid grid-cols-3 gap-x-3">
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  onChange={onChangeSpecialistInfoCheckbox}
                                  checked={
                                    client.specialistInfo
                                      ? client.specialistInfo.lastStudies ==
                                        "Licenta"
                                        ? true
                                        : false
                                      : ""
                                  }
                                  id="license"
                                  aria-describedby="license-description"
                                  name="lastStudies"
                                  type="radio"
                                  value="Licenta"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="license" className=" ">
                                  Licenta
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  onChange={onChangeSpecialistInfoCheckbox}
                                  id="masters"
                                  aria-describedby="masters-description"
                                  name="lastStudies"
                                  type="radio"
                                  value="Masterat"
                                  checked={
                                    client.specialistInfo
                                      ? client.specialistInfo.lastStudies ==
                                        "Masterat"
                                        ? true
                                        : false
                                      : ""
                                  }
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="masters" className=" ">
                                  Masterat
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  onChange={onChangeSpecialistInfoCheckbox}
                                  id="doctorate"
                                  aria-describedby="doctorate-description"
                                  name="lastStudies"
                                  type="radio"
                                  value="Doctorat"
                                  checked={
                                    client.specialistInfo
                                      ? client.specialistInfo.lastStudies ==
                                        "Doctorat"
                                        ? true
                                        : false
                                      : ""
                                  }
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor="doctorate" className=" ">
                                  Doctorat
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mt-4">
                          <label
                            htmlFor="otherCourses"
                            className="block text-sm  "
                          >
                            Alte cursuri
                          </label>
                          <div>
                            <textarea
                              onChange={onChangeSpecialistInfo}
                              value={
                                !client.specialistInfo
                                  ? ""
                                  : client.specialistInfo.otherCourses
                              }
                              rows={10}
                              name="otherCourses"
                              id="otherCourses"
                              className="pl-3 pt-3 h-10 border-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-5">
                      <div className="mb-3">
                        <p className="text-lg">Formari</p>
                      </div>
                      <div className="bg-white overflow-hidden shadow rounded-lg font-normal">
                        <div className="px-4 py-5 sm:p-6">
                          <div className="gap-5 font-raleway">
                            {!client.specialistInfo
                              ? ""
                              : !client.specialistInfo.trainings
                              ? ""
                              : specialistTrainings.map((spec, index) => (
                                  <div className="text-black text-xs border-b flex justify-between	">
                                    <div>
                                      <p className="text-lg font-bold text-black">
                                        {spec.title}
                                      </p>
                                      <p className="text-base">{spec.school}</p>
                                    </div>
                                    <p
                                      value={index}
                                      onClick={(e) => deleteTraining(e, index)}
                                      className="text-base cursor-pointer"
                                    >
                                      x
                                    </p>
                                  </div>
                                ))}
                          </div>

                          <div className={"mt-5"}>
                            <input
                              onChange={onChangeTrainingTitle}
                              placeholder={"Titlu"}
                              value={trainingTitle}
                              ref={trTitle}
                              type="text"
                              name="addTrainingTitle"
                              id="addSpec"
                              className={
                                "border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              }
                            />
                            <input
                              onChange={onChangeTrainingSchool}
                              placeholder={"Institutie emitenta"}
                              value={trainingSchool}
                              ref={trSchool}
                              type="text"
                              name="addTrainingSchool"
                              id="addSpec"
                              className={
                                "mt-2 border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              }
                            />
                          </div>
                          <div
                            onClick={handleSubmitTraining}
                            className="flex items-center justify-center mt-5 cursor-pointer"
                          >
                            <svg
                              id="Component_4_1"
                              data-name="Component 4 – 1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="21.699"
                              height="40"
                              viewBox="0 0 21.699 40"
                            >
                              <text
                                id="_"
                                data-name="+"
                                transform="translate(3.875 32)"
                                fill="#f89a76"
                                fontSize="34"
                                fontFamily="Raleway-Regular, Raleway"
                              >
                                <tspan x="0" y="0">
                                  +
                                </tspan>
                              </text>
                              <g
                                id="Ellipse_37"
                                data-name="Ellipse 37"
                                transform="translate(0 9.075)"
                                fill="none"
                                stroke="#f89a76"
                                strokeWidth="2"
                              >
                                <circle
                                  cx="10.85"
                                  cy="10.85"
                                  r="10.85"
                                  stroke="none"
                                />
                                <circle
                                  cx="10.85"
                                  cy="10.85"
                                  r="9.85"
                                  fill="none"
                                />
                              </g>
                            </svg>
                            <p className="inline-block text-primary font-raleway text-sm ml-1 font-bold">
                              Adauga Formare
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      <div className="mb-3">
                        <p className="text-lg">Institutii emitente</p>
                      </div>
                      <div className="bg-white overflow-hidden shadow rounded-lg font-normal">
                        <div className="px-4 py-5 sm:p-6">
                          <div className="gap-5 font-raleway">
                            {!client.specialistInfo
                              ? ""
                              : !client.specialistInfo.schools
                              ? ""
                              : spUni.map((spec, index) => (
                                  <div className="text-black text-xs border-b flex justify-between	">
                                    <div>
                                      <p className="text-lg font-bold text-black">
                                        {spec.title}
                                      </p>
                                      <p className="text-base">{spec.name}</p>
                                      <p className="text-base">
                                        {spec.docName}
                                      </p>
                                    </div>
                                    <p
                                      value={index}
                                      onClick={(e) => deleteSchool(e, index)}
                                      className="text-base cursor-pointer"
                                    >
                                      x
                                    </p>
                                  </div>
                                ))}
                          </div>

                          <div className={"mt-5"}>
                            <select
                              id="location"
                              onChange={onChangeSchoolTitle}
                              name="location"
                              className="mt-1 block border-2 pr-3 w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              defaultValue="Licenta"
                            >
                              <option value={"Licenta"}>Licenta</option>
                              <option value={"Master"}>Master</option>
                              <option value={"Doctorat"}>Doctorat</option>
                            </select>

                            <input
                              onChange={onChangeSchoolName}
                              placeholder={"Institutie emitenta"}
                              value={schoolName}
                              ref={trSchool}
                              type="text"
                              name="addTrainingSchool"
                              id="addSpec"
                              className={
                                "mt-2 border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              }
                            />
                            <input
                              onChange={onChangeSchoolDocName}
                              placeholder={"Nume diploma"}
                              value={schoolDocName}
                              ref={trSchool}
                              type="text"
                              name="addTrainingSchool"
                              id="addSpec"
                              className={
                                "mt-2 border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              }
                            />
                          </div>
                          <div
                            onClick={handleSubmitSchool}
                            className="flex items-center justify-center mt-5 cursor-pointer"
                          >
                            <svg
                              id="Component_4_1"
                              data-name="Component 4 – 1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="21.699"
                              height="40"
                              viewBox="0 0 21.699 40"
                            >
                              <text
                                id="_"
                                data-name="+"
                                transform="translate(3.875 32)"
                                fill="#f89a76"
                                fontSize="34"
                                fontFamily="Raleway-Regular, Raleway"
                              >
                                <tspan x="0" y="0">
                                  +
                                </tspan>
                              </text>
                              <g
                                id="Ellipse_37"
                                data-name="Ellipse 37"
                                transform="translate(0 9.075)"
                                fill="none"
                                stroke="#f89a76"
                                strokeWidth="2"
                              >
                                <circle
                                  cx="10.85"
                                  cy="10.85"
                                  r="10.85"
                                  stroke="none"
                                />
                                <circle
                                  cx="10.85"
                                  cy="10.85"
                                  r="9.85"
                                  fill="none"
                                />
                              </g>
                            </svg>
                            <p className="inline-block text-primary font-raleway text-sm ml-1 font-bold">
                              Adauga Institutie
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 md:mt-0">
                  <div className="grid grid-cols-1 2xl:grid-cols-2 gap-5">
                    <div>
                      <div className="mb-3">
                        <p className="text-lg">Imagine Avatar</p>
                      </div>
                      <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                          {user.user.avatarUrl ? (
                            <div className="text-center m-auto">
                              <img
                                className="m-auto"
                                height={30}
                                src={user.user.avatarUrl}
                              />
                            </div>
                          ) : (
                            <p className="px-16 text-center text-gray-inactive-text">
                              Nu aveti o imagine incarcata in acest moment
                            </p>
                          )}
                          <>
                            <Dropzone
                              classNames="h-full w-full overflow-hidden"
                              onChangeStatus={handleImageAsFile}
                              onSubmit={handleFireBaseUpload}
                              maxFiles={1}
                              SubmitButtonComponent={(e) => {
                                return (
                                  <>
                                    <button
                                      onClick={handleFireBaseUpload}
                                      type="button"
                                      className="w-32 flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                                    >
                                      Incarca
                                    </button>
                                  </>
                                );
                              }}
                              accept="*"
                              styles={{
                                dropzone: {
                                  overflow: "hidden",
                                  border: 0,
                                  minHeight: "64px",
                                },
                              }}
                              inputContent={
                                <div className="flex items-center justify-center font-normal">
                                  <svg
                                    id="Component_4_1"
                                    data-name="Component 4 – 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="21.699"
                                    height="40"
                                    viewBox="0 0 21.699 40"
                                  >
                                    <text
                                      id="_"
                                      data-name="+"
                                      transform="translate(3.875 32)"
                                      fill="#f89a76"
                                      fontSize="34"
                                      fontFamily="Raleway-Regular, Raleway"
                                    >
                                      <tspan x="0" y="0">
                                        +
                                      </tspan>
                                    </text>
                                    <g
                                      id="Ellipse_37"
                                      data-name="Ellipse 37"
                                      transform="translate(0 9.075)"
                                      fill="none"
                                      stroke="#f89a76"
                                      strokeWidth="2"
                                    >
                                      <circle
                                        cx="10.85"
                                        cy="10.85"
                                        r="10.85"
                                        stroke="none"
                                      />
                                      <circle
                                        cx="10.85"
                                        cy="10.85"
                                        r="9.85"
                                        fill="none"
                                      />
                                    </g>
                                  </svg>
                                  <p className="inline-block text-primary font-raleway text-sm ml-1 font-bold">
                                    Incarcati imagine
                                  </p>
                                </div>
                              }
                            />
                          </>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <p className="text-lg">Video de Prezentare</p>
                      </div>
                      <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                          <div
                            onClick={() => setActiveVideo(!isActiveVideo)}
                            className="cursor-pointer flex items-center justify-center font-normal h-16"
                          >
                            <svg
                              id="Component_4_1"
                              data-name="Component 4 – 1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="21.699"
                              height="40"
                              viewBox="0 0 21.699 40"
                            >
                              <text
                                id="_"
                                data-name="+"
                                transform="translate(3.875 32)"
                                fill="#f89a76"
                                fontSize="34"
                                fontFamily="Raleway"
                              >
                                <tspan x="0" y="0">
                                  +
                                </tspan>
                              </text>
                              <g
                                id="Ellipse_37"
                                data-name="Ellipse 37"
                                transform="translate(0 9.075)"
                                fill="none"
                                stroke="#f89a76"
                                strokeWidth="2"
                              >
                                <circle
                                  cx="10.85"
                                  cy="10.85"
                                  r="10.85"
                                  stroke="none"
                                />
                                <circle
                                  cx="10.85"
                                  cy="10.85"
                                  r="9.85"
                                  fill="none"
                                />
                              </g>
                            </svg>
                            <p className="inline-block text-primary font-raleway text-sm ml-1 font-bold">
                              Incarcati video
                            </p>
                          </div>
                          <div
                            className={
                              (isActiveVideo ? "" : "hidden ") + "mt-2"
                            }
                          >
                            <input
                              onChange={onChange}
                              value={client.videoUrl}
                              type="text"
                              name="videoUrl"
                              id="videoUrl"
                              className={
                                "border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="mb-3 mt-5">
                      <p className="text-lg">Setare cost pe sedinta</p>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg mt-5">
                      <div className="px-4 py-5 sm:p-6">
                        <div className="my-auto">
                          <div className="relative flex items-start mt-2">
                            <div className="flex items-center h-5">
                              <input
                                onChange={onChangeDiscountCheckbox}
                                id="companyDiscount"
                                aria-describedby="companyDiscount-description"
                                name="companyDiscount"
                                type="checkbox"
                                checked={
                                  client.specialistInfo
                                    ? client.specialistInfo.companyDiscount ==
                                      "on"
                                      ? true
                                      : false
                                    : ""
                                }
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label htmlFor="companyDiscount" className=" ">
                                Cu Discount de Companie
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-3 mt-5">
                          <div className="col-span-1">
                            <div className="">
                              <input
                                onChange={onChangeSpecialistInfo}
                                value={
                                  !client.specialistInfo
                                    ? ""
                                    : client.specialistInfo.price25
                                }
                                type="text"
                                name="price25"
                                id="price25"
                                className="pl-3 border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder="ex: 100 RON"
                              />
                            </div>
                          </div>
                          <div className="col-span-2 my-auto ml-5">
                            <div className="relative flex items-start mt-2">
                              <div className=" text-sm">
                                <label htmlFor="companyDiscount" className=" ">
                                  25 minute
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-3 mt-5">
                          <div className="col-span-1">
                            <div className="">
                              <input
                                onChange={onChangeSpecialistInfo}
                                value={
                                  !client.specialistInfo
                                    ? ""
                                    : client.specialistInfo.price50
                                }
                                type="text"
                                name="price50"
                                id="price50"
                                className="pl-3 border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder="ex: 100 RON"
                              />
                            </div>
                          </div>
                          <div className="col-span-2 my-auto ml-5">
                            <div className="relative flex items-start mt-2">
                              <div className="text-sm">
                                <label htmlFor="companyDiscount" className=" ">
                                  50 minute
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-3 mt-5">
                          <div className="col-span-1">
                            <div className="">
                              <input
                                onChange={onChangeSpecialistInfo}
                                value={
                                  !client.specialistInfo
                                    ? ""
                                    : client.specialistInfo.price90
                                }
                                type="text"
                                name="price90"
                                id="price90"
                                className="pl-3 border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder="ex: 100 RON"
                              />
                            </div>
                          </div>
                          <div className="col-span-2 my-auto ml-5">
                            <div className="relative flex items-start mt-2">
                              <div className="text-sm">
                                <label htmlFor="companyDiscount" className=" ">
                                  90 minute
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="mb-3">
                      <p className="text-lg">Documente de Studiu</p>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <DocumenteStudii storageDocuments={storageDocuments} />
                        <Dropzone
                          classNames="h-full w-full overflow-hidden"
                          onChangeStatus={handleDocument}
                          onSubmit={handleFireBaseUploadDocuments}
                          accept="*"
                          SubmitButtonComponent={(e) => {
                            return (
                              <>
                                <button
                                  onClick={handleFireBaseUploadDocuments}
                                  type="button"
                                  className="w-32 flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                                >
                                  Incarca
                                </button>
                              </>
                            );
                          }}
                          styles={{
                            dropzone: { overflow: "hidden", border: 0 },
                          }}
                          inputContent={
                            <div className="flex items-center justify-center font-normal">
                              <svg
                                id="Component_4_1"
                                data-name="Component 4 – 1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="21.699"
                                height="40"
                                viewBox="0 0 21.699 40"
                              >
                                <text
                                  id="_"
                                  data-name="+"
                                  transform="translate(3.875 32)"
                                  fill="#f89a76"
                                  fontSize="34"
                                  fontFamily="Raleway-Regular, Raleway"
                                >
                                  <tspan x="0" y="0">
                                    +
                                  </tspan>
                                </text>
                                <g
                                  id="Ellipse_37"
                                  data-name="Ellipse 37"
                                  transform="translate(0 9.075)"
                                  fill="none"
                                  stroke="#f89a76"
                                  strokeWidth="2"
                                >
                                  <circle
                                    cx="10.85"
                                    cy="10.85"
                                    r="10.85"
                                    stroke="none"
                                  />
                                  <circle
                                    cx="10.85"
                                    cy="10.85"
                                    r="9.85"
                                    fill="none"
                                  />
                                </g>
                              </svg>
                              <p className="inline-block text-primary font-raleway text-sm ml-1 font-bold">
                                Incarca documente
                              </p>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="mb-3">
                      <p className="text-lg">Specializari</p>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg font-normal">
                      <div className="px-4 py-5 sm:p-6">
                        <div className="gap-5 flex flex-wrap font-raleway">
                          {!client.specialistInfo
                            ? ""
                            : !client.specialistInfo.specializations
                            ? ""
                            : client.specialistInfo.specializations.map(
                                (spec, index) => (
                                  <div className="px-5 py-3 rounded-full bg-primary-blur text-black w-fit text-xs">
                                    <p>
                                      {spec}{" "}
                                      <span
                                        value={index}
                                        onClick={(e) => deleteSpecs(e, index)}
                                        className=" pl-2 text-base cursor-pointer"
                                      >
                                        x
                                      </span>
                                    </p>
                                  </div>
                                )
                              )}
                        </div>

                        <div className={"mt-5"}>
                          <input
                            onChange={onChangeSpec}
                            ref={addSpecRef}
                            type="text"
                            name="addSpec"
                            id="addSpec"
                            className={
                              "border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            }
                          />
                        </div>
                        <div
                          onClick={onSaveSpec}
                          className="flex items-center justify-center mt-5 cursor-pointer"
                        >
                          <svg
                            id="Component_4_1"
                            data-name="Component 4 – 1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="21.699"
                            height="40"
                            viewBox="0 0 21.699 40"
                          >
                            <text
                              id="_"
                              data-name="+"
                              transform="translate(3.875 32)"
                              fill="#f89a76"
                              fontSize="34"
                              fontFamily="Raleway-Regular, Raleway"
                            >
                              <tspan x="0" y="0">
                                +
                              </tspan>
                            </text>
                            <g
                              id="Ellipse_37"
                              data-name="Ellipse 37"
                              transform="translate(0 9.075)"
                              fill="none"
                              stroke="#f89a76"
                              strokeWidth="2"
                            >
                              <circle
                                cx="10.85"
                                cy="10.85"
                                r="10.85"
                                stroke="none"
                              />
                              <circle
                                cx="10.85"
                                cy="10.85"
                                r="9.85"
                                fill="none"
                              />
                            </g>
                          </svg>
                          <p className="inline-block text-primary font-raleway text-sm ml-1 font-bold">
                            Adauga Specializari
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div
          className={
            (!showPopup ? "hidden " : "") +
            "w-80 h-96 fixed inset-0 m-auto z-50"
          }
        >
          <div className="bg-white overflow-hidden shadow rounded-lg h-full w-full">
            <div className="px-4 py-5 sm:px-6 sm:py-4 bg-dark-blue relative">
              <p className="text-white font-openSans font-bold m-auto">
                Stergere cont
              </p>
              <p
                onClick={(e) => setShowPopup(false)}
                className="text-white cursor-pointer w-fit absolute top-2.5 right-2.5"
              >
                x
              </p>
            </div>
            <div className="px-4 sm:px-6 mt-5">
              <p className="text-secondary font-openSans font-bold m-auto">
                Scrie "solutiontalk" mai jos pentru a confirma stergerea
                contului
              </p>
              <div className="mt-4">
                <input
                  type="text"
                  name="deleteAccountVerify"
                  id="deleteAccountVerify"
                  value={deleteAccountVerify}
                  onChange={(e) => {
                    e.preventDefault();
                    setDeleteAccountVerfiy(e.target.value);
                  }}
                  className="border-2 h-10 shadow-sm focus:ring-indigo-500 pl-3 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <button
                className="w-2/5 flex mx-auto mt-5 justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                onClick={deleteAccount}
                type="button"
              >
                {" "}
                Sterge
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetariCont;
