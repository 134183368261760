import { Link } from "react-router-dom";
import women from "../../assets/homepage/women.png";
import path_footer from "../../assets/homepage/path_footer.png";
import circle_right from "../../assets/homepage/circle_right.png";
import logo_white from "../../assets/img/logo_white.png";
import CookieBot from "react-cookiebot";
import anpcBanner from "../../assets/img/anpc-sal1-1.png";
import solBanner from "../../assets/img/anpc-sol.png";
import stripeBanner from "../../assets/img/stripe.jpg";
const navigation = {
  platform: [
    { name: "Crează Cont", href: "/register" },
    { name: "Accesează Cont", href: "/login" },
  ],
  menu: [
    { name: "Home", href: "/" },
    { name: "Despre Noi", href: "/despre-noi" },
    { name: "Cum funcționează", href: "/cum-functioneaza" },
    { name: "Sfaturi", href: "/blog" },
    { name: "F.A.Q", href: "/contact" },
    { name: "Contact", href: "/contact" },
  ],
  legal: [
    { name: "Termene si Conditii", href: "/termeni-si-conditii" },
    {
      name: "Politica Confidentialitate",
      href: "/politica-de-confidentialitate",
    },
    { name: "Politica Cookies", href: "/politica-cookies" },
    { name: "Data Processing Agreement", href: "/dpa" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/profile.php?id=100078364837351",
      icon: (props) => (
        <svg
          id="Facebook"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="37"
          viewBox="0 0 30 37"
        >
          <rect
            id="Rectangle_392"
            data-name="Rectangle 392"
            width="30"
            height="37"
            fill="none"
          />
          <path
            id="Path_1"
            data-name="Path 1"
            d="M90.031,29.6V16.116h4.6l.658-5.262H90.031V7.564c0-1.48.493-2.631,2.631-2.631h2.8V.164C94.8.164,93.156,0,91.347,0,87.4,0,84.6,2.467,84.6,6.907v3.947H80v5.262h4.6V29.6Z"
            transform="translate(-72.6 0.001)"
            fill="#ceeff1"
            fillRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/solutiontalk.ro/",
      icon: (props) => (
        <svg
          id="Instagram"
          xmlns="http://www.w3.org/2000/svg"
          width="29.6"
          height="29.6"
          viewBox="0 0 29.6 29.6"
        >
          <path
            id="Path_3"
            data-name="Path 3"
            d="M14.8,2.631a45.38,45.38,0,0,1,5.92.164,7.627,7.627,0,0,1,2.8.493,5.775,5.775,0,0,1,2.8,2.8,7.627,7.627,0,0,1,.493,2.8c0,1.48.164,1.973.164,5.92a45.38,45.38,0,0,1-.164,5.92,7.627,7.627,0,0,1-.493,2.8,5.775,5.775,0,0,1-2.8,2.8,7.627,7.627,0,0,1-2.8.493c-1.48,0-1.973.164-5.92.164A45.38,45.38,0,0,1,8.88,26.8a7.627,7.627,0,0,1-2.8-.493,5.775,5.775,0,0,1-2.8-2.8,7.627,7.627,0,0,1-.493-2.8c0-1.48-.164-1.973-.164-5.92A45.38,45.38,0,0,1,2.8,8.88a7.627,7.627,0,0,1,.493-2.8A5.906,5.906,0,0,1,4.44,4.44,2.78,2.78,0,0,1,6.084,3.289,7.627,7.627,0,0,1,8.88,2.8a45.38,45.38,0,0,1,5.92-.164M14.8,0A48.589,48.589,0,0,0,8.716.164,10.152,10.152,0,0,0,5.1.822,6.438,6.438,0,0,0,2.467,2.467,6.438,6.438,0,0,0,.822,5.1,7.493,7.493,0,0,0,.164,8.716,48.589,48.589,0,0,0,0,14.8a48.589,48.589,0,0,0,.164,6.084A10.152,10.152,0,0,0,.822,24.5a6.438,6.438,0,0,0,1.644,2.631A6.438,6.438,0,0,0,5.1,28.778a10.152,10.152,0,0,0,3.618.658A48.588,48.588,0,0,0,14.8,29.6a48.588,48.588,0,0,0,6.084-.164,10.152,10.152,0,0,0,3.618-.658A6.9,6.9,0,0,0,28.778,24.5a10.152,10.152,0,0,0,.658-3.618c0-1.644.164-2.138.164-6.084a48.588,48.588,0,0,0-.164-6.084A10.152,10.152,0,0,0,28.778,5.1a6.438,6.438,0,0,0-1.644-2.631A6.438,6.438,0,0,0,24.5.822,10.152,10.152,0,0,0,20.884.164,48.589,48.589,0,0,0,14.8,0m0,7.236A7.443,7.443,0,0,0,7.236,14.8,7.564,7.564,0,1,0,14.8,7.236m0,12.5A4.845,4.845,0,0,1,9.867,14.8,4.845,4.845,0,0,1,14.8,9.867,4.845,4.845,0,0,1,19.733,14.8,4.845,4.845,0,0,1,14.8,19.733M22.693,5.1A1.809,1.809,0,1,0,24.5,6.907,1.825,1.825,0,0,0,22.693,5.1"
            fill="#ceeff1"
            fillRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Linkedin",
      href: "https://www.linkedin.com/company/solutiontalk/",
      icon: (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29.992"
          height="29.926"
          viewBox="0 0 29.992 29.926"
        >
          <g id="XMLID_801_" transform="translate(0 -0.341)">
            <path
              id="XMLID_802_"
              d="M11.432,99.73H5.411a.484.484,0,0,0-.484.484v19.343a.484.484,0,0,0,.484.484h6.021a.484.484,0,0,0,.484-.484V100.214A.484.484,0,0,0,11.432,99.73Z"
              transform="translate(-4.45 -89.773)"
              fill="#ceeff1"
            />
            <path
              id="XMLID_803_"
              d="M3.973.341A3.97,3.97,0,1,0,7.943,4.31,3.975,3.975,0,0,0,3.973.341Z"
              fill="#ceeff1"
            />
            <path
              id="XMLID_804_"
              d="M118.2,94.761a7.072,7.072,0,0,0-5.29,2.221V95.725a.484.484,0,0,0-.484-.484H106.66a.484.484,0,0,0-.484.484v19.343a.484.484,0,0,0,.484.484h6.008a.484.484,0,0,0,.484-.484V105.5c0-3.225.876-4.481,3.124-4.481,2.448,0,2.643,2.014,2.643,4.647v9.4a.484.484,0,0,0,.484.484h6.01a.484.484,0,0,0,.484-.484v-10.61C125.9,99.663,124.981,94.761,118.2,94.761Z"
              transform="translate(-95.904 -85.285)"
              fill="#ceeff1"
            />
          </g>
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  return (
    <footer
      className=" "
      aria-labelledby="footer-heading"
      style={{
        background: "linear-gradient(to top, #2E324D 77%, transparent 77%)",
      }}
    >
      <CookieBot domainGroupId={"b70c3606-96f5-4ef3-aedc-5481424f1d31"} />
      <section className="bg-footer1 bg-top	bg-cover bg-no-repeat px-10 sm:px-20 xl:px-52">
        <div className="flex sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto  py-20 sm:py-44 gap-10 sm:gap-0 flex-col sm:flex-row">
          <div className="left  sm:pr-6  basis-95%">
            <h2 className="text-3xl xl:text-6xl uppercase font-ralewayBold text-white">
              Inregistrare
            </h2>
            <h2 className="text-3xl xl:text-6xl uppercase font-ralewayBold text-white pb-3"></h2>
            <p className=" text-sm sm:text-lg xl:text-xl text-white font-ralewaySemibold pb-6 sm:pb-14">
              Intră acum în lumea SolutionTalk
            </p>
            <Link
              to="/login"
              className=" whitespace-nowrap inline-flex items-center font-ralewayBold text-sm lg:text-lg  uppercase justify-center px-5 lg:px-10  py-3   rounded-3xl shadow-lg  text-white bg-primary hover:bg-primary-hover"
            >
              FA-TI CONT GRATUIT
            </Link>
          </div>
          <div className="right relative basis-1/2">
            <img
              className="absolute hidden lg:block -top-52 xl:-top-72 -right-2/3"
              src={path_footer}
              alt=""
            />
            <img
              className="hidden lg:block absolute -bottom-32"
              src={circle_right}
              alt=""
            />
            <img className="" src={women} alt="" />
          </div>
        </div>
      </section>
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px mx-auto py-6 sm:py-12 px-4 sm:px-6 lg:py-16 lg:px-8 ">
        <div className="flex justify-between flex-col lg:flex-row gap-6 lg:gap-0">
          <div className="left flex flex-col items-start gap-6 lg:gap-10">
            <Link to="/">
              <img src={logo_white} alt="" />
            </Link>
            <div className="flex space-x-6 md:order-2">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className=" hover:opacity-50"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="right flex flex-col lg:flex-row grid-cols-3 text-white  gap-3 sm:gap-6 lg:gap-16 xl:gap-28 2xl:gap-36">
            <div className="flex  flex-col">
              <h3 className="text-xl lg:text-3xl font-ralewayBold pb-2 lg:pb-3.5">
                Platformă
              </h3>
              {navigation.platform.map((item, index) => (
                <Link
                  className=" pb-2 sm:pb-3 lg:pb-5 hover:text-gray-500 text-sm sm:text-xl"
                  to={item.href}
                  key={index}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="flex flex-col">
              <h3 className="text-xl lg:text-3xl font-ralewayBold pb-3.5">
                Meniu
              </h3>
              {navigation.menu.map((item, index) => (
                <Link
                  className=" pb-2 sm:pb-3 lg:pb-5 hover:text-gray-500 text-sm sm:text-xl"
                  to={item.href}
                  key={index}
                >
                  {" "}
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="flex flex-col">
              <h3 className="text-xl lg:text-3xl font-ralewayBold pb-3.5">
                Legal
              </h3>
              {navigation.legal.map((item, index) => (
                <Link
                  className="pb-2 sm:pb-3 lg:pb-5 hover:text-gray-500 text-sm sm:text-xl"
                  to={item.href}
                  key={index}
                >
                  {" "}
                  {item.name}
                </Link>
              ))}
              <a
                href="https://anpc.ro/ce-este-sal/"
                className="pb-2 sm:pb-3 lg:pb-5 hover:text-gray-500 text-sm sm:text-xl"
                target="_blank"
              >
                <img src={anpcBanner} className="w-40" />
              </a>
              <a
                href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
                className="pb-2 sm:pb-3 lg:pb-5 hover:text-gray-500 text-sm sm:text-xl"
                target="_blank"
              >
                <img src={solBanner} className="w-40" />
              </a>
              <a
                href="https://stripe.com/"
                className="pb-2 sm:pb-3 lg:pb-5 hover:text-gray-500 text-sm sm:text-xl"
                target="_blank"
              >
                <img src={stripeBanner} className="w-40" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-black-g  py-4 sm:py-8 ">
        <h5 className=" text-sm sm:text-xl text-white text-center">
          Copyright © 2023 . Toate drepturile rezervate.
        </h5>
      </div>
    </footer>
  );
}
