import axios from "axios";

import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { HOST, PORT } from "../utils/Constants";

export const createUser = async (token, user, accountType) => {
  const { email, uid, metadata } = user._delegate;
  var publicId =
    Date.now() + "" + Math.floor(Math.random() * (999 - 100 + 1) + 100);
  var approved;
  if (accountType == "specialist") {
    approved = false;
  } else {
    approved = true;
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/users/createuser`,
      {
        user: { email, uid, metadata, accountType, publicId, approved },
      }
    );
    const response = res;
    return response;
  } catch (err) {
    return err.response;
  }
};

/*
  Aici la getUser cauta un user in baza de date(mongo) si daca nu exista, il cream
*/
export const getUser = async (token, user, accountType) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/users/getuser`,
      {
        headers: {
          id_token: token,
        },
      }
    );
    const response = res;

    return response;
  } catch (err) {
    if (err.response.status === 404) {
      const response = await createUser(token, user, accountType);

      return response;
    }
  }
};

export const deleteAccount = async (token, uid) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_DEV_HOST}/api/users/deleteaccount`,
      {
        headers: {
          id_token: token,
        },
        data: {
          account: uid,
        },
      }
    );
    const response = res;

    return res;
  } catch (err) {
    return err.response;
  }
};

export const deleteSelfAccount = async (token) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_DEV_HOST}/api/users/deleteselfaccount`,
      {
        headers: {
          id_token: token,
        },
        data: {
          da: "test",
        },
      }
    );

    return res;
  } catch (err) {
    return err.response;
  }
};
