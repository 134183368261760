import React, { useState } from "react";
import { sendMail } from "../../api/mail";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

const FormContact = () => {
  const [lastName, setLastName] = useState();
  const [firstName, setFirstName] = useState();
  const [email, setEmail] = useState();
  const [company, setCompany] = useState();
  const [message, setMessage] = useState();
  const [terms, setTerms] = useState(false);
  const [policy, setPolicy] = useState(false);

  const onChangeTerms = (e) => {
    if (e.target.checked) {
      setTerms(true);
    } else {
      setTerms(false);
    }
  };
  const onChangePolicy = (e) => {
    if (e.target.checked) {
      setPolicy(true);
    } else {
      setPolicy(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (terms == false) {
      toast.error(
        'Trebuie sa bifati "Am peste 18 ani, am citit și sunt de acord cu Termenii și Condițiile"',
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (policy == false) {
      toast.error(
        'Trebuie sa bifati "Am citit și sunt de acord cu Politica de confidențialitate și Politica Cookies"',
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }
    if (
      email != "" &&
      lastName != "" &&
      message != "" &&
      email &&
      lastName &&
      message
    ) {
      const response = await sendMail({
        lastName,
        firstName,
        email,
        company,
        message,
      });

      if (response.msg == "success") {
        setLastName("");
        setFirstName("");
        setEmail("");
        setCompany("");
        setMessage("");
        toast.success("Mesaj trimis!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Campuri incorecte!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="mt-10 sm:mt-0 mb-10">
      <div className="">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <form>
            <div className="shadow overflow-hidden sm:rounded-md font-ralewayMedium">
              <div className="pb-5 p-5 sm:p-10  bg-white">
                <h3 className="  text-2xl font-ralewayBold text-secondary uppercase pb-4 xl:pb-6">
                  Contactează-ne
                </h3>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="nume"
                      className="block text-base font-medium text-gray-700"
                    >
                      Nume
                    </label>
                    <input
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      type="text"
                      name="nume"
                      id="nume"
                      autoComplete="nume"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-inactive-text rounded-md h-12 px-3 "
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-base font-medium text-gray-700"
                    >
                      Prenume
                    </label>
                    <input
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      type="text"
                      name="prenume"
                      id="prenume"
                      autoComplete="prenume"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-inactive-text rounded-md h-12 px-3"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-base font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-inactive-text rounded-md h-12 px-3"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="companie"
                      className="block text-base text-gray-700"
                    >
                      Companie
                    </label>
                    <input
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      type="text"
                      name="companie"
                      id="companie"
                      autoComplete="companie"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-inactive-text rounded-md h-12 px-3"
                    />
                  </div>

                  <div className="col-span-6">
                    <label
                      htmlFor="mesaj"
                      className="block text-base font-medium text-gray-700"
                    >
                      Mesaj
                    </label>
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      name="mesaj"
                      id="mesaj"
                      autoComplete="mesaj"
                      className="mt-1 focus:ring-indigo-500  resize-none focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-inactive-text rounded-md h-52 p-3"
                    />
                  </div>
                  <div className="flex gap-2 sm:gap-5 col-span-6">
                    <input
                      type="checkbox"
                      name="terms"
                      id="terms"
                      onChange={onChangeTerms}
                      className="w-6 h-6"
                    />
                    <p className="text-xs sm:text-sm  text-black font-ralewayRegular flex-1">
                      Am peste 18 ani, am citit și sunt de acord cu{" "}
                      <Link to="/termeni-si-conditii" className="font-bold">
                        Termenii și Condițiile
                      </Link>
                    </p>
                  </div>
                  <div className="flex gap-2 sm:gap-5 col-span-6">
                    <input
                      type="checkbox"
                      name="terms"
                      id="terms"
                      onChange={onChangePolicy}
                      className="w-6 h-6"
                    />
                    <p className="text-xs sm:text-sm  text-black font-ralewayRegular flex-1">
                      Am citit și sunt de acord cu{" "}
                      <Link
                        to="/politica-de-confidentialitate"
                        className="font-bold"
                      >
                        Politica de confidențialitate
                      </Link>{" "}
                      și{" "}
                      <Link to="/politica-cookies" className="font-bold">
                        Politica Cookies
                      </Link>
                    </p>
                  </div>
                  <div className="flex gap-2 sm:gap-5 col-span-6">
                    <input
                      type="checkbox"
                      name="terms"
                      id="terms"
                      className="w-6 h-6"
                    />
                    <p className="text-xs sm:text-sm  text-black font-ralewayRegular flex-1">
                      Vreau să primesc pe e-mail notificări comerciale privind
                      ofertele și promoțiile SOLUTION TALK, cele mai noi
                      articole și sfaturi pentru sănătate psihică și emoțională
                    </p>
                  </div>
                </div>
              </div>

              <div className="px-4 py-3 pb-8 bg-white text-right sm:px-6 flex justify-center">
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className=" py-2 basis-full px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  Trimite
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default FormContact;
