import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { StarIcon } from "@heroicons/react/solid";
import { Link, useParams } from "react-router-dom";
import {
  createreview,
  getReviewByApp,
  getReviews,
} from "../../api/getReviews.api";
import { auth } from "../../api";
import Moment from "moment";
import { UserContext } from "../../providers/UserProvider";
import { getAppoimentById } from "../../api/getAppoiments.api";
import { toast } from "react-toastify";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const UltimulReview = (props) => {
  const [review, setReview] = useState({});
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const [comment, setComment] = useState();
  const [sedinta, setSedinta] = useState({
    clientFirstName: "",
  });
  useEffect(() => {
    console.log(id);
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getAppoimentById(idToken, id).then((res) => {
          setSedinta(res.appointment);
          auth
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then(async function (idToken2) {
              getReviewByApp(id, idToken2).then((res) => {
                setReview(res.review);
                setComment(res.review.comment);
              });
            });
        });
      });
  }, []);
  const saveReview = () => {
    var review2 = {
      specialist: sedinta.specialist,
      appointment: id,
      client: user.user.uid,
      clientFirstName: user.user.firstName,
      clientLastName: user.user.lastName,
      ratingScore: review.ratingScore,
      avatar: user.user.avatarUrl,
      comment: comment,
    };
    setReview(review2);
    console.log(review);
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        console.log(review);
        var ct = 0;
        var avg = 0;
        if (props.count) {
          ct = props.count;
        }
        if (props.avg) {
          avg = props.avg;
        }
        createreview(review2, ct, avg, idToken).then((res) => {
          console.log(res);
          if (res.status == "200") {
            toast.success("Recenzia a fost salvata!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("A aparut o eroare la salvarea recenziei!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      });
  };

  const onChange = (e) => {
    setComment(e.target.value);
  };

  const selectRating = (value) => {
    setReview((prevRev) => ({ ...prevRev, ["ratingScore"]: value + 1 }));
  };

  return (
    <>
      <>
        <div className="font-openSans">
          <div className="mb-3">
            <p className="text-lg font-bold text-secondary">Review</p>
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div className="mt-1 flex items-center">
                <p className="text-lg text-secondary font-bold">
                  {review ? review.ratingScore : ""}
                </p>
                <div>
                  <div className="ml-1 flex items-center">
                    {[0, 1, 2, 3, 4].map((rating) => (
                      <StarIcon
                        key={rating}
                        value={rating}
                        className={classNames(
                          (review ? review.ratingScore : 0) > rating
                            ? "text-primary"
                            : "text-gray-300",
                          "flex-shrink-0 h-5 w-5"
                        )}
                        aria-hidden="true"
                        onClick={() => selectRating(rating)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-1">
                <p>
                  {review
                    ? "Data: " + Moment(review.date).format("DD-MM-YYYY")
                    : ""}
                </p>
              </div>
              <div className="mt-3">
                <p className="font-raleway">{review ? review.text : ""}</p>
              </div>
              <div className="mt-1">
                <textarea
                  onChange={onChange}
                  rows={4}
                  name="description"
                  id="description"
                  className="pl-3 pt-3 h-52 border-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={comment ? comment : ""}
                />
              </div>
              <div className="mt-4">
                <button
                  onClick={saveReview}
                  className="m-auto w-2/5 flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                >
                  Salveaza review
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default UltimulReview;
