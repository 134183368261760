import axios from "axios";

import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { HOST, PORT } from "../utils/Constants";

export const getSumaDatorata = async (companyId, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/suma-datorata/${companyId}`,
      {
        headers: {
          id_token: token,
        },
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getCompanyInvoices = async (companyId, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/getcompanyinvoices/${companyId}`,
      {
        headers: {
          id_token: token,
        },
      }
    );
    return res;
  } catch (err) {
    return err.response;
    console.log(err);
  }
};

export const getTransaction = async (token, year, month) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/gettransactions/${year}/${month}}`,
      {
        headers: {
          id_token: token,
        },
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getClientSecret = async (appId, loc, email) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/create-payment-intent`,

      {
        appId: appId,
        loc: loc,
        email: email,
      },
      {
        headers: {},
      }
    );
    const response = res.data;
    window.location.href = response.url;
    return res;
  } catch (err) {
    return err.response;
  }
};

export const createInvoice = async (appId, loc, session_id) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/create-invoice`,

      {
        appId: appId,
        loc: loc,
        session_id: session_id,
      },
      {
        headers: {},
      }
    );

    return res;
  } catch (err) {
    return err.response;
  }
};

export const companyPayApi = async (token, companyId, specialist, cEmail) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/company-pay`,
      {
        companyId: companyId,
        specialist: specialist,
        companyEmail: cEmail,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    const response = res.data;
    window.location.href = response.url;
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getAccountLink = async (token, id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/getaccountlink/${id}`,
      {
        headers: {
          id_token: token,
        },
      }
    );

    return res;
  } catch (err) {
    return err.response;
  }
};

export const companyMarkAsPaid = async (
  token,
  companyId,
  session_id,
  name,
  city,
  line1,
  region,
  country
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/company-mark-as-paid`,
      {
        companyId: companyId,
        session_id: session_id,
        name: name,
        city,
        line1,
        region,
        country,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );

    return res;
  } catch (err) {
    return err.response;
  }
};
