import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeaderClient from "../../../components/Header/HeaderClient";
import _ from "lodash";

import { auth } from "../../../api";
import { getSpecialists } from "../../../api/getSpecialist.api";
import { LoadingContext } from "../../../providers/LoadingProvider";
import { LoadingComponent } from "../../../components";
import { StarIcon } from "@heroicons/react/solid";
// import user from "../../../../../backend/models/user";
import { UserContext } from "../../../providers/UserProvider";
import logo from "../../../assets/img/avatar-placeholder.png";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const VizualizareSpecialisti = () => {
  const { user } = useContext(UserContext);
  const [buttonShow, setButtonShow] = useState(true);
  const [specialists, setSpecialists] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filterByName, setFilterByName] = useState();
  const [filterByType, setFilterByType] = useState([]);
  const [filterByTypePsihoterapeut, setFilterByTypePsihoterapeut] =
    useState(false);
  const [filterByTypePsihiatru, setFilterByTypePsihiatru] = useState(false);
  const [filterByTypeCoach, setFilterByTypeCoach] = useState(false);
  const [filterByStar1, setFilterByStar1] = useState(false);
  const [filterByStar2, setFilterByStar2] = useState(false);
  const [filterByStar3, setFilterByStar3] = useState(false);
  const [filterByStar4, setFilterByStar4] = useState(false);
  const [filterByStar5, setFilterByStar5] = useState(false);
  const toggleButtonShow = () => {
    if (buttonShow) {
      setButtonShow(false);
    } else {
      setButtonShow(true);
    }
  };
  const onChangeFilterByType = (e) => {
    var tempArray = filterByType;
    if (e.target.checked) {
      if (e.target.name == "psihoterapeut") {
        setFilterByTypePsihoterapeut(true);
      } else if (e.target.name == "psihiatru") {
        setFilterByTypePsihiatru(true);
      } else if (e.target.name == "coach") {
        setFilterByTypeCoach(true);
      }
      tempArray.push(e.target.name);
    } else {
      if (e.target.name == "psihoterapeut") {
        setFilterByTypePsihoterapeut(false);
      } else if (e.target.name == "psihiatru") {
        setFilterByTypePsihiatru(false);
      } else if (e.target.name == "coach") {
        setFilterByTypeCoach(false);
      }
      tempArray = tempArray.filter((e2) => {
        return e2 != e.target.name;
      });
    }
    setFilterByType(tempArray);
  };
  const onChangeFilterByStar = (e) => {
    var tempArray = filterByType;
    if (e.target.checked) {
      if (e.target.name == "star-1") {
        setFilterByStar1(true);
      } else if (e.target.name == "star-2") {
        setFilterByStar2(true);
      } else if (e.target.name == "star-3") {
        setFilterByStar3(true);
      } else if (e.target.name == "star-4") {
        setFilterByStar4(true);
      } else if (e.target.name == "star-5") {
        setFilterByStar5(true);
      }
      tempArray.push(e.target.name);
    } else {
      if (e.target.name == "star-1") {
        setFilterByStar1(false);
      } else if (e.target.name == "star-2") {
        setFilterByStar2(false);
      } else if (e.target.name == "star-3") {
        setFilterByStar3(false);
      } else if (e.target.name == "star-4") {
        setFilterByStar4(false);
      } else if (e.target.name == "star-5") {
        setFilterByStar5(false);
      }
    }
  };
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getSpecialists(idToken).then((res) => {
          if (user.user.companyId || user.user.isCompany == true) {
            var filtered = res.specialists.filter((sp) => {
              return sp.specialistInfo.companyDiscount == "on";
            });
            setSpecialists(filtered);
          } else setSpecialists(res.specialists);
          setLoading(false);
        });
      });
  }, []);

  const numbers = [5, 4, 3, 2, 1];
  return (
    <>
      <HeaderClient />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="mb-3 hidden md:block">
          <h4 className="font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Filtreaza Specialisti{" "}
          </h4>
        </div>
        <div className="grid grid-rows-1 grid-cols-2 md:grid-cols-6 gap-0 md:gap-5 mb-10 text-sm">
          <div className="font-openSans col-span-2 2xl:col-span-1 text-secondary flex flex-col flex-nowrap h-full font-bold">
            <div className="mb-3">
              <h4
                className=" show-review md:hidden text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold"
                onClick={() => toggleButtonShow()}
              >
                Filtreaza Specialisti{" "}
                <span className="md:hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.96"
                    height="4.227"
                    viewBox="0 0 6.96 4.227"
                  >
                    <g
                      id="up-arrow-svgrepo-com"
                      transform="translate(6.96 4.227) rotate(180)"
                    >
                      <path
                        id="Path_283"
                        data-name="Path 283"
                        d="M5.684,4.009A.748.748,0,0,0,6.741,2.951L4.009.219a.749.749,0,0,0-1.058,0L.219,2.951A.748.748,0,0,0,1.276,4.009l2.2-2.2Z"
                        fill="#0b1e39"
                      />
                    </g>
                  </svg>
                </span>
              </h4>
              <div
                className={`bg-white rounded-xl ${
                  buttonShow === true ? "hidden" : ""
                } md:flex flex-col p-4 shadow-lg`}
              >
                <h3 className="text-dark-blue pb-2">Nume Specialist</h3>
                <div className="relative pb-2">
                  <input
                    onChange={(e) => setFilterByName(e.target.value)}
                    name="nume-specialist"
                    className="border-2 rounded-lg py-1 pl-10 w-full font-sans"
                  />
                  <svg
                    className="absolute top-2 left-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.918"
                    height="20.919"
                    viewBox="0 0 20.918 20.919"
                  >
                    <g id="magnifier" transform="translate(-0.001 0)">
                      <g
                        id="Group_145"
                        data-name="Group 145"
                        transform="translate(0.001 0)"
                      >
                        <path
                          id="Path_101"
                          data-name="Path 101"
                          d="M18.437,14.486a8.488,8.488,0,0,0-12-12A8.323,8.323,0,0,0,5.156,12.726a.357.357,0,0,1-.049.425L.777,17.482a1.978,1.978,0,0,0-.3,2.832l.132.131a1.978,1.978,0,0,0,2.832-.3l4.321-4.321a.362.362,0,0,1,.435-.058A8.323,8.323,0,0,0,18.437,14.486ZM8,12.919a6.272,6.272,0,1,1,8.87,0A6.279,6.279,0,0,1,8,12.919Z"
                          transform="translate(-0.001 0)"
                          fill="#0b1e39"
                        />
                        <g
                          id="Group_144"
                          data-name="Group 144"
                          transform="translate(7.665 3.397)"
                        >
                          <path
                            id="Path_102"
                            data-name="Path 102"
                            d="M114.628,54.992a.877.877,0,0,1-.806-1.218,5.521,5.521,0,0,1,7.228-2.93.876.876,0,1,1-.683,1.614,3.767,3.767,0,0,0-4.932,2A.876.876,0,0,1,114.628,54.992Z"
                            transform="translate(-113.752 -50.409)"
                            fill="#0b1e39"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <h3 className="text-dark-blue pb-1">Tipul specialistului</h3>
                <div className="flex flex-col gap-1 text-dark-blue pb-2">
                  <div className="flex items-center gap-2.5 mt-2">
                    <input
                      name="psihoterapeut"
                      onChange={onChangeFilterByType}
                      type="checkbox"
                      className=" h-6 w-6 text-indigo-600 border-gray-900 rounded w-6 h-6"
                    />
                    <span>Psihoterapeut</span>
                  </div>
                  <div className="flex items-center gap-2.5 mt-2">
                    <input
                      name="psihiatru"
                      onChange={onChangeFilterByType}
                      type="checkbox"
                      className=" h-6 w-6 text-indigo-600 border-gray-900 rounded"
                    />
                    <span>Psihiatru</span>
                  </div>
                  <div className="flex items-center gap-2.5 mt-2">
                    <input
                      name="coach"
                      onChange={onChangeFilterByType}
                      type="checkbox"
                      className=" h-6 w-6 text-indigo-600 border-gray-900 rounded"
                    />
                    <span>Coach</span>
                  </div>
                </div>
                <h3 className=" text-dark-blue pb-1">Numar de stele</h3>
                <div className="flex flex-col gap-1 pb-2">
                  {numbers.map((number) => (
                    <div
                      className="flex items-center gap-2.5 mt-2"
                      key={number}
                    >
                      <input
                        name={"star-" + number}
                        onChange={onChangeFilterByStar}
                        type="checkbox"
                        className=" h-6 w-6 text-indigo-600 border-gray-900 rounded"
                      />
                      <span className="text-dark-blue">{number}.00</span>
                      <div className="start flex gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.97"
                          height="15.36"
                          viewBox="0 0 11.44 11"
                        >
                          <path
                            id="bookmark-favorite-heart-like-rating-star-svgrepo-com"
                            d="M5.924,2A.876.876,0,0,1,7.516,2L8.747,4.672l2.918.347a.88.88,0,0,1,.492,1.519L10,8.54l.573,2.891a.877.877,0,0,1-1.288.939L6.72,10.93,4.156,12.37a.877.877,0,0,1-1.288-.939L3.44,8.54l-2.158-2a.88.88,0,0,1,.492-1.519l2.918-.347Zm1,.8a.22.22,0,0,0-.4,0L5.489,5.041a.877.877,0,0,1-.693.505L2.35,5.837a.22.22,0,0,0-.124.38L4.035,7.894a.881.881,0,0,1,.265.817l-.48,2.423a.22.22,0,0,0,.324.235l2.148-1.206a.874.874,0,0,1,.857,0L9.3,11.369a.22.22,0,0,0,.324-.235L9.14,8.711A.881.881,0,0,1,9.4,7.894l1.809-1.678a.22.22,0,0,0-.124-.38L8.644,5.546a.877.877,0,0,1-.693-.505Z"
                            transform="translate(-1 -1.484)"
                            fill="#f89a76"
                            fillRule="evenodd"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.97"
                          height="15.36"
                          viewBox="0 0 11.44 11"
                        >
                          <path
                            id="bookmark-favorite-heart-like-rating-star-svgrepo-com"
                            d="M5.924,2A.876.876,0,0,1,7.516,2L8.747,4.672l2.918.347a.88.88,0,0,1,.492,1.519L10,8.54l.573,2.891a.877.877,0,0,1-1.288.939L6.72,10.93,4.156,12.37a.877.877,0,0,1-1.288-.939L3.44,8.54l-2.158-2a.88.88,0,0,1,.492-1.519l2.918-.347Zm1,.8a.22.22,0,0,0-.4,0L5.489,5.041a.877.877,0,0,1-.693.505L2.35,5.837a.22.22,0,0,0-.124.38L4.035,7.894a.881.881,0,0,1,.265.817l-.48,2.423a.22.22,0,0,0,.324.235l2.148-1.206a.874.874,0,0,1,.857,0L9.3,11.369a.22.22,0,0,0,.324-.235L9.14,8.711A.881.881,0,0,1,9.4,7.894l1.809-1.678a.22.22,0,0,0-.124-.38L8.644,5.546a.877.877,0,0,1-.693-.505Z"
                            transform="translate(-1 -1.484)"
                            fill="#f89a76"
                            fillRule="evenodd"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.97"
                          height="15.36"
                          viewBox="0 0 11.44 11"
                        >
                          <path
                            id="bookmark-favorite-heart-like-rating-star-svgrepo-com"
                            d="M5.924,2A.876.876,0,0,1,7.516,2L8.747,4.672l2.918.347a.88.88,0,0,1,.492,1.519L10,8.54l.573,2.891a.877.877,0,0,1-1.288.939L6.72,10.93,4.156,12.37a.877.877,0,0,1-1.288-.939L3.44,8.54l-2.158-2a.88.88,0,0,1,.492-1.519l2.918-.347Zm1,.8a.22.22,0,0,0-.4,0L5.489,5.041a.877.877,0,0,1-.693.505L2.35,5.837a.22.22,0,0,0-.124.38L4.035,7.894a.881.881,0,0,1,.265.817l-.48,2.423a.22.22,0,0,0,.324.235l2.148-1.206a.874.874,0,0,1,.857,0L9.3,11.369a.22.22,0,0,0,.324-.235L9.14,8.711A.881.881,0,0,1,9.4,7.894l1.809-1.678a.22.22,0,0,0-.124-.38L8.644,5.546a.877.877,0,0,1-.693-.505Z"
                            transform="translate(-1 -1.484)"
                            fill="#f89a76"
                            fillRule="evenodd"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.97"
                          height="15.36"
                          viewBox="0 0 11.44 11"
                        >
                          <path
                            id="bookmark-favorite-heart-like-rating-star-svgrepo-com"
                            d="M5.924,2A.876.876,0,0,1,7.516,2L8.747,4.672l2.918.347a.88.88,0,0,1,.492,1.519L10,8.54l.573,2.891a.877.877,0,0,1-1.288.939L6.72,10.93,4.156,12.37a.877.877,0,0,1-1.288-.939L3.44,8.54l-2.158-2a.88.88,0,0,1,.492-1.519l2.918-.347Zm1,.8a.22.22,0,0,0-.4,0L5.489,5.041a.877.877,0,0,1-.693.505L2.35,5.837a.22.22,0,0,0-.124.38L4.035,7.894a.881.881,0,0,1,.265.817l-.48,2.423a.22.22,0,0,0,.324.235l2.148-1.206a.874.874,0,0,1,.857,0L9.3,11.369a.22.22,0,0,0,.324-.235L9.14,8.711A.881.881,0,0,1,9.4,7.894l1.809-1.678a.22.22,0,0,0-.124-.38L8.644,5.546a.877.877,0,0,1-.693-.505Z"
                            transform="translate(-1 -1.484)"
                            fill="#f89a76"
                            fillRule="evenodd"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.97"
                          height="15.36"
                          viewBox="0 0 11.44 11"
                        >
                          <path
                            id="bookmark-favorite-heart-like-rating-star-svgrepo-com"
                            d="M5.924,2A.876.876,0,0,1,7.516,2L8.747,4.672l2.918.347a.88.88,0,0,1,.492,1.519L10,8.54l.573,2.891a.877.877,0,0,1-1.288.939L6.72,10.93,4.156,12.37a.877.877,0,0,1-1.288-.939L3.44,8.54l-2.158-2a.88.88,0,0,1,.492-1.519l2.918-.347Zm1,.8a.22.22,0,0,0-.4,0L5.489,5.041a.877.877,0,0,1-.693.505L2.35,5.837a.22.22,0,0,0-.124.38L4.035,7.894a.881.881,0,0,1,.265.817l-.48,2.423a.22.22,0,0,0,.324.235l2.148-1.206a.874.874,0,0,1,.857,0L9.3,11.369a.22.22,0,0,0,.324-.235L9.14,8.711A.881.881,0,0,1,9.4,7.894l1.809-1.678a.22.22,0,0,0-.124-.38L8.644,5.546a.877.877,0,0,1-.693-.505Z"
                            transform="translate(-1 -1.484)"
                            fill="#f89a76"
                            fillRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4 2xl:col-span-5 grid xl:grid-cols-2 2xl:grid-cols-3 gap-5">
            {loading ? (
              <LoadingComponent />
            ) : !specialists ? (
              ""
            ) : (
              specialists
                .filter((sp) => {
                  var name = true;
                  var typePsihoterapeut = false;
                  var typePsihiatru = false;
                  var typeCoach = false;
                  var star1 = false;
                  var star2 = false;
                  var star3 = false;
                  var star4 = false;
                  var star5 = false;
                  if (
                    filterByStar1 == false &&
                    filterByStar2 == false &&
                    filterByStar3 == false &&
                    filterByStar4 == false &&
                    filterByStar5 == false
                  ) {
                    star1 = true;
                    star2 = true;
                    star3 = true;
                    star4 = true;
                    star5 = true;
                  } else {
                    if (
                      filterByStar1 &&
                      sp.reviewsAverage >= 0 &&
                      sp.reviewsAverage < 2
                    ) {
                      star1 = true;
                    }
                    if (
                      filterByStar2 &&
                      sp.reviewsAverage >= 2 &&
                      sp.reviewsAverage < 3
                    ) {
                      star2 = true;
                    }
                    if (
                      filterByStar3 &&
                      sp.reviewsAverage >= 3 &&
                      sp.reviewsAverage < 4
                    ) {
                      star3 = true;
                    }
                    if (
                      filterByStar4 &&
                      sp.reviewsAverage >= 4 &&
                      sp.reviewsAverage < 5
                    ) {
                      star4 = true;
                    }
                    if (
                      filterByStar5 &&
                      sp.reviewsAverage >= 5 &&
                      sp.reviewsAverage < 6
                    ) {
                      star5 = true;
                    }
                  }
                  console.log(filterByName);
                  if (filterByName) {
                    name = (sp.lastName + " " + sp.firstName)
                      .toLowerCase()
                      .includes(filterByName.toLowerCase());
                  }

                  if (
                    filterByTypeCoach == false &&
                    filterByTypePsihiatru == false &&
                    filterByTypePsihoterapeut == false
                  ) {
                    typePsihoterapeut = true;
                    typePsihiatru = true;
                    typeCoach = true;
                  } else {
                    if (sp.specialistInfo.specializationType)
                      if (
                        filterByTypePsihoterapeut &&
                        sp.specialistInfo.specializationType.toLowerCase() ==
                          "psihoterapeut"
                      ) {
                        typePsihoterapeut = true;
                      } else if (
                        filterByTypePsihiatru &&
                        sp.specialistInfo.specializationType.toLowerCase() ==
                          "psihiatru"
                      ) {
                        typePsihiatru = true;
                      } else if (
                        filterByTypeCoach &&
                        sp.specialistInfo.specializationType.toLowerCase() ==
                          "coach"
                      ) {
                        typeCoach = true;
                      }
                  }

                  return (
                    name &&
                    (typePsihiatru || typePsihoterapeut || typeCoach) &&
                    (star1 || star2 || star3 || star4 || star5)
                  );
                })
                .map((sp) =>
                  sp.approved ? (
                    <div className="">
                      <div className="bg-white rounded-xl shadow-lg flex flex-col rounded-3xl  shadow-lg pt-3 sm:pt-6 pb-6 px-6 2xl:px-6 items-start">
                        <div className="flex w-full pb-2 sm:pb-4 gap-2.5 items-start">
                          <div className="rounded-full w-16 h-16">
                            <img
                              src={sp.avatarUrl ? sp.avatarUrl : logo}
                              className="rounded-full w-full h-full object-center object-cover"
                            />
                          </div>
                          <div className="flex flex-col">
                            <h5 className="text-primary font-bold text-base">
                              {!sp.specialistInfo
                                ? ""
                                : sp.specialistInfo.specializationType}
                            </h5>
                            <h4 className="font-ralewayBold text-xl text-secondary">
                              {sp.lastName + " " + sp.firstName}
                            </h4>
                            <h3 className="font-ralewayRegular text-dark-blue text-sm ">
                              {!sp.specialistInfo
                                ? ""
                                : sp.specialistInfo.lastStudies}
                            </h3>
                          </div>
                        </div>
                        <pre className="whitespace-pre-line font-ralewayRegular text-dark-blue text-sm   pb-2 ">
                          {sp.description}
                        </pre>
                        <h3 className="text-lg text-dark-blue font-ralewayBold pb-2">
                          Specializari
                        </h3>
                        <div className="flex flex-wrap gap-1 sm:gap-3">
                          {sp.specialistInfo.specializations.map((itm, ind) => (
                            <p
                              className=" text-xs font-ralewayMedium text-dark-blue px-2.5 sm:px-4 py-1 sm:py-2 bg-light-orange-w rounded-full"
                              key={ind}
                            >
                              {itm}
                            </p>
                          ))}
                        </div>
                        <div className="flex w-full gap-4 pt-4">
                          <div>
                            <h3 className="text-lg text-dark-blue font-ralewayBold pb-1">
                              Rating
                            </h3>
                            <div>
                              <div className=" flex items-center">
                                <div>
                                  <div className="flex items-center">
                                    {[0, 1, 2, 3, 4].map((rating) => (
                                      <StarIcon
                                        key={rating}
                                        className={classNames(
                                          sp.reviewsAverage > rating
                                            ? "text-primary"
                                            : "text-gray-300",
                                          "flex-shrink-0 h-5 w-5"
                                        )}
                                        aria-hidden="true"
                                      />
                                    ))}
                                  </div>
                                  <p className="sr-only">
                                    {sp.reviewsAverage} out of 5 stars
                                  </p>
                                </div>
                                <p className="ml-2 text-sm text-gray-900">
                                  ({sp.reviewsCount ? sp.reviewsCount : 0}{" "}
                                  reviews)
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {sp.specialistInfo ? (
                          sp.specialistInfo.yearsExperience ? (
                            <>
                              <h3 className="text-lg text-dark-blue font-ralewayBold pb-2 mt-4">
                                Ani experienta
                              </h3>
                              <div className="flex flex-wrap gap-1 sm:gap-3">
                                <p className="">
                                  {sp.specialistInfo.yearsExperience + " ani"}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        <>
                          <h3 className="text-lg text-dark-blue font-ralewayBold pb-2 mt-4">
                            Preturi
                          </h3>
                          <div className="">
                            {sp.specialistInfo.price25 ? (
                              <p>
                                25 min: {sp.specialistInfo.price25 + " RON"}
                              </p>
                            ) : (
                              ""
                            )}
                            {sp.specialistInfo.price50 ? (
                              <p className="">
                                50 min: {sp.specialistInfo.price50 + " RON"}
                              </p>
                            ) : (
                              ""
                            )}
                            {sp.specialistInfo.price90 ? (
                              <p className="">
                                90 min: {sp.specialistInfo.price90 + " RON"}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </>

                        <div className="flex justify-between gap-2 flex-col sm:flex-row lg:flex-row mt-4">
                          <Link
                            to={
                              "/dashboard/client/specialist/" +
                              sp.publicId +
                              "/afisare-specialist"
                            }
                            className="whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm px-6 py-2 border border-primary text-secondary rounded-full shadow-lg duration-300  hover:bg-primary hover:text-white"
                          >
                            Vezi Profil
                          </Link>
                          <Link
                            to={
                              "/dashboard/client/specialist/" +
                              sp.publicId +
                              "/calendar"
                            }
                            className="whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm px-6 py-2 border bg-primary border-light-orange text-white rounded-full shadow-lg duration-300  hover:bg-primary-hover "
                          >
                            Rezerva sedinta
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default VizualizareSpecialisti;
