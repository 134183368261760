import React from "react";

const FiltruSedinte = (props) => {
  return (
    <>
      <div>
        <div className="mb-3">
          <p className="text-lg font-bold">Filtreaza Istoric</p>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="left basis-1/6 font-bold">
              <div>
                <h3 className="text-dark-blue pb-2.5 ">Tipologie</h3>
                <div className="flex flex-col gap-2.5 text-dark-blue">
                  <div className="flex items-center gap-2.5">
                    <input
                      name="online"
                      onClick={(e) => {
                        if (e.target.checked) {
                          props.setLocationFilterOnline(true);
                        } else {
                          props.setLocationFilterOnline(false);
                        }
                      }}
                      type="checkbox"
                      className=" h-6 w-6 text-indigo-600 border-gray-900 rounded"
                    />
                    <span>Sedinte Online</span>
                  </div>
                  <div className="flex items-center gap-2.5 mt-2">
                    <input
                      name="cabinet"
                      type="checkbox"
                      onClick={(e) => {
                        if (e.target.checked) {
                          props.setLocationFilterOffline(true);
                        } else {
                          props.setLocationFilterOffline(false);
                        }
                      }}
                      className=" h-6 w-6 text-indigo-600 border-gray-900 rounded"
                    />
                    <span>Sedinte la Cabinet</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltruSedinte;
