import React from "react";
import avatar from "../../assets/img/John_Doe.jpg";
import { useState, useEffect } from "react";
import HeaderSecond from "../../components/Header/HeaderSecond";
import { auth } from "../../api";
import { getReviews } from "../../api/getReviews.api";
import Moment from "moment";
import { StarIcon } from "@heroicons/react/solid";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Review = () => {
  const [buttonShow, setButtonShow] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getReviews(idToken).then((res) => {
          setReviews(res.data.reviews);
          setLoading(false);
        });
      });
  }, []);

  const toggleButtonShow = () => {
    if (buttonShow) {
      setButtonShow(false);
    } else {
      setButtonShow(true);
    }
  };
  return (
    <>
      <HeaderSecond />
      <div className="flex max-w-1753px m-auto px-5 flex-col sm:flex-row gap-4 sm:gap-0">
        <div className="right  sm:pl-8">
          <h4 className="font-bold text-dark-blue pb-4 text-lg">Reviewuri</h4>
          <div className="reviews-box  flex flex-row flex-wrap">
            {!reviews
              ? ""
              : reviews.map((review, index) => (
                  <div
                    className="review-box p-2 pt-0  basis-full lg:basis-1/2 2xl:basis-1/3"
                    key={index}
                  >
                    <div className="bg-white flex flex-col rounded-xl h-full p-4 sm:p-9 shadow-lg">
                      <div className="flex gap-3">
                        <div className="basis-full">
                          <h3 className="text-sm text-dark-blue font-bold pb-1.5">
                            {review.clientFirstName +
                              " " +
                              review.clientLastName}
                          </h3>

                          <div className="flex font-ralewayBold text-dark-blue items-center justify-between">
                            <h4 className="pr-5  text-xs ">Notite</h4>
                            <div className="flex gap-0.5 items-center">
                              <h4 className=" pr-1.5 text-xs">Review</h4>
                              <div className=" flex items-center">
                                <div>
                                  <div className="flex items-center">
                                    {[0, 1, 2, 3, 4].map((rating) => (
                                      <StarIcon
                                        key={rating}
                                        className={classNames(
                                          review.ratingScore > rating
                                            ? "text-primary"
                                            : "text-gray-300",
                                          "flex-shrink-0 h-5 w-5"
                                        )}
                                        aria-hidden="true"
                                      />
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className=" text-gray-custom text-xs	flex flex-col gap-2 pt-1">
                          {review.comment}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Review;
