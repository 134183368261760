import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Footer, Header, Seo } from "../../components";
import { UserProvider } from "../../providers/UserProvider";
import { HandleLogout } from "../../utils";

const Dashboard = () => {
  return (
    <>
      <Seo title="Acasa" description="Pagina de acasa" />
      <Header />
      <section className="h-96 bg-white flex flex-col items-center justify-center">
        <p>Dashboard</p>
        <Link
          to="/dashboard/specialist/setari-cont"
          className="px-5 py-2 bg-primary text-white"
        >
          Specialist
        </Link>
        <Link
          to="/dashboard/client/setari-cont"
          className="px-5 py-2 bg-primary text-white"
        >
          Client
        </Link>
        <button onClick={HandleLogout}>Logout</button>
      </section>
      <Footer />
    </>
  );
};

export default Dashboard;
