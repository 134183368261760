import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { auth } from "../../api";
import { getAppoimentById } from "../../api/getAppoiments.api";
import download from "../../assets/img/download-svgrepo-com.svg";
import { SpecialistVisualizare } from "../../pages";

const DetaliiSedintaClient = (props) => {
  const { id } = useParams();
  const [sedinta, setSedinta] = useState({
    clientFirstName: "",
  });
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getAppoimentById(idToken, id).then((res) => {
          setSedinta(res.appointment);
        });
      });
  }, []);

  return (
    <>
      <div className="mb-3">
        <p className="text-lg font-bold font-openSans text-dark-blue">
          Detalii Sedinta
        </p>
      </div>
      <div className="bg-white overflow-hidden shadow rounded-lg h-full">
        <div className="px-4 py-5 sm:px-10 sm:py-5">
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-5 items-center px-2">
            <div className="flex gap-3">
              <div
                className="flex-shrink-0 inline-block rounded-full overflow-hidden h-32 w-32 "
                aria-hidden="true"
              >
                <img
                  className="rounded-full w-full h-full object-center object-cover border-primary p-2 border-2"
                  src={sedinta.specialistAvatarUrl}
                  alt=""
                />
              </div>
              <div className="items-center m-auto text-secondary">
                <p className="font-openSans text-xl font-bold">
                  {sedinta.specialistFirstName +
                    " " +
                    sedinta.specialistLastName}
                </p>
                <p className="font-openSans text-lg font-bold">
                  {sedinta.location}
                </p>
              </div>
            </div>
            <div className="flex text-dark-blue">
              <div className="font-raleway text-xs m-auto">
                <div className="flex gap-2">
                  <p className="font-bold">DATA</p>
                  <p>{moment(sedinta.date).format("YYYY-MM-DD")}</p>
                </div>
                <div className="flex gap-2">
                  <p className="font-bold">INTERVAL ORAR</p>
                  <p>{sedinta.startHour + "-" + sedinta.endHour}</p>
                </div>
              </div>
              <div className="font-raleway text-xs m-auto">
                <div className="flex gap-2">
                  <p className="font-bold">LOCATIE</p>
                  <p>{sedinta.location}</p>
                </div>
                <div className="flex gap-2">
                  <p className="font-bold">COST</p>
                  <p>{sedinta.price} RON</p>
                </div>
              </div>
            </div>
            {sedinta.type == "personal" ? (
              <div>
                <a
                  href={sedinta.url}
                  target="_blank"
                  className=" flex float-right items-end gap-1 xl:gap-3 text-xs font-raleway text-dark-blue"
                >
                  <img src={download} /> <span>Vezi Factura</span>
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
          {sedinta.hangoutLink ? (
            <div>
              <a
                href={sedinta.hangoutLink}
                target="_blank"
                className=" flex float-right items-end gap-1 xl:gap-3 text-xs font-raleway text-dark-blue py-5"
              >
                <button className="w-32 justify-center py-4 px-2 border rounded-md shadow-sm text-xs border-primary text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                  Google Meet
                </button>
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default DetaliiSedintaClient;
