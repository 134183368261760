import React, { createRef, useEffect, useState } from "react";
import HeaderClient from "../../../components/Header/HeaderClient";
import avatar from "../../../assets/img/Elena_Ionescu.jpg";
import { Link, useParams } from "react-router-dom";
import { auth } from "../../../api";
import {
  getAppoiments,
  getAppoimentsByPublicId,
} from "../../../api/getAppoiments.api";
import Moment from "moment";
import logo from "../../../assets/img/avatar-placeholder.png";
import { getClientById } from "../../../api/getSpecialist.api";

const VeziAngajat = () => {
  const [pastAppointments, setPastAppoiments] = useState([]);
  const [futureAppointments, setFutureAppointments] = useState([]);
  const [showPopup, setShowPopup] = useState();
  var popupRef = createRef();
  const { id } = useParams();
  const [angajat, setAngajat] = useState({});

  const cancelAppointment = () => {};
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getClientById(idToken, id).then((res) => {
          setAngajat(res.specialist);
        });
        getAppoimentsByPublicId(idToken, id).then((res) => {
          let apps = [];
          let fapps = [];
          if (res.status == 200) {
            res.data.appointments.forEach((element) => {
              if (Moment(element.date) < Moment()) apps.push(element);
              else fapps.push(element);
            });
            setPastAppoiments(apps);
            setFutureAppointments(fapps);
          }
        });
      });
  }, []);

  return (
    <>
      <HeaderClient />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="mb-3 block grid grid-cols-1 xl:grid-cols-7">
          <h4 className="col-span-1 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Detalii angajat
          </h4>
          <h4 className="hidden 2xl:block col-span-4 2xl:col-span-3 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Istoric Sedinte
          </h4>
          <h4 className="hidden 2xl:block col-span-2 2xl:col-span-3 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Sedinte Programate
          </h4>
        </div>
        <div className="2xl:grid  grid-cols-1 2xl:grid-cols-7 gap-0 2xl:gap-5 mb-10 text-sm">
          <div>
            <div className=" 2xl:col-span-1 bg-white overflow-hidden shadow rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <div className="flex gap-1 sm:gap-4">
                  <div className="rounded-full w-16 h-16">
                    <img
                      className="rounded-full w-full h-full object-center object-cover"
                      src={angajat.avatarUrl ? angajat.avatarUrl : logo}
                    />
                  </div>
                  <div className="flex flex-col justify-center">
                    <h3 className=" text-xs sm:text-base xl:text-lg text-dark-blue font-bold">
                      {angajat.lastName + " " + angajat.firstName}
                    </h3>
                    <h4 className="text-xs text-gray-p">{angajat.publicId}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4 className="block 2xl:hidden col-span-4 2xl:col-span-3 font-openSans show-review text-dark-blue pt-4 text-lg flex items-center gap-4 font-bold">
            Istoric Sedinte
          </h4>
          <div className=" pt-5 2xl:pt-0 h-auto col-span-4 2xl:col-span-3 flex flex-col overflow-hidden  items-start gap-4">
            <ul className="hidden sm:grid grid-cols-7 w-full bg-gray-inactive-bg text-dark-blue font-ralewayBold text-xs py-3.5 pl-3 sm:pl-9 pr-2">
              <li className="col-span-1">NR.</li>
              <li className="col-span-2">DATA</li>

              <li className="col-span-2">TERAPEUT</li>

              <li className="col-span-2">COST</li>
            </ul>
            {!pastAppointments
              ? ""
              : pastAppointments.map((sedinta, index) => (
                  <>
                    <ul className=" hidden sm:grid grid-cols-7 w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                      <li className="col-span-1">{index + 1}.</li>
                      <li className="col-span-2">
                        {Moment(sedinta.date).format("YYYY-MM-DD")}
                      </li>

                      <li className="col-span-2">
                        {sedinta.specialistLastName +
                          " " +
                          sedinta.specialistFirstName}
                      </li>

                      <li className="col-span-2">{sedinta.price}</li>
                    </ul>
                    <div className=" flex flex-col sm:hidden gap-1  w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                      <div className=" grid grid-cols-2">
                        <span className="font-ralewayBold">NUMĂR:</span>
                        {index + 1}.
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="font-ralewayBold">DATA:</span>
                        {Moment(sedinta.date).format("YYYY-MM-DD")}
                      </div>

                      <div className="grid grid-cols-2">
                        <span className="font-ralewayBold">TERAPEUT:</span>
                        {sedinta.specialistLastName +
                          " " +
                          sedinta.specialistLastName}
                      </div>

                      <div className="grid grid-cols-2">
                        <span className="font-ralewayBold">COST:</span>
                        {sedinta.price}
                      </div>
                    </div>
                  </>
                ))}
          </div>
          <h4 className="block 2xl:hidden  w-full font-openSans show-review text-dark-blue pt-4 text-lg  items-center gap-4 font-bold">
            Sedinte Programate
          </h4>
          <div className="col-span-2 flex flex-col 2xl:col-span-3 overflow-hidden gap-4 pt-5 2xl:pt-0">
            {!futureAppointments
              ? ""
              : futureAppointments.map((sedinta, index) => (
                  <>
                    <div className="bg-white shadow rounded-lg pb-6 pt-4 px-6 ">
                      <div className="flex flex-col sm:flex-row gap-2 items-start">
                        <div className=" flex-shrink-0 inline-block w-16 h-16 justify-centers items-center rounded-full">
                          <img
                            src={
                              sedinta.specialistAvatarUrl != null &&
                              sedinta.specialistAvatarUrl != ""
                                ? sedinta.specialistAvatarUrl
                                : logo
                            }
                            className="border-2 rounded-full p-0.5 border-primary object-cover object-center h-full w-full"
                          />
                        </div>
                        <div className="flex flex-col w-full">
                          <div>
                            <p className="text-primary font-bold">
                              {sedinta.specializationType}
                            </p>
                            <h6 className="font-bold text-secondary">
                              {sedinta.specialistLastName +
                                " " +
                                sedinta.specialistFirstName}
                            </h6>
                          </div>
                          <div className="sm:grid grid-cols-4 text-xs flex flex-col gap-2 sm:gap-0">
                            <div className="col-span-1 flex flex-col gap-1">
                              <p className=" text-gray-primary text-xs">
                                {sedinta.lastStudies}
                              </p>
                              <h6 className="font-bold text-secondary flex gap-1 items-center text-xs">
                                <svg
                                  id="location-pin-svgrepo-com_1_"
                                  data-name="location-pin-svgrepo-com"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8.912"
                                  height="14.167"
                                  viewBox="0 0 8.912 14.167"
                                >
                                  <path
                                    id="Path_286"
                                    data-name="Path 286"
                                    d="M83.414,0a4.58,4.58,0,0,0-4.456,4.686c0,1.041.632,2.737,1.932,5.187.919,1.733,1.824,3.177,1.862,3.238l.662,1.056.662-1.056c.038-.061.942-1.505,1.862-3.238,1.3-2.45,1.932-4.146,1.932-5.187A4.58,4.58,0,0,0,83.414,0Zm0,7.083A2.372,2.372,0,0,1,81.1,4.654a2.313,2.313,0,1,1,4.619,0A2.372,2.372,0,0,1,83.414,7.083Z"
                                    transform="translate(-78.958)"
                                    fill="#f89a76"
                                  />
                                </svg>
                                {sedinta.specialistCity}
                              </h6>
                            </div>
                            <div className="col-span-2 flex flex-col gap-1 text-secondary">
                              <h4 className="font-ralewayBold">
                                DATA{" "}
                                <span className="pl-2 font-ralewayRegular">
                                  {Moment(sedinta.date).format("YYYY-MM-DD")}
                                </span>
                              </h4>
                              <h4 className="font-ralewayBold">
                                COST{" "}
                                <span className="pl-2 font-ralewayRegular">
                                  {sedinta.price + "RON"}
                                </span>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-5 flex-col sm:flex-row justify-center pt-4 sm:pt-7">
                        <Link to="#">
                          <button
                            onClick={(e) => {
                              setShowPopup(true);
                            }}
                            className="w-full flex justify-center py-3 px-4 border rounded-md shadow-sm text-xs border-primary hover:bg-primary hover:text-white duration-300 text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                          >
                            Anuleaza Programarea
                          </button>
                        </Link>
                      </div>
                    </div>
                  </>
                ))}
          </div>
        </div>
        <div
          className={
            (!showPopup ? "hidden " : "") +
            "w-80 h-80 fixed inset-0 m-auto z-50"
          }
          ref={popupRef}
        >
          <div className="bg-white overflow-hidden shadow rounded-lg h-full w-full">
            <div className="px-4 py-5 sm:px-6 sm:py-4 bg-dark-blue relative">
              <p className="text-white font-openSans font-bold">
                Anulare programare
              </p>
              <p
                onClick={(e) => {
                  setShowPopup(false);
                }}
                className="text-white cursor-pointer w-fit absolute top-2.5 right-2.5"
              >
                x
              </p>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <p className="font-openSans text-xl text-dark-blue font-bold">
                Doriti sa continuati anularea programarii?
              </p>

              <button
                className="w-3/5 flex mx-auto mt-5 justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                onClick={cancelAppointment}
                type="button"
              >
                Anuleaza programarea
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VeziAngajat;
