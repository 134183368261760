import axios from "axios";

import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { HOST, PORT } from "../utils/Constants";
import { auth } from ".";

export const updateUser = async (token, client) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_DEV_HOST}/api/users/updateuser`,
      {
        user: client,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const cerereAprobareSp = async (token, client) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/users/cerereaprobare`,
      {
        client: client,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const addAngajat = async (token, idAngajat, idCompanie) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_DEV_HOST}/api/users/addangajat`,
      {
        idAngajat: idAngajat,
        idCompanie: idCompanie,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getAngajati = async (idToken, companyId) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/users/getangajati/${companyId}`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    const response = res.data;
    return response;
  } catch (err) {
    return err.response;
  }
};

export const updateUserAvatar = async (token, avatar) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_DEV_HOST}/api/users/updateuseravatar`,
      {
        avatar: avatar,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );

    return res;
  } catch (err) {
    return err.response;
  }
};

export const approveUser = async (token, uid, approved) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_DEV_HOST}/api/users/approveuser`,
      {
        uid: uid,
        approved: approved,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );

    return res;
  } catch (err) {
    return err.response;
  }
};

export const updateRules = async (token, rurl) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/admin/updaterules`,
      {
        url: rurl,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const updateContracte = async (token, contracte) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/admin/updatecontracts`,
      {
        contracts: contracte,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getRules = async (idToken) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/admin/getrules`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};
