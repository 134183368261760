import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { StarIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { getReviews } from "../../api/getReviews.api";
import { auth } from "../../api";
import Moment from "moment";
import LoadingComponent from "../LoadingScreen/LoadingComponent";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const UltimulReview = (props) => {
  const [loading, setLoading] = useState(true);
  const [review, setReview] = useState(null);
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getReviews(idToken).then((res) => {
          if (res.status == 200) {
            setReview(res.data.reviews[0]);
          }

          setLoading(false);
        });
      });
  }, []);
  return (
    <>
      <div className="font-openSans">
        <div className="mb-3">
          <p className="text-lg font-bold text-secondary">
            Ultimul review primit
          </p>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            {loading ? (
              <LoadingComponent />
            ) : (
              <>
                {!review ? (
                  <p>Nu ai primit niciun review.</p>
                ) : (
                  <>
                    <div className="mt-1 flex items-center">
                      <p className="text-lg text-secondary font-bold">
                        {review.ratingScore}
                      </p>
                      <div>
                        <div className="ml-1 flex items-center">
                          {[0, 1, 2, 3, 4].map((rating) => (
                            <StarIcon
                              key={rating}
                              className={classNames(
                                review.ratingScore > rating
                                  ? "text-primary"
                                  : "text-gray-300",
                                "flex-shrink-0 h-5 w-5"
                              )}
                              aria-hidden="true"
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="mt-1">
                      <p>Data: {Moment(review.date).format("DD-MM-YYYY")}</p>
                    </div>
                    <div className="mt-3">
                      <p className="font-raleway">{review.comment}</p>
                    </div>
                    <div className="mt-4">
                      <Link to="/dashboard/specialist/review">
                        <button className="m-auto w-2/5 flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                          Vezi toate reviewurile
                        </button>
                      </Link>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UltimulReview;
