import React from "react";
import { Header, Footer } from "../../components";

const TermeniSiConditii = () => {
  return (
    <>
      <Header />
      <div className="px-10">
        <section className="mt-20">
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h1 className="text-4xl text-center">TERMENI SI CONDITII</h1>
              <p className="text-base text-center mt-5">
                Versiune în vigoare la data de 04.10.2022
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                I. Prevederi generale. Forța obligatorie a Politicilor SOLUTION
                TALK
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Website-ul https://solutiontalk.ro/ (în continuare ”Website-ul”)
                este proprietatea SOLUTION TALK S.R.L., persoană juridică
                română, cu sediul în București, Str. Smaranda Brăescu, nr. 40,
                Bl. 20H, Sc. 1, Et. 2, Ap. 6, Sector 1, înmatriculată la ORC sub
                nr. J40 /2852 /2022, cod fiscal 45650372, e-mail
                contact@solutiontalk.ro (în continuare ” SOLUTION TALK”, ”noi”,
                ”a noastră”).
              </p>
              <p className="mt-2">
                Acești Termeni și condiții, inclusiv Politica de
                confidențialitate, Politica Cookies și Data Processing Agreement
                (”DPA”) (colectiv, „Politicile SOLUTION TALK”) conțin termenii
                și condițiile aplicabile raporturilor ce se nasc între SOLUTION
                TALK, pe de o parte și fiecare Utilizator al Website-ului, pe de
                altă parte precum și modul în care vom prelucra datele cu
                caracter personal pe care le colectăm în calitate de Operator/
                Persoană Împuternicită.
              </p>
              <p className="mt-2">
                Acești Termeni și Condiții, împreună cu Politica de
                Confidențialitate, Politica Cookies și DPA au forță juridică
                obligatorie între Părți, reprezentând acordul între Părți.
                Utilizarea Website-ului, indiferent de modalitatea utilizării și
                indiferent de calitatea dvs. (Specialist, Client, Companie sau
                Vizitator) implică acceptarea Politicilor SOLUTION TALK,
                conformarea la clauzele lor și răspunderea dvs. în acord cu
                prevederile specificate în acestea.
              </p>
              <p className="mt-2">
                În anumite cazuri, este posibil ca raporturile dintre SOLUTION
                TALK și anumiți Utilizatori să fie guvernate de contracte
                particulare. Ori de câte există asemenea contracte particulare
                valabil încheiate, acestea se vor completa cu Politicile
                SOLUTION TALK. În cazul în care vor exista neconcordanțe între
                contractele particulare și Politicile SOLUTION TALK, prevederile
                contractelor particulare vor prevala.
              </p>
              <p className="mt-2">
                Acest Website nu este destinat copiilor sub 18 ani. Prin
                utilizarea acestui Website declarați că aveți peste 18 ani și
                aveți capacitatea de vă asuma Politicile SOLUTION TALK.
              </p>
              <p className="mt-2">
                Dacă utilizați acest Website în numele unei societăți,
                organizații sau al unei alte entități, atunci (a) „dvs.” include
                și acea entitate și (b) declarați și garantați că sunteți un
                reprezentant autorizat al respectivei entități și că aveți
                capacitatea de a vă asuma Politicile SOLUTION TALK în numele
                entității.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                II. Serviciile oferite de SOLUTION TALK prin intermediul
                Website-ului
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Prin intermediul Website-ului, SOLUTION TALK facilitează
                interacțiunea între psihologi și/ sau psihiatri (în continuare
                ”Specialiști”), pe de o parte și clienții care sunt interesați
                de servicii de psihologie și/ sau psihiatrie (în continuare
                ”Clienți”), pe de altă parte. Website-ul permite Specialiștilor
                să listeze servicii de psihologie și/ sau psihiatrie, pe care le
                pot presta online sau în locații fizice (în continuare
                ”Serviciile”) iar Clienților să le rezerve, să le plătească și
                să beneficieze de aceste Servicii.
              </p>
              <p className="mt-2">
                Relația cu Specialiștii. Crearea unui Cont de Specialist și
                listarea unui Serviciu pe Website presupun încheierea unui
                contract cu SOLUTION TALK, guvernat de prevederile Politicilor
                SOLUTION TALK, pe care vă obligați să le respectați întocmai.
                Contractul dintre SOLUTION TALK și Specialist se consideră
                încheiat la momentul creării Contului și acceptării Politicilor
                SOLUTION TALK, condiționat de validarea ulterioară a Contului de
                către SOLUTION TALK, potrivit prevederilor acestor Termeni și
                Condiții. Toate Serviciile listate de Specialist, rezervate și
                cumpărate de către un Client prin intermediul Website-ului se
                vor considera Servicii intermediate pentru care Specialistul va
                datora companiei SOLUTION TALK remunerația prevăzută în acești
                Termeni și Condiții (”Comisionul”). Listarea Serviciilor pe
                acest Website și prestarea lor se fac pe propria răspundere a
                Specialistului, fără niciun control și fără nicio răspundere din
                partea SOLUTION TALK.
              </p>
              <p className="mt-2">
                Relația cu Clienții. Crearea unui Cont de Client și accesarea
                Serviciilor prin intermediul Website-ului presupun acceptarea
                Politicilor SOLUTION TALK, pe care vă obligați să le respectați
                întocmai. Totuși, atunci când cumpărați un Serviciu de pe acest
                Website, în calitate de Client, încheiați un raport juridic
                (contract) direct cu Specialistul ales de dvs. SOLUTION TALK nu
                prestează servicii de psihologie, psihiatrie sau de altă
                asemenea natură, nu este parte a raportului stabilit între
                Client și Specialist, nu poate influența și nu își asumă
                răspunderea pentru prestarea sau calitatea Serviciului, pentru
                comportamentul Specialistului, pentru funcționarea platformei pe
                care se desfășoară ședințele online, pentru calitatea sunetului
                sau a imaginii sau pentru altele asemenea, care țin de raportul
                între Client și Specialist. Serviciile pe care le accesați prin
                acest Website sunt prestate direct de Specialiști iar întreaga
                răspundere pentru aceste Servicii este asumată de Specialiștii
                care le prestează. Website-ul este adresat doar psihologilor și
                psihiatrilor atestați. Specialiștii sunt verificați în
                registrele Colegiului Psihologilor și cel al Medicilor și le
                sunt verificate diplomele și acreditările pe care le afișează pe
                Website. Totuși, SOLUTION TALK nu poate garanta că fiecare
                Specialist listat pe acest Website își desfășoară activitatea în
                mod legal în orice moment al prezenței sale pe Website. Este în
                sarcina dvs., ca și Client, să vă asigurați că Specialistul ale
                cărui Servicii doriți să le cumpărați este atestat de instituția
                competentă potrivit legii.
              </p>
              <p className="mt-2">
                Relația cu Companiile. Companiile au posibilitatea de a încheia
                contracte de colaborare cu SOLUTION TALK, în baza cărora
                Companiile se obligă să achite Prețul Serviciilor rezervate de
                salariații lor, la un Preț de discount (-10% din Prețul
                Serviciilor listat pe Website). Contractul de colaborare dintre
                SOLUTION TALK și Companii se consideră încheiat la momentul
                creării Contului de Companie și acceptării Politicilor SOLUTION
                TALK.
              </p>
              <p className="mt-2">
                Relația cu Vizitatorii. Website-ul poate fi vizitat și de către
                Utilizatori neînregistrați (”Vizitatori”). Utilizarea
                Website-ului de către Vizitatori presupune acceptarea implicită
                a Politicilor SOLUTION TALK.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">III. Definiții </h2>
            </div>
            <div>
              <p className="mt-2">
                <span className="font-bold">Utilizator</span> – înseamnă orice
                persoană sau entitate care utilizează Website-ul, incluzând
                Specialiștii, Clienții, Companiile și Vizitatorii;
              </p>
              <p className="mt-2">
                <span className="font-bold">Specialist </span> – înseamnă un
                Utilizator al Website-ului care, în calitatea sa de psiholog sau
                psihiatru atestat potrivit legislației în vigoare, listează
                servicii de psihologie sau psihiatrie prin intermediul
                Website-ului iar în schimb datorează companiei SOLUTION TALK
                Comisionul prevăzut în acești Termeni și Condiții;
              </p>
              <p className="mt-2">
                <span className="font-bold">Client</span> – înseamnă un
                Utilizator al Website-ului care apelează la Serviciile unui
                Specialist prin intermediul Website-ului (care rezervă, plătește
                sau participă la o ședință online sau offline);
              </p>
              <p className="mt-2">
                <span className="font-bold">Companie</span> – înseamnă o
                persoană juridică care, prin crearea Contului de Companie pe
                Website, încheie un contract de colaborare cu SOLUTION TALK în
                baza căruia persoana juridică se obligă să achite Prețul
                Serviciilor rezervate de angajații săi potrivit acestor Termeni
                și Condiții, cu aplicarea unui discount de 10%;
              </p>
              <p className="mt-2">
                <span className="font-bold">Vizitator</span> – înseamnă orice
                persoană sau entitate care utilizează Website-ul fără a fi
                înregistrată (fără crearea unui Cont de Utilizator);
              </p>
              <p className="mt-2">
                <span className="font-bold">Serviciu</span> – înseamnă un
                serviciu de psihologie și/ sau psihiatrie, care este prestat de
                către un Specialist unuia sau mai multor Clienți prin
                interacțiune directă, care se poate realiza online (audio și/
                sau video) sau offline (în locațiile fizice ale Specialistului);
              </p>
              <p className="mt-2">
                <span className="font-bold">Prețul serviciului</span> – înseamnă
                costul total datorat de Client sau Companie, după caz, pentru
                Serviciile rezervate, care este afișat pe Website la profilul
                fiecărui Specialist;
              </p>
              <p className="mt-2">
                <span className="font-bold">Comision</span> – înseamnă un
                procent de 20% din Prețul Serviciilor, datorat de Specialist
                companiei SOLUTION TALK în acord cu prevederile acestor Termeni
                și Condiții;
              </p>
              <p className="mt-2">
                <span className="font-bold">Conținut</span> – înseamnă toate
                materialele și conținutul proprietatea SOLUTION TALK sau a unor
                terțe persoane de la care SOLUTION TALK a obținut o licență de
                utilizare sau orice alt drept, care fie sunt publicate pe
                Website, fie pot fi accesate prin intermediul Website-ului, fie
                sunt în legătură cu campaniile promoționale sau canalele de
                social medial ale SOLUTION TALK; sunt incluse aici, cu titlu
                exemplificativ iar nu limitativ următoarele: domeniu, texte,
                grafică web, design, produse software, coduri sursă, elemente
                multimedia, imagini, înregistrări sonore, clipuri video,
                broșuri, documente, baze de date, mărci, logo-uri, denumiri
                comerciale precum și orice alte date, informații sau materiale
                indiferent de forma de prezentare;
              </p>
              <p className="mt-2">
                <span className="font-bold">Materiale</span> – înseamnă toate
                materialele și conținutul, indiferent de forma de prezentare, pe
                care un Utilizator îl încarcă, transmite sau publică în/prin
                Contul său de Utilizator, zona ”Notițe pentru Ședință”, zona de
                ”Mesagerie”, în cadrul ședințelor la care participă sau în orice
                alt mod;
              </p>
              <p className="mt-2">
                <span className="font-bold">Regulamentul GDPR</span> –
                Regulamentul (UE) 2016/679 al Parlamentului European și al
                Consiliului din 27 aprilie 2016 privind protecția persoanelor
                fizice în ceea ce privește prelucrarea datelor cu caracter
                personal și privind libera circulație a acestor date și de
                abrogare a Directivei 95/46/CE (Regulamentul general privind
                protecția datelor);
              </p>
              <p className="mt-2">
                <span className="font-bold">Politica de confidențialitate</span>{" "}
                – înseamnă documentul (disponibil aici) care descrie modul în
                care SOLUTION TALK prelucrează datele cu caracter personal,
                întocmit cu respectarea prevederilor art. 13 din Regulamentul
                GDPR;
              </p>
              <p className="mt-2">
                <span className="font-bold">Politica Cookies</span> – înseamnă
                documentul (disponibil aici) care descrie modul în care
                Website-ul utilizează module Cookies și alte tehnologii
                similare;
              </p>
              <p className="mt-2">
                <span className="font-bold">DPA</span> – înseamnă contractul
                (disponibil aici) încheiat conform prevederilor art. 28 alin.
                (3) din Regulamentul GDPR, între SOLUTION TALK și fiecare
                Specialist care listează Servicii prin intermediul Website-ului
                și care stabilește modul în care SOLUTION TALK va prelucra, în
                calitate de Persoană Împuternicită, date cu caracter personal în
                numele Specialistului;
              </p>
              <p className="mt-2">
                <span className="font-bold">Politicile SOLUTION TALK</span> –
                înseamnă Termenii și Condițiile, Politica de confidențialitate,
                Politica Cookies și DPA;
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                IV. Contul de utilizator
              </h2>
            </div>
            <div>
              <h2 className="text-xl mt-5 font-bold">
                4.1 Crearea contului de utilizator
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Vizitarea Website-ului se poate face fără înregistrare însă
                listarea și cumpărarea de Servicii prin intermediul Website-ului
                presupune crearea unui Cont de Utilizator care poate fi Cont de
                Client, Cont de Companie sau Cont de Specialist.
              </p>
              <p className="mt-2">
                Crearea unui Cont de Utilizator se face prin introducerea unei
                adrese de e-mail valide și setarea unei parole. Înainte de
                crearea Contului de Utilizator, veți citi și accepta Politicile
                SOLUTION TALK. Acordul se consideră dat prin bifarea căsuțelor
                corespunzătoare. Contul de Utilizator nu va putea fi creat în
                lipsa acceptării Politicilor SOLUTION TALK.
              </p>
              <p className="mt-2">
                Prin crearea unui Cont de Utilizator declarați și garantați că
                aveți peste 18 ani, că Materialele introduse sunt corecte,
                actuale și complete și că nu încalcă drepturile terților.
              </p>
              <p className="mt-2">
                Un Utilizator poate deține un singur Cont de Utilizator activ.
                Crearea de conturi multiple presupune încălcarea acestor Termeni
                și Condiții și poate conduce la ștergerea/ dezactivarea
                conturilor și limitarea accesului la Website.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  4.2 Setarea și protejarea parolei
                </h2>
              </div>
              <p className="mt-2">
                La setarea parolei, este recomandat să nu folosiți anumite
                cuvinte relevante pentru dvs. dacă acestea sunt cunoscute altor
                persoane, să nu folosiți cifre dublate (cum ar fi 1111) sau
                modele ușor de tastat (cum ar fi 123456, 14789 sau 12369). Este
                de asemenea recomandat să nu mai folosiți parola pentru
                accesarea altor conturi. Din motive de securitate, SOLUTION TALK
                va impune anumite cerințe de complexitate a parolei, care vor fi
                afișate la introducerea parolei.
              </p>
              <p className="mt-2">
                Pentru protejarea Contului, aveți obligația să păstrați parola
                confidențială. SOLUTION TALK nu răspunde pentru niciun
                prejudiciu sau pierdere produse ca urmare a nerespectării de
                către dvs. a acestor obligații și recomandări sau care sunt
                consecința unei neglijențe din partea dvs. sau unor acțiuni ale
                terților pentru care SOLUTION TALK nu este ținută potrivit legii
                să răspundă. În cazul în care aveți cunoștință de sau suspectați
                o accesare neautorizată a Contului dvs. de Utilizator, trebuie
                să procedați imediat la schimbarea parolei și să ne contactați
                prin e-mail la contact@solutiontalk.ro.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  4.3 Resetarea parolei
                </h2>
              </div>
              <p className="mt-2">
                Pentru resetarea parolei, veți introduce adresa de e-mail, în
                primul pas al procesului de autentificare în Cont și veți accesa
                butonul “Ai uitat parola?”, urmând să primiți automat un e-mail
                cu instrucțiuni de resetare a parolei.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                V. Listarea serviciilor de către Specialiști
              </h2>
            </div>
            <div>
              <h2 className="text-xl mt-5 font-bold">
                5.1 Crearea contului de utilizator
              </h2>
            </div>
            <div>
              <p className="mt-2">
                După crearea Contului de Specialist, acesta va fi inactiv până
                la validarea sa de către SOLUTION TALK, proces care poate dura
                aproximativ 72 ore. SOLUTION TALK își rezervă dreptul să nu
                valideze sau să invalideze ulterior un Cont de Specialist, fără
                nicio răspundere față de Specialist, în oricare dintre
                următoarele situații:
              </p>
              <ul className="list-decimal">
                <li className="ml-8 mt-1">
                  <p>
                    Specialistul nu deține un atestat valabil sau nu
                    îndeplinește alte condiții necesare potrivit legii pentru
                    desfășurarea legală a activității (sub nicio formă această
                    clauză nu poate fi interpretată în sensul că impune SOLUTION
                    TALK o obligație de verificare a legalității activității
                    Specialistului, întreaga responsabilitate în acest sens
                    fiind asumată de către Specialist);
                  </p>
                </li>
                <li className="ml-8 mt-1">
                  <p>
                    Specialistul nu furnizează informațiile/ documentele
                    solicitate de SOLUTION TALK în secțiunile Contului sau
                    furnizează informații sau documente inexacte, false sau
                    incomplete;
                  </p>
                </li>
                <li className="ml-8 mt-1">
                  <p>
                    Specialistul are sau dobândește o proastă reputație iar
                    SOLUTION TALK apreciază cu bună-credință că demararea sau
                    continuarea unei relații de colaborare cu acesta ar dăuna
                    imaginii și intereselor SOLUTION TALK;
                  </p>
                </li>
                <li className="ml-8 mt-1">
                  <p>
                    Specialistul beneficiază de multiple recenzii negative din
                    partea Clienților, astfel încât rating-ul acestuia scade sub
                    nivelul acceptat, de 3 stele;
                  </p>
                </li>
                <li className="ml-8 mt-1">
                  <p>există alte motive legitime.</p>
                </li>
              </ul>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.2 Efectuarea setărilor pentru contul de Stripe al
                  Specialistului
                </h2>
              </div>
              <p className="mt-2">
                După validarea Contului, Specialistul va accesa link-ul Stripe
                din meniu pentru a face setările legate de plată. Prin
                intermediul acestui cont, Specialistul va încasa Prețul
                Serviciilor prestate prin intermediul Website-ului și va achita
                Comisionul datorat societății SOLUTION TALK. Mai multe
                informații pot fi accesate pe website-ul procesatorului de
                plăți: https://stripe.com/en-ro.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.3 Listarea Serviciilor pe Website
                </h2>
              </div>
              <p className="mt-2">
                După validarea Contului de utilizator și efectuarea setărilor
                pentru contul de Stripe, Specialistul poate lista Servicii pe
                Website, ocazie cu care va furniza următoarele informații: tipul
                Serviciului (psihologie/ psihiatrie), modalitatea de prestare a
                Serviciului (online/ offline, cu indicarea locației fizice în
                care Specialistul este autorizat să funcționeze în mod legal),
                intervalele orare în care este posibilă rezervarea Serviciului,
                inclusiv durata unei ședințe precum și Prețul Serviciului
                practicat.
              </p>
              <p className="mt-2">
                Prin crearea Contului și listarea de Servicii pe Website,
                Specialistul declară și garantează că este atestat de Colegiul
                Psihologilor din România sau de Colegiul Medicilor din România,
                după caz, și că desfășoară în mod legal activitatea care face
                obiectul Serviciului listat, asumându-și întreaga răspundere
                pentru toate prejudiciile cauzate ca urmare a încălcării acestor
                obligații, declarații și garanții.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.4 Prețul afișat de Specialist
                </h2>
              </div>
              <p className="mt-2">
                Toate preturile afișate pe Website sunt exprimate în RON și
                includ TVA potrivit legislației în vigoare. Specialistul
                înțelege că Prețul Serviciului pe care îl afișează pe Website
                reprezintă costul total plătibil de Client sau Companii pentru
                Serviciile rezervate și că în afara acestui Preț Specialistul nu
                mai poate percepe Clienților sau Companiilor niciun cost sau
                tarif suplimentar. Companiile care încheie un contract de
                colaborare cu SOLUTION TALK vor beneficia de un discount de 10%
                din Prețul Serviciilor afișat de Specialist pe Website.
              </p>
              <p className="mt-2">
                De asemenea, Specialistul declară, garantează și se obligă să nu
                listeze Servicii identice sau similare pe alte site-uri
                concurente, la un preț inferior celui practicat pe Website, sub
                sancțiunea rezilierii contractului cu SOLUTION TALK, închiderii
                Contului și reparării prejudiciului cauzat.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.5 Poziționarea profilelor de Specialist
                </h2>
              </div>
              <p className="mt-2">
                Poziționarea profilelor Specialiștilor pe Website se va efectua
                în funcție de mai multe criterii, precum [...]. SOLUTION TALK nu
                garantează niciunui Specialist o anumită poziționare a
                profilului în cadrul Website-ului, aceștia (Specialiștii)
                neputând emite niciun fel de pretenții în acest sens împotriva
                SOLUTION TALK.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.6. Rezervarea Serviciilor de către Clienți
                </h2>
              </div>
              <p className="mt-2">
                Odată listate pe Website, ședințele pentru Serviciile prestate
                de Specialiști pot fi rezervate și cumpărate de Clienți. Fiecare
                rezervare efectuată cu succes de către Client va fi notificată
                Specialistului prin e-mail/ sms împreună cu detaliile aferente
                și va apărea în Calendarul Programărilor Specialistului
                respectiv. Orice Serviciu rezervat și achitat de către Client
                trebuie prestat acestuia de către Specialist în mod direct, fără
                a recurge la persoane interpuse, cu un nivel ridicat de
                profesionalism, cu respectarea drepturilor pacienților, a
                legislației în vigoare și a Politicilor SOLUTION TALK.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.7. Inițierea ședinței de către Specialist
                </h2>
              </div>
              <p className="mt-2">
                Serviciul va fi prestat de Specialist conform rezervării
                Clientului, fie online (situație în care Specialistul se va loga
                pe platforma Zoom), fie offline, situație în care Specialistul
                va fi prezent la locația fizică unde își desfășoară activitatea.
              </p>
              <p className="mt-2">
                Specialistul trebuie să inițieze ședința (fie că este online sau
                offline) în decurs de 15 minute calculate începând cu ora
                rezervării, în caz contrar Clientul fiind îndreptățit să obțină
                restituirea Prețului aferent rezervării respective.
              </p>
              <p className="mt-2">
                Pentru prestarea Serviciilor online, Specialistul trebuie să
                dețină un telefon, o tabletă sau un laptop cu cameră video,
                microfon si o conexiune bună la internet, care sa permită
                interacționarea stabilă și în bune condiții tehnice cu Clientul.
                Înainte de inițierea ședinței, Specialistul se va asigura că nu
                există probleme de natură tehnică care să împiedice o bună
                desfășurare a ședinței.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.8. Încasarea Prețului de la Clienți/ Companii
                </h2>
              </div>
              <p className="mt-2">
                Încasarea Prețului Serviciilor se va efectua prin intermediului
                procesatorilor de plăți Netopia Payments și Stripe.
              </p>
              <p className="mt-2">
                Sumele de bani achitate de Clienți și Companii vor fi
                direcționate către contul de Stripe al Specialistului.
                Specialistul are obligația să nu pretindă și/sau accepte plăți
                directe, efectuate altfel decât prin intermediul Website-ului și
                să nu pretindă și/ sau accepte alte sume de bani în afara
                Prețului care i se cuvine în baza acestor Termeni și Condiții.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.9. Facturarea Prețului către Client/ Companie
                </h2>
              </div>
              <p className="mt-2">
                Factura pentru Prețul Serviciilor achitat de Clienți/ Companii
                se va emite de către Specialist conform legislației în vigoare
                și va putea fi descărcată din Contul de Utilizator al
                Clienților/ Companiilor.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.10. Condiții financiare între Specialist și SOLUTION TALK și
                  între Specialist și procesatorul de plăți Stripe
                </h2>
              </div>
              <p className="mt-2">
                Ca urmare a listării serviciilor pe Website, a utilizării
                sistemului de rezervări și/ sau a prestării Serviciilor prin
                intermediul Website-ului, SOLUTION TALK percepe de la
                Specialiști un Comision de 20% din Prețul Serviciilor plătit de
                Client/ Companie. Acest Comision se aplică la toate ședințele
                listate de Specialist pe Website și la toate Serviciile pe care
                Specialistul le furnizează prin intermediul Website-ului sau a
                căror furnizare a fost facilitată de Website. Cuantumul acestui
                Comision poate fi modificat de SOLUTION TALK prin modificarea
                acestor Termeni și Condiții (pentru detalii, vedeți secțiunea
                XXV de mai jos).
              </p>
              <p className="mt-2">
                Comisionul datorat de Specialist va fi transferat automat din
                contul de Stripe al Specialistului către contul de Stripe al
                SOLUTION TALK. Contravaloarea Comisionului încasat va fi
                facturată de către SOLUTION TALK lunar, factura urmând a fi
                emisă în intervalul 01-05 al lunii pentru Comisionul încasat în
                luna precedentă. Factura va fi comunicată Specialistului prin
                e-mail, în termen de 3 zile de la emiterea sa.
              </p>
              <p className="mt-2">
                În plus față de Comisionul SOLUTION TALK, Specialistului îi va
                fi reținut și comisionul intermediarului Stripe, care este de
                [...].
              </p>
              <p className="mt-2">
                Transferul sumelor de bani din contul de Stripe al
                Specialistului către contul de bancă real al acestuia se face
                automat la un interval ales (minim 7 zile).
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                VI. Cumpărarea Serviciilor de către Clienți
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Cumpărarea unui Serviciu prin intermediul Website-ului presupune
                crearea unui Cont de Client, efectuarea unei rezervări, plata
                Prețului Serviciului și confirmarea rezervării
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  6.1. Efectuarea unei rezervări
                </h2>
              </div>
              <p className="mt-2">
                Odată listate pe Website, Serviciile Specialiștilor pot fi
                rezervate și cumpărate de Clienți. Pentru efectuarea unei
                rezervări, Clientul va alege Specialistul, tipul Serviciului
                precum și intervalul ședinței, în funcție de disponibilitatea
                Specialistului afișată pe Website. După selectarea detaliilor
                rezervării, Clientul va fi direcționat către pagina
                procesatorului de plăți, în vederea efectuării plății.
              </p>

              <div>
                <h2 className="text-xl mt-5 font-bold">
                  6.2. Plata Prețului Serviciului
                </h2>
              </div>
              <p className="mt-2">
                Prețul Serviciului este prețul total pe care Clientul îl
                plătește în momentul definitivării rezervării. Prețul
                Serviciilor este afișat pe Website fiind stabilit de fiecare
                Specialist în parte. Toate preturile afișate pe Website sunt
                exprimate în RON și includ TVA potrivit legislației în vigoare.
                Nici SOLUTION TALK și nici Specialistul nu mai percep de la
                Client niciun alt cost al Serviciului în afara Prețului afișat
                pe Website.
              </p>
              <p className="mt-2">
                Contravaloarea Serviciilor se achită în avans. Rezervarea
                Serviciului de către un Client se va face numai după validarea
                plății Prețului aferent. Plata serviciului se face online cu
                card bancar (Visa/ Visa Electron/ Maestro/ MasterCard), ocazie
                cu care Clientul va completa un formular cu informațiile despre
                cardul acestuia în pagina securizata a procesatorului de plăti
                [...]. Datele cardului Clientului nu vor fi accesibile
                societății SOLUTION TALK.
              </p>
              <p className="mt-2">
                Clientul este obligat să nu efectueze plăți directe către
                Specialist, altfel decât prin intermediul Website-ului și să nu
                achite alte sume de bani în afara Prețului afișat pe Website.
              </p>
              <p className="mt-2">
                Contravaloarea Prețului achitat de către Client va fi facturată
                de către Specialist potrivit legislației în vigoare. Factura va
                putea fi descărcată de Client direct din Contul său de
                Utilizator.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  6.3. Confirmarea rezervării
                </h2>
              </div>
              <p className="mt-2">
                După efectuarea cu succes a plății, Clientul va primi un e-mail
                de confirmare a rezervării. În același timp, o confirmare a
                rezervării împreună cu detaliile aferente va fi comunicată și
                Specialistului al cărui Serviciu a fost rezervat.
              </p>

              <div>
                <h2 className="text-xl mt-5 font-bold">
                  6.4. Inițierea ședinței de către Client (accesarea efectivă a
                  Serviciului)
                </h2>
              </div>
              <p className="mt-2">
                După primirea confirmării, Clientul poate accesa Serviciul
                conform rezervării, fie online (situație în care va primi prin
                e-mail un link de logare pe platforma Google Meet), fie offline,
                situație în care se va prezenta la locația fizică în care
                Specialistul își desfășoară activitatea.
              </p>
              <p className="mt-2">
                Pentru ca rezervarea să nu fie pierdută, inițierea ședinței (fie
                că este online sau offline) trebuie efectuată de către Client în
                decurs de 15 minute calculate începând cu ora rezervării.
                Neinițierea ședinței de către Client în acest interval se
                consideră o renunțare a Clientului la ședința respectivă, caz în
                care ședința se pierde iar Prețul nu va fi restituit.
                Neinițierea ședinței de către Specialist în acest interval va
                îndreptăți Clientul să obțină restituirea Prețului aferent.
              </p>
              <p className="mt-2">
                Pentru accesarea Serviciilor online Clientul trebuie să dețină
                un telefon, o tabletă sau un laptop cu cameră video (dacă se
                dorește ca interacțiunea să fie video), microfon si o conexiune
                bună la internet, care sa permită interacționarea stabilă și în
                bune condiții tehnice cu Specialistul. Înainte de inițierea
                ședinței, Clientul se va asigura că nu există probleme de natură
                tehnică care să împiedice o bună desfășurare a ședinței.
              </p>
              <p className="mt-2">
                În cazul ședințelor fizice, Clientul este unicul responsabil
                pentru deplasarea sa în timp util la sediul Specialistului.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  6.5. Derogări pentru Clienții care accesează Serviciile în
                  calitate de angajați ai unor Companii
                </h2>
              </div>
              <p className="mt-2">
                Pentru Clienții care accesează Serviciile în baza unui contract
                încheiat de angajatorul lor cu SOLUTION TALK nu se vor aplica
                prevederile de mai sus privind efectuarea plății. În cazul
                acestor Clienți, plata se va efectua de angajatorul lor
                (Compania), potrivit acestor Termeni și Condiții.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                VII. Discount-uri pentru Companii
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Companiile au posibilitatea de a încheia contracte de colaborare
                cu SOLUTION TALK, în baza cărora Companiile vor achita
                contravaloarea Serviciilor rezervate de angajații lor la preț de
                discount (-10% față de Prețul Serviciului listat pe Website).
                Contractul se consideră încheiat prin crearea Contului de
                Companie și acceptarea Politicilor SOLUTION TALK. Discount-ul se
                va acorda exclusiv pentru Serviciile rezervate de salariați prin
                intermediul Website-ului iar nu și pentru serviciile care nu au
                fost rezervate / accesate prin Website.
              </p>
              <p className="mt-2">
                Urmare a încheierii contractului cu SOLUTION TALK, Companiile
                își asumă obligația plății Prețului tuturor Serviciilor
                rezervate cu succes de către angajații lor. Pentru a verifica
                rezervările efectuate de salariați și Prețul aferent, salariații
                vor comunica Companiilor ID-ul primit cu ocazia creării Contului
                de Client, care va constitui Codul lor de angajat. Prin
                introducerea ID-ului salariaților în Contul de Utilizator,
                Companiile vor putea identifica numele și prenumele salariaților
                care au rezervat cu succes Servicii, data și ora ședințelor
                rezervate precum și Prețul aferent. Din motive de protecție a
                datelor cu caracter personal, Companiile nu au dreptul să
                solicite SOLUTION TALK niciun fel de date sau informații
                suplimentare cu privire la Serviciile accesate de salariații
                lor. Orice astfel de informații suplimentare pot fi obținute
                direct de la salariați, dacă aceștia sunt de acord să le
                dezvăluie.
              </p>
              <p className="mt-2">
                Plata Prețului de către Companii se va efectua cu cardul bancar,
                prin intermediul procesatorilor de plăți Netopia Payments și
                Stripe astfel: <br></br>a) în data de 16 a lunii pentru
                rezervările din intervalul 01 – 15 al lunii respective;{" "}
                <br></br>
                b) în data de 01 a lunii pentru rezervările din intervalul 16 –
                31 al lunii precedente;
              </p>
              <p className="mt-2">
                Pentru efectuarea plății, administratorul Companiei se va loga
                în Contul de utilizator iar la secțiunea ”Angajați” va găsi
                butonul ”Plătește” care îl va direcționa spre pagina
                procesatorului de plată Stripe.
              </p>
              <p className="mt-2">
                Facturile aferente Prețului datorat de Companie vor fi emise de
                Specialiști și vor fi accesibile acestora în Contul de Companie,
                având evidențiat discount-ul de 10% aplicat.
              </p>
              <p className="mt-2">
                Pentru neplata Prețului la scadență Compania datorează
                penalități de întârziere de 1% pe fiecare zi de întârziere până
                la plata efectivă, cu mențiunea că penalitățile pot depăși
                cuantumul debitului. Neplata prețului la scadență va atrage
                blocarea Contului de Companie și implicit limitarea accesului
                salariaților Companiei respective la Servicii în condiții
                preferențiale. Salariații Companiilor respective vor putea
                accesa în continuare Serviciile prin intermediul Website-ului
                însă în condiții obișnuite, cu plata integrală și directă a
                Prețului.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                VIII. Reprogramarea rezervărilor
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Clienții și Specialiștii pot solicita reprogramarea unei
                rezervări cu cel puțin 48 de ore înainte de data de început a
                rezervării respective.
              </p>
              <p className="mt-2">
                Clientul poate solicita reprogramarea direct din Contul său de
                Utilizator, în orice alt interval de timp liber al
                Specialistului, afișat în profilul de Specialist.
              </p>
              <p className="mt-2">
                Reprogramarea unei rezervări la inițiativa Specialistului
                necesită confirmarea Clientului, care se va efectua din Contul
                de Utilizator.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                IX. Anularea/ neonorarea rezervărilor de către Client
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Clientul poate anula o rezervare cu cel puțin 48 de ore înainte
                de data de început a rezervării respective, situație în care va
                beneficia de restituirea Prețului achitat. Anularea rezervării
                se va face direct din Contul de Utilizator al Clientului.
              </p>
              <p className="mt-2">
                Dacă anularea rezervării nu se face cu cel puțin 48 de ore
                înainte sau dacă accesarea Serviciului de către Client nu se
                efectuează din motive ce țin de Client (spre exemplu: Clientul
                nu a inițiat logarea/ nu s-a prezentat la întâlnirea fizică în
                decurs de 15 minute de la ora stabilită pentru programare,
                Clientul nu se poate loga din cauza unor erori tehnice etc.),
                Clientul va pierde rezervarea respectivă iar Prețul Serviciului
                nu i se va restitui.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                X. Anularea/ neonorarea rezervărilor de către Specialist
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Specialiștii pot anula o rezervare pentru motive excepționale,
                situație în care Clientul va beneficia de restituirea Prețului
                aferent. Orice anulare efectuată de Specialist va fi vizibilă
                Clientului în Contul său de Utilizator.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XI. Restituirea Prețului
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Restituirea Prețului către Client se va face în următoarele
                situații: <br></br>a) Rezervarea este anulată de către
                Specialist (din motive neimputabile Clientului); <br></br>b)
                Ședința nu este inițiată de Specialist în decurs de 15 minute
                (Specialistul nu se loghează în decurs de 15 minute sau nu se
                prezintă la locația fizică, după caz); <br></br>c) Rezervarea
                este anulată de către Client cu cel puțin 48 de ore înainte;{" "}
                <br></br>d) Accesarea unui Serviciu plătit nu se poate realiza
                din cauza unor erori tehnice ale Website-ului.
              </p>
              <p className="mt-2">
                Pentru a beneficia de restituirea Prețului, Clientul va
                transmite o cerere în acest sens prin e-mail la
                contact@solutiotalk.ro. Cererea se va depune în maxim 7 zile de
                la data la care intervine oricare dintre situațiile de mai sus
                (motivele de restituire). În toate cazurile în care Clientul
                este îndreptățit la restituirea Prețului, restituirea se va
                efectua prin virament bancar, în termen de maxim 7 zile de la
                primirea cererii. Restituirea prețului se va efectua de către
                SOLUTION TALK urmând ca Specialistul să ramburseze către
                SOLUTION TALK sumele eferente în termen de maxim 3 zile de la
                solicitarea scrisă transmisă prin e-mail.
              </p>
              <p className="mt-2">
                În toate situațiile în care se impune restituirea Prețului
                Serviciului către Client, acesta va regăsi în Contul de
                Utilizator facturile storno asociate plății respective.
              </p>
              <p className="mt-2">
                Nici SOLUTION TALK și nici Specialistul nu își asumă nicio
                răspundere pentru situațiile în care Serviciul online nu se
                poate furniza/ consuma sau este întrerupt din cauza
                echipamentelor sau conexiunii slabe la internet a Clientului,
                din cauza unor situații neprevăzute (întreruperi de curent etc.)
                sau din alte motive care nu sunt imputabile Specialistului sau
                SOLUTION TALK. În aceste situații contravaloarea Prețului
                Serviciului nu se va returna Clientului.
              </p>
              <p className="mt-2">
                De asemenea, nici SOLUTION TALK și nici Specialistul nu își
                asumă nicio răspundere pentru situațiile în care ședința fizică
                nu se poate furniza/ consuma din cauza întârzierilor Clientului
                sau din alte motive ce țin de Client. În aceste situații
                contravaloarea Prețului Serviciului nu se va returna Clientului.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">XII. Recenzii</h2>
            </div>
            <div>
              <p className="mt-2">
                Clienții au posibilitatea să acorde o recenzie Specialiștilor cu
                care au interacționat prin intermediul Website-ului. Clienții
                declară că înțeleg și acceptă faptul că sunt unicii responsabili
                pentru recenziile acordate Specialiștilor și că dreptul de a-și
                expune părerea cu privire la competențele/ comportamentul unui
                Specialist trebuie exercitat în limite legale, cu respectarea
                drepturilor Specialistului.
              </p>
              <p className="mt-2">
                Specialiștii declară că înțeleg și acceptă faptul că SOLUTION
                TALK nu poate influența în vreun fel recenziile oferite de
                Clienți, nu le poate șterge sau modifica și nu își asumă nicio
                răspundere cu privire la eventualele prejudicii, daune de
                imagine sau de altă natură cauzate Specialistului ca urmare a
                unor recenzii negative.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XIII. Proprietate intelectuală
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Utilizarea Website-ului se poate face exclusiv potrivit scopului
                căruia îi este destinat, respectiv pentru listarea/ cumpărarea
                Serviciilor și doar cu respectarea Politicilor SOLUTION TALK.
                Prin utilizarea Website-ului, Utilizatorii nu dobândesc vreun
                drept de autor / proprietate intelectuală cu privire la Website
                sau Conținutul său. Întregul Conținut, astfel cum este definit
                la Secțiunea III din acești Termeni și Condiții este și rămâne
                proprietatea exclusivă a SOLUTION TALK sau a persoanelor de la
                care SOLUTION TALK a dobândit un drept de utilizare sau licență.
                Conținutul nu poate fi descărcat, utilizat, reprodus, copiat,
                modificat, alterat, adaptat, tradus, închiriat, vândut,
                distribuit, publicat sau exploatat în alt mod fără permisiunea
                expresă, prealabilă și scrisă a SOLUTION TALK.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XIV. Confidențialitate
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Toate informațiile referitoare la raporturile dintre SOLUTION
                TALK și Utilizatori precum și informațiile referitoare (direct
                sau indirect) la SOLUTION TALK și/ sau business-ul său, obținute
                de către Utilizatori în urma negocierii, încheierii sau
                executării Contractului cu SOLUTION TALK vor fi considerate
                informații confidențiale. În oricare moment al derulării
                raporturilor dintre Părți sau pe viitor, pe o perioadă de 5 ani
                de la data încetării Contractului, Utilizatorii (i) nu vor
                dezvălui informații confidențiale unor terțe persoane fără a
                avea consimțământul prealabil scris al SOLUTION TALK, (ii) nu
                vor utiliza în scop personal sau în folosul unei terțe persoane
                informațiile confidențiale și (iii) nu vor manifesta o atitudine
                neglijentă care să dea posibilitatea unei terțe persoane să
                intre în posesia informațiilor confidențiale.
              </p>
              <p className="mt-2">
                Atât Specialișii cât și Clienții se obligă să păstreze
                confidențialitatea Materialelor primite/ transferate și, acolo
                unde este cazul, secretul profesional, sub sacțiunea răspunderii
                pentru toate prejudiciile astfel cauzate.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XV. Obligațiile, declarațiile, garanțiile și răspunderea
                Utilizatorilor
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Utilizatorii au obligația de a utiliza acest Website și
                Conținutul său numai în scopuri legitime și de a se abține de la
                orice acțiuni, inacțiuni, comportamente ilicite sau dăunătoare,
                inclusiv, dar fără a ne limita la: <br></br>(i) crearea de
                conturi false, crearea de conturi în numele unor terțe persoane,
                furnizarea unor date / Materiale inexacte, incorecte sau care
                pot induce în eroare cu privire la identitatea reală a
                Utilizatorului; <br></br>(ii) încărcarea sau transmiterea de
                Materiale care au un caracter ilegal, defăimător, abuziv,
                licențios, pornografic, care conțin viruși sau forme similare de
                malware, care promovează rasismul, ura, discriminarea, violența
                de orice fel sau altele asemenea, care hărțuiesc o persoană,
                care sunt înșelătoare sau promovează activități ilegale sau
                comportamente abuzive, amenințătoare, obscene, indecente,
                defăimătoare sau calomnioase sau care prejudiciază sau ar putea
                prejudicia în alt mod SOLUTION TALK sau terțele persoane;{" "}
                <br></br>(iii) transmiterea de Materiale protejate de drepturi
                de autor sau proprietate intelectuală, dacă Utilizatorul nu este
                autorul acestora sau nu are permisiunea expresă a autorului;{" "}
                <br></br>(iv) transmiterea/ divulgarea de Materiale protejate
                printr-o obligație de confidențialitate / secret profesional
                dacă Utilizatorul nu are permisiunea expresă a titularului
                dreptului;
                <br></br>(v) utilizarea oricărui dispozitiv automat sau proces
                manual pentru copierea, distrugerea sau modificarea acestui
                Website sau a Conținutului său, în tot sau în parte sau în
                scopul de a accesa / expune secțiuni sau Conținuturi ale
                Website-ului care nu sunt în mod legitim disponibile; <br></br>
                (vi) utilizarea acestui Website în orice scop care este ilegal,
                fraudulos sau dăunător pentru SOLUTION TALK sau pentru terțe
                persoane; <br></br>
                (vii) orice acțiuni, inacțiuni, comportamente prin care se
                încalcă Politicile SOLUTION TALK sau prevederile legale în
                vigoare sau care sunt de natură să încalce drepturile de
                proprietate intelectuală (sau de altă natură) ale SOLUTION TALK
                sau ale terților;
              </p>
              <p className="mt-2">
                Atât Specialiștii cât și Clienții înțeleg că sunt unicii
                responsabili pentru modul în care decurg ședințele, indiferent
                dacă acestea sunt online sau offline.
              </p>
              <p className="mt-2">
                Pentru neplata Prețului la scadență de către Companii precum și
                pentru nerestituirea prețului de către Specialiști, aceștia vor
                datora penalități de întârziere de 1% pe fiecare zi de
                întârziere până la plata efectivă, penalitățile putând depăși
                cuantumul debitului.
              </p>
              <p className="mt-2">
                În mod particular și suplimentar, Specialiștii declară și
                garantează că: <br></br>a) îndeplinesc toate condițiile legale
                pentru prestarea Serviciilor listate prin intermediul
                Website-ului și își asumă integral răspunderea în relația cu
                Clienții pentru orice prejudicii care pot deriva din prestarea
                Serviciului sau au legătură cu prestarea Serviciului; <br></br>
                b) vor presta în mod direct Serviciile listate prin intermediul
                Website-ului și nu vor recurge la intermediari; <br></br>c) vor
                respecta programările efectuate; <br></br>d) vor presta
                Serviciile cu profesionalism și vor respecta toate drepturile
                pacienților, astfel cum sunt reglementate de legislația în
                vigoare; <br></br>e) sunt singurii responsabili pentru calitatea
                Serviciilor prestate și vor răspunde direct în relația cu
                Clientul pentru aceste Servicii; <br></br>f) sunt singurii
                responsabili pentru acțiunile, inacțiunile lor în relația cu
                ceilalți Utilizatori și vor răspunde direct în relația cu
                aceștia pentru orice prejudicii aduse lor, fără implicarea
                SOLUTION TALK; <br></br>g) nu vor lista Servicii identice sau
                similare pe site-uri concurente la un preț inferior celui
                practicat pe acest Website, nu vor întreprinde nicio acțiune de
                natură să deturneze Clienții SOLUTION TALK, să îi convingă sau
                să încerce să îi determine să stabilească un contact direct sau
                prin terțe platforme concurente sau orice altă acțiune care ar
                avea ca rezultat eludarea plății Comisionului către SOLUTION
                TALK, sub sancțiunea rezilierii imediate a Contractului cu
                SOLUTION TALK, ștergerii Contului și plata de daune interese
                evaluate anticipat de Părți la suma de 20000 Euro/ pentru
                fiecare încălcare în parte;
              </p>
              <p className="mt-2">
                În mod particular și suplimentar, Clienții, Companiile (și
                inclusiv administratorii acestora) declară și garantează că:{" "}
                <br></br>a) nu vor rezerva Servicii în numele altor persoane;{" "}
                <br></br>b) nu vor contacta Specialiștii listați pe Website cu
                scopul de a-i determina să le presteze Servicii în mod
                independent de implicarea Website-ului și nu vor accepta
                propunerea unui Specialist de a efectua o plată sau de a stabili
                un contact în afara Website-ului, sub sancțiunea rezilierii
                imediate a Contractului cu SOLUTION TALK, ștergerii Contului și
                plata de daune interese evaluate anticipat de Părți la suma de
                20000 Euro/ pentru fiecare încălcare în parte; <br></br>c) sunt
                singurii responsabili pentru acțiunile, inacțiunile lor în
                relația cu ceilalți Utilizatori și vor răspunde direct în
                relația cu aceștia pentru orice prejudicii aduse lor, fără
                implicarea SOLUTION TALK; <br></br>d) sunt singurii responsabili
                pentru toate datele și Materialele puse la dispoziția
                Specialiștilor în interacțiunea cu aceștia;
              </p>
              <p className="mt-2">
                În cazul încălcării oricăror obligații, declarații sau garanții
                prevăzute în Politicile SOLUTION TALK: <br></br>a) SOLUTION TALK
                poate rezilia unilateral contractul cu Utilizatorul, fără
                intervenția instanțelor de judecată, fără acordarea unui termen
                de remediere și fără nicio altă formalitate prealabilă;{" "}
                <br></br>b) SOLUTION TALK poate decide imediat invalidarea/
                dezactivarea sau ștergerea Contului de Utilizator și implicit a
                profilului Specialistului fără nicio răspundere față de
                Utilizator; <br></br>
                c) Utilizatorul culpabil va despăgubi SOLUTION TALK precum și
                terțele persoane prejudiciate pentru orice plângeri, cereri de
                chemare în judecată, prejudicii (atât pierderea efectiv suferită
                cât și beneficiul nerealizat, inclusiv daune de imagine),
                amenzi, taxe sau pierderi de orice fel în legătură cu sau
                derivate din aceste încălcări.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XVI. Răspunderea SOLUTION TALK
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Utilizarea Website-ului, listarea și/ sau accesarea de Servicii
                prin intermediului Website-ului la fel și interacțiunea cu alți
                Utilizatori ai Website-ului se fac pe propria răspundere a
                fiecărui Utilizator.
              </p>
              <p className="mt-2">
                SOLUTION TALK nu răspunde pentru și nu își asumă niciun fel de
                obligații de reparare a oricăror eventuale prejudicii derivând
                din sau în legătură cu: <br></br>(i) Serviciile listate sau
                accesate prin intermediul acestui Website, <br></br>(ii)
                Acțiunile, inacțiunile, comportamentul de orice fel sau
                Materialele Utilizatorilor Website-ului, <br></br>(iii)
                Informațiile/ Materialele de orice fel din Conturile de
                Utilizator sau transmise în alt mod de către/ între Utilizatori,{" "}
                <br></br>(iv) Platformele terțe utilizate pentru plata,
                furnizarea sau accesarea Serviciilor online; <br></br>(v)
                Interacțiunea în orice mod (online sau offline) a unui
                Utilizator cu un alt Utilizator.
              </p>
              <p className="mt-2">
                Deși Website-ul este adresat doar psihologilor și psihiatrilor
                atestați, totuși, SOLUTION TALK nu poate garanta că fiecare
                Specialist listat pe acest Website își desfășoară activitatea în
                mod legal, în oricare moment al prezenței sale pe Website.
                Anterior validării Contului unui Specialist, SOLUTION TALK va
                verifica specialiștii în registrele publice ale Colegiului
                Psihologilor și cel al Medicilor, însă nu își asumă obligația
                unor verificări suplimentare sau ulterioare validării contului.
              </p>
              <p className="mt-2">
                SOLUTION TALK nu oferă nicio garanție cu privire la Website sau
                Conținutul său. În mod particular, dar fără a ne limita la,
                SOLUTION TALK nu garantează că Website-ul va funcționa
                neîntrerupt, fără viruși sau fără erori sau că informația din
                Conținutul Website-ului este corectă, actuală, fără erori sau
                alte inadvertențe.
              </p>
              <p className="mt-2">
                Dacă accesarea unui Serviciu plătit nu se poate realiza din
                cauza unor erori tehnice ale Website-ului, Clientul va beneficia
                de restituirea Prețului achitat pentru ședința respectivă, fără
                nicio răspundere suplimentară din partea SOLUTION TALK. Astfel,
                exceptând obligația de restituire a Prețului, SOLUTION TALK nu
                răspunde pentru niciun fel de daune rezultate din sau în vreun
                fel legate de (i) utilizarea acestui Website, (ii) de
                incapacitatea de a-l accesa sau utiliza, (iii) de întreruperea
                Serviciilor sau (iv) care rezultă din sau sunt în legătură cu
                utilizarea de către dvs. a Conținutului disponibil pe acest
                Website.
              </p>
              <p className="mt-2">
                În cazul în care legea aplicabilă are (sau va avea) dispoziții
                specifice cu privire la exonerarea sau limitarea răspunderii
                care intră în conflict cu cele de mai sus, limitarea răspunderii
                SOLUTION TALK va opera în limitele permise de lege.
              </p>
              <p className="mt-2">
                SOLUTION TALK nu va fi implicată în nicio dispută intervenită
                între Utilizatori indiferent de calitatea lor (de exemplu: între
                un Client și un Specialist, între doi sau mai mulți Clienți sau
                între doi sau mai mulți Specialiști) care derivă din sau are
                legătură cu Serviciile listate/ accesate prin intermediul
                acestui Website sau prin utilizarea în alt mod a Website-ului.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XVII. Încetarea contractului între SOLUTION TALK și Utilizatori
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Încetarea prezentului Contract între SOLUTION TALK și
                Utilizatori se poate face în oricare dintre următoarele cazuri:
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  17.1. Denunțarea contractului de către Utilizatori (ștergerea
                  Contului de către Utilizatori)
                </h2>
              </div>
              <p className="mt-2">
                Utilizatorii pot denunța Contractul încheiat cu SOLUTION TALK
                prin ștergerea Contului de Utilizator. Contul de Utilizator și
                implicit profilul unui Specialist pot fi șterse oricând de către
                Clienți, Companii și Specialiști, direct din Cont. În acest caz,
                toate rezervările confirmate dar neaccesate până la data
                ștergerii Contului vor fi anulate automat.
              </p>
              <p className="mt-2">
                În ipoteza anulării rezervărilor ca urmare a ștergerii Contului
                Specialistului, Clienții vor beneficia de restituirea Prețului
                aferent tuturor rezervărilor anulate pe acest motiv. În schimb,
                în ipoteza anulării rezervărilor ca urmare a ștergerii Contului
                Clientului, Clientul va beneficia de restituirea Prețului
                aferent rezervărilor anulate doar dacă ștergerea Contului s-a
                efectuat cu cel puțin 48 de ore înainte de rezervare.
              </p>
              <p className="mt-2">
                Ștergerea Contului nu exonerează Utilizatorii (Client, Companie,
                Specialist) de obligațiile scadente care le revin în baza
                Politicilor SOLUTION TALK, a contractelor particulare încheiate
                sau legislației în vigoare. De asemenea, în ipoteza încetării
                contractului între Părți ca urmare a ștergerii Contului, vor
                continua să se aplice toate clauzele acestor Termeni și Condiții
                și în general ale Politicilor SOLUTION TALK care sunt menite să
                producă efecte și după încetarea Contractului.
              </p>

              <div>
                <h2 className="text-xl mt-5 font-bold">
                  17.2. Denunțarea/ rezilierea contractului de către SOLUTION
                  TALK (ștergerea Contului de către SOLUTION TALK)
                </h2>
              </div>
              <p className="mt-2">
                Fără a fi ținută să acorde vreo compensație, SOLUTION TALK are
                dreptul să decidă ștergerea Contului de Utilizator (și implicit
                a profilului unui Specialist), fără niciun preaviz sau
                notificare în acest sens, în oricare dintre următoarele
                situații: <br></br>(i) ați încălcat Politicile SOLUTION TALK sau
                legislația în vigoare; <br></br>(ii) ați furnizat Materiale
                incorecte, incomplete, inexacte, de neactualitate sau care
                încalcă drepturile și/ sau interesele de orice natură ale
                SOLUTION TALK sau ale unui terț; <br></br>(iii) prin acțiunile/
                inacțiunile dvs. de orice fel încălcați drepturile sau
                interesele legitime ale SOLUTION TALK sau ale unor terți;{" "}
                <br></br>(iv) anulați mai mult de 2 rezervări în decursul unei
                perioade de 2 luni; <br></br>(v) primiți review-uri negative
                legate de Serviciile prestate în cadrul Website-ului iar
                rating-ul scade sub 3; <br></br>(vi) ștergerea Contului este
                obligatorie pentru punerea în executarea a unei hotărâri
                judecătorești definitive; <br></br>(vii) există alte motive
                legitime care îndreptățesc SOLUTION TALK să considere, cu bună
                credință, că ștergerea Contului se impune din motive de
                securitate, din motive de protecție a drepturilor și intereselor
                legitime ale SOLUTION TALK sau ale altor persoane.
              </p>
              <p className="mt-2">
                În cazul Ștergerii Contului de către SOLUTION TALK toate
                rezervările confirmate dar neaccesate până la data ștergerii
                Contului vor fi anulate automat.
              </p>
              <p className="mt-2">
                În ipoteza anulării rezervărilor ca urmare a ștergerii Contului
                unui Specialist, Clienții vor beneficia de restituirea Prețului
                aferent tuturor rezervărilor anulate pe acest motiv. În schimb,
                în ipoteza anulării rezervărilor ca urmare a ștergerii Contului
                Clientului, Clientul NU va beneficia de restituirea Prețului
                aferent rezervărilor anulate (considerându-se că ștergerea
                Contului are la bază o acțiune culpabilă a Clientului).
              </p>
              <p className="mt-2">
                SOLUTION TALK va avea dreptul de a informa Utilizatorii
                interesați cu privire la orice măsură de ștergere a unui Cont de
                Utilizator.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  6.3. Confirmarea rezervării
                </h2>
              </div>
              <p className="mt-2">
                După efectuarea cu succes a plății, Clientul va primi un e-mail
                de confirmare a rezervării. În același timp, o confirmare a
                rezervării împreună cu detaliile aferente va fi comunicată și
                Specialistului al cărui Serviciu a fost rezervat.
              </p>

              <div>
                <h2 className="text-xl mt-5 font-bold">
                  6.4. Inițierea ședinței de către Client (accesarea efectivă a
                  Serviciului)
                </h2>
              </div>
              <p className="mt-2">
                După primirea confirmării, Clientul poate accesa Serviciul
                conform rezervării, fie online (situație în care va primi prin
                e-mail un link de logare pe platforma Google Meet), fie offline,
                situație în care se va prezenta la locația fizică în care
                Specialistul își desfășoară activitatea.
              </p>
              <p className="mt-2">
                Pentru ca rezervarea să nu fie pierdută, inițierea ședinței (fie
                că este online sau offline) trebuie efectuată de către Client în
                decurs de 15 minute calculate începând cu ora rezervării.
                Neinițierea ședinței de către Client în acest interval se
                consideră o renunțare a Clientului la ședința respectivă, caz în
                care ședința se pierde iar Prețul nu va fi restituit.
                Neinițierea ședinței de către Specialist în acest interval va
                îndreptăți Clientul să obțină restituirea Prețului aferent.
              </p>
              <p className="mt-2">
                Pentru accesarea Serviciilor online Clientul trebuie să dețină
                un telefon, o tabletă sau un laptop cu cameră video (dacă se
                dorește ca interacțiunea să fie video), microfon si o conexiune
                bună la internet, care sa permită interacționarea stabilă și în
                bune condiții tehnice cu Specialistul. Înainte de inițierea
                ședinței, Clientul se va asigura că nu există probleme de natură
                tehnică care să împiedice o bună desfășurare a ședinței.
              </p>
              <p className="mt-2">
                În cazul ședințelor fizice, Clientul este unicul responsabil
                pentru deplasarea sa în timp util la sediul Specialistului.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  6.5. Derogări pentru Clienții care accesează Serviciile în
                  calitate de angajați ai unor Companii
                </h2>
              </div>
              <p className="mt-2">
                Pentru Clienții care accesează Serviciile în baza unui contract
                încheiat de angajatorul lor cu SOLUTION TALK nu se vor aplica
                prevederile de mai sus privind efectuarea plății. În cazul
                acestor Clienți, plata se va efectua de angajatorul lor
                (Compania), potrivit acestor Termeni și Condiții.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XVIII. Reclamații și sesizări
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Satisfacția dvs. extrem de importantă pentru noi! Dacă aveți
                reclamații sau sesizări, le puteți trimite prin e-mail la
                contact@solutiontalk.ro. Vom răspunde acestora în decurs de
                maxim 7 zile cu excepția cazului în care avem motive temeinice
                să prelungim acest termen, caz în care vă vom informa cu privire
                la aceste circumstanțe. Pentru o soluționare eficientă, vă rugăm
                să ne trimiteți toate detaliile reclamației și să anexați dovezi
                în susținerea pretențiilor dvs.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">XIX. Forță majoră</h2>
            </div>
            <div>
              <p className="mt-2">
                Părțile nu vor putea fi făcute responsabile pentru neexecutarea
                parțiala sau totală sau pentru executarea necorespunzătoare a
                obligațiilor născute din Politicile SOLUTION TALK dacă aceasta
                s-a datorat unor circumstanțe de forță majoră, astfel cum este
                definită de lege.
              </p>
              <p className="mt-2">
                Partea care invocă circumstanțele forței majore este obligată să
                notifice celeilalte părți faptele întâmplate având caracter de
                eveniment a forței majore, în termen de maxim 10 zile de la data
                producerii. Depășirea acestui termen limită duce la pierderea
                dreptului Părții de a mai invoca forța majoră ca circumstanță
                (eveniment) de forță majoră. Daca in termen de 15
                (cincisprezece) zile de la data producerii lui, respectivul
                eveniment de forță majoră nu încetează, fiecare Parte va avea
                dreptul sa notifice celeilalte parți încetarea de plin drept a
                Contractului fără ca vreuna dintre ele sa poată pretinde
                celeilalte daune-interese.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XX. Legea aplicabilă și soluționarea disputelor
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Politicile SOLUTION TALK (deci implicit acești Termeni și
                Condiții) precum și toate raporturile născute între SOLUTION
                TALK, pe de o parte și fiecare Utilizator al acestui Website, pe
                de altă parte vor fi guvernate de legea română. Eventualele
                diferende care s-ar putea ivi în legătură cu Politicile SOLUTION
                TALK sau raporturile dintre SOLUTION TALK și Utilizatori vor fi
                soluționate pe cale amiabilă, iar dacă Părțile nu cad de acord,
                vor fi soluționate de instanțele de judecată competente de la
                sediul SOLUTION TALK cu excepția situațiilor în care legea
                prevede în mod imperativ o altă competență.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">XXI. ANPC</h2>
            </div>
            <div>
              <p className="mt-2">
                Consumatorii, astfel cum sunt definiți de legea română, se pot
                adresa și Autorității Naționale pentru Protecția Consumatorilor
                , cu sediul în București, Bulevardul Aviatorilor nr. 72, sector
                1. Mai multe informații se pot regăsi pe site-ul autorității,
                https://anpc.ro/
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XXII. Soluționarea alternativă și online a litigiilor - SOL și
                SAL (pentru consumatori)
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Soluționarea alternativă a litigiilor (SAL) permite soluționarea
                litigiilor între SOLUTION TALK și consumatori (astfel cum sunt
                definiți de legea română) în afara instanței, cu asistență din
                partea unui organism imparțial de soluționare a litigiilor.
                Această metodă de soluționare a litigiilor în materie de consum
                este mai ușoară, mai rapidă și mai puțin costisitoare decât
                acționarea în instanță. Mai multe detalii găsiți aici:
                https://anpc.ro/ce-este-sal/.
              </p>
              <p className="mt-2">
                Dacă litigiul dumneavoastră se referă la o achiziție online,
                platforma de soluționare online a litigiilor (SOL)
                (ec.europa.eu/consumers/odr) vă permite să depuneți o plângere
                online la un organism SAL în orice limbă și în orice țară din
                UE.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">XXIII. Notificări</h2>
            </div>
            <div>
              <p className="mt-2">
                Întreaga corespondență dintre Părți, inclusiv notificarea
                privind modificarea Politicilor SOLUTION TALK se realizează prin
                astfel: <br></br>- Notificările și comunicările care provin de
                la SOLUTION TALK se vor transmite prin email, utilizându-se
                adresa furnizată cu ocazia creării Contului de Utilizator sau
                prin mesaje (pop-up-uri) publicate pe Website; <br></br>-
                Notificările și comunicările care provin de la Utilizatori,
                adresate SOLUTION TALK se vor transmite prin e-mail la
                contact@solutiontalk.ro sau la sediul SOLUTION TALK situat în
                București, Str. Smaranda Brăescu, nr. 40, Bl. 20H, Sc. 1, Et. 2,
                Ap. 6, Sector 1.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XXIV. Trimiteri (link-uri) către site-uri ale terților
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Acest Website poate furniza link-uri sau referințe către alte
                site-uri (deținute de terți), asupra cărora SOLUTION TALK nu are
                niciun control. SOLUTION TALK nu operează și nu controlează în
                niciun fel conținutul, informațiile, software-ul, produsele sau
                serviciile disponibile pe astfel de site-uri web și nu are nicio
                responsabilitate pentru conținutul acestora și nu va răspunde
                pentru daune sau vătămări generate de acel conținut sau de acel
                acces. Dacă faceți clic pe un link către un site web terț, veți
                fi direcționat către site-ul terțului respectiv, situație în
                care vă asumați toate riscurile care decurg din utilizarea
                acestui web sau a serviciilor aferente acestuia.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XXV. Modificări ale Politicilor SOLUTION TALK
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Politicile SOLUTION TALK pot fi modificate din când în când.
                Aceste modificări pot viza inclusiv Comisioanele datorate de
                Specialiști companiei SOLUTION TALK. Totuși, nicio modificare
                adusă acestor Politici nu se va aplica retroactiv și nu se va
                aplica raporturilor juridice, litigiilor sau altor evenimente
                care au fost încheiate/ care au avut loc înainte de publicarea
                modificării. Noua versiune a Politicilor va fi publicată pe
                Website iar dvs. veți fi notificați printr-un pop-up sau
                printr-un e-mail, înainte ca modificarea să intre în vigoare.
                Data ultimelor actualizări va fi disponibilă întotdeauna în
                partea introductivă a Politicilor. Continuarea utilizării
                Website-ului și după publicarea modificării va constitui
                acceptul dvs. cu privire la varianta actualizată a Politicilor,
                inclusiv acceptul cu privire la noile modificări ale
                Comisioanelor, atunci când este cazul.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px mb-20">
            <div>
              <h2 className="text-2xl mt-5 font-bold">XXVI. Clauze finale</h2>
            </div>
            <div>
              <p className="mt-2">
                Acești Termeni și Condiții reprezintă înțelegerea dintre
                SOLUTION TALK și Utilizatori privind modul de utilizare a
                Website-ului. Utilizatorul declară și garantează că a înțeles și
                acceptă în mod expres conținutul acestor Termeni și Condiții și
                a celorlalte Politici SOLUTION TALK și își asumă riscul de
                eroare cu privire la înțelegerea oricărei prevederi.
              </p>
              <p className="mt-2">
                Dacă orice prevedere din Politicile SOLUTION TALK este sau
                devine nulă, restul dispozițiilor vor rămâne valabile.
              </p>
              <p className="mt-2">
                Acești Termeni și Condiții sunt disponibili Utilizatorului
                pentru stocare cu scopul de dovedi înțelegerea contractuală
                intervenită între părți.
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default TermeniSiConditii;
