import React, { useContext, useEffect } from "react";
import { UserContext } from "../../providers/UserProvider";
import { Footer, Header, Seo } from "../../components";
import { Link } from "react-router-dom";
import Hero from "../../components/Hero/Hero";
import blog from "../../assets/img/blog.png";
import latest_new from "../../assets/homepage/latest_new.jpg";
import person1 from "../../assets/homepage/Persona1.png";
import { useState } from "react";

const Blog = () => {
  const [posts, setPosts] = useState();
  const [nameFilter, setNameFilter] = useState();

  // Luam postarile din cms-ul facut in wordpress. Pentru articole noi, trebuie sa le introducem acolo
  useEffect(() => {
    fetch("https://cms.solutiontalk.ro/wp-json/wp/v2/posts")
      .then((response) => response.json())
      .then((posts2) => {
        setPosts(posts2);
      });
  }, []);
  return (
    <>
      <Seo
        title="Blog cu Sfaturi de la Psihoterapeuți - Solution Talk"
        description="Sfaturi date de psihoterapeuți pentru tine. Blogul Solution Talk îți pune la dispoziție sfaturi si informații interesante despre psihoterapie. "
      />
      <Header />
      <Hero
        title="Blog"
        image={blog}
        imgback={false}
        background={"back-blog"}
      />
      <div className="background-elipse pt-32 2xl:pt-0 hidden sm:block  -mt-32 xl:mt-0 2xl:-mt-64 "></div>
      <section className="  relative bg-body-g sm:pt-20 2xl:pt-0 px-10 sm:px-20 xl:px-52">
        <div className="flex flex-col xl:flex-row sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto  pb-6 sm:pb-14 xl:pb-28">
          <div className="basis-1/6 flex flex-col gap-5 mb-10 mt-10">
            <div className="bg-white p-5 rounded-2xl">
              <h5 className="font-bold mb-3">Caută articol</h5>
              <input
                className="border pl-2"
                placeholder="Nume articol"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
              ></input>
            </div>

            <div className="bg-white p-5 rounded-2xl flex flex-col gap-5 ">
              <h5 className="font-bold">Cele mai noi postări</h5>
              {!posts
                ? ""
                : posts.slice(0, 5).map((item, index) => (
                    <Link to={"/blog-post/" + item.slug}>
                      <div>
                        <div
                          className="text-lg mb-1"
                          dangerouslySetInnerHTML={{
                            __html: "<h4>" + item.title.rendered + "</h4>",
                          }}
                        ></div>
                      </div>
                    </Link>
                  ))}
            </div>
          </div>
          <div className=" flex flex-wrap mt-4 pb-10 sm:pb-20 flex-col lg:flex-row basis-5/6 gap-10 lg:gap-0">
            {!posts
              ? ""
              : posts
                  .filter((post) => {
                    var name = true;
                    if (nameFilter) {
                      name = post.title.rendered
                        .toLowerCase()
                        .includes(nameFilter.toLowerCase());
                      console.log(name);
                    }
                    return name;
                  })
                  .map((item, index) => (
                    <div
                      className="flex basis-1/2 flex-col lg:p-4 items-start"
                      key={index}
                    >
                      <Link
                        to={"/blog-post/" + item.slug}
                        className="bg-white  rounded-3xl shadow-lg h-full"
                      >
                        <div className="w-full h-[236px] rounded-t-3xl">
                          <img
                            className="w-full h-full object-center object-cover rounded-t-3xl"
                            src={item.featured_image_url}
                          />
                        </div>
                        <div className="flex flex-col p-4 sm:p-9">
                          <div
                            className="text-xl font-bold mb-3"
                            dangerouslySetInnerHTML={{
                              __html: "<h3>" + item.title.rendered + "</h3>",
                            }}
                          ></div>
                          <div
                            className="text-lg"
                            style={({ fontWeight: 300 }, { fontSize: 15 })}
                            dangerouslySetInnerHTML={{
                              __html: item.excerpt.rendered,
                            }}
                          />
                          <div className="flex w-full pb-4 sm:pb-8 gap-2.5 items-center">
                            <div className="flex flex-col"></div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Blog;
