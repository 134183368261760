import React from "react";
import { Header, Footer } from "../../../components";

const PoliticaConfidentialitate = () => {
  return (
    <>
      <Header />
      <div className="px-10 mb-20">
        <section className="mt-20">
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h1 className="text-4xl text-center">
                POLITICA DE CONFIDENȚIALITATE
              </h1>

              <p className="text-base text-center mt-5">
                Versiune în vigoare la data de 04.10.2022
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">I. Cine suntem noi?</h2>
            </div>
            <div>
              <p className="mt-2">
                Website-ul https://solutiontalk.ro/ (în continuare ”Website-ul”)
                este proprietatea SOLUTION TALK S.R.L., persoană juridică
                română, cu sediul în București, Str. Smaranda Brăescu, nr. 40,
                Bl. 20H, Sc. 1, Et. 2, Ap. 6, Sector 1, înmatriculată la ORC sub
                nr. J40 /2852 /2022, cod fiscal 45650372, e-mail
                contact@solutiontalk.ro (în continuare ” SOLUTION TALK”, ”noi”,
                ”a noastră”).
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                II. Scopul și aplicabilitatea acestei Politici{" "}
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Această politică vă informează cu privire la modul în care
                SOLUTION TALK prelucrează datele dvs. cu caracter personal
                atunci când utilizați acest Website. Ori de câte ori utilizați
                acest Website, indiferent de modalitatea utilizării și
                indiferent de calitatea dvs. (Specialist, Client, Companie sau
                Vizitator neînregistrat), se va aplica această Politică de
                Confidențialitate, care se completează cu Politica Cookies
                (disponibilă aici) și cu Data Processing Agreement – DPA
                (disponibil aici). <br></br>
                <br></br>Orice informații suplimentare cu privire la modul de
                prelucrare a datelor dvs. cu caracter personal pot fi obținute
                utilizând datele de contact din Cap. XVI ”Contactați-ne” de mai
                jos.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">III. Definiții</h2>
            </div>
            <div>
              <p className="mt-2">
                Date cu caracter personal – înseamnă orice informații privind o
                persoană fizică identificată sau identificabilă; o persoană
                fizică identificabilă este o persoană care poate fi
                identificată, direct sau indirect, în special prin referire la
                un element de identificare, cum ar fi un nume, un număr de
                identificare, date de localizare, un identificator online, sau
                la unul sau mai multe elemente specifice, proprii identității
                sale fizice, fiziologice, genetice, psihice, economice,
                culturale sau sociale;
              </p>
              <p className="mt-2">
                Prelucrarea datelor cu caracter personal – înseamnă orice
                operațiune sau set de operațiuni efectuate asupra datelor cu
                caracter personal sau asupra seturilor de date cu caracter
                personal, cu sau fără utilizarea de mijloace automatizate, cum
                ar fi colectarea, înregistrarea, organizarea, structurarea,
                stocarea, adaptarea sau modificarea, extragerea, consultarea,
                utilizarea, divulgarea prin transmitere, diseminarea sau punerea
                la dispoziție în orice alt mod, alinierea sau combinarea,
                restricționarea, ștergerea sau distrugerea;
              </p>
              <p className="mt-2">
                Operator – înseamnă persoana fizică sau juridică care (singură
                sau împreună cu altele), stabilește scopurile și mijloacele de
                prelucrare a datelor cu caracter personal;
              </p>
              <p className="mt-2">
                Persoană împuternicită de operator – înseamnă persoana fizică
                sau juridică care prelucrează datele cu caracter personal în
                numele Operatorului. În anumite cazuri, SOLUTION TALK poate
                folosi serviciile unor persoane împuternicite pentru a prelucra
                datele dvs. cu caracter personal. De asemenea, pentru anumite
                categorii de date, SOLUTION TALK va acționa în calitate de
                Persoană Împuternicită de Specialiști să prelucreze datele dvs.
                cu caracter personal.
              </p>
              <p className="mt-2">
                Cookie – înseamnă un fișier text de mici dimensiuni care este
                descărcat pe echipamentul dvs. terminal (cum ar fi, de exemplu,
                un computer sau un smartphone) atunci când accesați un site web
                și care permite site-ului web să vă recunoască dispozitivul și
                să stocheze anumite informații despre preferințele sau istoricul
                căutărilor dvs.;
              </p>
              <p className="mt-2">
                Utilizator – înseamnă orice persoană sau entitate care
                utilizează Website-ul, incluzând Specialiștii, Clienții,
                Companiile și Vizitatorii;
              </p>
              <p className="mt-2">
                Specialist – înseamnă un Utilizator al Website-ului care, în
                calitatea sa de psiholog sau psihiatru atestat potrivit
                legislației în vigoare, listează servicii de psihologie sau
                psihiatrie prin intermediul Website-ului iar în schimb datorează
                companiei SOLUTION TALK Comisionul prevăzut în Termeni și
                Condiții (disponibil aici);
              </p>
              <p className="mt-2">
                Client – înseamnă un Utilizator al Website-ului care apelează la
                Serviciile unui Specialist prin intermediul Website-ului (care
                rezervă, plătește sau participă la o ședință online sau
                offline);
              </p>
              <p className="mt-2">
                Companie – înseamnă o persoană juridică care, prin crearea
                Contului de Companie pe Website, încheie un contract de
                colaborare cu SOLUTION TALK în baza căruia persoana juridică se
                obligă să achite Prețul Serviciilor rezervate de angajații săi
                potrivit Termenilor și Condițiilor, cu aplicarea unui discount
                de 10%;
              </p>
              <p className="mt-2">
                Vizitator – înseamnă orice persoană sau entitate care utilizează
                Website-ul fără a fi înregistrată (fără crearea unui Cont de
                Utilizator);
              </p>
              <p className="mt-2">
                Serviciu – înseamnă un serviciu de psihologie și/ sau
                psihiatrie, care este prestat de către un Specialist unuia sau
                mai multor Clienți prin interacțiune directă, care se poate
                realiza online (audio și/ sau video) sau offline (în locațiile
                fizice ale Specialistului);
              </p>
              <p className="mt-2">
                Prețul Serviciului – înseamnă costul total datorat de Client sau
                Companie, după caz, pentru Serviciile rezervate, care este
                afișat pe Website la profilul fiecărui Specialist;
              </p>
              <p className="mt-2">
                Comision – înseamnă un procent de 20% din Prețul Serviciilor,
                datorat de Specialist companiei SOLUTION TALK în acord cu
                prevederile Termenilor și Condițiilor;
              </p>
              <p className="mt-2">
                Conținut - înseamnă toate materialele și conținutul proprietatea
                SOLUTION TALK sau a unor terțe persoane de la care SOLUTION TALK
                a obținut o licență de utilizare sau orice alt drept, care fie
                sunt publicate pe Website, fie pot fi accesate prin intermediul
                Website-ului, fie sunt în legătură cu campaniile promoționale
                sau canalele de social medial ale SOLUTION TALK; sunt incluse
                aici, cu titlu exemplificativ iar nu limitativ următoarele:
                domeniu, texte, grafică web, design, produse software, coduri
                sursă, elemente multimedia, imagini, înregistrări sonore,
                clipuri video, broșuri, documente, baze de date, mărci,
                logo-uri, denumiri comerciale precum și orice alte date,
                informații sau materiale indiferent de forma de prezentare;
              </p>
              <p className="mt-2">
                Materiale – înseamnă toate materialele și conținutul, indiferent
                de forma de prezentare, pe care un Utilizator îl încarcă,
                transmite sau publică în/prin Contul său de Utilizator, zona
                ”Notițe pentru Ședință”, zona de ”Mesagerie”, în cadrul
                ședințelor la care participă sau în orice alt mod;
              </p>
              <p className="mt-2">
                Regulamentul GDPR – Regulamentul (UE) 2016/679 al Parlamentului
                European și al Consiliului din 27 aprilie 2016 privind protecția
                persoanelor fizice în ceea ce privește prelucrarea datelor cu
                caracter personal și privind libera circulație a acestor date și
                de abrogare a Directivei 95/46/CE (Regulamentul general privind
                protecția datelor);
              </p>
              <p className="mt-2">
                Termeni și Condiții - înseamnă documentul (disponibil aici) care
                conține termenii și condițiile aplicabile raporturilor ce se
                nasc între SOLUTION TALK, pe de o parte și fiecare Utilizator al
                Website-ului, pe de altă parte;
              </p>
              <p className="mt-2">
                Politica Cookies - înseamnă documentul (disponibil aici) care
                descrie modul în care Website-ul utilizează module Cookies și
                alte tehnologii similare;
              </p>
              <p className="mt-2">
                DPA – înseamnă contractul (disponibil aici) încheiat conform
                prevederilor art. 28 alin. (3) din Regulamentul GDPR, între
                SOLUTION TALK și fiecare Specialist care listează Servicii prin
                intermediul Website-ului și care stabilește modul în care
                SOLUTION TALK va prelucra, în calitate de Persoană
                Împuternicită, date cu caracter personal în numele
                Specialistului;
              </p>
              <p className="mt-2">
                Politicile SOLUTION TALK – înseamnă Termenii și Condițiile,
                Politica de confidențialitate, Politica Cookies și DPA;
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                IV. Calitatea în care SOLUTION TALK prelucrează Date cu caracter
                personal
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Atunci când decide scopurile și mijloacele de prelucrare a
                Datelor cu caracter personal, SOLUTION TALK este Operator, în
                sensul Regulamentului GDPR. SOLUTION TALK va fi Operator cu
                privire la toate datele prevăzute la Cap. V de mai jos. Aceste
                date se referă în principal la datele de identificare și contact
                ale Clienților, Specialiștilor și reprezentanților/ salariaților
                Companiilor, datele de logare, datele de facturare, datele
                bancare, datele în legătură cu titlul profesional al
                Specialiștilor, datele în legătură cu Serviciile listate,
                prestate și accesate prin Website, descrierile din profilul
                Specialiștilor, recenziile precum și datele colectate automat
                prin intermediul Cookies și al tehnologii similare. Pentru
                aceste date, exercitarea drepturilor dvs. se face în relație cu
                SOLUTION TALK.
              </p>
              <p className="mt-2">
                În schimb, în legătură cu toate datele și Materialele pe care
                Clienții și Specialiștii decid să și le transfere în cadrul
                Zonei de Mesagerie sau care sunt extrase de Specialist în urma
                interacțiunii cu Clientul și notate în zona Notițe pentru
                Ședințe din Contul său de Utilizator sau care sunt transferate
                în alt mod între Client și Specialist (în general datele care
                privesc interacțiunea Client-Specialist și care se pot referi la
                situația personală, familială, profesională, de sănătate sau de
                altă natură a Clienților sau ale unor terțe persoane) SOLUTION
                TALK va avea calitatea de Persoană Împuternicită, Operator fiind
                Specialistul. Aceste date sunt prevăzute la Cap. VI de mai jos
                și vor fi prelucrate de Specialist, SOLUTION TALK oferind doar
                mijlocul tehnic de prelucrare a lor. Relația dintre SOLUTION
                TALK și Specialist în ceea ce privește aceste date va fi
                guvernată de Data Processing Agreement (DPA). Pentru aceste
                date, exercitarea drepturilor dvs. se face în relație cu
                Specialistul.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                V. Datele pe care SOLUTION TALK le prelucrează în calitate de
                Operator de date, scopurile și temeiul juridic al prelucrării
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Prezentul capitol vă explică ce date colectează SOLUTION TALK cu
                privire la dvs., cum le colectează (direct de la dvs., de la
                terțe părți sau în mod automat), în ce scop și care este temeiul
                legal. Deși depunem toate eforturile pentru a identifica toate
                datele cu caracter personal pe care le prelucrăm, totuși,
                circumstanțe concrete pot face să prelucrăm și alte date, în
                funcție și de conduita pe care dvs. o aveți în relația cu noi.
                În orice caz, ne obligăm să avem o atitudine transparentă în
                relația cu dvs. și să prelucrăm datele dvs. în acord cu
                cerințele Regulamentului GDPR.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.1. Datele prelucrate de SOLUTION TALK în relația cu
                  Specialiștii
                </h2>
              </div>
              <table className="my-4 ">
                <tr>
                  <td className="border border-slate-300 p-4">
                    Date furnizate de Specialist, cum ar fi: <br></br>- Date de
                    identificare și contact (nume, prenume, telefon, e-mail,
                    adresa de reședință); <br></br>- Date de logare (e-mail și
                    parolă); - Date privind titlul profesional (specializarea,
                    ultimele studii absolvite, codul de colegiu, anii de
                    experiență profesională, cursuri de specialitate la care ați
                    participat, formări profesionale și titluri dobândite,
                    documentele de studii și implicit toate datele cuprinse în
                    aceste documente); <br></br>- Date privind tarifele
                    practicate pentru Serviciile oferite; <br></br>- Date de
                    facturare (și implicit locul muncii/ forma de exercitare a
                    profesiei); <br></br>- Detaliile contului bancar; <br></br>-
                    Date cu privire la Serviciile listate și furnizate pe
                    Website, inclusiv intervale orare disponibile și istoricul
                    rezervărilor; <br></br>- Fotografia de profil precum și
                    orice alte date pe care dvs. decideți să ni le furnizați
                    prin crearea și administrarea Contului, prin upload-ul
                    materialului video de prezentare, prin publicarea de
                    comentarii, conținut, opinii, recenzii sau în orice altă
                    manieră.
                  </td>
                  <td className="border border-slate-300 p-4">
                    Date colectate din alte surse, respectiv: <br></br>- Date în
                    legătură cu tarifele pe care le practicați pe site-uri
                    concurente, pe care le putem colecta de pe site-uri web
                    terțe pentru a verifica dacă vă respectați obligațiile
                    asumate în relația cu SOLUTION TALK; <br></br>- Date privind
                    gradul de satisfacție a Clienților dvs. și opinia acestora
                    despre Serviciile dvs., pe care le colectăm de la Clienți,
                    prin intermediul recenziilor pe care aceștia le postează;{" "}
                    <br></br>- Date în legătură cu Serviciile pe care le
                    furnizați Clienților prin intermediul Website-ului, inclusiv
                    calendarul programărilor, tipul ședințelor (online/offline)
                    și intervalele orare, Clienții dvs., istoricul rezervărilor,
                    istoricul ședințelor onorate și al rezervărilor anulate,
                    încasările realizate, Comisioanele datorate, date colectate
                    în principal ca urmare a acțiunilor Clienților pe Website
                    sau în mod automat;
                  </td>
                  <td className="border border-slate-300 p-4">
                    Date colectate automat. Atunci când utilizați Website-ul,
                    anumite date cu privire la dvs. pot fi colectate automat,
                    prin intermediului Cookie-urilor sau al altor tehnologii
                    similare. Pentru aceste date, vedeți secțiunea 5.4. de mai
                    jos precum și Politica Cookies (disponibilă aici).
                  </td>
                </tr>
              </table>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.2. Datele prelucrate de SOLUTION TALK în relația cu Clienții
                </h2>
              </div>
              <table className="my-4 ">
                <tr>
                  <td className="border border-slate-300 p-4">
                    Date furnizate de Client, cum ar fi: <br></br>- Date de
                    identificare și contact (nume, prenume, telefon, adresa de
                    reședință); - Date de logare (e-mail și parolă); <br></br>-
                    Locul de muncă, atunci când accesați Serviciile în calitate
                    de angajat al unei Companii; <br></br>- Date de facturare;{" "}
                    <br></br>- Gradul de satisfacție și alte comentarii cu
                    privire la un Specialist și Serviciile sale, date colectate
                    prin intermediul recenziilor pe care le lăsați; <br></br>-
                    Date în legătură cu Serviciile pe care le accesați prin
                    intermediul Website-ului, respectiv calendarul programărilor
                    dvs., tipul ședințelor rezervate, intervale orare, istoricul
                    rezervărilor dvs., istoricul ședințelor la care ați
                    participat și al rezervărilor anulate, Specialiștii la
                    Serviciile cărora ați apelat, Prețurile plătite; <br></br>-
                    Fotografia de profil precum și orice alte date pe care dvs.
                    decideți să ni le furnizați prin crearea și administrarea
                    Contului, prin publicarea de comentarii, conținut, opinii,
                    recenzii sau în altă manieră.
                  </td>
                  <td className="border border-slate-300 p-4">
                    Date colectate din alte surse, respectiv: <br></br>- Date
                    colectate de la procesatorul de plăți. Atunci când efectuați
                    o plată online, veți fi direcționați către pagina
                    procesatorului de plăți, care va prelucra datele dvs. de
                    card; aceste date nu sunt prelucrate de SOLUTION TALK.
                    Totuși, SOLUTION TALK va primi anumite date de la
                    procesatorul de plăți, respectiv contravaloarea
                    tranzacțiilor efectuate, statusul tranzacțiilor precum și
                    […].
                  </td>
                  <td className="border border-slate-300 p-4">
                    Date colectate automat. Atunci când utilizați Website-ul,
                    anumite date cu privire la dvs. pot fi colectate automat,
                    prin intermediului Cookie-urilor sau al altor tehnologii
                    similare. Pentru aceste date, vedeți secțiunea 5.4. de mai
                    jos precum și Politica Cookies (disponibilă aici).
                  </td>
                </tr>
              </table>
              <p className="mt-2">
                În interacțiunea dvs. cu un Specialist, puteți furniza acestuia
                și alte date cu caracter personal cu privire la dvs. inclusiv cu
                privire la starea dvs. de sănătate. Aceste date cu caracter
                personal vor fi prelucrate de către Specialist, în calitate de
                Operator de date cu caracter personal independent, conform
                propriei politici de prelucrare a datelor (pentru detalii,
                vedeți Cap. VI de mai jos).
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.3. Datele prelucrate de SOLUTION TALK în relația cu
                  reprezentanții, salariații sau persoanele de contact ale
                  Companiilor
                </h2>
              </div>
              <table className="my-4 ">
                <tr>
                  <td className="border border-slate-300 p-4">
                    Date furnizate de persoana vizată, cum ar fi: <br></br>-
                    Date de identificare și contact (nume, prenume, telefon);{" "}
                    <br></br>- Date de logare (e-mail și parolă); <br></br>-
                    Orice alte date furnizate de dvs. prin crearea și
                    administrarea Contului de Utilizator al Companiei, prin
                    publicarea de comentarii, conținut, opinii, recenzii sau în
                    altă manieră.
                  </td>
                  <td className="border border-slate-300 p-4">
                    Date colectate din alte surse, respectiv: <br></br>- Date
                    colectate de colegii dvs. sau alți reprezentanți ai
                    Companiei. Anumite date cu privire la dvs. ne pot fi
                    furnizate de colegii dvs. sau de reprezentanții Companiei.
                  </td>
                  <td className="border border-slate-300 p-4">
                    Date colectate automat. Atunci când utilizați Website-ul,
                    anumite date cu privire la dvs. pot fi colectate automat,
                    prin intermediului Cookie-urilor sau al altor tehnologii
                    similare. Pentru aceste date, vedeți secțiunea 5.4. de mai
                    jos precum și Politica Cookies (disponibilă aici).
                  </td>
                </tr>
              </table>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.4. Date și informații colectate de SOLUTION TALK prin
                  intermediul Cookies și al altor tehnologii similare, în
                  relația cu toți Utilizatorii Website-ului (Clienți,
                  Specialiști, Companii, Vizitatori)
                </h2>
              </div>
              <p className="mt-2">
                Anumite informații tehnice pot fi colectate automat, prin
                intermediul Cookies si al altor tehnologii similare. Astfel de
                informații includ adresa IP, tipul și versiunea browser-ului
                folosit, identificatorul unic al dispozitivului pe care îl
                folosiți, informații privind vizitele dvs. pe Website, paginile
                pe care le-ați vizitat, informații privind modul în care vă
                deplasați printre diferitele secțiuni ale Website-ului, data și
                ora vizitelor, timpul petrecut pe acele pagini, preferințele
                dvs., erorile întâmpinate, […].
              </p>
              <p className="mt-2">
                Astfel de informații sunt prelucrate în următoarele scopuri:{" "}
                <br></br>• pentru a asigura funcționarea Website-ului, <br></br>
                • în scop de analiză, respectiv pentru a înțelege modul în care
                Utilizatorii folosesc Website-ul, astfel încât să îl putem face
                mai intuitiv, să îi optimizăm Conținutul, să îmbunătățim
                experiența de navigare pe Website precum și să putem măsura și
                îmbunătăți performanțele Website-ului; <br></br>• în scop de
                securitate, siguranță și pentru prevenirea fraudelor; <br></br>•
                pentru a înțelege, diagnostica și remedia problemele
                Website-ului; <br></br>• pentru a ne aminti preferințele dvs.;{" "}
                <br></br>• în scop de marketing, respectiv pentru a direcționa
                către dvs. publicitatea realizată pe acest Website, pe alte
                Website-uri sau aplicații, care este de interes pentru dvs. și
                pe care am identificat-o pe baza serviciilor pe care le-ați
                căutat și accesat; <br></br>• pentru evaluarea impactului
                campaniilor publicitare pe care le facem; <br></br>•
                Cookie-urile plasate de terți pot fi utilizate de aceștia și în
                scopul creării de profile ale utilizatorilor, în baza cărora vi
                se va livra publicitate personalizată; <br></br>• pentru […].
              </p>
              <p className="mt-2">
                Utilizarea Cookie-urilor și a tehnologiilor similare (cu
                excepția celor necesare) se va face doar în baza
                consimțământului dvs. Pentru detalii, consultați Politica
                Cookies (disponibilă aici).
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.5. Prelucrarea datelor cu caracter personal în scop de
                  marketing direct, în relația cu toți Utilizatorii Website-ului
                  (Clienți, Specialiști, Companii, Vizitatori)
                </h2>
              </div>
              <p className="mt-2">
                Numele, prenumele și adresa dvs. de e-mail pot fi prelucrate în
                scop de marketing direct, respectiv pentru a vă trimite
                notificări comerciale cu privire la ofertele și promoțiile
                noastre precum și informații cu privire la cele mai noi articole
                și sfaturi pentru sănătate psihică și emoțională. Prelucrarea
                datelor cu caracter personal în scop de marketing direct se va
                efectua în baza consimțământului dvs. explicit, acordat prin
                abonarea la newsletter.
              </p>
              <p className="mt-2">
                Vă informăm că aveți dreptul de a vă retrage consimțământul și
                de a vă opune prelucrării datelor lor cu caracter personal în
                scop de marketing direct. Retragerea consimțământului sau
                exercitarea dreptului de opoziție se poate face în orice moment
                și în mod gratuit, printr-o solicitare transmisă prin e-mail la
                contact@solutiontalk.ro sau prin utilizarea link-ului de
                dezabonare din cuprinsul notificărilor comerciale.
              </p>
              <p className="mt-2">
                În cazul în care vă retrageți consimțământul sau vă opuneți
                prelucrării datelor lor în scop de marketing direct, nu vom mai
                prelucra datele dvs. în acest scop, fără însă ca retragerea
                consimțământului dvs. să afecteze legalitatea prelucrării
                efectuate pe baza acestuia înainte de retragere.
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.6. Scopul prelucrării datelor cu caracter personal
                </h2>
              </div>
              <p className="mt-2">
                Datele dvs. cu caracter personal vor fi prelucrate în
                următoarele scopuri:
                <br></br>- pentru a efectua demersuri înainte de încheierea
                contractelor;
                <br></br>- pentru a determina eligibilitatea dvs. de a vă afișa
                pe Website în calitate de Specialist;
                <br></br>- pentru încheierea și executarea contractului cu dvs.,
                inclusiv pentru facilitarea accesului dvs. la Website, pentru a
                vă permite crearea și administrarea Contului / Profilului,
                listarea, prestarea, rezervarea, plata și accesarea de Servicii
                pe Website, pentru validarea Profilului, pentru efectuarea de
                tranzacții financiare, pentru a facilita interacțiunea dintre
                Utilizatori, pentru justificarea motivelor de blocare/ ștergere
                a Contului/ Profilului dvs., pentru a vă oferi suport tehnic în
                utilizarea Website-ului, pentru a ne îndeplini orice alte
                obligații și a ne exercita orice alte drepturi care decurg din
                Contractul încheiat, din utilizarea Website-ului și din
                interacțiunea dvs. cu noi;
                <br></br>- pentru a comunica cu dvs. și a răspunde solicitărilor
                dvs., inclusiv pentru a vă notifica și informa cu privire la
                modificările Politicilor SOLUTION TALK;
                <br></br>- pentru îndeplinirea obligațiilor noastre legale,
                inclusiv cele în materie financiar-contabilă, cele în materie de
                arhivare precum și pentru a pune în executare hotărâri
                judecătorești definitive sau pentru a da curs solicitărilor
                legale ale instituțiilor și autorităților publice;
                <br></br>- în scopul realizării intereselor noastre legitime,
                respectiv administrarea și îmbunătățirea serviciilor noastre și
                a Website-ului, detectarea, diagnosticarea, remedierea și
                prevenirea problemelor Website-ului, promovarea Website-ului și
                a serviciilor SOLUTION TALK, determinarea încălcării Politicilor
                SOLUTION TALK, protecția împotriva fraudelor, menținerea unei
                evidențe a relației dvs. cu noi astfel încât să ne putem apăra
                drepturile în cadrul oricăror proceduri judiciare sau
                extrajudiciare;
                <br></br>- pentru a vă oferi informații privind serviciile
                noastre, la solicitarea dvs.;
                <br></br>- pentru a răspunde cererilor, plângerilor,
                întrebărilor, comentariilor sau sugestiilor dvs. sau ale unor
                terțe persoane;
                <br></br>- recenziile postate de Clienți pot fi utilizate și
                pentru a determina eligibilitatea Specialiștilor, inclusiv
                pentru identificarea și justificarea situațiilor care pot
                conduce la încetarea contractului cu Specialiștii și/sau
                blocarea/ ștergerea Contului unui Specialist;
                <br></br>- în scop de marketing direct, respectiv pentru a vă
                trimite notificări comerciale privind ofertele și promoțiile
                noastre precum și informații cu privire la cele mai noi articole
                și sfaturi pentru sănătate psihică și emoțională, dar numai dacă
                decideți să le primiți, prin abonarea la newsletter (pentru
                detalii, vedeți secțiunea 5.5. de mai jos);
              </p>
              <p className="mt-2">
                Scopurile în care utilizăm Cookie-uri și tehnologii similare
                sunt prevăzute în secțiunea 5.4. de mai sus precum și în
                Politica Cookies (disponibilă aici).
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  5.7. Temeiul juridic al prelucrării datelor cu caracter
                  personal
                </h2>
              </div>
              <p className="mt-2">
                În activitatea de prelucrare de date pe care o realizăm, ne bază
                pe următoarele temeiuri juridice:
                <br></br>- Demersuri înainte de încheierea unui contract [Art. 6
                alin.(1) lit. b) din Regulamentul GDPR];
                <br></br>- Executarea contractului [Art. 6 alin. (1) lit. b) din
                Regulamentul GDPR];
                <br></br>- Obligația legală [Art. 6 alin.(1) lit. c) din
                Regulamentul GDPR];
                <br></br>- Interesul legitim [Art. 6 alin.(1) lit. f) din
                Regulamentul GDPR];
                <br></br>- Consimțământul dvs. [Art. 6 alin. (1) lit. a) din
                Regulamentul GDPR]. Ne vom baza pe consimțământ exclusiv pentru
                trimiterea de notificări comerciale precum și pentru colectarea
                de date prin intermediul Cookies și al altor tehnologii
                similare.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                VI. Datele pe care SOLUTION TALK le prelucrează în calitate de
                Persoană Împuternicită a Specialiștilor
              </h2>
            </div>

            <div>
              <p className="mt-2">
                Întrucât Website-ul este dotat cu anumite funcționalități care
                facilitează interacțiunea dintre Client și Specialist și anume
                ”Notițe pentru ședințe” și ”Zona de mesagerie”, SOLUTION TALK
                poate prelucra și alte date cu caracter personal cu privire la
                Clienți și Specialiști. Asemenea date pot consta în:
                <br></br>• date cu privire la starea de sănătate a Clienților,
                inclusiv istoricul medical, diagnostice, afecțiuni, simptome,
                tratamente, rezultatele tratamentelor, recomandările medicale,
                evoluții, investigații și rezultatele lor etc.,
                <br></br>• date legate de situația personală, familială,
                profesională sau de altă natură a Clienților sau a terțelor
                persoane, furnizate de Client Specialistului în cadrul
                interacțiunii dintre aceștia, care pot consta în relația cu
                familia, persoanele apropiate, relațiile dintre soți, stări
                generale și evoluția lor, relațiile profesionale etc.
                <br></br>• orice alte date care sunt transmise între Client și
                Specialist în cadrul ”Zonei de Mesagerie”, care sunt extrase de
                Specialist în urma interacțiunii cu Clientul și notate de
                Specialist în zona ”Notițe pentru Ședințe” sau care sunt în alt
                mod prelucrate de Specialist prin intermediul Website-ului;
              </p>

              <p className="mt-2">
                În ceea ce privește aceste date, Operator în sensul
                Regulamentului GDPR, care determină scopul și mijloacele
                prelucrării datelor, durata prelucrării, destinatarii datelor și
                alte elemente esențiale va fi considerat Specialistul. SOLUTION
                TALK va prelucra aceste date în numele Specialistului, în
                calitate de Persoană Împuternicită, în acord cu clauzele DPA
                (disponibil aici). În acest caz, Specialistul este unicul
                responsabili pentru respectarea tuturor legilor și
                reglementărilor care se pot aplica pentru colectarea și
                prelucrarea acestor date precum și pentru notificarea Clienților
                cu privire la modul de prelucrare a datelor lor personale.
              </p>
              <p className="mt-2">
                Pentru toate datele pe care Clientul le transmite Specialistului
                (în timpul ședințelor online / offline, printr-un schimb de
                mesaje, prin Zona de mesagerie sau în orice alt mod), Clientul
                se poate adresa direct Specialistului în legătură cu drepturile
                sale.
              </p>
              <p className="mt-2">
                Prin utilizarea Website-ului, Specialistul declară și garantează
                că a obținut consimțământul Clientului pentru prelucrarea de
                date efectuată sau că prelucrarea se bazează pe un alt temei
                juridic, potrivit legii și că Clientului i s-au furnizat toate
                informațiile cu privire la modul în care datele sale cu caracter
                personal vor fi prelucrate și implicit cu privire la drepturile
                pe care le are în baza Regulamentului GDPR. Specialistul este pe
                deplin răspunzător față de Client cât și față de SOLUTION TALK
                pentru orice prejudicii derivând din sau în legătură cu
                efectuarea unei prelucrări de date fără respectarea prevederilor
                Regulamentului GDPR și a legislației naționale incidente.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                VII. Cât timp stocăm datele dvs.?
              </h2>
            </div>

            <div>
              <p className="mt-2">
                SOLUTION TALK va stoca datele dvs. cu caracter personal atât
                timp cât este necesar pentru realizarea scopurilor menționate în
                prezenta Politică. De principiu, datele dvs. cu caracter
                personal vor fi stocate pe întreaga durată în care dețineți un
                Cont/ Profil pe Website. Totuși, putem stoca datele dvs. cu
                caracter personal și ulterior închiderii Contului atunci când
                legea ne obligă să procedăm în acest sens sau atunci când avem
                un interes legitim să o facem.
              </p>
              <p className="mt-2">
                Spre exemplu, documentele financiar contabile și documentele
                justificative care stau la baza înregistrărilor în
                contabilitatea financiară vor fi păstrate pe o perioadă de 5 sau
                10 ani, conform legislației în vigoare.
              </p>
              <p className="mt-2">
                Datele care derivă din sau sunt în legătură cu contractele/
                acordurile încheiate și/ sau relația dvs. cu SOLUTION TALK vor
                fi păstrate pe toată durata cât avem un contract activ. De
                asemenea, din considerente ce țin de obligația de arhivare a
                documentelor pe durata termenului de prescripție fiscală precum
                și în scopul realizării intereselor noastre legitime (acelea de
                a păstra o evidență a relației dvs. cu noi astfel încât să ne
                putem apăra drepturile în cazul oricăror proceduri judiciare sau
                extrajudiciare), putem păstra datele dvs. timp de 5 ani de la
                încetarea indiferent de motiv a contractului sau, după caz, de
                la ultima interacțiune cu dvs. (oricare situație intervine
                ultima), cu excepția cazului în care avem motive temeinice să le
                păstrăm pe o durată mai mare de timp.
              </p>
              <p className="mt-2">
                Datele tehnice, colectate în mod automat, vor fi stocate pe
                durata prevăzută în Politica Cookies (disponibilă aici).
              </p>
              <p className="mt-2">
                Datele utilizate pentru transmiterea de notificări comerciale
                vor fi păstrate și utilizate în acest scop atâta timp cât
                considerăm că newsletter-ele noastre ar fi de interes pentru
                dvs., dar în orice caz, nu mai mult de […] de la ultima
                interacțiune cu noi sau până când vă retrageți consimțământul
                (până la dezabonare).
              </p>
              <p className="mt-2">
                Atunci când prelucrarea datelor dvs. se face în baza intereselor
                noastre legitime, aveți dreptul de a vă opune prelucrării caz în
                care vom pune în balanță interesele noastre și respectiv
                situația dvs. particulară pentru a lua o decizie finală.
              </p>
              <p className="mt-2">
                Atunci când prelucrarea datelor dvs. se face în baza
                consimțământului dvs., aveți dreptul să vă retrageți oricând
                consimțământul, în mod gratuit, caz în care prelucrarea va
                înceta.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                VIII. Ce drepturi aveți dvs., în calitate de persoane vizate?
              </h2>
            </div>
            <div>
              <p className="mt-2">
                (i) Dreptul la informare cu privire la modul în care prelucrăm
                datele dvs.; informarea dvs. se realizează prin această Politică
                de Confidențialitate, iar dacă aveți nevoie de informații
                suplimentare, ni le puteți cere oricând prin e-mail la
                contact@solutiontalk.ro.
                <br></br>(ii) Dreptul de acces la datele dvs., care vă permite
                să obțineți o confirmare din partea noastră cu privire la
                prelucrarea datelor dvs. personale precum și acces la datele
                respective. Este important să cunoașteți faptul că nu vom putea
                da curs cererii dvs. în toate cazurile, cum ar fi situațiile în
                care exercitarea dreptului dvs. ar putea afecta drepturile altor
                persoane, situațiile în care nu vă putem identifica sau
                situațiile în care cererea dvs. este vădit nefondată sau
                excesivă.
                <br></br>(iii) Dreptul la rectificarea datelor personale
                inexacte care vă privesc;
                <br></br>(iv) Dreptul la ștergerea datelor, care vă permite să
                obțineți ștergerea datelor dvs. în anumite cazuri cum ar fi,
                spre exemplu, situațiile în care datele dvs. nu mai sunt
                necesare pentru îndeplinirea scopurilor pentru care au fost
                colectate, vă retrageți consimțământul pe baza căruia are loc
                prelucrarea și nu avem niciun alt temei juridic pentru
                prelucrare, vă opuneți prelucrării și nu există motive legitime
                care să prevaleze, datele dvs. au fost prelucrate ilegal sau
                datele trebuie șterse pentru respectarea unei obligații legale
                care ne revine. Este important să cunoașteți faptul că nu vom
                putea da curs cererii dvs. în toate cazurile, cum ar fi
                situațiile în care legea ne obligă să păstrăm datele o anumită
                perioadă, datele dvs. sunt necesare pentru exercitarea dreptului
                la liberă exprimare și la informare, pentru constatarea,
                exercitarea sau apărarea unui drept în instanță, datele dvs.
                trebuie păstrate din motive de interes public în domeniul
                sănătății publice etc.
                <br></br>(v) Dreptul la restricționarea prelucrării datelor
                dvs., care vă permite să ne solicitați să nu utilizăm datele
                dvs. cu caracter personal, ci doar să le stocăm, în următoarele
                cazuri: (i) ați contestat exactitatea datelor, pentru o perioadă
                care ne permite să verificăm exactitatea datelor; (ii) v-ați
                opus ștergerii datelor în situația unei prelucrări ilegale;
                (iii) ne-ați solicitat să vă furnizăm anumite date pentru
                constatarea, exercitarea sau apărarea unui drept în instanță;
                (iv) v-ați opus prelucrării datelor, pentru intervalul de timp
                în care verificăm dacă drepturile noastre legitime prevalează
                asupra drepturilor dvs.;
                <br></br>(vi) Dreptul la retragerea consimțământului, în orice
                moment și în mod gratuit, atunci când prelucrarea datelor se
                face în baza consimțământului dvs.;
                <br></br>(vii) Dreptul la portabilitatea datelor, care vă
                permite să primiți datele dvs. cu caracter personal pe care ni
                le-ați furnizat într-un format structurat, utilizat în mod
                curent și care poate fi citit automat sau să transmiteți aceste
                date unui alt operator de date. Un asemenea drept vă este
                conferit doar atunci când prelucrarea datelor dvs. se bazează pe
                consimțământul dvs. sau pe un contract încheiat între noi și
                dvs. și doar dacă prelucrarea este efectuată prin mijloace
                automate;
                <br></br>(viii) Dreptul de a vă opune prelucrării datelor dvs.
                cu caracter personal, în condițiile și limitele prevăzute de
                lege. Este important să cunoașteți faptul că legea ne obligă să
                dăm curs cererii doar pentru prelucrările realizate în scop de
                marketing direct. În celelalte cazuri, avem dreptul să punem în
                balanță interesele noastre și respectiv situația dumneavoastră
                particulară pentru a lua o decizie finală. De aceea, este
                important să ne explicați și de ce vă opuneți prelucrării atunci
                când formulați o astfel de cerere.
                <br></br>(ix) Dreptul de a depune o plângere în fața unei
                autorități de supraveghere (ANSPDCP,
                https://www.dataprotection.ro), dacă considerați că prelucrarea
                datelor dvs. personale este ilegală.
                <br></br>(x) Dreptul de a nu face obiectul unei decizii bazate
                exclusiv pe prelucrarea automată, inclusiv crearea de profiluri,
                care produce efecte juridice care vă privesc sau vă afectează în
                mod similar într-o măsură semnificativă. Este important să
                cunoașteți faptul că acest drept nu se aplică în anumite cazuri,
                cum ar fi situația în care decizia bazată exclusiv pe
                prelucrarea automată este necesară pentru încheierea sau
                executarea unui contract între noi și dvs., este autorizată prin
                lege sau are la bază consimțământul dvs. explicit.
                <br></br>(xi) Dreptul la o cale de atac judiciară.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                IX. Întrebări, solicitări și exercitarea drepturilor dvs.
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Dacă aveți întrebări cu privire la modul în care prelucrăm
                datele dvs. personale sau dacă doriți să vă exercitați
                drepturile, ne puteți transmite o cerere la sediul SOLUTION TALK
                sau prin e-mail la contact@solutiontalk.ro. Nu vom refuza să dăm
                curs unei asemenea cereri, cu excepția cazului în care aceasta
                nu este exercitată în condițiile legii sau nu suntem în măsură
                să vă identificăm.
              </p>
              <p className="mt-2">
                Vom depune toate eforturile pentru a vă răspunde în cel mult o
                lună de la primirea cererii. Totuși, legea ne permite să
                prelungim termenul de răspuns cu două luni atunci când este
                necesar, caz în care vă vom informa cu privire la motivele care
                conduc la această prelungire.
              </p>
              <p className="mt-2">
                Vom răspunde cererilor prin care dvs. vă exercitați drepturile
                în mod gratuit dar dacă cererile dvs. sunt în mod vădit
                nefondate sau excesive, în special din cauza caracterului lor
                repetitiv, SOLUTION TALK poate: (a) fie să perceapă o taxă
                rezonabilă ținând cont de costurile administrative pentru
                furnizarea informațiilor sau a comunicării sau pentru luarea
                măsurilor solicitate; (b) fie să refuze să dea curs cererii.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                X. Ce obligații aveți în relația cu noi?
              </h2>
            </div>
            <div>
              <p className="mt-2">
                În calitate de persoană vizată, aveți obligația de a ne furniza
                date adevărate, exacte și actualizate despre dvs. Totodată vă
                asumați obligația de a nu ne furniza materiale care conțin
                viruși, materiale cu drept de autor, dacă nu sunteți autorul sau
                dacă nu aveți permisiunea autorului de a publica sau divulga
                materialul respectiv, materiale obscene, defăimătoare, de
                amenințare, răuvoitoare sau orice alte materiale ori informații
                de acest gen. În caz de nerespectare a acestei obligații,
                SOLUTION TALK poate șterge informațiile respective și poate
                întreprinde toate măsurile legale împotriva dvs.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XI. Cui vom divulga datele dvs. cu caracter personal?
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Datele dvs. cu caracter personal pot fi divulgate către
                următoarele categorii de destinatari , inclusiv terțe părți,
                după caz:
                <br></br>(i) Salariații SOLUTION TALK, în vederea exercitării
                atribuțiilor de serviciu;
                <br></br>(ii) Alți Utilizatori ai Website-ului, cu următoarele
                distincții:
                <br></br>• Divulgarea datelor Clienților către Specialiști. Cu
                ocazia rezervării, plății și accesării Serviciilor unui
                Specialist prin intermediul Website-ului, Specialiștii vor avea
                acces la următoarele date cu privire la Client: numele,
                prenumele, datele de facturare, istoricul rezervărilor (data și
                ora rezervării, tipul ședințelor, Prețul achitat, data și ora de
                începere/ sfârșit a ședințelor si alte informații privind
                derularea ședinței), [...] . De asemenea, fiecare Specialist va
                avea acces la recenziile lăsate de către Clienți pe Website.
                Datele cu caracter personal transmise între Client și Specialist
                în cadrul ”Zonei de Mesagerie” de pe Website sau notate de
                Specialist în zona ”Notițe pentru Ședințe” de pe Website se vor
                considera adresate și transmise Specialistului/ Clientului iar
                nu companiei SOLUTION TALK. Cu privire la aceste date SOLUTION
                TALK va acționa în calitate de Persoană Împuternicită,
                responsabilitatea privind modul prelucrării revenind
                Specialistului. Datele cu caracter personal transmise între
                Client și Specialist prin intermediul altor instrumente străine
                Website-ului cum ar fi platformele terțe prin care se realizează
                ședințele online (Zoom), în mod direct în cadrul ședințelor
                offline sau în general în orice mod care nu implică transferul/
                stocarea datelor prin/pe Website nu vor fi accesibile companiei
                SOLUTION TALK și nu vor fi prelucrate de către SOLUTION TALK.
                Prelucrarea acestor date se va realiza de către Specialist, în
                calitatea sa de operator de date, conform propriilor politici de
                confidențialitate, fără implicarea și răspunderea SOLUTION TALK,
                care nu va avea nici calitatea de Persoană Împuternicită.
                Clientul este personal răspunzător pentru orice date pe care le
                transferă Specialistului altfel decât prin intermediul
                Website-ului.
                <br></br>• Divulgarea datelor Clienților către alți Utilizatori
                ai Website-ului. Singurele date despre un Client disponibile
                altor Utilizatori sunt recenziile (evaluarea/ numărul de stele
                acordat împreună cu eventualele comentarii).
                <br></br>• Divulgarea datelor Specialiștilor către alți
                Utilizatori ai Website-ului. Pentru luarea deciziei de a apela
                la Serviciile unui anumit Specialist, Utilizatorul trebuie să
                aibă posibilitatea să cunoască anumite date și informații cu
                privire la Specialistul respectiv. De aceea, datele din Profilul
                Specialistului (datele de identificare și contact, datele
                privind titlul profesional, tarifele practicate, fotografia de
                profil, descrierea și video-ul de prezentare, serviciile
                listate, calendarul cu intervalele orare disponibile) precum și
                recenziile postate de Clienți vor fi disponibile tuturor
                Utilizatorilor de pe Website, indiferent dacă navighează
                autentificați sau neautentificați. Anumite date, cum ar fi
                calendarul programărilor, istoricul rezervărilor, istoricul
                ședințelor consumate, istoricul ședințelor anulate, informațiile
                din zona ”Notițe pentru Ședințe” sau ”Zona de mesagerie” vor fi
                disponibile exclusiv Clientului pe care acestea îl privesc.
                Orice transfer de date sau informații realizat de Specialist
                către Client (indiferent că se efectuează prin intermediul
                zonelor ”Notițe pentru Ședințe” sau ”Zona de mesagerie” sau prin
                alte funcționalități fără legătură cu Website-ul) se realizează
                pe propria răspundere a Specialistului.
                <br></br>• Divulgarea datelor Clienților către angajatorii lor.
                Companiile au posibilitatea de a încheia contracte de colaborare
                cu SOLUTION TALK, în baza cărora Companiile vor achita la un
                Preț cu discount rezervările salariaților lor. În astfel de
                situații, pentru a putea verifica rezervările efectuate de
                salariații lor și Prețul pe care Companiile îl datorează pentru
                aceste rezervări, salariații (Clienții) vor comunica Companiilor
                ID-ul primit cu ocazia creării Contului Client, care va
                constitui Codul lor de angajat. Prin introducerea ID-ului
                salariaților în Contul de Utilizator, Companiile vor avea acces
                la numele și prenumele salariaților care au rezervat cu succes
                Servicii, data și ora ședințelor rezervate precum și Prețul
                aferent. Din motive de protecție a datelor cu caracter personal
                SOLUTION TALK nu va transfera Companiilor niciun fel de date sau
                informații suplimentare cu privire la Serviciile accesate de
                salariații lor.
                <br></br>(iii) Furnizorii de servicii care oferă facilități de
                găzduire. Pentru găzduirea Website-ului, SOLUTION TALK
                colaborează cu CLAUS WEB S.R.L., având sediul în Grigore Ureche,
                nr. 20, Satu Mare, 440108, Romania.
                <br></br>(iv) Furnizorii de servicii de e-mail marketing. Atunci
                când decideți să vă abonați la newsletter, anumite date cu
                privire la dvs. (adresa de e-mail precum și numele), sunt
                furnizate companiei […].
                <br></br>(v) Procesatorii de plăți. Prin intermediul
                Website-ului sunt oferite Servicii cu plată. Pentru procesarea
                plăților, SOLUTION TALK folosește serviciile următoarelor
                companii: [… ] . SOLUTION TALK nu colectează detaliile cardului
                de plată. Aceste informații sunt furnizate direct procesatorilor
                de plăți care vor prelucra datele dvs. cu caracter personal
                conform propriilor Politici de confidențialitate, disponibile pe
                website-ul acestora.
                <br></br>(vi) Furnizorii de servicii de comunicații de tip
                videoconferință – Zoom, cărora le vor fi furnizate următoarele
                informații, atunci când se derulează o ședință online: […].
                <br></br>(vii) Furnizorii de servicii și instrumente de analiză,
                targetare/ retargetare, publicitate, marketing comportamental ,
                situație în care datele dvs. pot fi transferate în SUA sau alte
                țări din afara UE, care nu oferă un nivel echivalent de
                protecție a datelor cu caracter personal. SOLUTION TALK apelează
                la servicii terțe (Google Analytics, Google AdWords,
                FacebookAds, […]) pentru a monitoriza și analiza modul în care
                utilizați Website-ul, pentru a vă afișa reclame personalizate pe
                site-uri terțe după ce ați utilizat Website-ul nostru, pentru
                [...].
                <br></br>(viii) Furnizorii de servicii juridice;
                <br></br>(ix) Furnizorii de servicii contabile și financiare;
                <br></br>(x) Furnizorii de servicii de securitate și suport IT;
                <br></br>(xi) Furnizorii de servicii de […]
                <br></br>(xii) Asociații SOLUTION TALK, în scopuri
                administrative și de control, o asemenea prelucrare fiind
                efectuată fie în baza interesului legitim fie în baza obligației
                legale (art. 35 din Legea 82/1991 a contabilității, care conferă
                asociaților unei societăți dreptul să se informeze în legătură
                cu situațiile financiare anuale ale societății);
                <br></br>(xiii) Autorități și instituții publice centrale/
                locale;
                <br></br>(xiv) Organe judiciare, executori judecătorești;
              </p>
              <p className="mt-2">
                De asemenea, datele dvs. pot fi dezvăluite terților atunci când
                acest lucru se impune pentru apărarea drepturilor SOLUTION TALK,
                în cadrul oricăror proceduri judiciare sau extrajudiciare în
                care SOLUTION TALK este parte.
              </p>
              <p className="mt-2">
                În toate cazurile de mai sus, SOLUTION TALK se va asigura că
                divulgarea este limitată la datele care sunt necesare având în
                vedere scopul/ motivul divulgării.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XII. Transferul datelor dvs. cu caracter personal în țări terțe
                (din afara UE/ SEE)
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Datele cu caracter personal sunt arhivate și stocate pe
                serverele companiilor cu care SOLUTION TALK a încheiat contracte
                de colaborare. Aceste servere sunt localizate, în principiu, în
                Uniunea Europeană. Totuși, în anumite situații, este posibil să
                transferăm datele dvs. cu caracter personal către țări terțe UE/
                SEE, caz în care transferul datelor către țări terțe UE/SEE se
                va efectua:
                <br></br>(a) conform art. 45 din Regulamentul GDPR doar către
                acele țări terțe care au fost recunoscute de Comisia Europeană
                ca asigurând un nivel adecvat de protecție a datelor cu caracter
                personal. Lista acestor țări o găsiți aici sau, în lipsă,
                <br></br>(b) numai în baza unor garanții adecvate, ceea ce
                înseamnă că, atunci când transferăm date către o organizație din
                afara UE, vom utiliza fie modele de contracte aprobate de
                Comisia Europeană, care oferă datelor dvs. personale un nivel de
                protecție adecvat (mai multe detalii găsiți aici ) fie alte
                garanții adecvate, astfel cum sunt prevăzute de art. 46 din
                Regulamentul GDPR sau, în lipsă,
                <br></br>(c) conform art. 49 alin. 1 lit. a) din Regulamentul
                GDPR, doar în baza consimțământului dvs. explicit, sau, în
                lipsă,
                <br></br>(d) conform art. 49 alin. 1 lit. b) – f) din
                Regulamentul GDPR, numai dacă transferul este necesar pentru
                executarea unui contract încheiat între noi și dvs. sau între
                noi și o terță parte, dar în interesul dvs., pentru a aplica
                anumite măsuri precontractuale pe care dvs. ni le-ați cerut, din
                considerente importante de interes public, pentru exercitarea
                sau apărarea unui drept în instanță sau pentru protejarea
                intereselor vitale ale dvs. sau ale unei alte persoane, sau, în
                lipsă,
                <br></br>(e) conform art. 49 alin. 1 teza finală din
                Regulamentul GDPR, numai dacă transferul nu este repetitiv și
                este necesar în scopul realizării intereselor noastre legitime
                majore, asupra cărora nu prevalează interesele sau drepturile și
                libertățile dvs. însă numai în baza unor garanții
                corespunzătoare în ceea ce privește protecția datelor dvs. și
                numai după informarea dvs. cu privire la transfer și cu privire
                la interesele legitime urmărite.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XIII. Actualizarea datelor dvs. cu caracter personal
              </h2>
            </div>
            <div>
              <p className="mt-2">
                În cazul în care datele cu caracter personal pe care ni le-ați
                furnizat suferă modificări sau dacă luați cunoștință de faptul
                că deținem orice date cu caracter personal incorecte sau
                neactualizate cu privire la dvs., le puteți actualiza direct
                dvs. in Contul de Utilizator sau ne puteți transmite o
                solicitare în acest sens prin e-mail la contact@solutiontalk.ro.
                Nu vom fi răspunzători pentru nicio pierdere decurgând din date
                cu caracter personal incorecte, neautentice, insuficiente sau
                incomplete pe care dvs. ni le furnizați.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XIV. Prelucrarea datelor copiilor
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Website-ul, serviciile SOLUTION TALK și Serviciile
                Specialiștilor nu se adresează persoanelor sub 18 ani (“Copii”).
                SOLUTION TALK nu prelucrează cu bună știință date cu caracter
                personal aparținând copiilor. Dacă sunteți părinte și sunteți
                conștient de faptul că copiii dvs. ne-au furnizat date
                personale, vă rugăm să ne contactați prin e-mail la
                contact@solutiontalk.ro.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                XV. Modificări ale Politicii de confidențialitate
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Cea mai recentă actualizare a acestei Politici a fost realizată
                la data de 04.10.2022. Vă rugăm să luați în considerare că
                Politica de confidențialitate poate fi actualizată în mod
                regulat. Vă vom notifica cu privire la orice modificări prin
                postarea noii Politici de confidențialitate pe această pagină.
                De asemenea, vă vom anunța prin e-mail sau printr-o notificare
                pe Website, înainte ca modificarea să intre în vigoare.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">XVI. Contactați-ne</h2>
            </div>
            <div>
              <p className="mt-2">
                Pentru orice întrebări în legătură cu prezenta Politică de
                confidențialitate, ne puteți contacta utilizând următoarele date
                de contact:
                <br></br>SOLUTION TALK S.R.L.
                <br></br>sediu: București, Str. Smaranda Brăescu, nr. 40, Bl.
                20H, Sc. 1, Et. 2, Ap. 6, Sector 1<br></br>Nr. ORC: J40 /2852
                /2022
                <br></br>Cod fiscal 45650372
                <br></br>E-mail contact@solutiontalk.ro
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default PoliticaConfidentialitate;
