import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
// import { translateAliases } from "../../../../../backend/models/Appointment";
import { auth } from "../../../api";
import { getAppoiments } from "../../../api/getAppoiments.api";
import { getTransaction } from "../../../api/stripe.api";
import { LoadingComponent } from "../../../components";
import HeaderSecond from "../../../components/Header/HeaderSecond";
import TabelaIstoricSedinte from "../../../components/IstoricSedinte/TabelaIstoricSedinte";
import TabelaIstoricSedinteRapoarte from "../../../components/IstoricSedinte/TabelaIstoricSedinteRapoarte";
import Card from "../../../components/Rapoarte/Card";
import Incasari from "../../../components/Rapoarte/Incasari";
import IstoricComision from "../../../components/Rapoarte/IstoricComision";
const cols = [
  { nume: "Nr.", hidden: false },
  { nume: "Data", hidden: false },
  { nume: "Interval orar", hidden: false },
  { nume: "Nume", hidden: false },
];
const sed = [
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "",
  },
];
const Rapoarte = (props) => {
  const [years, setYears] = useState([2022, 2023, 2024, 2025, 2026]);
  const [cards, setCards] = useState([]);
  const [year, setYear] = useState(2022);
  const [month, setMonth] = useState(1);
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState();
  const [appointments, setAppoiments] = useState();

  useEffect(() => {
    var countSedinte = 0;
    var countSedinteOnline = 0;
    var countSedinteCabinet = 0;
    var incasari = 0;
    var comision = 0;
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getTransaction(idToken, 7, 10).then((res) => {
          if (res.status == 200) {
            console.log(res);
            setTransactions(res.data.transactions);
            var transaction = res.data.transactions;
            var filter = res.data.transactions.filter((tr) => {
              const micadate = new Date(
                new Date(year, 6, 1).toString().split("GMT")[0] + " UTC"
              ).toISOString();
              const actualdate = new Date(
                new Date().toString().split("GMT")[0] + " UTC"
              ).toISOString();
              const maredate = new Date(
                new Date(year, 6, 31).toString().split("GMT")[0] + " UTC"
              ).toISOString();
              console.log(maredate);
              return tr.createdAt > micadate && tr.createdAt < maredate;
            });
            var sum = 0;
            filter.forEach((tr) => {
              sum += tr.price;
            });
            console.log("da tati");
            getAppoiments(idToken).then((res2) => {
              if (res2.status == 200) {
                setAppoiments(res2.data.appointments);
                res2.data.appointments.forEach((element) => {
                  if (element.client) {
                    countSedinte++;
                    if (element.location == "online") {
                      countSedinteOnline++;
                    } else {
                      countSedinteCabinet++;
                    }
                    if (element.commision)
                      comision += parseInt(element.commision);
                    if (element.price != null)
                      incasari += parseInt(element.price);
                    console.log(incasari);
                  }
                });
                const carduri = [
                  {
                    value: countSedinte,
                    title: "Consultatii",
                    stats: "+2,5%",
                  },
                  {
                    value: countSedinteOnline,
                    title: "Consultatii Online",
                    stats: "+2,5%",
                  },
                  {
                    value: countSedinteCabinet,
                    title: "Consultatii Cabinet",
                    stats: "+2,5%",
                  },
                  {
                    value: sum,
                    title: "Incasari totale",
                    stats: "+2,5%",
                  },
                  {
                    value: sum * 0.2,
                    title: "Comision Datorat",
                    stats: "+2,5%",
                  },
                  {
                    value: sum * 0.8,
                    title: "Profit realizat",
                    stats: "+2,5%",
                  },
                ];

                setCards(carduri);
                setLoading(false);
              }
            });
          } else {
            const carduri = [
              {
                value: 0,
                title: "Consultatii",
                stats: "+0%",
              },
              {
                value: 0,
                title: "Consultatii Online",
                stats: "+0%",
              },
              {
                value: 0,
                title: "Consultatii Cabinet",
                stats: "+0%",
              },
              {
                value: 0,
                title: "Incasari totale",
                stats: "+0%",
              },
              {
                value: 0,
                title: "Comision Datorat",
                stats: "+0%",
              },
              {
                value: 0,
                title: "Profit realizat",
                stats: "+0%",
              },
            ];
            setCards(carduri);
            setLoading(false);
          }
        });
      });
  }, []);

  const onChangeMonth = (e) => {
    setMonth(e.target.value);
    var countSedinte = 0;
    var countSedinteCabinet = 0;
    var countSedinteOnline = 0;
    var filterApp = appointments.filter((tr) => {
      const micadate = new Date(
        new Date(year, e.target.value - 1, 1).toString().split("GMT")[0] +
          " UTC"
      ).toISOString();
      const actualdate = new Date(
        new Date().toString().split("GMT")[0] + " UTC"
      ).toISOString();
      const maredate = new Date(
        new Date(year, e.target.value - 1, 31).toString().split("GMT")[0] +
          " UTC"
      ).toISOString();
      console.log(maredate);
      return tr.createdAt > micadate && tr.createdAt < maredate;
    });
    filterApp.forEach((tr) => {
      if (tr.client) {
        countSedinte++;
        if (tr.location == "online") {
          countSedinteOnline++;
        } else {
          countSedinteCabinet++;
        }
      }
    });
    var filter = transactions.filter((tr) => {
      const micadate = new Date(
        new Date(year, e.target.value - 1, 1).toString().split("GMT")[0] +
          " UTC"
      ).toISOString();
      const actualdate = new Date(
        new Date().toString().split("GMT")[0] + " UTC"
      ).toISOString();
      const maredate = new Date(
        new Date(year, e.target.value - 1, 31).toString().split("GMT")[0] +
          " UTC"
      ).toISOString();
      console.log(maredate);
      return tr.createdAt > micadate && tr.createdAt < maredate;
    });
    var sum = 0;
    filter.forEach((tr) => {
      sum += tr.price;
    });
    const carduri = [
      {
        value: countSedinte,
        title: "Consultatii",
        stats: "+2,5%",
      },
      {
        value: countSedinteOnline,
        title: "Consultatii Online",
        stats: "+2,5%",
      },
      {
        value: countSedinteCabinet,
        title: "Consultatii Cabinet",
        stats: "+2,5%",
      },
      {
        value: sum,
        title: "Incasari totale",
        stats: "+2,5%",
      },
      {
        value: sum * 0.2,
        title: "Comision Datorat",
        stats: "+2,5%",
      },
      {
        value: sum * 0.8,
        title: "Profit realizat",
        stats: "+2,5%",
      },
    ];
    setCards(carduri);
  };

  return (
    <>
      <HeaderSecond />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="flex justify-end">
          <div className="w-32">
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700"
            >
              Anul
            </label>
            <select
              id="location"
              name="location"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              defaultValue="Canada"
            >
              <option value={2022}>2022</option>
            </select>
          </div>
          <div className="w-32 ml-5">
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700"
            >
              Luna
            </label>
            <select
              id="location"
              value={month}
              onChange={onChangeMonth}
              name="location"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              defaultValue="Canada"
            >
              <option value={1}>01</option>
              <option value={2}>02</option>
              <option value={3}>03</option>
              <option value={4}>04</option>
              <option value={5}>05</option>
              <option value={6}>06</option>
              <option value={7}>07</option>
              <option value={8}>08</option>
              <option value={9}>09</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
            </select>
          </div>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="grid grid-cols-2 xl:grid-cols-6 gap-5 mt-5">
              {cards
                ? cards.map((card) => (
                    <Card
                      value={card.value}
                      title={card.title}
                      stats={card.stats}
                    />
                  ))
                : ""}
            </div>
          </>
        )}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-x-5 mt-5">
          <div className="xl:col-span-2">
            {transactions ? <Incasari transactions={transactions} /> : ""}
          </div>

          <div id={"sed1"} className="xl:col-span-1 mt-5 lg:mt-0">
            <div className="grid grid-cols-2 py-5 px-8 bg-white md:rounded-t-lg ">
              <div className="my-auto bg-white md:rounded-t-lg text-lg font-bold text-dark-blue font-openSans">
                <p>Istoric Sedinte</p>
              </div>
              <div>
                <div className="">
                  <Link to="/dashboard/specialist/istoric-sedinte">
                    <button className="float-right w-32 justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                      Vezi tot
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <TabelaIstoricSedinteRapoarte columns={cols} sed={sed} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Rapoarte;
