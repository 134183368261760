import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useState } from "react";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Incasari = (props) => {
  const [data, setData] = useState();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const labels = [
    "Ianuarie",
    "Februarie",
    "Martie",
    "Aprilie",
    "Mai",
    "Iunie",
    "Iulie",
    "August",
    "Septembrie",
    "Octombrie",
    "Noiembrie",
    "Decembrie",
  ];
  useEffect(() => {
    var dataAux = [];
    const transactions = props.transactions;

    for (var i = 1; i <= 12; i++) {
      var transaction = transactions.filter((tr) => {
        const micadate = new Date(
          new Date(2022, i - 1, 1).toString().split("GMT")[0] + " UTC"
        ).toISOString();
        const actualdate = new Date(
          new Date().toString().split("GMT")[0] + " UTC"
        ).toISOString();
        const maredate = new Date(
          new Date(2022, i - 1, 31).toString().split("GMT")[0] + " UTC"
        ).toISOString();
        return tr.createdAt > micadate && tr.createdAt < maredate;
      });
      var sum = 0;
      transaction.forEach((tr) => {
        sum += tr.price;
      });
      dataAux.push(sum);
    }
    console.log(dataAux);
    var tempdata = {
      labels,
      datasets: [
        {
          label: "Incasari",
          data: dataAux,
          backgroundColor: "#63687C",
        },
      ],
    };
    console.log(tempdata);
    setData(tempdata);
  }, [props.transactions]);

  return (
    <>
      <div className="bg-white rounded-lg mb-5 p-5">
        <div>
          <p className="text-lg font-openSans font-bold text-secondary p-5">
            Incasari
          </p>
        </div>
        {data ? <Bar options={options} data={data} /> : ""};
      </div>
    </>
  );
};

export default Incasari;
