import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./providers/UserProvider";
import { LoadingProvider } from "./providers/LoadingProvider";
import { ToastContainer } from "react-toastify";
import HeaderSecond from "./components/Header/HeaderSecond";
import ScrollToTop from "./utils/ScrollToTop";
ReactDOM.render(
  <BrowserRouter>
    <LoadingProvider>
      <UserProvider>
        <ScrollToTop />
        <App />
        <ToastContainer />
      </UserProvider>
    </LoadingProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
