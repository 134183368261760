import React, { useEffect, useState } from "react";
import HeaderAdmin from "../../../components/Header/HeaderAdmin";
import download from "../../../assets/img/download-svgrepo-com.svg";
import Dropzone from "react-dropzone-uploader";
import { storage } from "../../../api/firebaseConfig";
import { toast } from "react-toastify";
import { auth } from "../../../api";
import {
  getRules,
  updateContracte,
  updateRules,
} from "../../../api/saveSettings.api";
import moment from "moment";

const RegulamentAdmin = () => {
  const [setari, setSetari] = useState();
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getRules(idToken).then((res) => {
          setSetari(res.data.settings);
          console.log(res.data);
          setContracte(res.data.settings.contracts);
          console.log(contracte);
        });
      });
  }, []);

  const deleteContract = (e, index) => {
    e.preventDefault();
    var auxCon2 = contracte;

    auxCon2.splice(index, 1);

    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        updateContracte(idToken, auxCon2).then((res) => {
          console.log(res);
          setContracte(auxCon2);
          toast.success("Contractul fost sters cu succes!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      });

    setContracte(auxCon2);
    console.log("gat");
  };

  const [imageAsFile, setImageAsFile] = useState("");
  const [contracte, setContracte] = useState([]);
  const handleImageAsFile = ({ meta, file }, status) => {
    setImageAsFile((imageFile) => file);
  };

  const handleDocument = ({ meta, file }, status) => {
    setDocument(file);
    setImageAsFile((imageFile) => file);
  };

  const [document, setDocument] = useState();

  const contracts = [
    {
      name: "Model contract Nume Aici",
      file: "../../assets/img/download-svgrepo-com.svg",
    },
    {
      name: "Model contract Nume Aici",
      file: "../../assets/img/download-svgrepo-com.svg",
    },
    {
      name: "Model contract Nume Aici",
      file: "../../assets/img/download-svgrepo-com.svg",
    },
  ];

  const handleFireBaseUploadDocuments = (e) => {
    e.preventDefault();

    const uploadTask = storage
      .ref(`/admin/documents/regulament/${document.name}`)
      .put(document);

    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
      },
      (err) => {
        //catches the errors
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref(`/admin/documents/regulament/`)
          .child(document.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            auth
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true)
              .then(async function (idToken) {
                updateRules(idToken, fireBaseUrl).then((res) =>
                  console.log(res)
                );
              });
            console.log(fireBaseUrl);
            toast.success("Regulamentul a fost actualizat cu succes!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            //storageDocuments.push({ name: document.name, url: fireBaseUrl });
            // setClient((prevState) => ({
            //   ...prevState,
            //   specialistInfo: {
            //     ...prevState.specialistInfo, // shallow copy all previous state
            //     ["documents"]: storageDocuments, // update specific key/value
            //   },
            // }));
          });
      }
    );
  };

  const handleFireBaseUploadContracts = (e) => {
    e.preventDefault();

    const uploadTask = storage
      .ref(`/admin/documents/contracte/${document.name}`)
      .put(document);

    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
      },
      (err) => {
        //catches the errors
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref(`/admin/documents/contracte/`)
          .child(document.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            auth
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true)
              .then(async function (idToken) {
                var auxCon = [];
                auxCon = contracte;
                auxCon.push({ name: document.name, file: fireBaseUrl });
                setContracte(auxCon);
                updateContracte(idToken, auxCon).then((res) => {
                  console.log(res);
                });
              });
            console.log(fireBaseUrl);
            toast.success("Contractul a fost incarcat cu succes!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            //storageDocuments.push({ name: document.name, url: fireBaseUrl });
            // setClient((prevState) => ({
            //   ...prevState,
            //   specialistInfo: {
            //     ...prevState.specialistInfo, // shallow copy all previous state
            //     ["documents"]: storageDocuments, // update specific key/value
            //   },
            // }));
          });
      }
    );
  };
  return (
    <>
      <HeaderAdmin />
      <div className="flex  max-w-1753px m-auto px-5 flex-col lg:flex-row gap-4 lg:gap-0">
        <div className="left basis-3/5 ">
          <div className="lg:pr-8">
            <h4 className="font-bold text-dark-blue pb-4 text-lg">
              Regulament Platforma
            </h4>
            <div className="bg-white flex flex-col rounded-xl p-4 sm:p-10 shadow-lg font-semibold pt-4 max-h-710px">
              <p>
                Ultima actualizare:{" "}
                {setari
                  ? moment(setari.lastUpdatedRules).format("DD.MM.YYYY")
                  : ""}
              </p>
              <a target="_blank" href={setari ? setari.rulesUrl : "#"}>
                <h2 className="text-xl sm:text-2xl xl:text-3xl">
                  DESCARCĂ Regulamentul
                </h2>
              </a>
              <h2 className="mt-10">Poti incarca regulamentul aici</h2>
              <div className="text-area max-h-full  text-sm sm:text-lg xl:text-xl">
                <Dropzone
                  classNames="h-full w-full overflow-hidden"
                  onChangeStatus={handleDocument}
                  onSubmit={handleFireBaseUploadDocuments}
                  accept="*"
                  SubmitButtonComponent={(e) => {
                    return (
                      <>
                        <button
                          onClick={handleFireBaseUploadDocuments}
                          type="button"
                          className="w-32 flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                        >
                          Incarca
                        </button>
                      </>
                    );
                  }}
                  styles={{
                    dropzone: { overflow: "hidden", border: 0 },
                  }}
                  inputContent={
                    <div className="flex items-center justify-center font-normal">
                      <svg
                        id="Component_4_1"
                        data-name="Component 4 – 1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.699"
                        height="40"
                        viewBox="0 0 21.699 40"
                      >
                        <text
                          id="_"
                          data-name="+"
                          transform="translate(3.875 32)"
                          fill="#f89a76"
                          fontSize="34"
                          fontFamily="Raleway-Regular, Raleway"
                        >
                          <tspan x="0" y="0">
                            +
                          </tspan>
                        </text>
                        <g
                          id="Ellipse_37"
                          data-name="Ellipse 37"
                          transform="translate(0 9.075)"
                          fill="none"
                          stroke="#f89a76"
                          strokeWidth="2"
                        >
                          <circle
                            cx="10.85"
                            cy="10.85"
                            r="10.85"
                            stroke="none"
                          />
                          <circle cx="10.85" cy="10.85" r="9.85" fill="none" />
                        </g>
                      </svg>
                      <p className="inline-block text-primary font-raleway text-sm ml-1 font-bold">
                        Incarca regulament
                      </p>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="left basis-2/5 font-bold text-dark-blue text-lg">
          <h4 className="font-bold text-dark-blue pb-4 text-lg">Contracte</h4>
          <div className=" flex flex-col gap-3.5">
            {contracte
              ? contracte.map((contract, index) => (
                  <div
                    className="bg-white flex rounded-xl shadow-lg p-3 xl:p-7 gap-2 xl:gap-5 justify-between"
                    key={index}
                  >
                    <h4 className=" text-sm xl:text-xl font-semibold text-dark-blue">
                      {contract.name}
                    </h4>
                    <p
                      onClick={(e) => deleteContract(e, index)}
                      className="font-ralewayBold text-xs   gap-1 xl:gap-3 hover:opacity-50 duration-300 cursor-pointer m-auto"
                    >
                      Sterge model
                    </p>
                    <a href={contract.file} download>
                      <button className="flex items-end font-ralewayBold text-xs  gap-1 xl:gap-3 hover:opacity-50 duration-300">
                        <img src={download} /> <span>Descarca Model</span>
                      </button>
                    </a>
                  </div>
                ))
              : ""}
          </div>
          <div className="bg-white mt-5 rounded-xl shadow-lg p-3 xl:p-7 gap-2 xl:gap-5">
            <div className="text-area max-h-full text-sm sm:text-lg xl:text-xl">
              <Dropzone
                classNames="h-full w-full overflow-hidden"
                onChangeStatus={handleDocument}
                onSubmit={handleFireBaseUploadContracts}
                accept="*"
                SubmitButtonComponent={(e) => {
                  return (
                    <>
                      <button
                        onClick={handleFireBaseUploadContracts}
                        type="button"
                        className="w-32 flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                      >
                        Incarca
                      </button>
                    </>
                  );
                }}
                styles={{
                  dropzone: { overflow: "hidden", border: 0 },
                }}
                inputContent={
                  <div className="flex items-center justify-center font-normal">
                    <svg
                      id="Component_4_1"
                      data-name="Component 4 – 1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="21.699"
                      height="40"
                      viewBox="0 0 21.699 40"
                    >
                      <text
                        id="_"
                        data-name="+"
                        transform="translate(3.875 32)"
                        fill="#f89a76"
                        fontSize="34"
                        fontFamily="Raleway-Regular, Raleway"
                      >
                        <tspan x="0" y="0">
                          +
                        </tspan>
                      </text>
                      <g
                        id="Ellipse_37"
                        data-name="Ellipse 37"
                        transform="translate(0 9.075)"
                        fill="none"
                        stroke="#f89a76"
                        strokeWidth="2"
                      >
                        <circle cx="10.85" cy="10.85" r="10.85" stroke="none" />
                        <circle cx="10.85" cy="10.85" r="9.85" fill="none" />
                      </g>
                    </svg>
                    <p className="inline-block text-primary font-raleway text-sm ml-1 font-bold">
                      Incarca contract
                    </p>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegulamentAdmin;
