import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

const VideoPrezentare = (props) => {
  return (
    <>
      <div className="font-openSans flex flex-col flex-nowrap h-full">
        <div className="mb-3">
          <p className="text-lg font-bold text-secondary">
            Video de Prezentare
          </p>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg h-full">
          <div className="px-4 py-5 sm:p-6 h-full flex flex-col flex-nowrap">
            <iframe
              src={props.url}
              className="m-auto"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
            />
            <div className="mt-4">
              <Link to="/dashboard/specialist/setari-cont">
                <button className="m-auto w-2/5 flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                  Inlocuieste videoclip
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoPrezentare;
