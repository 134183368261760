import React, { createRef, useEffect, useState } from "react";
import HeaderClient from "../../../components/Header/HeaderClient";
import avatar from "../../../assets/img/Elena_Ionescu.jpg";
import { Link } from "react-router-dom";
import { auth } from "../../../api";
import { getAppoiments } from "../../../api/getAppoiments.api";
import Moment from "moment";
import { addAngajat, getAngajati } from "../../../api/saveSettings.api";
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import { companyPayApi, getSumaDatorata } from "../../../api/stripe.api";
import { toast } from "react-toastify";

const Angajati = () => {
  const [showPopup, setShowPopup] = useState();
  const [idAngajat, setIdAngajat] = useState();
  const [angajatiArray, setAngajatiArray] = useState();
  const [filterByName, setFilterByName] = useState();
  const [filterByCity, setFilterByCity] = useState();
  const [filterByCode, setFilterByCode] = useState();
  const [priceToPay, setPriceToPay] = useState();
  const [specialists, setSpecialists] = useState([]);
  const { user } = useContext(UserContext);
  var popupRef = createRef();

  const onChange = (e) => {
    setIdAngajat(e.target.value);
  };

  const companyPay = (e, specialist) => {
    e.preventDefault();
    if (priceToPay && specialist) {
      auth
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(async function (idToken) {
          companyPayApi(
            idToken,
            user.user.publicId,
            specialist,
            user.user.email
          ).then((res) => console.log(res));
        });
    } else {
      toast.error("Nu aveti nimic de plata!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const handleSubmit = (e) => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        addAngajat(idToken, idAngajat, user.user.publicId);
      });
  };

  const cancelAppointment = () => {};

  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getAngajati(idToken, user.user.publicId).then((res) => {
          setAngajatiArray(res.angajati);
        });
        getSumaDatorata(user.user.publicId, idToken).then((res) => {
          setPriceToPay(res.data.price);
          var aux = [];
          for (var i = 0; i < res.data.transactions.length; i++) {
            if (aux.length == 0) {
              aux.push({
                specialist: res.data.transactions[i].specialist,
                email: res.data.transactions[i].specialistEmail
                  ? res.data.transactions[i].specialistEmail
                  : "",
                nume:
                  (res.data.transactions[i].specialistLastName
                    ? res.data.transactions[i].specialistLastName
                    : "") +
                  " " +
                  (res.data.transactions[i].specialistFirstName
                    ? res.data.transactions[i].specialistFirstName
                    : ""),
                priceToPay: parseFloat(res.data.transactions[i].price) * 0.9,
              });
            } else {
              var found = false;
              for (var j = 0; j < aux.length; j++) {
                if (aux[j].specialist == res.data.transactions[i].specialist) {
                  aux[j].priceToPay +=
                    parseFloat(res.data.transactions[i].price) * 0.9;
                  found = true;
                  break;
                }
              }
              if (found == false) {
                aux.push({
                  specialist: res.data.transactions[i].specialist,
                  email: res.data.transactions[i].specialistEmail
                    ? res.data.transactions[i].specialistEmail
                    : "",
                  nume:
                    (res.data.transactions[i].specialistLastName
                      ? res.data.transactions[i].specialistLastName
                      : "") +
                    " " +
                    (res.data.transactions[i].specialistFirstName
                      ? res.data.transactions[i].specialistFirstName
                      : ""),
                  priceToPay: parseFloat(res.data.transactions[i].price) * 0.9,
                });
              }
            }
          }
          setSpecialists(aux);
        });
      });
  }, []);

  return (
    <>
      <HeaderClient />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="mb-3 block grid grid-cols-1 xl:grid-cols-7">
          <h4 className="col-span-1 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Suma datorata
          </h4>
          <h4 className="hidden 2xl:block col-span-4 2xl:col-span-3 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Plata specialisti
          </h4>
        </div>
        <div className="2xl:grid  grid-cols-1 2xl:grid-cols-7 gap-0 2xl:gap-5 mb-10 text-sm">
          <div className="col-span-1">
            <div className="font-openSans text-secondary flex flex-col flex-nowrap font-bold">
              <div className="mb-0">
                <h4 className=" show-review md:hidden text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
                  <span className="md:hidden">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.96"
                      height="4.227"
                      viewBox="0 0 6.96 4.227"
                    >
                      <g
                        id="up-arrow-svgrepo-com"
                        transform="translate(6.96 4.227) rotate(180)"
                      >
                        <path
                          id="Path_283"
                          data-name="Path 283"
                          d="M5.684,4.009A.748.748,0,0,0,6.741,2.951L4.009.219a.749.749,0,0,0-1.058,0L.219,2.951A.748.748,0,0,0,1.276,4.009l2.2-2.2Z"
                          fill="#0b1e39"
                        />
                      </g>
                    </svg>
                  </span>
                </h4>
              </div>
            </div>
            <div className=" bg-white overflow-hidden shadow-lg rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <div className="left  font-bold flex flex-col justify-between  md:flex-row 2xl:flex-col gap-2 sm:gap-5 ">
                  <p>Aveti de platit:</p>
                  <p>{priceToPay ? priceToPay : "0"} RON</p>
                </div>
              </div>
            </div>
          </div>

          <div className=" pt-5 2xl:pt-0 h-auto col-span-4 2xl:col-span-6 flex flex-col   items-start gap-4">
            <ul className="hidden sm:grid grid-cols-12 w-full bg-gray-inactive-bg text-dark-blue font-ralewayBold text-xs py-3.5 pl-3 sm:pl-9 pr-2">
              <li className="col-span-1">NR.</li>
              <li className="col-span-2">NUME</li>
              <li className="col-span-2">EMAIL</li>

              <li className="col-span-2">DE PLATA</li>
              <li className="col-span-1"></li>
            </ul>
            {!specialists
              ? ""
              : specialists.map((ang, index) => (
                  <>
                    <ul className=" hidden sm:grid grid-cols-12 w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                      <li className="col-span-1">{index + 1}.</li>
                      <li className="col-span-2">{ang.nume}</li>
                      <li className="col-span-2">{ang.email}</li>

                      <li className="col-span-2">{ang.priceToPay + " RON"}</li>
                      <li className="col-span-1">
                        <button
                          onClick={(e) => companyPay(e, ang.specialist)}
                          type="button"
                          className="w-full flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                        >
                          Plateste
                        </button>
                      </li>
                    </ul>
                    <div className="mt-3 flex flex-col sm:hidden gap-1  w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow-lg ">
                      <div className=" grid grid-cols-2">
                        <span className="font-ralewayBold">NUMĂR:</span>
                        {index + 1}.
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="font-ralewayBold">NUME:</span>
                        {ang.nume}
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="font-ralewayBold">EMAIL:</span>
                        {ang.email}
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="font-ralewayBold">DE PLATA:</span>
                        {ang.priceToPay + " RON"}
                      </div>
                      <div className="col-span-1">
                        <button
                          type="button"
                          onClick={(e) => companyPay(e, ang.specialist)}
                          className="mt-2 whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm px-6 py-2 border border-primary text-secondary rounded-full shadow-lg duration-300  hover:bg-primary hover:text-white"
                        >
                          PLATESTE
                        </button>
                      </div>
                    </div>
                  </>
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Angajati;
