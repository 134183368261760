import React, { useContext, useEffect, useState } from "react";
import {
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../../api/firebaseConfig";
import { Seo } from "../../components";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/img/Solution_Talk_Logo.png";
const RegisterSpecialist = () => {
  const [fields, setFields] = useState({
    email: "",
    password: "",
    subscribe: false,
  });
  const [terms, setTerms] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [dataP, setDataP] = useState(false);

  const onChangeTerms = (e) => {
    if (e.target.checked) {
      setTerms(true);
    } else {
      setTerms(false);
    }
  };
  const onChangePolicy = (e) => {
    if (e.target.checked) {
      setPolicy(true);
    } else {
      setPolicy(false);
    }
  };
  const onChangeDataP = (e) => {
    if (e.target.checked) {
      setDataP(true);
    } else {
      setDataP(false);
    }
  };

  const onChangeSubscribe = (e) => {
    let fieldsCopy = Object.assign({}, fields);
    if (e.target.checked) {
      fieldsCopy["subscribe"] = true;
    } else {
      fieldsCopy["subscribe"] = false;
    }
    setFields(fieldsCopy);
  };

  const handleInputChange = (e) => {
    let fieldsCopy = Object.assign({}, fields);

    fieldsCopy[e.target.id] = e.target.value;

    setFields(fieldsCopy);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (terms == false) {
      toast.error(
        'Trebuie sa bifati "Am peste 18 ani, am citit și sunt de acord cu Termenii și Condițiile"',
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (policy == false) {
      toast.error(
        'Trebuie sa bifati "Am citit și sunt de acord cu Politica de confidențialitate și Politica Cookies"',
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (dataP == false) {
      toast.error(
        'Trebuie sa bifati "Am citit și sunt de acord cu Data Processing Agreement"',
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }
    if (fields["password"] != fields["passwordVerify"]) {
      toast.error("Parolele introduse nu corespund!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      registerWithEmailAndPassword(fields);
    }
  };

  return (
    <>
      <Seo
        title="Înregistrează-te Aici | Specialist - Solutuion Talk"
        description="Nu ai un cont de specialist pe platforma Solution Talk? Înregistreză-te gratuit chiar aici!"
      />
      <div className="min-h-screen flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8 font-raleway font-medium">
        <div className="sm:mx-auto sm:w-full sm:max-w-2xl mx-6">
          <Link to="/">
            <img className="mx-auto h-auto w-2/5" src={logo} alt="Workflow" />
          </Link>
          <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
            Inregistrare specialist
          </h2>
          <p className="text-center text-xl font-light text-gray-900">
            Creaza-ti acum gratuit un cont de specialist!
          </p>
        </div>

        <div className="mt-8 sm:mx-auto md:w-full sm:max-w-2xl mx-6">
          <div className="bg-white md:py-16 py-10 px-8 shadow sm:rounded-lg md:px-16">
            <form className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-base text-gray-700"
                >
                  Adresa de email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={fields.email}
                    onChange={handleInputChange}
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-base text-gray-700"
                >
                  Parola
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={fields.password}
                    onChange={handleInputChange}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password-2"
                  className="block text-base text-gray-700"
                >
                  Confirma parola
                </label>
                <div className="mt-1">
                  <input
                    id="passwordVerify"
                    name="passwordVerify"
                    type="password"
                    value={fields.passwordVerify}
                    onChange={handleInputChange}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                >
                  Inregistrare
                </button>
              </div>
              <div className="flex gap-2 sm:gap-5">
                <input
                  type="checkbox"
                  name="terms"
                  id="terms"
                  onChange={onChangeTerms}
                  className="w-6 h-6"
                />
                <p className="text-xs sm:text-sm  text-black font-ralewayRegular flex-1">
                  Am peste 18 ani, am citit și sunt de acord cu{" "}
                  <Link to="/termeni-si-conditii" className="font-bold">
                    Termenii și Condițiile
                  </Link>
                </p>
              </div>
              <div className="flex gap-2 sm:gap-5">
                <input
                  type="checkbox"
                  name="terms"
                  id="terms"
                  onChange={onChangePolicy}
                  className="w-6 h-6"
                />
                <p className="text-xs sm:text-sm  text-black font-ralewayRegular flex-1">
                  Am citit și sunt de acord cu{" "}
                  <Link
                    to="/politica-de-confidentialitate"
                    className="font-bold"
                  >
                    Politica de confidențialitate
                  </Link>{" "}
                  și{" "}
                  <Link to="/politica-cookies" className="font-bold">
                    Politica Cookies
                  </Link>
                </p>
              </div>
              <div className="flex gap-2 sm:gap-5">
                <input
                  type="checkbox"
                  name="terms"
                  id="terms"
                  onChange={onChangeDataP}
                  className="w-6 h-6"
                />
                <p className="text-xs sm:text-sm  text-black font-ralewayRegular flex-1">
                  Am citit și sunt de acord cu{" "}
                  <Link to="/dpa" className="font-bold">
                    Data Processing Agreement
                  </Link>{" "}
                </p>
              </div>
              <div className="flex gap-2 sm:gap-5">
                <input
                  type="checkbox"
                  name="terms"
                  id="terms"
                  className="w-6 h-6"
                  onChange={onChangeSubscribe}
                />
                <p className="text-xs sm:text-sm  text-black font-ralewayRegular flex-1">
                  Vreau să primesc pe e-mail notificări comerciale privind
                  ofertele și promoțiile SOLUTION TALK, cele mai noi articole și
                  sfaturi pentru sănătate psihică și emoțională
                </p>
              </div>
            </form>
            <div className="text-center mt-6">
              <p className="font-regular text-xl">
                Ai deja un cont?
                <Link
                  to="/login"
                  className="text-primary ml-1 hover:text-primary-hover"
                >
                  Autentifica-te acum!
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterSpecialist;
