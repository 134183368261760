import React from "react";
import { useEffect } from "react";
import { getClientSecret } from "../../api/stripe.api";

const TestStripe = () => {
  useEffect(() => {
    getClientSecret().then((res) => console.log(res));
  }, []);

  return (
    <>
      <div>
        <h1>DA</h1>
      </div>
    </>
  );
};

export default TestStripe;
