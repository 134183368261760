import React from "react";
import HeaderClient from "../../../components/Header/HeaderClient";
import { Link } from "react-router-dom";
import avatar from "../../../assets/img/Ioana Popescu.png";

const AfisareContClient = () => {
  return (
    <>
      <HeaderClient />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="grid grid-cols-2 ;g:grid-cols-2 gap-5 xl:gap-20 mb-10">
          <div></div>
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:w-3/5 lg:float-right">
              <div></div>
              <div>
                <div className="my-auto">
                  <div className="mt-2 mb-1 lg:m-0">
                    <Link to="/dashboard/specialist/contul-meu">
                      <button className="w-full flex justify-center py-4 px-2 border rounded-md shadow-sm text-xs border-primary text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                        Inchide Contul
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" grid grid-cols-1 2xl:grid-cols-2 gap-5 font-openSans font-bold text-secondary">
          <div>
            <div className="mb-3">
              <p className="text-lg">Informatii cont</p>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg grid md:grid-cols-5 py-4 xl:py-9 px-4 sm:px-8 xl:px-12">
              <div className=" mf:block md:col-span-2">
                <div className="m-auto md:m-0 w-fit rounded-full border-4 border-primary p-2">
                  <img src={avatar} className="w-20 md:w-36 rounded-full" />
                </div>
              </div>
              <div className="md:col-span-3 py-4 flex flex-col justify-center gap-2">
                <div className="flex items-center gap-2 text-base md:text-lg">
                  <h3>Nume:</h3>
                  <p className="font-normal">Ioana Popescu</p>
                </div>
                <div className="flex items-center gap-2 text-base md:text-lg">
                  <h3>Email:</h3>
                  <p className="font-normal ">ioana_popescu@yahoo.com</p>
                </div>
                <div className="flex items-center gap-2 text-base md:text-lg">
                  <h3>Telefon:</h3>
                  <p className="font-normal">0700 000 000</p>
                </div>
              </div>
            </div>
            <div className="mb-3 mt-4">
              <p className="text-lg">Adresa</p>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg flex flex-col md:flex-row justify-between py-6 sm:py-9 px-4 gap-3 sm:gap-0 sm:px-8 2xl:px-12 items-center">
              <div className="font-normal flex gap-2 flex-col">
                <p>Ioana Popescu - 0700 000 000 </p>
                <p>
                  Strada Smardan, nr9, Gran Cafe VanGogh, etaj 2, camera 22,
                  Bucuresti.
                </p>
                <p>Bucureşti, Bucureşti(Sectorul 3)</p>
              </div>
              <div>
                <button
                  type="button"
                  className="w-full flex justify-center py-4 px-6 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                >
                  Salveaza Informatia
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-3">
              <p className="text-lg">Latest News</p>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg  py-6 sm:py-16 px-8 2xl:px-12 flex flex-col gap-6 sm:gap-12">
              <div className="grid grid-cols-2 sm:grid-cols-5 gap-4 sm:gap-0">
                <div className="bg-gray-inactive-bg w-24 h-24 col-span-1"></div>
                <div className="col-span-4">
                  <h2 className="text-xl mb-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                  </h2>
                  <p className="text-gray-inactive-text font-medium mb-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nullam vitae consequat ante. Aenean quis libero ac felis
                    sollicitudin mattis. Duis tristique sapien ac eros
                    vestibulum, et eleifend velit eleifend. Sed dui sem,
                    hendrerit in
                  </p>
                  <Link to="#" className="text-primary">
                    Citeste Articolul
                  </Link>
                </div>
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-5 gap-4 sm:gap-0">
                <div className="bg-gray-inactive-bg w-24 h-24 col-span-1"></div>
                <div className="col-span-4">
                  <h2 className="text-xl mb-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                  </h2>
                  <p className="text-gray-inactive-text font-medium mb-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nullam vitae consequat ante. Aenean quis libero ac felis
                    sollicitudin mattis. Duis tristique sapien ac eros
                    vestibulum, et eleifend velit eleifend. Sed dui sem,
                    hendrerit in
                  </p>
                  <Link to="#" className="text-primary">
                    Citeste Articolul
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" grid grid-cols-1 xl:grid-cols-2 gap-5 font-openSans font-bold text-secondary  mt-4">
          <div>
            <div className="mb-3">
              <p className="text-lg">Date Facturare</p>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg flex justify-between py-6 sm:py-12 px-8 2xl:px-12 items-center">
              <div className="font-normal">
                <p>Ioana Popescu - 0700 000 000 </p>
                <p>
                  Strada Smardan, nr9, Gran Cafe VanGogh, etaj 2, camera 22,
                  Bucuresti.
                </p>
                <p>Bucureşti, Bucureşti(Sectorul 3)</p>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-3">
              <p className="text-lg">Reclamatii</p>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg  py-6 sm:py-9 px-12 items-center">
              <p className="text-lg text-center mb-5">
                Ai o reclamatie? Contacteaza adminul platformei
              </p>
              <div className="flex justify-center">
                <Link
                  to="#"
                  className="flex justify-center py-4 px-6 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                >
                  Contact Admin
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AfisareContClient;
