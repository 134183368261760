import React, { useEffect, useState } from "react";
import HeaderClient from "../../../components/Header/HeaderClient";
import { StarIcon } from "@heroicons/react/solid";
import { Link, useParams } from "react-router-dom";
import { getSpecialistById } from "../../../api/getSpecialist.api";
import { auth } from "../../../api";
import { getSpecialistReviews } from "../../../api/getReviews.api";
import HeaderAdmin from "../../../components/Header/HeaderAdmin";
import DocumenteStudii from "../../Specialist/SetariCont/DocumenteStudii";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const VeziProfilSpecialist = () => {
  const [reviews, setReviews] = useState();
  const [specialist, setSpecialist] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    street: "",
    streetNr: "",
    building: "",
    staircase: "",
    apartment: "",
    videoUrl: "",
    description: "",
    companyInfo: {
      companyName: "",
      companyCUI: "",
      companyRegNr: "",
      companyTVACode: "",
      companyCity: "",
      companyRegion: "",
      companyEmail: "",
      companyStreetNr: "",
      companyBuilding: "",
      companyStaircase: "",
      companyApartment: "",
      companyStreet: "",
    },
    specialistInfo: {
      specializationType: "",
      specializationName: "",
      yearsExperience: "",
      collegeCode: "",
      license: "off",
      masters: "off",
      doctorate: "off",
      university: "",
      otherCourses: "",
      lastStudies: "",
      price: "",
      companyDiscount: "",
      specializations: [],
    },
  });
  const { id } = useParams();

  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getSpecialistReviews(id, idToken).then((res) =>
          setReviews(res.reviews)
        );
        getSpecialistById(idToken, id).then((res) => {
          setSpecialist(res.specialist);
        });
      });
  }, []);
  return (
    <>
      <HeaderAdmin />

      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-2 sm:gap-5 xl:gap-20 mb-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:w-3/5">
            <Link to="/dashboard/admin/specialisti">
              <button className="w-full flex justify-center py-4 px-2 border rounded-md shadow-sm text-xs border-primary text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                Inapoi pagina de Specialisti
              </button>
            </Link>
          </div>
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-5 lg:w-3/5 lg:float-right">
              <div></div>
              <div>
                <div className="my-auto">
                  <div className="lg:mt-2 mb-1 lg:m-0">
                    <Link
                      to={
                        "/dashboard/admin/specialist/" +
                        specialist.publicId +
                        "/afisare-specialist"
                      }
                      className="w-full flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                    >
                      Vezi sedinte
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 2xl:grid-cols-2 gap-5">
          <div className="font-openSans text-secondary flex flex-col flex-nowrap h-full ">
            <div className="mb-3">
              <p className="text-lg font-bold">Despre Terapeut</p>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg h-full">
              <div className="px-4 py-5 sm:p-6">
                <div className="grid grid-cols-1 lg:grid-cols-4">
                  <div className="col-span-1 text-center">
                    <div
                      className="flex-shrink-0 inline-block rounded-full overflow-hidden h-44 w-44"
                      aria-hidden="true"
                    >
                      <img
                        className="rounded-full h-full w-full border-primary p-2 border-2 object-cover"
                        src={specialist.avatarUrl}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div>
                      <div className="flex justify-between">
                        <div>
                          <p className="font-bold text-primary text-sm">
                            {specialist.specialistInfo.specializationType}
                          </p>
                          <p className="text-3xl text-secondary font-bold">
                            {specialist.lastName + " " + specialist.firstName}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {specialist.specialistInfo.lastStudies}
                          </p>
                        </div>
                        {/* <div className="flex flex-col justify-end">
                          <div className="mt-2 mb-1 lg:m-0 font-bold">
                            <p>Cost</p>
                            <p>
                              {!specialist.specialistInfo
                                ? ""
                                : specialist.specialistInfo.price}
                            </p>
                          </div>
                        </div> */}
                      </div>
                      <div className="border-t-2 mt-1 pt-2">
                        <p className="font-bold text-lg">{specialist.city}</p>
                        <div>
                          <div className="mt-1 flex items-center">
                            <div>
                              <div className="flex items-center">
                                {[0, 1, 2, 3, 4].map((rating) => (
                                  <StarIcon
                                    key={rating}
                                    className={classNames(
                                      specialist.reviewsAverage > rating
                                        ? "text-primary"
                                        : "text-gray-300",
                                      "flex-shrink-0 h-5 w-5"
                                    )}
                                    aria-hidden="true"
                                  />
                                ))}
                              </div>
                              <p className="sr-only">
                                {specialist.reviewsAverage} out of 5 stars
                              </p>
                            </div>
                            <p className="ml-2 text-sm text-gray-900">
                              (
                              {specialist.reviewsCount
                                ? specialist.reviewsCount
                                : "0"}{" "}
                              reviews)
                            </p>
                          </div>
                        </div>
                        <div className="mt-4">
                          {/* <p className="font-bold text-lg">Descriere</p> */}
                          <div className="mt-2">
                            <pre className="whitespace-pre-line font-raleway font-medium">
                              {/* <p className="font-raleway font-medium"> */}
                              {specialist.description}
                            </pre>
                          </div>
                        </div>
                        <div className="mt-4">
                          <p className="font-bold text-lg">Specializari</p>
                          <div className="mt-2 flex flex-wrap text-xs gap-2">
                            {!specialist.specialistInfo
                              ? ""
                              : specialist.specialistInfo.specializations.map(
                                  (itm, ind) => (
                                    <p
                                      className=" text-xs font-ralewayMedium text-dark-blue px-2.5 sm:px-4 py-1 sm:py-2 bg-light-orange-w rounded-full"
                                      key={ind}
                                    >
                                      {itm}
                                    </p>
                                  )
                                )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div className="grid gap-5">
              {!specialist.videoUrl ? (
                ""
              ) : (
                <div className="font-openSans flex flex-col flex-nowrap ">
                  <div>
                    <div className="mb-3">
                      <p className="text-lg font-bold text-secondary">
                        Video de Prezentare
                      </p>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg ">
                      <div className="px-4 py-5 sm:p-6 flex flex-col flex-nowrap">
                        <iframe
                          src={specialist.videoUrl}
                          className="m-0 h-60"
                          frameBorder="0"
                          allow="autoplay; encrypted-media"
                          allowFullScreen
                          title="video"
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <div className="">
                  <div className="mb-3">
                    <p className="text-lg font-bold text-secondary">Preturi</p>
                  </div>
                  <div className="bg-white  shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-6 font-bold">
                      <p>
                        25 min -{" "}
                        {specialist.specialistInfo.price25
                          ? specialist.specialistInfo.price25 + " RON"
                          : "nedefinit"}
                      </p>
                      <p>
                        50 min -{" "}
                        {specialist.specialistInfo.price50
                          ? specialist.specialistInfo.price50 + " RON"
                          : "nedefinit"}
                      </p>
                      <p>
                        90 min -{" "}
                        {specialist.specialistInfo.price90
                          ? specialist.specialistInfo.price90 + " RON"
                          : "nedefinit"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-3 mt-5">
                  <p className="text-lg font-bold text-secondary">Formari</p>
                </div>
                <div className="bg-white overflow-hidden shadow rounded-lg ">
                  <div className="px-4 py-5 sm:p-6 flex flex-col flex-nowrap">
                    {specialist
                      ? specialist.specialistInfo.trainings
                        ? specialist.specialistInfo.trainings.map(
                            (certificat, index) => (
                              <div
                                key={index}
                                className={`flex flex-col ${
                                  index <
                                  specialist.specialistInfo.trainings.length - 1
                                    ? "border-b-2 pb-4"
                                    : ""
                                }`}
                              >
                                <h2 className="font-bold text-secondary text-xl mb-2">
                                  {certificat.title}
                                </h2>
                                <p className="text-sm text-gray-custom">
                                  {certificat.school}
                                </p>
                              </div>
                            )
                          )
                        : ""
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="font-openSans">
              <div className="mb-3">
                <p className="text-lg font-bold text-secondary">Review-uri</p>
              </div>
              <div className="bg-white  shadow rounded-lg overflow-y-scroll  h-96">
                <div className="px-4 py-5 sm:p-6">
                  {!reviews
                    ? ""
                    : reviews.map((review, index) => (
                        <div key={index}>
                          <div className="mt-1 flex items-center">
                            <p className="text-lg text-secondary font-bold">
                              {review.ratingScore}
                            </p>
                            <div>
                              <div className="ml-1 flex items-center">
                                {[0, 1, 2, 3, 4].map((rating) => (
                                  <StarIcon
                                    key={rating}
                                    className={classNames(
                                      review.ratingScore > rating
                                        ? "text-primary"
                                        : "text-gray-300",
                                      "flex-shrink-0 h-5 w-5"
                                    )}
                                    aria-hidden="true"
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="font-raleway text-xs">
                              Data: {review.date}
                            </p>
                          </div>
                          <div className="mt-3">
                            <p className="font-raleway text-xs">
                              {review.comment}
                            </p>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
              <div className="mt-5">
                <div className="mb-3">
                  <p className="text-lg font-bold text-secondary">
                    Documente incarcate
                  </p>
                </div>
                <div className="bg-white  shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6 font-bold">
                    <DocumenteStudii
                      storageDocuments={specialist.specialistInfo.documents}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VeziProfilSpecialist;
