import React, {
  useEffect,
  useState,
  createRef,
  useRef,
  useContext,
} from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import roLocale from "@fullcalendar/core/locales/ro";
import { auth } from "../../../api";
import { toast } from "react-toastify";
import {
  getAppoiments,
  getspecialistAvailableSlots,
} from "../../../api/getAppoiments.api";
import Moment from "moment";
import {
  addClientToAppointment,
  createappointment,
} from "../../../api/createAppoinment";
import { UserContext } from "../../../providers/UserProvider";
import { Link, useParams } from "react-router-dom";
import { HeaderClient } from "../../../components";
import { getClientSecret } from "../../../api/stripe.api";

var events = [
  {
    // this object will be "parsed" into an Event Object
    title: "The Title", // a property!
    start: "2022-05-16 12:45", // a property!
    end: "2022-05-16 13:45", // a property! ** see important note below about 'end' **
    extendedProps: {
      location: "Online",
      name: "Daniela Lungu",
    },
  },
  {
    // this object will be "parsed" into an Event Object
    title: "The Title", // a property!
    start: "2022-05-17 08:00", // a property!
    end: "2022-05-17 09:00", // a property! ** see important note below about 'end' **
    extendedProps: {
      location: "Online",
      name: "Daniela Lungu",
    },
  },
  {
    // this object will be "parsed" into an Event Object
    title: "The Title", // a property!
    start: "2022-05-19 14:00", // a property!
    end: "2022-05-19 15:00", // a property! ** see important note below about 'end' **
    extendedProps: {
      location: "Online",
      name: "Daniela Lungu",
    },
  },
  {
    // this object will be "parsed" into an Event Object
    title: "The Title", // a property!
    start: "2022-05-15 13:00", // a property!
    end: "2022-05-15 14:00", // a property! ** see important note below about 'end' **
    extendedProps: {
      location: "Cabinet",
      name: "Daniela Lungu",
    },
  },
];

const Calendar = () => {
  var calendarRef = createRef();
  var popupRef = createRef();
  var popupDateRef = createRef();
  var popupTimeRef = createRef();
  var popupPriceRef = createRef();
  const [event, setEvent] = useState();
  var popupPrice = createRef();
  var [popupStartDateValue, setPopupStartDateValue] = useState();
  var [popupEndDateValue, setPopupEndDateValue] = useState();
  const [selectedEventId, setSelectedEventId] = useState();
  var [showPopup, setShowPopup] = useState(false);
  var [availableOffline, setAvailableOffline] = useState(false);
  var [availableOnline, setAvailableOnline] = useState(false);
  var [selectedLocation, setSelectedLocation] = useState();
  const { user, setUser } = useContext(UserContext);
  const { id } = useParams();

  const [terms, setTerms] = useState(false);
  const [policy, setPolicy] = useState(false);

  const onChangeTerms = (e) => {
    if (e.target.checked) {
      setTerms(true);
    } else {
      setTerms(false);
    }
  };
  const onChangePolicy = (e) => {
    if (e.target.checked) {
      setPolicy(true);
    } else {
      setPolicy(false);
    }
  };

  const onChangeCheckbox = (event) => {
    setSelectedLocation(event.target.value);
  };

  /*
    Functie pentru plata programarii. Te redirectioneaza catre pagina de checkout de la stripe
  */
  const paySlot = () => {
    if (terms == false) {
      toast.error(
        'Trebuie sa bifati "Am peste 18 ani, am citit și sunt de acord cu Termenii și Condițiile"',
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (policy == false) {
      toast.error(
        'Trebuie sa bifati "Am citit și sunt de acord cu Politica de confidențialitate și Politica Cookies"',
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }
    if (
      !user.user.firstName ||
      !user.user.lastName ||
      user.user.lastName == "" ||
      user.user.firstName == "" ||
      !user.user.city ||
      user.user.city == "" ||
      !user.user.street ||
      user.user.street == "" ||
      !user.user.streetNr ||
      user.user.stretNr == "" ||
      !user.user.meetingEmail ||
      user.user.meetingEmail == ""
    ) {
      toast.error(
        "Trebuie sa completezi numele, adresa si email-ul pentru meeting in setari pentru a te putea programa.",
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      if (selectedLocation) {
        getClientSecret(selectedEventId, selectedLocation, user.user.email);
      } else {
        toast.error("Trebuie sa selectezi locatia!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const selecteazaEvent = (e, arg) => {
    popupDateRef.current.innerHTML =
      "Data: " + Moment(arg.event.start).format("DD.MM.YYYY");
    popupTimeRef.current.innerHTML =
      "Interval: " +
      Moment(arg.event.start).format("LT") +
      " - " +
      Moment(arg.event.end).format("LT");
    popupPriceRef.current.innerHTML =
      "Pret: " + arg.event.extendedProps.price + " RON";

    setEvent(arg.event);
    setSelectedEventId(arg.event.extendedProps._id);
    setAvailableOffline(arg.event.extendedProps.availableOffline);
    setAvailableOnline(arg.event.extendedProps.availableOnline);

    setShowPopup(true);
  };

  /*
    Functia de programare fara plata( pentru companii si angajati)
  */

  const saveSlot = () => {
    if (terms == false) {
      toast.error(
        'Trebuie sa bifati "Am peste 18 ani, am citit și sunt de acord cu Termenii și Condițiile"',
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (policy == false) {
      toast.error(
        'Trebuie sa bifati "Am citit și sunt de acord cu Politica de confidențialitate și Politica Cookies"',
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        var type;
        var cpId;
        if (user.user.companyId || user.user.isCompany) {
          type = "company";
        } else {
          type = "personal";
        }
        if (user.user.isCompany == true) {
          cpId = user.user.publicId;
        } else {
          cpId = user.user.companyId;
        }

        addClientToAppointment(
          selectedLocation,
          user.user.firstName,
          selectedEventId,
          idToken,
          type,
          cpId
        ).then((res) => {
          if (res.status == 200) {
            toast.success("Te-ai programat cu succes!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowPopup(false);
            event.remove();
          } else if (res.status == 405) {
            toast.error(
              "Nu va puteti programa momentan la acest specialist deoarece nu are sistemul de plata activat!",
              {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          } else {
            toast.error("A aparut o eroare la efectuarea programarii!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      });
  };

  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        // Luam toate sloturile disponibile ale specialistului si le bagam in eventuri pentru FullCalendar
        getspecialistAvailableSlots(idToken, id).then((res) => {
          res.appointments.map((e) => {
            var x = {
              title: e.clientFirstName, // a property!
              start: Moment(e.date).format("YYYY-MM-DD") + " " + e.startHour, // a property!
              end: Moment(e.date).format("YYYY-MM-DD") + " " + e.endHour, // a property! ** see important note below about 'end' **

              extendedProps: {
                location: e.location,
                price: e.price,
                availableOffline: e.availableOffline,
                availableOnline: e.availableOnline,
                name: e.clientFirstName,
                _id: e._id,
                test: e.date,
              },
            };

            var eventData = calendarRef.current.getApi().addEvent(x);

            eventData.setProp("backgroundColor", "rgba(248, 154, 118,0.3)");
          });
        });
      });
  }, []);
  return (
    <>
      <HeaderClient />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="grid grid-cols-1 xl:grid-cols-5 gap-5">
          <div className="col-span-1">
            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="px-4 py-5 sm:px-5 sm:py-5 mini-calendar font-openSans">
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  locale={roLocale}
                  height={"auto"}
                  dayHeaderContent={(arg) => {
                    const day = arg.date.getUTCDay();
                    var dayToString = "";
                    switch (day) {
                      case 1:
                        dayToString = "LUN";
                        break;
                      case 2:
                        dayToString = "MAR";
                        break;
                      case 3:
                        dayToString = "MIE";
                        break;
                      case 4:
                        dayToString = "JOI";
                        break;
                      case 5:
                        dayToString = "VIN";
                        break;
                      case 6:
                        dayToString = "SAM";
                        break;
                      case 0:
                        dayToString = "DUM";
                        break;
                    }
                    return (
                      <>
                        <div className="font-openSans font-bold text-xs text-light-blue">
                          <p>{dayToString}</p>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-4">
            <div
              className={
                (!showPopup ? "hidden " : "") +
                "w-80 h-[450px] fixed inset-0 m-auto z-50"
              }
              ref={popupRef}
            >
              <div className="bg-white overflow-hidden shadow rounded-lg h-full w-full">
                <div className="px-4 py-5 sm:px-6 sm:py-4 bg-dark-blue relative">
                  <p className="text-white font-openSans font-bold">
                    Programare
                  </p>
                  <p
                    onClick={(e) => {
                      setSelectedLocation("");
                      setShowPopup(false);
                    }}
                    className="text-white cursor-pointer w-fit absolute top-2.5 right-2.5"
                  >
                    x
                  </p>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <p
                    className="font-openSans text-xl text-dark-blue font-bold"
                    ref={popupDateRef}
                  ></p>
                  <p
                    className="font-openSans text-xl text-dark-blue font-bold"
                    ref={popupTimeRef}
                  ></p>
                  <p
                    className="font-openSans text-xl text-dark-blue font-bold mb-4"
                    ref={popupPriceRef}
                  ></p>
                  <div
                    className={
                      (availableOnline ? "" : "hidden ") +
                      "relative flex items-start font-openSans text-base text-dark-blue font-bold"
                    }
                  >
                    <div className="flex items-center h-5">
                      <input
                        onChange={onChangeCheckbox}
                        id="availableSlotOnline"
                        aria-describedby="availableSlotOnline-description"
                        name="location"
                        type="radio"
                        value="online"
                        className="focus:ring-indigo-500 h-6 w-6 md:h-5 md:w-5 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>

                    <div className="ml-1">
                      <label
                        htmlFor="specializationTypePsihoterapeut"
                        className=" "
                      >
                        Online
                      </label>
                    </div>
                  </div>
                  <div
                    className={
                      (availableOffline ? "" : "hidden ") +
                      "relative flex items-start font-openSans text-base text-dark-blue font-bold"
                    }
                  >
                    <div className="flex items-center h-5">
                      <input
                        onChange={onChangeCheckbox}
                        id="availableSlotCabinet"
                        aria-describedby="specializationTypePsihiatru-description"
                        name="location"
                        value="offline"
                        type="radio"
                        className="focus:ring-indigo-500 h-6 w-6 md:h-5 md:w-5 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-1">
                      <label htmlFor="availableSlotCabinet" className=" ">
                        Cabinet
                      </label>
                    </div>
                  </div>
                  <p
                    className="font-openSans text-xl text-dark-blue font-bold"
                    ref={popupPrice}
                  ></p>
                  <div className="flex gap-2 sm:gap-5 mt-5 w-full inline-flex items-center">
                    <input
                      type="checkbox"
                      name="terms"
                      id="terms"
                      className="w-6 h-6"
                      onChange={onChangeTerms}
                    />
                    <p className="text-xs sm:text-sm  text-black font-ralewayRegular flex-1">
                      Am peste 18 ani, am citit și sunt de acord cu{" "}
                      <Link to="/termeni-si-conditii" className="font-bold">
                        Termenii și Condițiile
                      </Link>
                    </p>
                  </div>
                  <div className="flex gap-2 sm:gap-5 mt-5 w-full inline-flex items-center">
                    <input
                      type="checkbox"
                      name="terms"
                      id="terms"
                      className="w-6 h-6"
                      onChange={onChangePolicy}
                    />
                    <p className="text-xs sm:text-sm  text-black font-ralewayRegular flex-1">
                      Am citit și sunt de acord cu{" "}
                      <Link
                        to="/politica-de-confidentialitate"
                        className="font-bold"
                      >
                        Politica de confidențialitate
                      </Link>{" "}
                      și{" "}
                      <Link to="/politica-cookies" className="font-bold">
                        Politica Cookies
                      </Link>
                    </p>
                  </div>
                  {!user.user.companyId && user.user.isCompany != true ? (
                    <>
                      <h1>{user.user.isCompany == true}</h1>
                      <button
                        className="w-2/5 flex mx-auto mt-5 justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                        onClick={paySlot}
                        type="button"
                      >
                        {" "}
                        Plateste
                      </button>
                    </>
                  ) : (
                    <button
                      className="w-2/5 flex mx-auto mt-5 justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                      onClick={saveSlot}
                      type="button"
                    >
                      {" "}
                      Salveaza
                    </button>
                  )}
                </div>
              </div>
            </div>
            <FullCalendar
              plugins={[timeGridPlugin, interactionPlugin]}
              locale={roLocale}
              initialView={"timeGrid"}
              duration={{
                days: window.innerWidth < 760 ? 1 : 5,
              }}
              validRange={(now) => {
                return { start: now };
              }}
              ref={calendarRef}
              events={events}
              height={"1000px"}
              selectLongPressDelay={100}
              longPressDelay={100}
              eventLongPressDelay={100}
              contentHeight={"auto"}
              month="2-digit"
              allDaySlot={false}
              slotDuration="0:15:00"
              eventDataTransform={(eventData) => {
                if (eventData.extendedProps.location == "Online")
                  eventData.color = "rgba(248, 154, 118,0.3)";
                else if (eventData.extendedProps.location == "Cabinet")
                  eventData.color = "rgba(11, 30, 57,0.15)";
                eventData.classNames = "pl-3";
                return eventData;
              }}
              dayHeaderContent={(arg) => {
                const day = arg.date.getUTCDay();
                var dayToString = "";
                switch (day) {
                  case 0:
                    dayToString = "LUNI";
                    break;
                  case 1:
                    dayToString = "MARTI";
                    break;
                  case 2:
                    dayToString = "MIERCURI";
                    break;
                  case 3:
                    dayToString = "JOI";
                    break;
                  case 4:
                    dayToString = "VINERI";
                    break;
                  case 5:
                    dayToString = "SAMBATA";
                    break;
                  case 6:
                    dayToString = "DUMINICA";
                    break;
                }
                return (
                  <>
                    <div className="py-4">
                      <div className="font-openSans font-bold text-xs text-light-blue">
                        <p>{dayToString}</p>
                      </div>
                      <div className="mt-1">
                        <p className="text-3xl font-openSans font-bold text-light-blue">
                          {arg.date.getDate()}
                        </p>
                      </div>
                    </div>
                  </>
                );
              }}
              eventContent={(arg) => {
                return (
                  <>
                    <div className="text-secondary h-42 cursor-pointer flex gap-5 justify-between">
                      <div className="px-2">
                        <div className="font-openSans text-xs">
                          <p>Sedinta {arg.event.extendedProps.location}</p>
                        </div>
                        <div>
                          <p className="font-bold font-openSans text-base">
                            {arg.event.extendedProps.name}
                          </p>
                        </div>
                        <div>
                          <p>
                            {arg.event.start.getHours()}:
                            {arg.event.start.getMinutes() >= 10
                              ? arg.event.start.getMinutes()
                              : "0" + arg.event.start.getMinutes()}{" "}
                            - {arg.event.end.getHours()}:
                            {arg.event.end.getMinutes() >= 10
                              ? arg.event.end.getMinutes()
                              : "0" + arg.event.end.getMinutes()}
                          </p>
                        </div>
                      </div>
                      <div className="px-2">
                        <input
                          className=" cursor-pointer flex mx-auto my-2 justify-center py-2 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                          onTouchStart={(e) => selecteazaEvent(e, arg)}
                          onClick={(e) => selecteazaEvent(e, arg)}
                          type="button"
                          value={"Selecteaza"}
                        ></input>
                      </div>
                    </div>
                  </>
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
