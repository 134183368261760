import React, { useState, useEffect, useContext } from "react";
import { StarIcon } from "@heroicons/react/solid";
import { auth } from "../../api";
import { getAppoimentById } from "../../api/getAppoiments.api";
import { UserContext } from "../../providers/UserProvider";
import { getReviewByApp } from "../../api/getReviews.api";
import { useParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ReviewSedinta = (props) => {
  const { id } = useParams();
  const [comment, setComment] = useState("");
  const [review, setReview] = useState();

  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken2) {
        console.log(id);
        getReviewByApp(id, idToken2).then((res) => {
          setReview(res.review);
        });
      });
    const comm =
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\n";
    setComment(comm);
  }, []);

  return (
    <>
      {!review ? (
        ""
      ) : (
        <>
          <div>
            <div className="mb-3">
              <p className="text-lg font-bold text-dark-blue font-openSans">
                Review Sedinta
              </p>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg h-full font-openSans text-sm">
              <div className="px-4 py-5 lg:p-10">
                <div>
                  <div className="mt-1 flex items-center">
                    <p className="text-lg text-dark-blue font-bold text-base font-raleway">
                      Review
                    </p>
                    <div>
                      <div className="ml-1 flex items-center">
                        {[0, 1, 2, 3, 4].map((rating) => (
                          <StarIcon
                            key={rating}
                            className={classNames(
                              review.ratingScore > rating
                                ? "text-primary"
                                : "text-gray-300",
                              "flex-shrink-0 h-5 w-5"
                            )}
                            aria-hidden="true"
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 text-dark-blue font-raleway">
                    <p>Data: {review.date}</p>
                  </div>
                  <div className="mt-5">
                    <p>{review.comment}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ReviewSedinta;
