import React from "react";
import { Link } from "react-router-dom";

const BackWithText = ({ title, services, background }) => {
  return (
    <section
      className={`bg-secondary ${
        background === "white"
          ? "lg:bg-white"
          : background === "body-g"
          ? "lg:bg-body-g"
          : ""
      }  lg:bg-back2 bg-no-repeat bg-cover bg-center lg:px-32 lg:pt-20`}
    >
      <div className="sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto px-4 pt-10 pb-10 lg:pt-52 lg:pb-28 xl:pt-60 xl:pb-60 2xl:pt-28rem 2xl:pb-60 text-center">
        <h2 className="text-center text-2xl sm:text-3xl xl:text-5xl font-ralewayBold text-white uppercase pb-6 sm:pb-12">
          {title}
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 text-lg">
          <div className="bg-white p-10 2xl:p-10 rounded-3xl flex flex-col items-center sm:basis-45% lg:basis-21% justify-center">
            Clinica virtuală Solution Talk îți oferă prin platforma sa
            posibilitatea de a vorbi cu un psihoterapeut sau cu un psihiatru,
            prin internet, din confortul casei tale sau de oriunde te-ai afla.
            Te poți conecta ușor de pe orice dispozitiv conectat la internet:
            smartphone, tabletă, laptop, desktop.
          </div>
          <div className="bg-white p-10 2xl:p-10 rounded-3xl flex flex-col items-center sm:basis-45% lg:basis-21% justify-center">
            {" "}
            Ca să fii sigur că vei discuta cu un profesionist care te va ajuta
            să găsești soluții eficiente, toți psihoterapeuții și medicii
            psihiatri cu care lucrăm sunt atent selectați pentru experiența și
            abilitățile lor în domeniu, sunt atestați de către Colegiul
            Psihologilor și Colegiul Medicilor și sunt susținuți să se
            perfecționeze continuu.
          </div>
        </div>
        <Link
          to="/cum-functioneaza"
          className="mt-10 whitespace-nowrap inline-flex items-center uppercase font-ralewayBold justify-center px-14 py-3 border border-transparent rounded-full shadow-lg  text-white bg-primary hover:bg-primary-hover"
        >
          Citeste mai mult
        </Link>
      </div>
    </section>
  );
};

export default BackWithText;
