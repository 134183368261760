import React, { createRef, useEffect, useState } from "react";
import HeaderClient from "../../../components/Header/HeaderClient";
import avatar from "../../../assets/img/Elena_Ionescu.jpg";
import { Link } from "react-router-dom";
import { auth } from "../../../api";

import { LoadingComponent } from "../../../components";
import { getCompanies } from "../../../api/getSpecialist.api";
import HeaderAdmin from "../../../components/Header/HeaderAdmin";

const SpecialistiAdmin = () => {
  const [pastAppointments, setPastAppoiments] = useState([]);
  const [futureAppointments, setFutureAppointments] = useState([]);
  const [showPopup, setShowPopup] = useState();
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [selectedAppointmentDate, setSelectedAppointmentDate] = useState();
  const [loadingPast, setLoadingPast] = useState(true);
  const [clients, setClients] = useState();
  const [filterByName, setFilterByName] = useState();
  var popupRef = createRef();
  const numbers = [5, 4, 3, 2, 1];

  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getCompanies(idToken).then((res) => {
          setClients(res.companies);
          setLoadingPast(false);
        });
      });
  }, []);

  return (
    <>
      <HeaderAdmin />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="mb-3 block grid grid-cols-1 xl:grid-cols-7">
          <h4 className="hidden 2xl:block col-span-4 2xl:col-span-3 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Companii platforma
          </h4>
        </div>
        <div className="2xl:grid  grid-cols-1 2xl:grid-cols-7 gap-0 2xl:gap-5 mb-10 text-sm">
          <h4 className="block 2xl:hidden col-span-4 2xl:col-span-6 font-openSans show-review text-dark-blue pt-4 text-lg flex items-center gap-4 font-bold">
            Companii platforma
          </h4>
          <div className=" pt-5 2xl:pt-0 h-auto col-span-4 2xl:col-span-7 flex flex-col overflow-hidden  items-start gap-4">
            {loadingPast ? (
              <LoadingComponent />
            ) : (
              <>
                <ul className="hidden sm:grid grid-cols-12 w-full bg-gray-inactive-bg text-dark-blue font-ralewayBold text-xs py-3.5 pl-3 sm:pl-9 pr-2">
                  <li className="col-span-1">NR.</li>
                  <li className="col-span-2">NUME</li>
                  <li className="col-span-2">ID COMPANIE</li>
                  <li className="col-span-2">LOCATIE</li>
                  <li className="col-span-2">COD</li>
                  <li className="col-span-2">APROBAT</li>
                  <li className="col-span-1">VEZI</li>
                </ul>
                {!clients
                  ? ""
                  : clients
                      .filter((cp) => {
                        return !cp.deleted;
                      })
                      .map((cl, index) => (
                        <>
                          <ul className=" hidden sm:grid grid-cols-12 w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                            <li className="col-span-1">{index + 1}.</li>
                            <li className="col-span-2">
                              {cl.lastName + " " + cl.firstName}
                            </li>
                            <li className="col-span-2">
                              {cl.publicId ? cl.publicId : "-"}
                            </li>
                            <li className="col-span-2">{cl.city}</li>
                            <li className="col-span-2">{cl.publicId}</li>
                            <li className="col-span-2">
                              {!cl.approved ? "NU" : "DA"}
                            </li>
                            <li className="col-span-1">
                              <Link
                                to={
                                  "/dashboard/admin/companie/" +
                                  cl.publicId +
                                  "/afisare-companie"
                                }
                              >
                                VEZI
                              </Link>
                            </li>
                          </ul>
                          <div className=" flex flex-col sm:hidden gap-1  w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                            <div className=" grid grid-cols-2">
                              <span className="font-ralewayBold">NR.:</span>
                              {index + 1}.
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">NUME</span>
                              {cl.lastName + cl.firstName}
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">
                                ID COMPANIE
                              </span>
                              {cl.companyId ? cl.companyId : "-"}
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">LOCATIE</span>
                              {cl.city}
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">COD</span>
                              {cl.publicId}
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">APROBAT</span>
                              {!cl.approved ? "NU" : "DA"}
                            </div>
                            <Link
                              to={
                                "/dashboard/admin/companie/" +
                                cl.publicId +
                                "/afisare-companie"
                              }
                            >
                              <div className="grid grid-cols-2 mt-2">
                                <span className="font-ralewayBold">
                                  VEZI AICI
                                </span>
                              </div>
                            </Link>
                          </div>
                        </>
                      ))}
              </>
            )}
          </div>
        </div>
        <div
          className={
            (!showPopup ? "hidden " : "") +
            "w-80 h-80 fixed inset-0 m-auto z-50"
          }
          ref={popupRef}
        >
          <div className="bg-white overflow-hidden shadow rounded-lg h-full w-full">
            <div className="px-4 py-5 sm:px-6 sm:py-4 bg-dark-blue relative">
              <p className="text-white font-openSans font-bold">
                Anulare programare
              </p>
              <p
                onClick={(e) => {
                  setShowPopup(false);
                }}
                className="text-white cursor-pointer w-fit absolute top-2.5 right-2.5"
              >
                x
              </p>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <p className="font-openSans text-xl text-dark-blue font-bold">
                Doriti sa continuati anularea programarii?
              </p>

              <button
                className="w-3/5 flex mx-auto mt-5 justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                type="button"
              >
                Anuleaza programarea
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialistiAdmin;
