import React from "react";
import { useEffect } from "react";

const DocumenteStudii = (props) => {
  useEffect(() => {}, [props.storageDocuments, props]);

  return (
    <>
      {!props.storageDocuments || props.storageDocuments.length == 0
        ? ""
        : props.storageDocuments.map((doc) => (
            <div className="mt-2">
              <a target={"_blank"} href={doc.url}>
                {doc.name}
              </a>
            </div>
          ))}
    </>
  );
};

export default DocumenteStudii;
