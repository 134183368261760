import React from "react";
import { Header, Footer } from "../../../components";

const PoliticaCookies = () => {
  return (
    <>
      <Header />
      <div className="px-10 mb-20">
        <section className="mt-20">
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h1 className="text-4xl text-center">POLITICA COOKIES</h1>

              <p className="text-base text-center mt-5">
                Versiune în vigoare la data de 04.10.2022
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">I. Cine suntem noi?</h2>
            </div>
            <div>
              <p className="mt-2">
                Website-ul https://solutiontalk.ro/ (în continuare ”Website-ul”)
                este proprietatea SOLUTION TALK S.R.L., persoană juridică
                română, cu sediul în București, Str. Smaranda Brăescu, nr. 40,
                Bl. 20H, Sc. 1, Et. 2, Ap. 6, Sector 1, înmatriculată la ORC sub
                nr. J40 /2852 /2022, cod fiscal 45650372, e-mail
                contact@solutiontalk.ro (în continuare ” SOLUTION TALK”, ”noi”,
                ”a noastră”).
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                II. Scopul și aplicabilitatea acestei Politici{" "}
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Website-ul utilizează module Cookies și alte tehnologii similare
                în vederea satisfacerii următoarelor scopuri :<br></br>• pentru
                a asigura funcționarea Website-ului,
                <br></br>• în scop de analiză, respectiv pentru a înțelege modul
                în care Utilizatorii folosesc Website-ul, astfel încât să îl
                putem face mai intuitiv, să îi optimizăm Conținutul, să
                îmbunătățim experiența de navigare pe Website precum și să putem
                măsura și îmbunătăți performanțele Website-ului;
                <br></br>• în scop de securitate, siguranță și pentru prevenirea
                fraudelor;
                <br></br>• pentru a înțelege, diagnostica și remedia problemele
                Website-ului;
                <br></br>• pentru a ne aminti preferințele dvs.;
                <br></br>• în scop de marketing, respectiv pentru a direcționa
                către dvs. publicitatea realizată pe acest Website, pe alte
                Website-uri sau aplicații, care este de interes pentru dvs. și
                pe care am identificat-o pe baza serviciilor pe care le-ați
                căutat și accesat;
                <br></br>• pentru evaluarea impactului campaniilor publicitare
                pe care le facem;
                <br></br>• Cookie-urile plasate de terți pot fi utilizate de
                aceștia și în scopul creării de profile ale utilizatorilor, în
                baza cărora vi se va livra publicitate personalizată (reclame
                targetate);
              </p>
              <p className="mt-2">
                Această politică explică ce sunt modulele Cookies, modul în care
                SOLUTION TALK utilizează module Cookies și tehnologii similare,
                inclusiv modulele Cookies de tipul ”terță parte” pe care le
                utilizăm, opțiunile pe care le aveți cu privire la Cookies și
                alte informații relevante. Orice informații suplimentare pot fi
                obținute utilizând datele din secțiunea ”Contactați-ne” de mai
                jos.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                III. Ce sunt modulele Cookies?
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Cookie înseamnă un fișier text de mici dimensiuni care este
                descărcat pe echipamentul dvs. terminal (cum ar fi, de exemplu,
                un computer sau un smartphone) atunci când accesați un site web
                și care permite site-ului web să vă recunoască dispozitivul și
                să stocheze anumite informații despre preferințele sau istoricul
                căutărilor dvs.
              </p>
              <p className="mt-2">
                Cookie-urile nu reprezintă în toate cazurile date cu caracter
                personal.
              </p>
              <p className="mt-2">
                Modulele Cookie pot fi: <br></br>a) “persistente” sau “de
                sesiune”. Cookie-urile persistente rămân în terminalul
                Utilizatorului până când expiră sau până sunt șterse manual de
                Utilizator în timp ce Cookie-urile de sesiune expiră la
                închiderea browser-ului web.
                <br></br>b) ”primare (first-party)” sau ”de terță parte
                (third-party)”. Cookie-urile primare sunt plasate de către
                Website în timp ce Cookie-urile de terță parte sunt plasate de
                un alt domeniu.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                IV. Cum folosim modulele Cookies?
              </h2>
            </div>

            <div>
              <p className="mt-2">
                Website-ul poate plasa module Cookies proprii sau poate utiliza
                Cookies de terță parte. Tabelul de mai jos explică cum utilizăm
                module Cookies și de ce
              </p>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  4.1. Cookies necesare pentru funcționarea Website-ului
                </h2>
              </div>
              <table className="my-4">
                <tr>
                  <td className="border border-slate-300 p-4">Denumire</td>
                  <td className="border border-slate-300 p-4">
                    Tip (First/ Third Party), inclusiv furnizorul{" "}
                  </td>
                  <td className="border border-slate-300 p-4">Scop</td>
                  <td className="border border-slate-300 p-4">Durată</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 p-4">__stripe_mid</td>
                  <td className="border border-slate-300 p-4">Necessary</td>
                  <td className="border border-slate-300 p-4">
                    Stripe sets this cookie cookie to process payments.
                  </td>
                  <td className="border border-slate-300 p-4">1 an</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 p-4">__stripe_sid</td>
                  <td className="border border-slate-300 p-4">Necessary</td>
                  <td className="border border-slate-300 p-4">
                    Stripe sets this cookie cookie to process payments.
                  </td>
                  <td className="border border-slate-300 p-4">30 minute</td>
                </tr>
              </table>
              <div>
                <h2 className="text-xl mt-5 font-bold">
                  4.2. Cookies funcționale
                </h2>
              </div>
              <table className="my-4">
                <tr>
                  <td className="border border-slate-300 p-4">Denumire</td>
                  <td className="border border-slate-300 p-4">
                    Tip (First/ Third Party), inclusiv furnizorul{" "}
                  </td>
                  <td className="border border-slate-300 p-4">Scop</td>
                  <td className="border border-slate-300 p-4">Durată</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 p-4">m</td>
                  <td className="border border-slate-300 p-4">Altele</td>
                  <td className="border border-slate-300 p-4">
                    Fara descriere
                  </td>
                  <td className="border border-slate-300 p-4">2 ani</td>
                </tr>
              </table>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                V. Care sunt opțiunile dvs. privind Cookies?
              </h2>
            </div>

            <div>
              <p className="mt-2">
                Ca regulă generală, SOLUTION TALK va utiliza module Cookies în
                baza consimțământului dvs ., ceea ce înseamnă că aveți opțiunea
                de a accepta modulele Cookies sau de a le refuza. Cu toate
                acestea, pentru acele fișiere Cookies sau alte tehnologii
                similare care sunt absolut necesare în scopul furnizării
                serviciilor solicitate de dvs., nu vă vom cere consimțământul .
                Consimțământul dvs. expiră după 6 luni , astfel încât după
                această perioadă vă vom afișa din nou bannerul Cookies pentru a
                vă actualiza preferințele în materie de Cookies.
              </p>

              <p className="mt-2">
                De asemenea, dvs. vă puteți schimba preferințele în materie de
                Cookies oricând, prin setarile din bara de jos.
              </p>
              <p className="mt-2">
                În mod alternativ, vă puteți seta browserul să refuze toate
                Cookie-urile. Totuși, trebuie să aveți în considerare că, dacă
                dezactivați modulele Cookies, este posibil să nu vă puteți salva
                preferințele sau ca anumite secțiuni sau funcții ale
                Website-ului să nu funcționeze.
              </p>
              <p className="mt-2">
                Mai jos găsiți mai multe informații despre cum să dezactivați
                modulele Cookies sau cum să gestionați setările modulelor
                Cookies pentru browserul pe care îl utilizați:
                <br></br>• Google Chrome
                <br></br>• Microsoft Edge
                <br></br>• Mozilla Firefox
                <br></br>• Microsoft Internet Explorer
                <br></br>• Opera
                <br></br>• Apple Safari
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">
                VI. Unde puteți găsi mai multe informații despre Cookies?
              </h2>
            </div>
            <div>
              <p className="mt-2">
                Mai multe informații despre Cookies găsiți la
                www.aboutcookies.org sau www.allaboutcookies.org.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px">
            <div>
              <h2 className="text-2xl mt-5 font-bold">VII. Contactați-ne</h2>
            </div>
            <div>
              <p className="mt-2">
                Pentru orice întrebări în legătură cu prezenta Politică Cookies,
                ne puteți contacta utilizând următoarele date de contact:
                <br></br>SOLUTION TALK S.R.L.
                <br></br>sediu: București, Str. Smaranda Brăescu, nr. 40, Bl.
                20H, Sc. 1, Et. 2, Ap. 6, Sector 1<br></br>Nr. ORC: J40 /2852
                /2022
                <br></br>Cod fiscal 45650372
                <br></br>E-mail contact@solutiontalk.ro
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default PoliticaCookies;
