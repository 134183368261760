import React, { useContext, useEffect, useState } from "react";
import { Footer, Header, Seo } from "../../components";
import Hero from "../../components/Hero/Hero";
import { Link } from "react-router-dom";
import desprenoi from "../../assets/imgdesprenoi/image_despre_noi.png";
import { UserContext } from "../../providers/UserProvider";
import ContentImage from "../../components/ContentImage/ContentImage";
import mission from "../../assets/imgdesprenoi/mission.jpg";

import hourglass from "../../assets/homepage/hourglass_orange.svg";
import { getSpecialistsHp } from "../../api/getSpecialist.api";
import { StarIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const services = [
  {
    image: hourglass,
    title: "OPTIMISMUL",
    paragraph:
      "Prima valoare este optimismul, credem că oamenii se pot schimba și că au resursele necesare să o facă.",
  },
  {
    image: hourglass,
    title: "CORECTITUDINEA",
    paragraph:
      "Corectitudinea ar fi a doua, doar trăind corect în toate aspectele vieții vei putea să trăiești în armonie cu ceilalți, vei construi lucruri de calitate și te vei simți bine în pielea ta.",
  },
  {
    image: hourglass,
    title: "EFICIENȚA",
    paragraph:
      "Eficiența ne caracterizează și ea, ne place să fim eficienți, să avem rezultate concrete și satisfăcătoare.",
  },
  {
    image: hourglass,
    title: "CREATIVITATEA",
    paragraph:
      "Lista valorilor noastre este mai lungă, dar ultima pe care o vom aminti aici este creativitatea, pentru că o viață trăită frumos se clădește și cu ajutorul ei, ne dezvoltăm ca oameni, fiind creativi.",
  },
];

const DespreNoi = () => {
  const { userType } = useContext(UserContext);
  const [specialists, setSpecialists] = useState();
  useEffect(() => {
    getSpecialistsHp().then((res) => setSpecialists(res.specialists));
  }, []);
  return (
    <>
      <Seo
        title="Psihoterapie Online | Despre Noi - Solution Talk"
        description="Solution Talk este o clinică virtuală de psihoterapie unde găsești în principal servicii de psihoterapie, dar și servicii de psihiatrie. Programează-te online!"
      />
      <Header />
      <Hero
        title="Despre Noi"
        background="desprenoi"
        image={desprenoi}
        imgback={true}
      />
      <ContentImage
        image={mission}
        elipse={true}
        title={"Hai să îți spunem câte ceva despre noi."}
        link="/register"
        text_link="Fa-ti cont gratuit"
        paragraph={
          <>
            <p>
              Solution Talk este o clinică virtuală de psihoterapie unde găsești
              în principal servicii de psihoterapie, dar și servicii de
              psihiatrie. Noi, cei care am creat acest proiect, suntem
              psihoterapeuți și apariția Solution Talk a venit în mod firesc, ca
              o continuare a evoluției noastre în acest domeniu plin de
              recompense, în care ne considerăm privilegiați că putem ajuta
              oamenii să își trăiască viața așa cum și-au dorit dintotdeauna.
              <br></br>
              <br></br>
              Suntem pasionați de psihoterapie și ne-am dedicat tot timpul și
              energia doar acestui domeniu. După ani de experiență și momente
              magice în care cei pe care i-am însoțit pe drumul schimbării au
              reușit să ajungă acolo unde și-au dorit, și nu doar să viseze cum
              ar fi dacă ar ajunge acolo, vrem să continuăm pe acest drum și să
              ajutăm cât mai mulți oameni să își îndeplinească visurile. Aceasta
              este misiunea noastră, să ajutăm un număr cât mai mare de oameni
              să trăiască o viață împlinită. <br></br>
              <br></br>Ca să ne putem îndeplini această misiune, ne vom ghida
              după valorile care ne reprezintă și care ne-au ajutat să evoluăm.
            </p>
          </>
        }
      />
      <section className=" bg-light-blue sm:bg-body-g sm:bg-back-valori bg-cover mt-10 sm:mt-28 bg-center px-10 sm:px-20 xl:px-52">
        <div className="sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto  pb-8 xl:pb-48 pt-10 sm:pt-52 xl:pt-80">
          <h2 className="text-center text-2xl sm:text-3xl xl:text-5xl font-ralewayBold text-white uppercase pb-6 sm:pb-12">
            Valorile Noastre
          </h2>
          <div className=" pt-6 pb-4 sm:pb-0 sm:pt-10  flex flex-col items-center">
            <div className="flex flex-wrap  pb-10 sm:pb-20 gap-y-2 sm:gap-y-4  lg:gap-y-11 ">
              {services.map((service, index) => (
                <div
                  className="flex basis-full mx-auto sm:basis-1/2 lg:basis-1/3 xl:basis-1/4 px-4"
                  key={index}
                >
                  <div>
                    <h4 className="text-xl sm:text-2xl font-ralewaySemibold text-white uppercase pb-1">
                      {" "}
                      {service.title}
                    </h4>
                    <p className="text-sm font-ralewaySemibold text-white 2xl:pr-14">
                      {" "}
                      {service.paragraph}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className=" pt-10 sm:pt-10 py-20 pb-10 px-10 sm:px-20 xl:px-52">
        <div className="flex mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px flex-col items-center">
          <h2 className="text-center text-2xl sm:text-3xl xl:text-5xl font-ralewayBold text-secondary uppercase pb-6 sm:pb-12">
            Vezi specialisti
          </h2>
          <div className=" flex pb-10 sm:pb-20 flex-wrap gap-4 sm:gap-0">
            {!specialists
              ? ""
              : specialists
                  .filter((sp) => {
                    return sp.approved;
                  })
                  .slice(0, 3)
                  .map((item, index) => (
                    <div
                      className=" basis-full lg:basis-1/3 sm:px-4"
                      key={index}
                    >
                      <div
                        className="flex flex-col rounded-3xl  shadow-lg pt-6 sm:pt-14 pb-12 px-6 2xl:px-6 items-start"
                        key={index}
                      >
                        <div className="pb-4 sm:pb-8 gap-2.5 items-center w-full">
                          <div className="rounded-full w-full h-80">
                            <img
                              src={item.avatarUrl}
                              className="w-full h-full object-center object-cover"
                            />
                          </div>
                          <div className="flex flex-col">
                            <h4 className="font-ralewayBold pt-9 pb-1 text-xl sm:text-3xl text-secondary">
                              {item.lastName + " " + item.firstName}
                            </h4>
                            <h3 className="font-ralewayRegular text-dark-blue text-sm sm:text-xl">
                              {item.specialistInfo.specializationType}
                            </h3>
                          </div>
                        </div>
                        <p className="font-ralewayRegular text-dark-blue text-sm sm:text-base  pb-2 sm:pb-7">
                          {item.description.slice(0, 150) + "[...]"}
                        </p>
                        <h3 className="text-lg text-dark-blue font-ralewayBold pb-2">
                          Specializari
                        </h3>
                        <div className="flex flex-wrap gap-1 sm:gap-3">
                          {item.specialistInfo.specializations.map(
                            (itm, ind) => (
                              <p
                                className=" text-xs font-ralewayMedium text-dark-blue px-2.5 sm:px-5 py-2 sm:py-3.5 bg-light-orange-w rounded-full"
                                key={ind}
                              >
                                {itm}
                              </p>
                            )
                          )}
                        </div>
                        <h3 className="mt-3 text-lg text-dark-blue font-ralewayBold pb-2">
                          Rating
                        </h3>
                        <div className="flex gap-2 ">
                          {[0, 1, 2, 3, 4].map((rating) => (
                            <StarIcon
                              key={rating}
                              className={classNames(
                                item.reviewsAverage > rating
                                  ? "text-primary"
                                  : "text-gray-300",
                                "flex-shrink-0 h-5 w-5"
                              )}
                              aria-hidden="true"
                            />
                          ))}
                        </div>
                        <div className="flex justify-between gap-2 flex-col sm:flex-row lg:flex-col 2xl:flex-row"></div>
                      </div>
                    </div>
                  ))}
          </div>
          {!userType ? (
            <Link
              to="/login"
              className="mb-10 whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm sm:text-xl px-4 sm:px-14 py-4 border border-light-orange text-dark-blue rounded-full shadow-lg duration-300 text-white hover:bg-light-orange hover:text-white"
            >
              Vezi toti specialistii
            </Link>
          ) : (
            <Link
              to="/dashboard/client/afisare-specialisti"
              className="mb-10 whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm sm:text-xl px-4 sm:px-14 py-4 border border-light-orange text-dark-blue rounded-full shadow-lg duration-300 text-white hover:bg-light-orange hover:text-white"
            >
              Vezi toti specialistii
            </Link>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};
export default DespreNoi;
