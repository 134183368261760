import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import {
  getCompanyById,
  getSpecialistById,
} from "../../../api/getSpecialist.api";
import { auth } from "../../../api";
import HeaderAdmin from "../../../components/Header/HeaderAdmin";

const VeziProfilSpecialist = () => {
  const [specialist, setSpecialist] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    street: "",
    streetNr: "",
    building: "",
    staircase: "",
    apartment: "",
    videoUrl: "",
    description: "",
    companyInfo: {
      companyName: "",
      companyCUI: "",
      companyRegNr: "",
      companyTVACode: "",
      companyCity: "",
      companyRegion: "",
      companyEmail: "",
      companyStreetNr: "",
      companyBuilding: "",
      companyStaircase: "",
      companyApartment: "",
      companyStreet: "",
    },
    specialistInfo: {
      specializationType: "",
      specializationName: "",
      yearsExperience: "",
      collegeCode: "",
      license: "off",
      masters: "off",
      doctorate: "off",
      university: "",
      otherCourses: "",
      lastStudies: "",
      price: "",
      companyDiscount: "",
      specializations: [],
    },
  });
  const { id } = useParams();

  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getCompanyById(idToken, id).then((res) => {
          setSpecialist(res.company);
        });
      });
  }, []);
  return (
    <>
      <HeaderAdmin />

      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-2 sm:gap-5 xl:gap-20 mb-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:w-3/5">
            <Link to="/dashboard/admin/companii">
              <button className="w-full flex justify-center py-4 px-2 border rounded-md shadow-sm text-xs border-primary text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                Inapoi pagina de Companii
              </button>
            </Link>
          </div>
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-5 lg:w-3/5 lg:float-right">
              <div></div>
              <div>
                <div className="my-auto">
                  <div className="lg:mt-2 mb-1 lg:m-0">
                    <Link
                      to={
                        "/dashboard/admin/companie/" +
                        specialist.publicId +
                        "/afisare-companie"
                      }
                      className="w-full flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                    >
                      Vezi angajati
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 2xl:grid-cols-2 gap-5">
          <div className="font-openSans text-secondary flex flex-col flex-nowrap h-full ">
            <div className="mb-3">
              <p className="text-lg font-bold">Despre Companie</p>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg h-full">
              <div className="px-4 py-5 sm:p-6">
                <div className="grid grid-cols-1 lg:grid-cols-4">
                  <div className="col-span-1 text-center">
                    <div
                      className="flex-shrink-0 inline-block rounded-full overflow-hidden h-44 w-44"
                      aria-hidden="true"
                    >
                      <img
                        className="rounded-full h-full w-full border-primary p-2 border-2 object-cover"
                        src={specialist.avatarUrl}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div>
                      <div className="flex justify-between">
                        <div>
                          <p className="text-3xl text-secondary font-bold mb-4">
                            {specialist.lastName + " " + specialist.firstName}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Email: " + specialist.email}
                          </p>
                          <p className="text-base text-gray-primary font-normal mb-2">
                            {"Telefon: " + specialist.phone}
                          </p>
                          <p className="text-xl text-secondary font-bold mb-2 mt-4">
                            {"Adresa"}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Judet: " + specialist.region}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Localitate: " + specialist.city}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Strada: " + specialist.street}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Nr.: " + specialist.streetNr}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Bloc: " + specialist.building}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Scara: " + specialist.staircase}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Apartament: " + specialist.apartment}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="font-openSans text-secondary flex flex-col flex-nowrap h-full ">
            <div className="mb-3">
              <p className="text-lg font-bold">Date firma</p>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg h-full">
              <div className="px-4 py-5 sm:p-6">
                <div className="grid grid-cols-1 lg:grid-cols-4">
                  <div className="col-span-3">
                    <div>
                      <div className="flex justify-between">
                        <div>
                          <p className="text-base text-gray-primary font-normal">
                            {"Nume: " +
                              (specialist.companyInfo
                                ? specialist.companyInfo.companyName
                                  ? specialist.companyInfo.companyName
                                  : ""
                                : "")}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"CUI: " +
                              (specialist.companyInfo
                                ? specialist.companyInfo.companyCUI
                                  ? specialist.companyInfo.companyCUI
                                  : ""
                                : "")}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Nr. de inregistrare: " +
                              (specialist.companyInfo
                                ? specialist.companyInfo.companyRegNr
                                  ? specialist.companyInfo.companyRegNr
                                  : ""
                                : "")}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Cod TVA: " +
                              (specialist.companyInfo
                                ? specialist.companyInfo.companyTVACode
                                  ? specialist.companyInfo.companyTVACode
                                  : ""
                                : "")}
                          </p>
                          <p className="text-xl text-secondary font-bold mb-2 mt-4">
                            {"Adresa"}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Judet: " +
                              (specialist.companyInfo
                                ? specialist.companyInfo.companyRegion
                                  ? specialist.companyInfo.companyRegion
                                  : ""
                                : "")}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Localitate: " +
                              (specialist.companyInfo
                                ? specialist.companyInfo.companyCity
                                  ? specialist.companyInfo.companyCity
                                  : ""
                                : "")}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Strada: " +
                              (specialist.companyInfo
                                ? specialist.companyInfo.companyStreet
                                  ? specialist.companyInfo.companyStreet
                                  : ""
                                : "")}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Nr.: " +
                              (specialist.companyInfo
                                ? specialist.companyInfo.companyStreetNr
                                  ? specialist.companyInfo.companyStreetNr
                                  : ""
                                : "")}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Bloc: " +
                              (specialist.companyInfo
                                ? specialist.companyInfo.companyBuilding
                                  ? specialist.companyInfo.companyBuilding
                                  : ""
                                : "")}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Scara: " +
                              (specialist.companyInfo
                                ? specialist.companyInfo.companyStaircase
                                  ? specialist.companyInfo.companyStaircase
                                  : ""
                                : "")}
                          </p>
                          <p className="text-base text-gray-primary font-normal">
                            {"Apartament: " +
                              (specialist.companyInfo
                                ? specialist.companyInfo.companyApartment
                                  ? specialist.companyInfo.companyApartment
                                  : ""
                                : "")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VeziProfilSpecialist;
